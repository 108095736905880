import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { CustomPasswordInput } from "components/custom";
import { PhoneValidationSchema } from "validations/auth";
import { useFormik } from "formik";
import { PrimaryButton } from "components/buttons";
import { useNavigate } from "react-router";
import { IconButton } from "@chakra-ui/react";

export const ExporterDisableTwoFacMobile = () => {
  const navigate = useNavigate();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { password: "" },
    validationSchema: PhoneValidationSchema(),
    onSubmit: () => {},
  });

  return (
    <div>
      <h5
        className='fw-semibold fs-20 text-grey-900 py-4 d-flex align-items-center gap-5 justify-content-center w-100'
        style={{ position: "relative" }}
      >
        <div style={{ position: "absolute", left: 0 }}>
          <IconButton
            aria-label='Back'
            icon={<BackIcon />}
            onClick={() => window.history.back()}
            border={"none"}
            bg={"transparent"}
          />
        </div>
        Disable 2FA?
      </h5>
      <p className='text-grey-500 fs-12 fw-400 text-center mb-5'>To continue, please enter your password</p>
      <form className='mb-2 w-80 mx-auto d-flex flex-column align-items-center'>
        <CustomPasswordInput
          name='password'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          error={Boolean(touched.password && errors?.password)}
          bottomText={errors?.password}
        />
        <PrimaryButton
          className='btn-lg primary-btn mt-5 mx-auto'
          onClick={() => {
            navigate("/exporter/settings", { replace: true });
          }}
        >
          Deactivate 2FA
        </PrimaryButton>
      </form>
    </div>
  );
};
