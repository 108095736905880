import { FC, useEffect, useState } from "react";
import ProfileIcon from "assets/svg/profile-circle.svg";
import chile from "assets/images/Chile.png";
import finland from "assets/images/Finland.png";
import denmark from "assets/images/Denmark.png";
import { useWindowWidth } from "utilities/windowWidth";
import { CampaignData, ExporterProfileStats, useGetAllCampaignsQuery } from "store/campaigns";
import { BackerCampaignItem } from "../components";

interface ExporterProfileProps {
  exporterProfileData: ExporterProfileStats;
}

export const BackerExporterProfile: FC<ExporterProfileProps> = ({ exporterProfileData }) => {
  const windowWidth = useWindowWidth();

  const { data: campaigns } = useGetAllCampaignsQuery(
    {
      exporterId: exporterProfileData?._id,
    },
    {
      pollingInterval: 30000,
    }
  );

  console.log("data", exporterProfileData);
  const [countryFlags, setCountryFlags] = useState<string[]>([]);

  const countryCodes = exporterProfileData?.exportingCountries ?? [];
  useEffect(() => {
    const fetchFlags = async () => {
      const flags = await Promise.all(
        countryCodes.map(async (country) => {
          const countryName = country.includes("-") ? country.split("-")[1] : country;

          const response = await fetch(`https://restcountries.com/v3.1/name/${countryName}`);
          const data = await response.json();
          if (data[0]) {
            return data[0].flags.png;
          } else {
            return null;
          }
        })
      );
      setCountryFlags(flags.filter((flag) => flag !== null));
    };

    fetchFlags();
  }, []);

  return (
    <section className='row justify-content-between bg-transparent pb-5'>
      <div className='col-lg-10'>
        <div className={`border rounded-4 row align-items-center ${windowWidth <= 768 ? "p-4" : "py-4"}`}>
          <div className='col-lg-3'>
            {/* <ProfileImg width={windowWidth <= 768 ? "45px" : "128px"} /> */}
            <div>
              <img
                src={exporterProfileData.exporterImage || ProfileIcon}
                alt={"Avatar"}
                className='rounded rounded-circle'
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = ProfileIcon;
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
            </div>
          </div>
          <div className='col-lg-9'>
            <div className={windowWidth <= 768 ? "d-flex flex-row align-items-center gap-3 mb-0" : ""}>
              <div className='mb-3'>
                <h6 className={`${windowWidth <= 768 ? "fs-14 mb-2" : "fs-20 mb-0"} fw-600`}>
                  {exporterProfileData.exporterFullName}
                </h6>
                <p className='text-grey-500 mb-0 pt-2 text-capitalize'>{exporterProfileData.badge}</p>
              </div>

              <div className='row'>
                <div
                  className={`col-lg-3 ${
                    windowWidth <= 768 && "mb-3 d-flex flex-row-reverse gap-5 justify-content-end"
                  }`}
                >
                  <h6 className='fs-16 fw-600 text-grey-900'>{exporterProfileData.totalBackers}</h6>
                  <p className='fs-14 fw-400 text-grey-400 m-0'>Total Backers</p>
                </div>
                <div
                  className={`col-lg-3 ${
                    windowWidth <= 768 && "mb-3 d-flex flex-row-reverse gap-5 justify-content-end"
                  }`}
                >
                  <h6 className='fs-16 fw-600 text-grey-900'>{exporterProfileData.totalCampaigns}</h6>
                  <p className='fs-14 fw-400 text-grey-400  m-0'>Total Campaigns</p>
                </div>
                <div
                  className={`col-lg-3 ${
                    windowWidth <= 768 && "mb-3 d-flex flex-row-reverse gap-5 justify-content-end"
                  }`}
                >
                  <h6 className='fs-16 fw-600 text-grey-900'>{exporterProfileData.activeCampaigns}</h6>
                  <p className='fs-14 fw-400 text-grey-400 m-0'>Active Campaign</p>
                </div>
                <div
                  className={`col-lg-3 ${
                    windowWidth <= 768 && "mb-3 d-flex flex-row-reverse gap-5 justify-content-end"
                  }`}
                >
                  <div className='d-flex gap-1  align-items-center'>
                    {countryFlags.slice(0, 3).map((flag, index) => (
                      <img
                        key={index}
                        src={flag}
                        alt='Country flag'
                        className='rounded-circle'
                        style={{ width: "22px", height: "22px" }}
                      />
                    ))}
                    <div className='rounded-circle text-center bg-grey-100 px-2 py-2 text-grey-700 fs-8 fw-400'>
                      +{countryFlags.length > 3 ? countryFlags.length - 3 : 0}
                    </div>
                  </div>
                  <p className='fs-14 fw-400 text-grey-400 m-0'>Exporting Countries</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-5'>
          <p className={`fs-16 fw-600 text-grey-900 m-0 ${windowWidth <= 768 && "mb-3"}`}>All Campaigns</p>
        </div>
      </div>
      <div className='row mb-4'>
        {campaigns?.map((campaign: CampaignData, index: number) => (
          <div className='col-md-4' key={index}>
            <BackerCampaignItem campaign={campaign} />
          </div>
        ))}
      </div>
    </section>
  );
};
