import React from "react";
import { Dashboard } from "./components";
import { PrimaryButton } from "components/buttons";
import { Link } from "react-router-dom";
import { useBackerDashboardStatsQuery } from "store/campaigns";
import { DashboardStats } from "./components/interface";

export const BackerDashboardPage = () => {
  const { data, isLoading } = useBackerDashboardStatsQuery();
  const stats = data?.data as DashboardStats;
  console.log(stats);
  return (
    <section className="exporter-dashboard">
      <div className="d-flex flex-row justify-content-between align-items-center mb-4">
        <h3 className="fw-600">Dashboard</h3>
        <PrimaryButton className="btn-md primary-btn">
          <Link to="/backer/discover">Browse Campaigns</Link>
        </PrimaryButton>
      </div>
      <Dashboard data={stats} />
    </section>
  );
};
