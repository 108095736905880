import { useFormik } from "formik";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { ReactComponent as WarningIcon } from "assets/svg/password-warning.svg";
import { AlertDefaultBox, AuthHeaderText, CustomPasswordInput } from "components/custom";
import { PrimaryButton } from "components/buttons";
import "../../assets/css/forgot-password/forgot.scss";
import { ResetPasswordValidationSchema } from "validations/auth";
import { usePageNotificationProvider } from "providers";
import { ResetPayload, useResetMutation } from "store/auth";
import { resolveApiError } from "utilities";
import queryString from "query-string";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { email, oobcode } = queryString.parse(window.location.search) as {
    email: string;
    oobcode: string;
  };
  const [request, { isLoading }] = useResetMutation();
  const { initNotification } = usePageNotificationProvider();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      oobCode: oobcode,
      email: email,
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordValidationSchema(),
    onSubmit: () => initRequest(),
  });

  const initRequest = (payload?: ResetPayload) => {
    request({
      oobCode: oobcode,
      email,
      newPassword: values.newPassword,
    })
      .unwrap()
      .then((res: any) => {
        console.log(res, "jhgfdsaserty");
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "You have successfully reset your password.",
        });
        // navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  const isBtnDisabled = Boolean(errors.newPassword || errors.confirmPassword);

  return (
    <section className='container-fluid min-vh-100 py-md-5 py-4 d-flex justify-content-center align-items-center'>
      <div className='max-w-500 w-95'>
        <div className='d-flex flex-column justify-content-center align-items-center gap-5'>
          <Link to='/' className='d-flex flex-row gap-2 align-items-center'>
            <Logo />
          </Link>
          <form className='auth-form-wrapper w-100 border-grey-100' onSubmit={handleSubmit}>
            <div className='w-100'>
              <AuthHeaderText title='Reset Password' />
              {((errors.newPassword && touched.newPassword) ||
                (errors.confirmPassword && touched.confirmPassword)) && (
                <AlertDefaultBox
                  scheme='warning'
                  icon={<WarningIcon />}
                  body={errors.newPassword ?? errors.confirmPassword}
                />
              )}

              <div className='mb-4 mt-4'>
                <CustomPasswordInput
                  label='Password'
                  name='newPassword'
                  placeholder='********'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  error={Boolean(errors?.newPassword && touched.newPassword)}
                  // bottomText={errors?.newPassword || " "}
                />
              </div>
              <div className='mb-4'>
                <CustomPasswordInput
                  label='Confirm Password'
                  name='confirmPassword'
                  placeholder='********'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  error={Boolean(errors?.confirmPassword && touched.confirmPassword)}
                  // bottomText={errors?.confirmPassword || " "}
                />
              </div>
              <PrimaryButton
                type='submit'
                isDisabled={isBtnDisabled}
                isLoading={isLoading}
                className='btn-lg primary-btn mt-3'
              >
                Reset Password
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
