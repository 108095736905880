import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { useModalProvider } from "providers/modalProvider";
import { CustomPasswordInput } from "components";
import { useFormik } from "formik";
import { CreatePinValidationSchema } from "validations/auth";
import { ReactComponent as WalletFeaturedIcon } from "assets/svg/wallet-featured-icon.svg";

interface CreatePinModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const CreatePinModal: FC<CreatePinModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { pin: "", confirmPin: "" },
    validationSchema: CreatePinValidationSchema(),
    onSubmit: () => {},
  });
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, disableTwoFac: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Create PIN"}
        proceedButtonProps={{
          className: "btn-lg primary-btn w-50",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              createPin: false,
            }));
          },
        }}
        cancelButtonDefaultChild={"Cancel"}
        cancelButtonProps={{
          className: "btn-lg border ms-0 bg-white border-grey-300 w-50 text-grey-700",
          onClick: () => {
            initModal((prev) => ({ ...prev, createPin: false }));
          },
        }}
        useHeader={true}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className: "",
        }}
        title={""}
        size={"md"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 px-4 py-2" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className=''>
          <div className='text-center d-flex flex-column align-items-center gap-4'>
            <WalletFeaturedIcon />
            <div className=''>
              <h4 className='fs-18 fw-600 mb-2'>Create 4-Digit PIN</h4>
              <p className='fs-14 text-grey-500 fw-400 m-0'>PIN will be required for all transactions</p>
            </div>
            <form className='col-md-12 mb-4 d-flex flex-column gap-4'>
              <CustomPasswordInput
                label='Enter PIN'
                name='pin'
                placeholder='1234'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pin}
                error={Boolean(touched.pin && errors?.pin)}
                bottomText={errors?.pin}
              />
              <CustomPasswordInput
                label='Confirm PIN'
                name='confirmPin'
                placeholder='1234'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPin}
                error={Boolean(touched.confirmPin && errors?.confirmPin)}
                bottomText={errors?.confirmPin}
              />
            </form>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
