import { ReactComponent as ClockIcon } from "assets/svg/time-icon.svg";
import { ReactComponent as ProfileLight } from "assets/svg/profile-light.svg";
import { ProgressBar } from "components/custom";
import { CampaignItemProps } from "pages/backer/dashboard/components/interface";
import { Link } from "react-router-dom";
import { getDaysLeft } from "utilities";
import { getPercentage } from "utilities";
import defaultCampaignImg from "assets/images/viewcampaign.png";

export const BackerCampaignItem = ({ campaign }: CampaignItemProps) => {
  console.log(campaign);

  return (
    <Link
      className="text-decoration-none"
      to={`/backer/discover/${campaign._id}`}
    >
      <div className="mt-3 rounded-4 rounded-top-3 overflow-hidden shadow-sm border border-grey-100 campaign-item p-0 w-100 d-flex flex-column gap-2 bg-white">
        <div
          className="d-flex flex-column bg-white rounded-5 gap-1 w-100"
          style={{ height: "230px" }}
        >
          <img
            src={campaign.images[0] || defaultCampaignImg}
            alt="Campaign"
            className="w-100 h-100 object-fit-cover"
            onError={(e: any) => {
              e.target.onerror = null;
              e.target.src = defaultCampaignImg;
            }}
          />
        </div>

        <div className="py-3 border-bottom mx-4">
          <h6 className="fw-600 fs-20 text-black">{campaign.title}</h6>
          <div className="d-flex align-items-center my-3 gap-4 w-100">
            <p className="m-0 text-warning-700 bg-warning-50 rounded-5 fw-500 fs-12 py-1 px-2">
              Category/Tag
            </p>
            <div className="d-flex gap-2 align-items-center text-grey-700 bg-grey-50 fw-500 fs-12 rounded-5 py-1 px-2">
              <ClockIcon />
              <h6 className="fw-500 mb-0">
                {getDaysLeft(campaign.campaignEndDate)}
              </h6>
            </div>
          </div>
          <p className="text-grey-600 fw-400 fs-12 pb-3">
            {campaign.description.length > 100
              ? `${campaign.description.substring(0, 100)}...`
              : campaign.description}
          </p>

          <div className="loading-container pb-3 ">
            <ProgressBar
              value={getPercentage(
                Number(campaign?.fundingGoalString),
                Number(campaign?.amountRaisedString)
              )}
              width="100%"
            />
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center text-grey-900 fw-400">
            <p className="mb-0 fw-400 text-grey-900">
              ₦ {campaign.amountRaisedString} Raised
            </p>
            <p className="mb-0 fw-400 text-grey-900">
              ₦ {campaign.fundingGoalString} Goal
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 px-4 align-items-center mt-3 mb-4">
          <ProfileLight />
          <p className="fw-400 text-success-600 fs-14 m-0">
            {campaign.exporter.firstName}
            {""} {campaign.exporter.lastName}
          </p>
        </div>
      </div>
    </Link>
  );
};
