import React, { FC } from "react";

import { ReactComponent as DocumentIcon } from "assets/svg/document.svg";
import { PrimaryButton } from "components/buttons";
import { useWindowWidth } from "utilities/windowWidth";
import { CampaignData } from "store/campaigns";
import { Link } from "react-router-dom";

interface CampaignProps {
  campaignData: CampaignData | undefined;
}
export const BackerAboutCampaign: FC<CampaignProps> = ({ campaignData }) => {
  console.log("data", campaignData);
  const windowWidth = useWindowWidth();
  return (
    <div>
      <div className='pb-5'>
        <div className={`d-flex gap-5 ${windowWidth <= 768 && "flex-column-reverse"}`} id='description'>
          <div className='max-w-650'>
            <p className='text-grey-900 fs-14 fw-600'>Description</p>
            <p className='fw-400 fs-16 lh-base mb-5'>{campaignData?.description ?? "No description"}</p>
          </div>
        </div>

        <div id='country' className='mb-5'>
          <p className='text-grey-900 fw-600'>Exporting Country</p>
          <div className='d-flex gap-3'>
            <h5 className={`text-grey-900 ${windowWidth <= 768 ? "fs-14" : "fs-20"} fw-400`}>
              {campaignData?.exportingCountry}{" "}
            </h5>
          </div>
        </div>

        <div className='mt-5' id='risk-report'>
          <p className='text-grey-900 fw-600'>Risk Report</p>

          <div>
            {campaignData?.riskReport ? (
              <Link to={campaignData?.riskReport} target='__blank'>
                <PrimaryButton className='btn secondary-btn d-flex gap-2 p-4'>
                  <DocumentIcon />
                  View Risk Report
                </PrimaryButton>
              </Link>
            ) : (
              <p>No risk report uploaded</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
