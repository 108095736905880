import { SliderMenu } from "components/custom";
import { Switch } from "@chakra-ui/react";
import { useWindowWidth } from "utilities/windowWidth";
import { Box } from "@chakra-ui/react";
import { useUpdateNotificationSettingsMutation } from "store/notificationSettings";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities/errorHandling";

interface NotificationSettings {
  campaignUpdates: boolean;
  commentsOnCampaigns: boolean;
  systemAlerts: boolean;
  importantAnnouncements: boolean;
  inApp: boolean;
  email: boolean;
}

export const Notifications = ({
  activeMenu,
  setActiveMenu,
  notificationSettings,
  refetch,
}: {
  activeMenu: number;
  setActiveMenu: React.Dispatch<React.SetStateAction<number>>;
  notificationSettings: NotificationSettings;
  refetch: any;
}) => {
  const windowWidth = useWindowWidth();
  const { initNotification } = usePageNotificationProvider();
  const [updateNotificationSettings, { isLoading }] = useUpdateNotificationSettingsMutation();

  const handleUpdateSettings = async (value: any) => {
    const payload = {
      ...notificationSettings,
      ...value,
    };
    updateNotificationSettings(payload)
      .unwrap()
      .then((res: any) => {
        console.log(res);
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
        } else if (res) {
          refetch();
        }
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({
          message: resolveApiError(error),
          scheme: "error",
        });
      });
  };

  return (
    <div className='settings-content-wrapper'>
      <SliderMenu
        headings={["Security", "Notifications", "Verification Centre"]}
        setActiveMenu={setActiveMenu}
        activeMenu={activeMenu}
      />
      <div className='d-flex flex-column settings-content'>
        <div
          className={`d-flex ${
            windowWidth >= 768
              ? "flex-row border border-grey-100 gap-5 mt-3 mb-4 justify-content-start"
              : "flex-column gap-0"
          } align-items-start campaign-item`}
        >
          <div className={`d-flex flex-column gap-2 campaign-subitem ${windowWidth >= 768 && "w-40"}`}>
            <h6 className='fw-600 mb-1'>Notification Type</h6>
            <p className='text-grey-600 fw-400 fs-12'>
              Receive notifications for various activities to engage and track your campaigns
            </p>
          </div>
          <Box
            className={`d-flex flex-column fw-14 ${
              windowWidth <= 768 ? "bg-white w-100 px-4 rounded-lg" : "gap-4 "
            }`}
            rounded={"8px"}
          >
            <div
              className={`d-flex align-items-center gap-3 ${
                windowWidth <= 768
                  ? "flex-row-reverse justify-content-between border-bottom py-3"
                  : "justify-content-start"
              }`}
            >
              <Switch
                id='campaign-updates'
                colorScheme='brand-primary'
                size='lg'
                defaultChecked={notificationSettings?.campaignUpdates}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleUpdateSettings({
                    campaignUpdates: e.target.checked,
                  })
                }
              />
              <p className='mb-0'>Campaign updates</p>
            </div>
            <div
              className={`d-flex align-items-center gap-3 ${
                windowWidth <= 768
                  ? "flex-row-reverse justify-content-between border-bottom py-3"
                  : "justify-content-start"
              }`}
            >
              <Switch
                id='campaign-updates'
                colorScheme='brand-primary'
                size='lg'
                defaultChecked={notificationSettings?.commentsOnCampaigns}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleUpdateSettings({
                    commentsOnCampaigns: e.target.checked,
                  })
                }
              />
              <p className='mb-0'>Comments on campaigns</p>
            </div>
            <div
              className={`d-flex align-items-center gap-3 ${
                windowWidth <= 768
                  ? "flex-row-reverse justify-content-between border-bottom py-3"
                  : "justify-content-start"
              }`}
            >
              <Switch
                id='campaign-updates'
                colorScheme='brand-primary'
                size='lg'
                defaultChecked={notificationSettings?.systemAlerts}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleUpdateSettings({
                    systemAlerts: e.target.checked,
                  })
                }
              />
              <p className='mb-0'>System alerts</p>
            </div>
            <div
              className={`d-flex align-items-center gap-3 ${
                windowWidth <= 768 ? "flex-row-reverse justify-content-between py-3" : "justify-content-start"
              }`}
            >
              <Switch
                id='campaign-updates'
                colorScheme='brand-primary'
                size='lg'
                defaultChecked={notificationSettings?.importantAnnouncements}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleUpdateSettings({
                    importantAnnouncements: e.target.checked,
                  })
                }
              />
              <p className='mb-0'>Important announcements</p>
            </div>
          </Box>
        </div>
        <div
          className={`d-flex ${
            windowWidth >= 768 ? "flex-row border border-grey-100 gap-5" : "flex-column gap-0"
          } align-items-start campaign-item`}
        >
          <div className={`d-flex flex-column gap-2 campaign-subitem ${windowWidth >= 768 && "w-40"}`}>
            <h6 className='fw-600 mb-1'>Delivery channel</h6>
            <p className='text-grey-600 fw-400 fs-12'>
              Let us know where you would like to receive your notifications
            </p>
          </div>
          <Box
            className={`d-flex flex-column fw-14 ${
              windowWidth <= 768 ? "bg-white w-100 px-4 rounded-lg" : "gap-4 "
            }`}
            rounded={"8px"}
          >
            <div
              className={`d-flex align-items-center gap-3 ${
                windowWidth <= 768
                  ? "flex-row-reverse justify-content-between border-bottom py-3"
                  : "justify-content-start"
              }`}
            >
              <Switch
                id='campaign-updates'
                colorScheme='brand-primary'
                size='lg'
                defaultChecked={notificationSettings?.inApp}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleUpdateSettings({
                    inApp: e.target.checked,
                  })
                }
              />
              <p className='mb-0'>Push</p>
            </div>
            <div
              className={`d-flex align-items-center gap-3 ${
                windowWidth <= 768 ? "flex-row-reverse justify-content-between py-3" : "justify-content-start"
              }`}
            >
              <Switch
                id='campaign-updates'
                colorScheme='brand-primary'
                size='lg'
                defaultChecked={notificationSettings?.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleUpdateSettings({
                    email: e.target.checked,
                  })
                }
              />
              <p className='mb-0'>Email</p>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};
