import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { ReactComponent as SuccessIcon } from "assets/svg/verified-icon.svg";
import { useModalProvider } from "providers/modalProvider";
import { redirectTo } from "utilities/general";
interface BackCampaignSuccessModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const BackCampaignSuccessModal: FC<BackCampaignSuccessModalProps> = ({
  // isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();

  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={false}
        useProceedButton={true}
        onProceed={() => {
          initModal((prev) => ({ ...prev, successCampaign: false }));
        }}
        useHeader={false}
        proceedButtonDefaultChild={"Return to Dashboard"}
        proceedButtonProps={{
          className: "btn-md primary-btn w-100",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              successCampaign: false,
            }));
            redirectTo("/backer/dashboard");
          },
        }}
        modalFooterProps={{
          className: "justify-content-center pb-0 w-100",
        }}
        title={""}
        size={"md"}
        closeOnOverlayClick={false}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center">
            <SuccessIcon className="mb-3" />
            <h5 className="fw-600 text-grey-900 mb-3">Payment Successful!</h5>
            <p className="fw-400 text-grey-500 mb-2">
              You have successfully backed this campaign
            </p>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
