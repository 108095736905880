import { Select } from "@chakra-ui/react";
import { BsChevronDown } from "react-icons/bs";

interface DropdownSelectProps {
  options: string[];
  disabled: boolean;
}

export const DropdownSelect: React.FC<DropdownSelectProps> = ({
  options,
  disabled,
}) => {
  return (
    <>
      <Select
        icon={<BsChevronDown />}
        size="md"
        // placeholder="Select option"
        className="bg-primary-50 rounded-3 py-2 border-0 text-grey-900 fw-400 ps-3 pe-5"
        iconSize="14"
        cursor="pointer"
        disabled={disabled}
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </>
  );
};
