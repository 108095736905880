/* eslint-disable no-lone-blocks */
import { PrimaryButton } from "components/buttons";
import { AlertDefaultBox, AuthHeaderText, CustomPasswordInput } from "components/custom";
import { PrimaryInput } from "components/inputs";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { LoginPayload, setCredential, useLoginMutation } from "store/auth";
import { useFormik } from "formik";
import { LoginValidationSchema } from "validations";
import { useModalProvider, usePageNotificationProvider } from "providers";
import { resolveApiError } from "utilities";
import { ReactComponent as LockIcon } from "assets/svg/account-lock-icon.svg";
import { ReactComponent as WarningIcon } from "assets/svg/caution.svg";
import { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Recaptcha } from "./components";

export const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { initModal } = useModalProvider();
  const [accountLocked, setAccountLocked] = useState(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>("");
  const [message, setMessage] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [request, { isLoading }] = useLoginMutation();
  const { initNotification } = usePageNotificationProvider();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: LoginValidationSchema(),
    onSubmit: () => initRequest(),
  });

  const reCaptchaRef: any = useRef();

  const handleCaptchaChange = (value: any) => {
    setCaptchaValue(value);
  };

  // useEffect(() => {
  //   setCount(JSON.parse(localStorage.getItem("attempts")!));
  // }, []);

  const initRequest = (payload?: LoginPayload) => {
    // handleCaptchaSubmit();
    if (!captchaValue) {
      initNotification({
        message: "Please confirm you're not a robot",
        scheme: "error",
      });
      return;
    }
    request(payload ?? values)
      .unwrap()
      .then((res: any) => {
        console.log("BACKEND aUTH", res.data);
        if (res.error) {
          console.log(res);

          if (res.message === "auth/wrong-password") {
            // localStorage.setItem("attempts", JSON.stringify(count + 1));
            setCount(count + 1);
            setMessage(
              <AlertDefaultBox
                scheme='error'
                type='solid'
                icon={<WarningIcon />}
                body={`Incorrect Password. You have ${3 - (count + 1)} more attempt${count === 1 ? "" : "s"}`}
                className='mb-3'
              />
            );
            // setTimeout(() => {
            //   setMessage([]);
            // }, 3000);
          }
          if (
            res.message.includes(
              "Your account has been temporarily disabled due to too many login attempts. Please check your email for instructions on how to reset your password."
            )
          ) {
            // localStorage.setItem("attempts", JSON.stringify(0));
            setCount(0);
            setAccountLocked(true);
            setMessage(
              <AlertDefaultBox
                scheme='error'
                type='solid'
                icon={<LockIcon />}
                body={res.message}
                className='mb-3'
              />
            );
          } else {
            initNotification({
              message: res.message,
              scheme: "error",
            });
          }
          return;
        }

        setAccountLocked(false);
        initNotification({
          message: "You have successfully logged in.",
        });
        dispatch(
          setCredential({
            user: res.data.user,
            access_token: res.data.token,
            refresh_token: res.data.refreshToken,
          })
        );

        if (res.data.user?.enable2fa) {
          initModal((prev) => ({
            ...prev,
            verify2fa: {
              payload: { url: `${res.data.user.role}/dashboard` },
            },
          }));
          localStorage.setItem("2faModal", "true");
        } else {
          if (location.state) {
            navigate(`${location.state.from}`);
          } else {
            navigate(`/${res.data.user.role}/dashboard`);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  console.log(message, count, location.state);

  const isBtnDisabled = Boolean(isLoading || errors.email || errors.password);

  return (
    <section className='container-fluid min-vh-100 d-flex justify-content-center align-items-center py-md-5 py-4'>
      <div className='max-w-500 w-95'>
        <div className='d-flex flex-column justify-content-center align-items-center gap-5'>
          <Link to='/' className='d-flex flex-row gap-2 align-items-center'>
            <Logo />
          </Link>

          <form className='auth-form-wrapper w-100' onSubmit={handleSubmit}>
            <div className='auth-form-container w-100'>
              <div className='auth-form'>
                <AuthHeaderText
                  title='Log In'
                  subTitle='Please enter your details to log into your account'
                />
                {/* {accountLocked && (
                  // Alert for lock out with email
                  <AlertDefaultBox
                    scheme="error"
                    type="solid"
                    icon={<LockIcon />}
                    body="Your account has been locked. To recover your account, check your email inbox for instructions on how to unlock your account"
                    className="mb-3"
                  />

                  // Alert for temporary lock out
                  // <AlertDefaultBox
                  //   scheme='error'
                  //   type='solid'
                  //   icon={<LockIcon />}
                  //   body={`Your account has been temporarily disabled due to multiple failed login attempts. You can immediately restore it by resetting your password or you can try again later.`}
                  //   className='mb-3'
                  // />
                )} */}
                {message}
                <div className='mb-4'>
                  <PrimaryInput
                    label='Email Address'
                    name='email'
                    type={"email"}
                    placeholder='example@mail.com'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    // isInvalid={Boolean(errors?.email && touched.email)}
                    error={Boolean(errors?.email && touched.email)}
                    bottomText={errors?.email}
                  />
                </div>
                <div className=''>
                  <CustomPasswordInput
                    label='Password'
                    rightLabel='Forgot Password?'
                    name='password'
                    placeholder='********'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={Boolean(touched.password && errors?.password)}
                    bottomText={errors?.password}
                  />
                </div>
              </div>
              {/* <PrimaryCheckBox name="rememberMe" inlineText="Remember me" /> */}
            </div>
            {/* <ReCAPTCHA
              sitekey={"6LcBotApAAAAAL33eg_6Jn6ut34My2daJ6vodrqU"}
              ref={reCaptchaRef}
              onChange={handleCaptchaChange}
            /> */}

            <GoogleReCaptchaProvider reCaptchaKey={"6LcBotApAAAAAL33eg_6Jn6ut34My2daJ6vodrqU"}>
              <Recaptcha captchaValue={captchaValue} setCaptchaValue={setCaptchaValue} />
            </GoogleReCaptchaProvider>
            <PrimaryButton
              type='submit'
              isDisabled={isBtnDisabled}
              isLoading={isLoading}
              className='btn-lg primary-btn mt-4'
            >
              Log In
            </PrimaryButton>
          </form>
          <div className='to-register text-grey-500'>
            Don't have an account?{" "}
            <span>
              <Link className='text-primary-500' to='/register'>
                Register
              </Link>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

// const {
//   values,
//   errors,
//   touched,
//   handleChange,
//   handleBlur,
//   handleSubmit,
//   resetForm,
// } = useFormik({
//   initialValues: {
//     email: "",
//     firstName: "",
//     lastName: "",
//     role: "",
//     password: "",
//   },
//   validationSchema: RegisterValidationSchema(),
//   onSubmit: () => initRequest(),
// });

{
  /* <div>
      <section className="bg-primary-25">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6 col-lg-5 col-xl-4">
              <h3 className="fw-900 text-primary-600">LOG IN</h3>
              <div className="pt-2">
                <div className="mb-4">
                  <PrimaryInput
                    label="Email Address"
                    name="email"
                    type={"email"}
                    placeholder="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    // isInvalid={Boolean(errors?.email && touched.email)}
                    error={Boolean(errors?.email && touched.email)}
                    bottomText={errors?.email}
                  />
                </div>
                <div className="mb-4">
                  <PrimaryInput
                    label="First Name"
                    name="firstName"
                    type={"text"}
                    placeholder="First Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={Boolean(errors?.firstName && touched.firstName)}
                    bottomText={errors?.firstName}
                  />
                </div>
                <div className="mb-4">
                  <PrimaryInput
                    label="Last Name"
                    name="lastName"
                    type={"text"}
                    placeholder="Last Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={Boolean(errors?.lastName && touched.lastName)}
                    bottomText={errors?.lastName}
                  />
                </div>
                <div className="mb-4">
                  <PrimarySelect
                    label="Role"
                    name="role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.role}
                    error={Boolean(errors?.role && touched.role)}
                    bottomText={errors?.role}
                  >
                    <option value="">Select Role</option>
                    <option value="backer">Backer</option>
                    <option value="exporter">Exporter</option>
                  </PrimarySelect>
                </div>
                <div className="mb-4">
                  <CustomPasswordInput
                    label="Password"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={Boolean(touched.password && errors?.password)}
                    bottomText={errors?.password}
                  />
                </div>
                <div className="mb-4 d-flex justify-content-end">
                  <div className="text-end text-primary touchable d-inline-block">
                    Forgot Password
                  </div>
                </div>
                <div className="pb-5">
                  <PrimaryButton
                    className="primary-btn w-100 py-3 h-auto fw-normal"
                    isDisabled={isBtnDisabled()}
                    isLoading={isLoading}
                    onClick={() => handleSubmit()}
                  >
                    Register
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <PrimarySelect label="Select">
            <option value="1">One</option>
            <option value="2">Two</option>
          </PrimarySelect>
          <PrimaryButton
            type="button"
            // onClick={() =>
            //   request({
            //     email: "baggdbo@gmail.com",
            //     password: "damilolaj23",
            //     firstName: "John",
            //     lastName: "Doe",
            //     role: "backer",
            //   })
            // }
            className="btn-lg primary-btn mt-3"
          >
            Click me
          </PrimaryButton> */
}
