import { ChakraAlertDialog } from "components";
import { FC, useState } from "react";
import { useModalProvider } from "providers/modalProvider";
import { ReactComponent as VerifyPhoneIcon } from "assets/svg/verify-phone-icon.svg";
import { VerificationCodeInput } from "components";
import { maskPhoneNumber } from "utilities/formatting";

interface VerifyPhoneModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const VerifyPhoneModal: FC<VerifyPhoneModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal, getModalPayload } = useModalProvider();
  const [value, setValue] = useState("");
  const modalPayload = getModalPayload("verifyPhoneModal");
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, verifyPhoneModal: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Verify"}
        proceedButtonProps={{
          className: "btn-lg primary-btn ms-0 mx-4 w-50",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              verifyPhoneModal: false,
              disableTwoFac: true,
            }));
          },
        }}
        cancelButtonDefaultChild={"Cancel"}
        cancelButtonProps={{
          className: "btn-lg border ms-0 bg-white w-50",
          onClick: () => {
            initModal((prev) => ({ ...prev, verifyPhoneModal: false }));
          },
        }}
        useHeader={false}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className: "d-flex flex-row justify-content-start w-100 px-0 pb-0 mt-2 gap-3",
        }}
        title={"Two-factor authentication"}
        size={"lg"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className=''>
          <div className='text-center d-flex flex-column align-items-center gap-4'>
            <VerifyPhoneIcon />
            <div>
              <h5 className='fs-18 fw-600 mb-2'>Verify it’s you!</h5>
              <p className='fs-14 text-grey-600 fw-400 m-0'>
                A verification code has been sent to {maskPhoneNumber(modalPayload.phone)}.
              </p>
              <p className='fs-14 text-grey-600 fw-400 m-0'>Enter the code to continue</p>
            </div>
            <VerificationCodeInput value={value} setValue={setValue} />
            <p className='fs-14 text-grey-600 fw-400 mb-3'>
              Didn’t get a code?{" "}
              <span className='text-decoration-underline cursor-pointer'>Click to resend.</span>
            </p>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
