import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { ReactComponent as ConfirmIcon } from "assets/svg/payment-confirmation.svg";
import { useNavigate } from "react-router";
import { useModalProvider } from "providers/modalProvider";
import { ReactComponent as Naira } from "assets/svg/naira-icon.svg";
import { ReactComponent as BankIcon } from "assets/svg/bank-icon.svg";
import { redirectTo } from "utilities/general";
import { useParams } from "react-router";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities/errorHandling";
import { useBackCampaignPaymentMutation } from "store/backCampaign";

interface PaymentDetailsModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  // campaignId?: string;
}

export const PaymentDetailsModal: FC<PaymentDetailsModalProps> = ({
  //   isOpen = false,

  onClose = () => {},
  ...rest
}) => {
  const { initModal, getModalPayload } = useModalProvider();
  const { initNotification } = usePageNotificationProvider();
  const [backCampaignPayment, { isLoading }] = useBackCampaignPaymentMutation();

  const paymentInfo = getModalPayload("paymentDetails");
  console.log(paymentInfo);
  const campaignAmount = paymentInfo?.amount?.toString();
  const campaignId = paymentInfo?.campaignId;

  console.log("campaignId", campaignId);
  console.log("amount", campaignAmount);

  const handleProceed = async () => {
    const payload = {
      campaignId: campaignId,
      amount: campaignAmount,
    };

    backCampaignPayment(payload)
      .unwrap()
      .then((res: any) => {
        console.log(res);
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        } else {
          initNotification({
            message: "Successfull!",
          });
          initModal((prev) => ({ ...prev, paymentDetails: false }));

          if (res.data?.authorizationURL) {
            window.open(res.data?.authorizationURL, "_blank");
          } else {
            console.log("Authorization URL not found");
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({
          message: resolveApiError(error),
          scheme: "error",
        });
      });
  };

  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, paymentDetails: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Proceed"}
        proceedButtonProps={{
          className: "btn-lg border ms-4 primary-btn w-100 text-white",
          onClick: handleProceed,
          isLoading: isLoading,
        }}
        cancelButtonDefaultChild={"Cancel"}
        cancelButtonProps={{
          className: "btn-lg border ms-0 w-100",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              paymentDetails: false,
            }));
          },
        }}
        useHeader={true}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        useHeaderCancel={true}
        modalFooterProps={{
          className: "d-flex w-100 pb-0 px-0 mt-2",
        }}
        title={""}
        size={"md"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className=" d-flex flex-column align-items-center">
            <ConfirmIcon className="mb-3" />
            <h5 className="fw-600 text-grey-900 mb-3">Payment Confirmation</h5>
          </div>
          <div>
            <div className="d-flex items-align-center gap-4 py-3">
              <p className="m-0 fs-14 fw-400 text-grey-600">Pay</p>
              <div className="d-flex gap-1 align-items-center">
                <Naira />
                <h5 className="fw-400 fs-24 text-grey-800 m-0">
                  {Number(campaignAmount).toLocaleString()}
                </h5>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
