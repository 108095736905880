import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { ReactComponent as VerifiedIcon } from "assets/svg/verified-icon.svg";
import { useModalProvider } from "providers/modalProvider";
import { useAuth } from "store/auth";

interface ChangeProfileInfoSuccessModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const ChangeProfileInfoSuccessModal: FC<
  ChangeProfileInfoSuccessModalProps
> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  const { user } = useAuth();
  const handleProceed = () => {
    const verificationUrl = `/${user!.role}/settings?active=2`;
    window.location.replace(verificationUrl);
    onClose();
  };
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useHeader={true}
        useCancelButton={false}
        useProceedButton={true}
        useHeaderCancel={true}
        onProceed={() => {
          initModal((prev) => ({ ...prev, successModal: false }));
        }}
        onClose={() => {
          initModal((prev) => ({ ...prev, successModal: false }));
          window.location.reload();
        }}
        proceedButtonDefaultChild={"Go to Verification Center"}
        proceedButtonProps={{
          className: "btn-md primary-btn w-100 ms-0",
          onClick: handleProceed,
        }}
        modalFooterProps={{
          className: "justify-content-center p-0 w-100",
        }}
        title={""}
        size={"md"}
        closeOnOverlayClick={false}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="text-center d-flex flex-column align-items-center">
          <VerifiedIcon className="mb-3" />
          <h5 className="fw-600 text-grey-900 mb-3">Request Submitted!</h5>
          <p className="fw-400 text-grey-500 mb-4 lh-base">
            Visit the{" "}
            <span className="fw-600 text-primary-600">
              {" "}
              Verification Centre{" "}
            </span>{" "}
            to upload the necessary documents. Once we receive the submission,
            it will take up to 5 working days to review it
          </p>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
