import { ModalViewStateProps } from "providers";
import { EnterPhoneNoDialog } from "./enterPhoneNoModal";
import { VerificationCodeModal } from "./verifyCodeModal";
import { TwoFacAuthModal } from "./setupTwoFacAuth";
import { TwoFacAuthSuccessModal } from "./twoFacAuthSuccessModal";
import { DisableTwoFacModal } from "./disableTwoFacModal";
import { VerifyPhoneModal } from "./verifyPhoneModal";
import { VerifyModalSuccess } from "./verifyModalSuccess";
import { TwoFaPasswordConfirmationModal } from "./twofaPasswordConfirmationModal";

export interface SettingsDialogsProps {
  modal?: ModalViewStateProps;
  onInitModal?: React.Dispatch<
    React.SetStateAction<ModalViewStateProps | undefined>
  >;
}
export const SettingsDialogs: React.FC<SettingsDialogsProps> = ({
  modal,
  onInitModal = () => {},
}) => {
  return (
    <>
      <EnterPhoneNoDialog
        isOpen={Boolean(modal?.enterPhoneNo)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, enterPhoneNo: false }))
        }
      />
      <VerificationCodeModal
        isOpen={Boolean(modal?.verifyCodeModal)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, verifyCodeModal: false }))
        }
      />
      <VerifyPhoneModal
        isOpen={Boolean(modal?.verifyPhoneModal)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, verifyPhoneModal: false }))
        }
      />
      <TwoFacAuthModal
        isOpen={Boolean(modal?.twoFacAuth)}
        onClose={() => onInitModal((prev) => ({ ...prev, twoFacAuth: false }))}
      />
      <TwoFacAuthSuccessModal
        isOpen={Boolean(modal?.twoFacSuccess)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, twoFacSuccess: false }))
        }
      />
      <DisableTwoFacModal
        isOpen={Boolean(modal?.disableTwoFac)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, disableTwoFac: false }))
        }
      />
      <TwoFaPasswordConfirmationModal
        isOpen={Boolean(modal?.confirm2faPassword)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, confirm2faPassword: false }))
        }
      />
      <VerifyModalSuccess
        isOpen={Boolean(modal?.verifySuccess)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, verifySuccess: false }))
        }
      />
    </>
  );
};
