import React, { FC, useEffect } from "react";
import { PrimaryInput, PrimaryPhone, PrimarySelect, PrimaryButton } from "components";
import { useFormik } from "formik";
import { CreatePersonalDetailsValidationSchema } from "validations";
import { setCredential, useAuth } from "store/auth";
import { UpdateProfilePayload, useUpdateProfileMutation } from "store/profile";
import { useDispatch } from "react-redux";
import { useModalProvider, usePageNotificationProvider } from "providers";
import { resolveApiError } from "utilities";
import { useWindowWidth } from "utilities";

export type PersonalDetailsFormProps = {
  next: () => void;
  initialProfile: UpdateProfilePayload;
};

export const PersonalDetailsForm: FC<PersonalDetailsFormProps> = ({ next, initialProfile }) => {
  const windowWidth = useWindowWidth();
  const { user, token: access_token } = useAuth();
  const dispatch = useDispatch();
  const [request, { isLoading }] = useUpdateProfileMutation();
  const { initNotification } = usePageNotificationProvider();
  const { initModal } = useModalProvider();

  const details = user ?? initialProfile;

  const initialValues = {
    // ...initialProfile,
    firstName: (initialProfile?.firstName as string) ?? "",
    lastName: (initialProfile?.lastName as string) ?? "",
    email: (initialProfile?.email as string) ?? "",
    phoneNumber: (initialProfile?.phoneNumber as string) ?? "+234",
    sex: (initialProfile?.sex as string) ?? "",
    dateOfBirth: (initialProfile?.dateOfBirth as string) ?? "",
  };

  const { values, errors, touched, setValues, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: CreatePersonalDetailsValidationSchema,
    onSubmit: () => initRequest(),
  });

  const initRequest = () => {
    request({ ...values })
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "Personal details saved.",
        });
        dispatch(
          setCredential({
            user: res.data,
            access_token,
          })
        );
        setTimeout(() => {
          next();
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  let eighteenYearsAbove = new Date();
  eighteenYearsAbove.setFullYear(eighteenYearsAbove.getFullYear() - 18);

  let futureDate = new Date();
  futureDate.setFullYear(futureDate.getFullYear() - 200);

  return (
    <section className={`container-fluid ${windowWidth >= 768 ? "py-5" : "pt-3 pb-5"} bg-transparent`}>
      <div className='row'>
        <div className='col-md-12'>
          <form onSubmit={handleSubmit} className=''>
            {windowWidth <= 768 && (
              <>
                <h6 className='text-grey-900 fs-20 fw-semibold'>Personal Details</h6>
                <p className='mb-4 text-grey-400'>Enter in your personal details</p>
              </>
            )}
            <div className={`row mb-4 ${windowWidth <= 768 && "d-flex flex-row"} `}>
              <div className={windowWidth >= 768 ? "col-md-6" : "w-50"}>
                <PrimaryInput
                  type='text'
                  label='First Name*'
                  name='firstName'
                  placeholder='Enter Your First Name'
                  isReadOnly={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={Boolean(errors?.firstName && touched?.firstName)}
                  bottomText={errors?.firstName || ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                />
              </div>
              <div className={windowWidth >= 768 ? "col-md-6" : "w-50"}>
                <PrimaryInput
                  type='text'
                  label='Last Name*'
                  name='lastName'
                  placeholder='Enter Your Last Name'
                  isReadOnly={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={Boolean(errors?.lastName && touched?.lastName)}
                  bottomText={errors?.lastName || ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                />
              </div>
            </div>
            <div className={`mb-4 ${windowWidth <= 768 && "w-80"} `}>
              <PrimaryInput
                type='email'
                label='Email Address*'
                name='email'
                placeholder='example@mail.com'
                isReadOnly={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={Boolean(errors?.email && touched?.email)}
                bottomText={errors?.email || ""}
                bg={windowWidth <= 768 ? "white" : ""}
              />
            </div>
            <div className={`mb-4 ${windowWidth <= 768 && "w-80"} `}>
              <PrimaryPhone
                label='Phone Number*'
                placeholder=''
                name='phoneNumber'
                invalid={String(Boolean(errors?.phoneNumber))}
                onChange={(v) =>
                  setValues((prev) => {
                    return { ...prev, phoneNumber: v as string };
                  })
                }
                onBlur={handleBlur}
                value={values.phoneNumber}
                error={Boolean(errors?.phoneNumber && touched?.phoneNumber)}
                bottomText={errors?.phoneNumber || ""}
                bg={windowWidth <= 768 ? "white" : ""}
                isNigerianOnly={true}
                // rightComponent={
                //   <div
                //     onClick={() => {
                //       initModal((prev) => ({
                //         ...prev,
                //         verifyPhoneModal: {
                //           payload: {
                //             phone: values.phoneNumber,
                //           },
                //         },
                //       }));
                //     }}
                //   >
                //     Verify
                //   </div>
                // }
                // rightComponentProps={{ className: "h-100 fs-15 me-3 text-primary-700 cursor-pointer" }}
              />
            </div>
            <div className={`row mb-4 ${windowWidth <= 768 && "d-flex flex-row"} `}>
              <div className={windowWidth >= 768 ? "col-md-6" : "w-50"}>
                <PrimaryInput
                  type='date'
                  label='Date of Birth*'
                  name='dateOfBirth'
                  placeholder='Enter Your date of birth'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={futureDate.toISOString().split("T")[0]}
                  max={eighteenYearsAbove.toISOString().split("T")[0]}
                  value={values.dateOfBirth.substring(0, 10)}
                  error={Boolean(errors?.dateOfBirth && touched?.dateOfBirth)}
                  bottomText={errors?.dateOfBirth || ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                />
              </div>
              <div className={windowWidth >= 768 ? "col-md-6" : "w-50"}>
                <PrimarySelect
                  name='sex'
                  label='Gender*'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sex}
                  error={Boolean(errors?.sex && touched?.sex)}
                  bottomText={errors?.sex || ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                >
                  <option hidden>Select Gender</option>
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
                </PrimarySelect>
              </div>
            </div>

            {windowWidth <= 768 && (
              <PrimaryButton type='button' className='btn-lg secondary-btn mt-3 align-self-baseline me-4'>
                Cancel
              </PrimaryButton>
            )}

            {/* User should not be able to proceed if phone number has not been verified */}

            <PrimaryButton
              type='submit'
              className='btn-lg primary-btn mt-3 align-self-baseline'
              isLoading={isLoading}
            >
              Next
            </PrimaryButton>
          </form>
        </div>
      </div>
    </section>
  );
};
