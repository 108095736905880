import { ChakraAlertDialog } from "components";
import React, { FC, useEffect, useState } from "react";
import { useModalProvider } from "providers/modalProvider";
import { ReactComponent as QrAuthIcon } from "assets/svg/qr-authentication-icon.svg";
import { Box, useClipboard, Button } from "@chakra-ui/react";
import { VerificationCodeInput } from "components/custom";
import {
  Generate2faSecretResponse,
  useGenerate2faSecretOnClickMutation,
  useGenerate2faSecretQuery,
  useVerify2faTokenOnClickMutation,
  useVerify2faTokenQuery,
} from "store/2fa";
import { usePageNotificationProvider } from "providers";
import { resolveApiError } from "utilities/index";
import { MdCopyAll } from "react-icons/md";

interface TwoFacAuthModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const TwoFacAuthModal: FC<TwoFacAuthModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { onCopy, value, setValue, hasCopied } = useClipboard("");
  const { initNotification } = usePageNotificationProvider();
  const { initModal, getModalPayload } = useModalProvider();
  const modalPayload = getModalPayload("twoFacAuth");
  const [code, setCode] = useState("");
  const [tokenData, setTokenData] = useState<any>({});
  const [verify2faToken, { isLoading: isVerifying }] =
    useVerify2faTokenOnClickMutation();

  const [generate2faToken, { isLoading: isGenerating }] =
    useGenerate2faSecretOnClickMutation();

  // const {
  //   data: tokenResponse,
  //   isLoading,
  //   refetch,
  // } = useGenerate2faSecretQuery();

  console.log(modalPayload, "modallllllll");

  const handleGenerate2faToken = () => {
    if (modalPayload?.mounted) {
      generate2faToken()
        .unwrap()
        .then((res: Generate2faSecretResponse) => {
          setTokenData(res);
        })
        .catch((error) => {
          console.log(error);
          if (error.data.statusCode === 403)
            initModal({ sessionExpired: true });
          initNotification({
            message: resolveApiError(error),
            scheme: "error",
          });
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleGenerate2faToken();
    }, 3000);
  }, [modalPayload.mounted]);

  const initVerifytwoFaToken = () => {
    verify2faToken({ token: code })
      .unwrap()
      .then((res: boolean) => {
        if (res) {
          setCode("");
          initModal((prev) => ({
            ...prev,
            twoFacAuth: false,
            twoFacSuccess: true,
          }));
        } else {
          initNotification({
            message: "Something went wrong. Please ensure the code is valid",
            scheme: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.data.statusCode === 403) initModal({ sessionExpired: true });
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  console.log(tokenData);
  let qrCode = tokenData?.data?.data?.qrData;
  let twofaSecret = tokenData?.data?.data?.secret;

  useEffect(() => {
    setValue(twofaSecret);
  }, [twofaSecret]);

  // console.log(code, isGenerating);

  if (isGenerating) {
    return <p>Please wait...</p>;
  }
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, twoFacAuth: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Confirm"}
        proceedButtonProps={{
          className: "btn-lg primary-btn ms-0 w-50",
          onClick: () => initVerifytwoFaToken(),
          isDisabled: isVerifying,
          isLoading: isVerifying,
        }}
        cancelButtonDefaultChild={"Cancel"}
        cancelButtonProps={{
          className: "btn-lg border ms-0 bg-white w-50 border-grey-300",
          onClick: () => {
            initModal((prev) => ({ ...prev, twoFacAuth: false }));
          },
        }}
        useHeader={false}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className:
            "d-flex flex-row justify-content-start w-100 px-0 mt-2 gap-3",
        }}
        title={"Two-factor authentication"}
        size={"2xl"}
        closeOnOverlayClick={false}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center gap-4">
            <div className="w-100">
              <QrAuthIcon />
            </div>
            <div>
              <h5 className="fs-18 fw-600 w-100 text-start mb-2">
                Set up two-factor authentication
              </h5>
              <p className="fs-14 text-grey-600 fw-400 m-0 w-100 text-start">
                To authorise transactions, please scan this QR code with your
                Google Authenticator App and enter the verification code below.
              </p>
            </div>
            <Box
              h={"auto"}
              className="bg-grey-50 py-4 w-100 d-flex align-items-center justify-content-center"
              borderRadius={"8px"}
            >
              <img src={qrCode} alt="2FA QR Code" />
            </Box>
            {twofaSecret && (
              <div>
                <h4>OR</h4>
                <p className="fs-14 text-grey-600 fw-400 m-0 w-100 text-start mb-3">
                  Copy this key, and set it up manually on your Authenticator
                  App and enter the verification code below.
                </p>
                <div className="clipboard bg-grey-100 rounded text-start p-2 position-relative">
                  <span onClick={onCopy}>
                    {hasCopied ? "Copied!" : twofaSecret}
                  </span>
                  <button
                    style={{ top: "50%", transform: "translateY(-50%)" }}
                    className="position-absolute right-4 text-white bg-primary-600 p-2 rounded border-0"
                    onClick={onCopy}
                  >
                    <MdCopyAll />
                  </button>
                </div>
              </div>
            )}

            <div className="mb-3">
              <p className="fw-500 fs-14 w-100 text-start mb-2">
                Verification code
              </p>
              <VerificationCodeInput
                codeLength={6}
                colorTheme="grey"
                wrapperProps={{
                  className: "text-grey-300 mb-2",
                }}
                value={code}
                setValue={setCode}
              />
              <p className="fs-14 text-grey-600 fw-400 m-0 text-start w-100">
                Didn’t get a code?{" "}
                <button
                  type="button"
                  onClick={() => {
                    qrCode = "";
                    handleGenerate2faToken();
                  }}
                  className="text-decoration-underline border-0 cursor-pointer"
                >
                  {isGenerating ? "Please wait..." : "Click to resend."}
                </button>
              </p>
            </div>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
