import { ModalViewStateProps } from "providers";
import {
  ProfileCompletedModal,
  ChangePhoneNoModal,
  ChangeEmailModal,
  PasswordConfirmationModal,
  PersonalDetailChangeModal,
  ContactAddressChangeModal,
  ChangeProfileInfoSuccessModal,
} from "../profile";
import {
  SubmitCampaignModal,
  EditCampaignInformationDialog,
  EditCampaignMediaDialog,
  EditCampaignGoalsDialog,
  PaymentConfirmationModal,
  PaymentSubmittedModal,
  CancelCampaignModal,
  DeleteCampaignModal,
  DeleteSuccessfullCampaignModal,
  PreviewCampaignDocumentModal,
} from "../campaign";
import { DeletePaymentModal } from "../transactions";
import { ProvideKycDialog } from "./provideKycDialog";
import { BusinessInformationChangeModal } from "../profile/components/businessInformationChangeModal";
import { VerifyDocumentUploadSuccessModal } from "../settings";
import { ForgetPasswordSuccessDialog } from "pages/auth";

export interface ExporterDialogsProps {
  modal?: ModalViewStateProps;
  onInitModal?: React.Dispatch<
    React.SetStateAction<ModalViewStateProps | undefined>
  >;
}
export const ExporterDialogs: React.FC<ExporterDialogsProps> = ({
  modal,

  onInitModal = () => {},
}) => {
  return (
    <>
      <ProfileCompletedModal
        isOpen={Boolean(modal?.profileCompleted)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, profileCompleted: false }))
        }
      />
      <SubmitCampaignModal
        isOpen={Boolean(modal?.submitCampaign)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, submitCampaign: false }))
        }
      />
      <EditCampaignInformationDialog
        isOpen={Boolean(modal?.editCampaignInfo)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, editCampaignInfo: false }))
        }
      />
      <EditCampaignGoalsDialog
        isOpen={Boolean(modal?.editCampaignGoals)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, editCampaignGoals: false }))
        }
      />
      <EditCampaignMediaDialog
        isOpen={Boolean(modal?.editCampaignMedia)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, editCampaignMedia: false }))
        }
      />
      <DeletePaymentModal
        isOpen={Boolean(modal?.deletePaymentInfo)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, deletePaymentInfo: false }))
        }
      />
      <ChangePhoneNoModal
        isOpen={Boolean(modal?.profileChangePhoneNo)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, profileChangePhoneNo: false }))
        }
      />
      <ChangeEmailModal
        isOpen={Boolean(modal?.profileChangeEmail)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, profileChangeEmail: false }))
        }
      />
      <PaymentConfirmationModal
        isOpen={Boolean(modal?.requestPayment)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, requestPayment: false }))
        }
      />
      <PaymentSubmittedModal
        isOpen={Boolean(modal?.paymentSubmitted)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, paymentSubmitted: false }))
        }
      />
      <PasswordConfirmationModal
        isOpen={Boolean(modal?.passwordConfirmation)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, passwordConfirmation: false }))
        }
      />
      <ProvideKycDialog
        role="backer"
        isOpen={Boolean(modal?.provideBackerKyc)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, provideBackerKyc: false }))
        }
      />
      <ProvideKycDialog
        role="exporter"
        isOpen={Boolean(modal?.provideExporterKyc)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, provideExporterKyc: false }))
        }
      />
      <CancelCampaignModal
        isOpen={Boolean(modal?.cancelCampaign)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, cancelCampaign: false }))
        }
      />
      <DeleteCampaignModal
        isOpen={Boolean(modal?.deleteCampaign)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, deleteCampaign: false }))
        }
      />
      <PersonalDetailChangeModal
        isOpen={Boolean(modal?.editPersonalDetails)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, editPersonalDetails: false }))
        }
      />
      <ContactAddressChangeModal
        isOpen={Boolean(modal?.editContactDetails)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, editContactDetails: false }))
        }
      />
      <BusinessInformationChangeModal
        isOpen={Boolean(modal?.editBusinessInformation)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, editBusinessInformation: false }))
        }
      />
      <ChangeProfileInfoSuccessModal
        isOpen={Boolean(modal?.successModal)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, successModal: false }))
        }
      />
      <DeleteSuccessfullCampaignModal
        isOpen={Boolean(modal?.deleteSuccess)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, deleteSuccess: false }))
        }
      />
      <PreviewCampaignDocumentModal
        isOpen={Boolean(modal?.previewCampaignDocument)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, previewCampaignDocument: false }))
        }
      />
      <VerifyDocumentUploadSuccessModal
        isOpen={Boolean(modal?.verifyDocumentUploadSuccess)}
        onClose={() =>
          onInitModal((prev) => ({
            ...prev,
            verifyDocumentUploadSuccess: false,
          }))
        }
      />
      <ForgetPasswordSuccessDialog
        isOpen={Boolean(modal?.forgetPasswordSuccess)}
        onClose={() =>
          onInitModal((prev) => ({
            ...prev,
            forgetPasswordSuccess: false,
          }))
        }
      />
    </>
  );
};
