// import { ReactComponent as ExporterProcess } from "assets/svg/homepage/exporter-process.svg";
import Circle from "assets/svg/homepage/oval-icon.svg";
import { ReactComponent as HandShake } from "assets/svg/homepage/hand-shake.svg";
import { useWindowWidth } from "utilities/windowWidth";
import ExportersJourney from "./exportersJourney";

export const ExporterJourneyProcess = () => {
  const windowWidth = useWindowWidth();

  return (
    <div className='position-relative'>
      <section className={`journey-container bg-primary-50 ${windowWidth < 768 ? "px-3" : "px-5"}`}>
        <div className='journey-oval '>
          <img src={Circle} alt='journey-process' />
        </div>
        <h1
          className={`text-primary-600 fw-600 w-90 mx-auto mb-0 ${
            windowWidth <= 768 ? " fs-47 lh-sm pt-4 " : " fs-62 lh-1 py-5"
          }`}
        >
          The Krowdfi Journey
        </h1>
        <div
          className='d-flex justify-content-center align-items-center'
          style={{
            height: windowWidth > 768 ? "100%" : "1200px",
          }}
        >
          <ExportersJourney />
        </div>
      </section>

      <section className='journey-ends px-5 bg-grey-50 mt-3'>
        <div className={`${windowWidth <= 768 ? "w-100" : "d-flex w-80"}  mx-auto gap-5 align-items-center `}>
          <div className='col-lg-6 bg-primary-50 ps-2 py-2 rounded-5'>
            <div className=''>
              <HandShake width='100%' height='100%' />
            </div>
          </div>
          <div className='col-lg-6  '>
            <h5
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "396px",
              }}
              className={`${windowWidth <= 768 ? "fs-37" : "fs-47"}  fw-600 text-grey-900 mt-5`}
            >
              Every Campaign Matters!
            </h5>
            <p
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "427px",
              }}
              className={`
              fs-16 fw-400 text-grey-900 lh-base pt-3  `}
            >
              Krowdfi provides unparalleled exposure to international markets, allowing you to showcase your
              products to a diverse audience of potential customers.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
