// import { AuthBoxedContainer, DashboardBaseViewContainer } from 'containers';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Pages from "pages";
import ScrollToTop from "./ScrollToTop";
import useIdleTimeout from "../hooks/useIdleTimeout";
import useTokenRefresher from "../hooks/useTokenRefresher";

export const RootNavigator = () => {
  useIdleTimeout();
  useTokenRefresher();

  return (
    <BrowserRouter>
      <ScrollToTop>
        {/* <Suspense fallback={<div>Loading...</div>}> */}
        <Routes>
          <Route index path='/' element={<Pages.HomePage />} />
          <Route index path='/sitemap' element={<Pages.Sitemap />} />
          <Route index path='/campaign-creation' element={<Pages.CampaignCreation />} />
          <Route index path='/approval-process' element={<Pages.ApprovalProcess />} />
          <Route index path='/back-campaign/:campaignId' element={<Pages.BackCampaign />} />
          <Route index path='/privacy-policy' element={<Pages.PrivacyPolicy />} />
          <Route index path='/transaction-status' element={<Pages.TransactionStatus />} />
          <Route index path='/terms-of-use' element={<Pages.Terms />} />
          <Route
            index
            path='/exporter/campaign/request-payment/:campaignId'
            element={<Pages.RequestPayment />}
          />
          <Route
            index
            path='/exporter/campaign/verify-payment/:campaignId'
            element={<Pages.RequestPaymentVerification />}
          />

          {/* Auth Routes  */}
          <Route path='/'>
            <Route path='login' element={<Pages.LoginPage />} />
            <Route path='register' element={<Pages.RegisterPage />} />
            <Route path='reset-password' element={<Pages.ResetPasswordPage />} />
            <Route path='forgot-password' element={<Pages.ForgotPasswordPage />} />
            <Route path='verify-email' element={<Pages.VerifyEmailPage />} />
            <Route path='verify-email/status' element={<Pages.VerifyEmailStatusPage />} />
            <Route path='admin/login' element={<Pages.AdminLoginPage />} />
          </Route>

          {/* Admin Routes  */}
          <Route path='/admin' element={<Pages.AdminDashboardContainer />}>
            <Route path='dashboard' element={<Pages.AdminDashboardPage />} />
            <Route path='users' element={<Pages.AdminUsersPage />} />
          </Route>

          {/* Exporter Routes  */}
          <Route path='/exporter' element={<Pages.ExporterDashboardContainer />}>
            <Route path='dashboard' element={<Pages.ExporterDashboardPage />} />
            <Route path='campaign' element={<Pages.ExporterCampaignPage />} />
            <Route path='campaign/create' element={<Pages.NewCampaign />} />
            <Route path='campaign/:campaignId' element={<Pages.ViewCampaigns />} />
            <Route path='profile/create' element={<Pages.CreateProfilePage />} />
            <Route path='profile' element={<Pages.UserProfilePage />} />
            <Route path='settings' element={<Pages.SettingsPage />} />
            <Route path='settings/change-password' element={<Pages.ChangePassword />} />
            <Route path='settings/two-fac-auth/phone' element={<Pages.EnterPhoneNoMobile />} />
            <Route path='settings/two-fac-auth/verify' element={<Pages.ExporterVerifyCodeMobile />} />
            <Route path='settings/two-fac-auth/success' element={<Pages.ExporterTwoFacAuthSuccessMobile />} />
            <Route path='settings/two-fac-auth/disable' element={<Pages.ExporterDisableTwoFacMobile />} />
            <Route path='transactions' element={<Pages.ExporterTransactionHistory />} />
            <Route path='transactions/payment-info' element={<Pages.ExporterAddPaymentInfo />} />
            <Route path='transactions/update-payment-info' element={<Pages.ExporterUpdatePaymentInfo />} />
          </Route>
          {/* Backer Routes  */}
          <Route path='/backer' element={<Pages.BackerDashboardContainer />}>
            <Route path='dashboard' element={<Pages.BackerDashboardPage />} />
            <Route path='discover' element={<Pages.BackerDiscoverCampaigns />} />
            <Route path='discover/:campaignId' element={<Pages.BackerViewCampaigns />} />
            <Route path='transactions' element={<Pages.BackerTransactionHistory />} />

            <Route path='wallet' element={<Pages.BackerWalletPage />} />
            <Route path='wallet/fund-wallet' element={<Pages.BackerFundWallet />} />
            <Route path='profile/create' element={<Pages.CreateProfilePage />} />
            <Route path='profile' element={<Pages.UserProfilePage />} />

            <Route path='settings' element={<Pages.SettingsPage />} />
            <Route path='settings/change-password' element={<Pages.ChangePassword />} />
          </Route>
        </Routes>
        {/* </Suspense> */}
      </ScrollToTop>
    </BrowserRouter>
  );
};
