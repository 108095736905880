import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { ReactComponent as ConfirmIcon } from "assets/svg/payment-confirmation.svg";
import { useNavigate } from "react-router";
import { useModalProvider } from "providers/modalProvider";
import { ReactComponent as Naira } from "assets/svg/naira-icon.svg";
import { ReactComponent as BankIcon } from "assets/svg/bank-icon.svg";
import { convertObjectToURLParams, redirectTo } from "utilities/general";
import { useParams } from "react-router";
interface PaymentConfirmationModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  // campaignId?: string;
}

export const PaymentConfirmationModal: FC<PaymentConfirmationModalProps> = ({
  //   isOpen = false,

  onClose = () => {},
  ...rest
}) => {
  const { initModal, getModalPayload } = useModalProvider();
  // const { initNotification } = usePageNotificationProvider();
  const modalPayload = getModalPayload("requestPayment");
  const campaignId = modalPayload?.id;
  const values = modalPayload?.values;
  const availableFunds = modalPayload?.availableFunds;

  console.log("modal", campaignId);
  const handleProceed = () => {
    initModal((prev) => ({ ...prev, requestPayment: false }));
    redirectTo(
      `/exporter/campaign/verify-payment/${campaignId}?${convertObjectToURLParams(
        values
      )}`
    );
  };
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, requestPayment: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Proceed"}
        proceedButtonProps={{
          className: "btn-lg border ms-0 primary-btn w-100 text-white",
          onClick: handleProceed,
        }}
        cancelButtonDefaultChild={"Cancel"}
        cancelButtonProps={{
          className: "btn-lg border ms-0 w-100 mx-4",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              requestPayment: false,
            }));
          },
        }}
        useHeader={true}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className: "d-flex w-100 pb-0 px-0 mt-2",
        }}
        title={""}
        size={"md"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className=" d-flex flex-column align-items-center">
            <ConfirmIcon className="mb-3" />
            <h5 className="fw-600 text-grey-900 mb-3">Payment Confirmation</h5>
          </div>
          <div>
            <div className="d-flex items-align-center gap-4 py-3">
              <p className="m-0 fs-14 fw-400 text-grey-600">Pay</p>
              <div className="d-flex gap-1 align-items-center">
                <Naira />
                <h5 className="fw-400 fs-20 text-grey-800 m-0">
                  {availableFunds}
                </h5>
              </div>
            </div>
            <hr />

            <div className="d-flex gap-4 align-items-center py-3">
              <p className="m-0 fs-14 fw-400 text-grey-600">To</p>
              <div className="d-flex gap-4 align-items-center">
                <BankIcon />
                <div>
                  <p className="m-0 text-grey-900 fs-16 fw-400">
                    {values?.bankName}
                  </p>
                  <div className="text-grey-500 fs-14 fw-400 pt-2 d-flex align-items-center gap-2">
                    <p className="m-0">{values?.accountNumber}</p>
                    <div
                      className="rounded-circle bg-grey-500"
                      style={{ width: "5px", height: "5px" }}
                    ></div>
                    <p className="m-0">{values?.accountName}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
