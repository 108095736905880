import {
  PrimaryInput,
  PrimarySelect,
  PrimaryPhone,
  PrimaryButton,
} from "components";
import React, { FC, useEffect, useState } from "react";
import { BsPencil } from "react-icons/bs";
import { User, setCredential, useAuth } from "store/auth";
import { useFormik } from "formik";
import UserIcon from "assets/svg/profile-circle.svg";
import {
  UpdateProfilePayload,
  useCancelProfileChangeRequestMutation,
  useGetProfileInformationQuery,
  useUpdateProfileMutation,
} from "store/profile";
import { useModalProvider, usePageNotificationProvider } from "providers";
import { useDispatch } from "react-redux";
import { resolveApiError } from "utilities";
import { useWindowWidth } from "utilities/windowWidth";
import { useGetRejectedDocumentsQuery } from "store/verificationCenter";

interface ContactAddressViewBoxProps {
  profile: User | null;
  initialProfileValues: UpdateProfilePayload;
  isEditable: boolean;
}

const contactAddressItems = [
  {
    name: "country",
    type: "select",
    label: "Country of Residence",
    placeholder: "",
  },
  {
    name: "state",
    type: "select",
    label: "State",
    placeholder: "",
  },
  {
    name: "city",
    type: "select",
    label: "City",
    placeholder: "",
  },
  {
    name: "postalCode",
    type: "text",
    label: "Postal Code",
    placeholder: "Enter postal code",
  },
  {
    name: "address",
    type: "text",
    label: "Home Address",
    placeholder: "Enter home address",
  },
];

export const ContactAddressViewBox: FC<ContactAddressViewBoxProps> = ({
  initialProfileValues,
  isEditable,
}) => {
  const windowWidth = useWindowWidth();
  const { user, token: access_token } = useAuth();
  const dispatch = useDispatch();
  const [request, { isLoading }] = useUpdateProfileMutation();
  const { initNotification } = usePageNotificationProvider();
  const { initModal } = useModalProvider();
  const { data: profileInfo, refetch } = useGetProfileInformationQuery(
    (() => {})(),
    {
      pollingInterval: 30000,
    }
  );
  const [cancelProfileRequestUpdate] = useCancelProfileChangeRequestMutation();
  const profile = profileInfo?.data || user;
  const [toggleEdit, setToggleEdit] = useState<number | null>(null);
  const [requestCanceled, setRequestCanceled] = useState(false);
  const { data: documents } = useGetRejectedDocumentsQuery((() => {})(), {
    pollingInterval: 30000,
  });
  const data = documents?.data;

  const userRequest = data?.dataRequestChange.some(
    (requestItem: any) => requestItem.dataRequestDocument.request === "address"
  );

  const handleCancelContactChangeRequest = () => {
    const targetRequest = data?.dataRequestChange.find(
      (requestItem: any) =>
        requestItem.dataRequestDocument.request === "address"
    );

    if (targetRequest) {
      const { _id: payload } = targetRequest.dataRequestDocument;
      cancelProfileRequestUpdate(payload)
        .unwrap()
        .then((res: any) => {
          if (res.error) {
            initNotification({
              message: res.message,
              scheme: "error",
            });
            return;
          }
          initNotification({
            message: "Request Cancelled Successfully",
          });
          setRequestCanceled(true);
          refetch();
        })
        .catch((error: any) => {
          console.log(error);
          initNotification({ message: error.message, scheme: "error" });
        });
    } else {
      initNotification({
        message: "No pending request found for name, gender, or dateOfBirth",
        scheme: "info",
      });
    }
  };

  const displayCancelButton = userRequest && !requestCanceled;

  const handleEditToggle = (index: number | null) => {
    setToggleEdit(index);
  };

  const initialValues = {
    country: (initialProfileValues?.country as string) ?? "",
    state: (initialProfileValues?.state as string) ?? "",
    city: (initialProfileValues?.city as string) ?? "",
    postalCode: (initialProfileValues?.postalCode as string) ?? "",
    address: (initialProfileValues?.address as string) ?? "",
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      // validationSchema: CreatePersonalDetailsValidationSchema,
      onSubmit: () => initRequest(),
    });

  const [countries, setCountries] = useState<any>([]);
  const [states, setStates] = useState<any["states"][0]>([]);
  const [cities, setCities] = useState<any["states"][0]["cities"][0]>([]);

  const handleFetchCountriesJson = () => {
    import("assets/json/countries+states+cities.json")
      .then((data) => {
        // console.log(data,)
        const extract = data ? Object.values(data) : null;
        if (extract) {
          const countries = extract as any;
          let mapped: any[] = [];
          countries.map((c: { name: string | any[] }, _i: any) => {
            if (c.name && c.name.length) {
              let mappedObj: any = {
                label: c.name,
                value: c.name,
                data: c,
              };
              mapped.push(mappedObj);
            }
          });
          setCountries(mapped);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleFetchCountriesJson();
  }, []);

  useEffect(() => {
    // mapCountries();
    const selectedCountry: any = countries.find(
      (c: { value: any }) => c.value === values.country
    );
    if (selectedCountry) {
      setStates(selectedCountry.data.states);
    }
    const selectedState: any = states.find(
      (c: { name: any }) => c.name === values.state
    );
    if (selectedState) {
      setCities(selectedState.cities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.country, values.state]);

  const initRequest = () => {
    request({ ...initialProfileValues, ...values })
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "Contact detail saved",
        });
        refetch();
        dispatch(
          setCredential({
            user: res.data,
            access_token,
          })
        );
        setToggleEdit(null);
      })
      .catch((error) => {
        console.log(error);
        if (error.data.statusCode === 403) initModal({ sessionExpired: true });
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  return (
    <div
      className={`details-box ${
        windowWidth >= 768 ? "border-1 border-grey-100 p-34-px" : "pt-1"
      } br-8 mb-34-px`}
    >
      <div className="w-100 d-flex justify-content-between align-items-center mb-32-px">
        <h5 className="text-grey-900 fw-600">Contact Address</h5>

        {displayCancelButton ? (
          <PrimaryButton
            type="button"
            onClick={handleCancelContactChangeRequest}
            className="btn-lg secondary-error-btn"
          >
            Cancel Pending Request
          </PrimaryButton>
        ) : (
          <PrimaryButton
            type="button"
            onClick={() => {
              if (profile?.isVerified === "accepted") {
                initModal((prev) => ({
                  ...prev,
                  editContactDetails: true,
                }));
              } else {
                initNotification({
                  message:
                    "Account not verified, please verify your account to proceed",
                  scheme: "error",
                });
              }
            }}
            className="btn-lg secondary-btn"
          >
            Request Contact Address Change
          </PrimaryButton>
        )}
      </div>

      {windowWidth >= 768 && <hr className="border-1 border-grey-200" />}
      <div className="pt-4 pb-1">
        {contactAddressItems.map(
          (
            detail: {
              name: any;
              type: string;
              label: string;
              placeholder: string;
            },
            index
          ) => (
            <div
              className="row align-items-center justify-content-between mb-30-px"
              key={index}
            >
              {toggleEdit !== index ? (
                <>
                  <div className="col-md-4">
                    <label className="text-grey-500 fs-15 fw-400" htmlFor="">
                      {detail.label}
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0 fs-15 text-capitalize">
                        {profile![detail.name as keyof typeof values] ?? "None"}
                      </p>
                      {/* {isEditable ? (
                        <button
                          type="button"
                          onClick={() => handleEditToggle(index)}
                          className="border-0 bg-transparent text-primary-600 text-end"
                        >
                          <BsPencil />
                        </button>
                      ) : ( */}
                      <p className="mb-0">&nbsp;</p>
                      {/* )} */}
                    </div>
                  </div>
                </>
              ) : (
                <form onSubmit={handleSubmit} className="col-12">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="max-w-300 w-95">
                      {(detail.type === "text" ||
                        detail.type === "date" ||
                        detail.type === "email") && (
                        <PrimaryInput
                          type={detail.type}
                          label={detail.label}
                          name={detail.name}
                          placeholder={detail.placeholder}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values[detail.name as keyof typeof values]}
                          autoFocus
                          // error={Boolean(errors?.email && touched?.email)}
                          // bottomText={errors?.email}
                        />
                      )}
                      {
                        detail.type === "select" &&
                          (detail.name === "country" ? (
                            <PrimarySelect
                              name="country"
                              label="Country"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.country}
                              error={Boolean(
                                errors?.country && touched?.country
                              )}
                              bottomText={errors?.country || ""}
                            >
                              <option hidden>Select Country</option>
                              {countries?.map(
                                (
                                  c: {
                                    value:
                                      | string
                                      | number
                                      | readonly string[]
                                      | undefined;
                                    label: string;
                                  },
                                  i: React.Key | null | undefined
                                ) => (
                                  <option key={i} value={c.value}>
                                    {c.label}
                                  </option>
                                )
                              )}
                            </PrimarySelect>
                          ) : detail.name === "state" ? (
                            <PrimarySelect
                              name="state"
                              label="State"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.state}
                              error={Boolean(errors?.state && touched?.state)}
                              bottomText={errors?.state || ""}
                            >
                              <option hidden>Select State</option>
                              {states.map(
                                (c: any, i: React.Key | null | undefined) => (
                                  <option key={i} value={c.name}>
                                    {c.name}
                                  </option>
                                )
                              )}
                            </PrimarySelect>
                          ) : (
                            <PrimarySelect
                              name="city"
                              label="City"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city}
                              error={Boolean(errors?.city && touched?.city)}
                              bottomText={errors?.city || ""}
                            >
                              <option hidden>Select City</option>
                              {cities.map(
                                (c: any, i: React.Key | null | undefined) => (
                                  <option key={i} value={c.name}>
                                    {c.name}
                                  </option>
                                )
                              )}
                            </PrimarySelect>
                          ))
                        // <PrimarySelect
                        //   name={detail.name}
                        //   label={detail.label}
                        //   onChange={handleChange}
                        //   onBlur={handleBlur}
                        //   autoFocus
                        //   value={values[detail.name as keyof typeof values]}
                        //   error={Boolean(
                        //     errors[detail.name as keyof typeof values] &&
                        //       touched[detail.name as keyof typeof values]
                        //   )}
                        //   bottomText={
                        //     errors[detail.name as keyof typeof values] || ""
                        //   }
                        // >
                        //   <option hidden>Select Gender</option>
                        //   <option value="male">Male</option>
                        //   <option value="female">Female</option>
                        // </PrimarySelect>
                      }
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <PrimaryButton
                        type="button"
                        onClick={() => handleEditToggle(null)}
                        className="btn-lg secondary-btn mt-3 me-3 align-self-baseline"
                      >
                        Cancel
                      </PrimaryButton>
                      <PrimaryButton
                        type="submit"
                        className="btn-lg primary-btn mt-3 align-self-baseline"
                        isLoading={isLoading}
                      >
                        Submit
                      </PrimaryButton>
                    </div>
                  </div>
                </form>
              )}
            </div>
          )
        )}
      </div>
      {windowWidth <= 768 && <hr className="border-1 border-grey-400" />}
    </div>
  );
};
