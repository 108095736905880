import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { Image } from "@chakra-ui/react";
import { useModalProvider } from "providers/modalProvider";
import { useWindowWidth } from "utilities/windowWidth";

interface EditCampaignInformationDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const EditCampaignMediaDialog: FC<
  EditCampaignInformationDialogProps
> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const windowWidth = useWindowWidth();
  const { initModal, getModalPayload } = useModalProvider();
  const campaignMedia = getModalPayload("editCampaignMedia");
  const imgCounter = campaignMedia?.length > 1 ? "images" : "image";

  function extractFileNameFromUrl(url: string | URL) {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const pathSegments = pathname.split("/");
    const filenameWithParams = pathSegments[pathSegments.length - 1];
    const filenameSegments = filenameWithParams.split("?");
    const filename = filenameSegments[0];
    return filename;
  }

  return (
    <>
      <ChakraAlertDialog
        useFooter={false}
        useCancelButton={false}
        onClose={() => {
          initModal((prev) => ({ ...prev, editCampaignMedia: false }));
        }}
        useProceedButton={false}
        useHeader={true}
        headerProps={{
          className:
            windowWidth <= 768
              ? "px-0 pt-0 text-grey-600 fw-600 mt-3"
              : "px-0 pt-0 text-grey-600 fw-600",
        }}
        useFloatingCancel={false}
        title={"Campaign Media Files"}
        size={windowWidth >= 768 ? "4xl" : "full"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <p className="w-100">
          {campaignMedia?.length ?? "No"} {imgCounter}
        </p>
        <div
          className="max-h-500 overflow-auto"
          style={{
            maxHeight: windowWidth >= 768 ? "400px" : "600px",
            marginTop: windowWidth >= 768 ? "0" : "40px",
          }}
        >
          <div className="d-flex flex-column align-items-center gap-3">
            <div
              className={`d-flex flex-wrap ${
                campaignMedia?.length > 2 ? "justify-content-between" : ""
              } w-100 gap-3`}
            >
              {campaignMedia?.length > 0 &&
                campaignMedia?.map((media: string, i: number) => (
                  <div
                    key={i}
                    style={{
                      height: "166px",
                      maxWidth: "100%",
                      width: "23%",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "1px solid #8080801f",
                    }}
                  >
                    {extractFileNameFromUrl(media).includes("mp4") ||
                    extractFileNameFromUrl(media).includes("mpeg") ? (
                      <>
                        {/* <video
                          src={media}
                          width={"100%"}
                          // maxWidth={"100%"}
                          height={"166px"}
                          controls
                        ></video> */}
                        <iframe
                          title={extractFileNameFromUrl(media)}
                          src={media}
                          width={"100%"}
                          // maxWidth={"100%"}
                          height={"100%"}
                          allowFullScreen
                        />
                      </>
                    ) : (
                      <Image
                        src={media}
                        alt={"Image" + (i + 1)}
                        width={"100%"}
                        // maxWidth={"100%"}
                        height={"100%"}
                        objectFit={"cover"}
                        objectPosition={"top"}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
