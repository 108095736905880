import React from "react";
import { Dashboard } from "./components";
import { PrimaryButton } from "components/buttons";
import { Link } from "react-router-dom";
import { useWindowWidth } from "utilities";

export const ExporterDashboardPage = () => {

  return (
    <section className="exporter-dashboard px-3">
      <div className="d-flex flex-row justify-content-between align-items-center mb-4">
        <h3 className="fw-600">Dashboard</h3>
        <PrimaryButton className="btn-md primary-btn">
          <Link to="/exporter/campaign/create" className={useWindowWidth() >= 768 ? 'fs-14' : 'fs-12'}>Create New Campaign</Link>
        </PrimaryButton>
      </div>
      <Dashboard />
    </section>
  );
};
