import { ModalViewStateProps } from "providers";
import { LogoutAlertDialog } from "components";
import { SessionExpiryDialog } from "./sessionExpiryDialog";
import { TwoFaVerificationModal } from "./twoFaVerificationModal";
import { InactivityDialog } from "./inActivityDialog";

export interface AuthDialogsProps {
  modal?: ModalViewStateProps;
  onInitModal?: React.Dispatch<React.SetStateAction<ModalViewStateProps | undefined>>;
}
export const AuthDialogs: React.FC<AuthDialogsProps> = ({ modal, onInitModal = () => {} }) => {
  return (
    <>
      <LogoutAlertDialog
        isOpen={Boolean(modal?.logout)}
        onClose={() => onInitModal((prev) => ({ ...prev, logout: false }))}
      />
      <InactivityDialog
        isOpen={Boolean(modal?.inactivity)}
        onClose={() => onInitModal((prev) => ({ ...prev, inactivity: false }))}
      />
      <SessionExpiryDialog
        isOpen={Boolean(modal?.sessionExpired)}
        onClose={() => onInitModal((prev) => ({ ...prev, sessionExpired: false }))}
      />
      <TwoFaVerificationModal
        isOpen={Boolean(modal?.verify2fa)}
        onClose={() => onInitModal((prev) => ({ ...prev, verify2fa: false }))}
      />
    </>
  );
};
