// import { HeaderComponent } from "pages/home/components";
import { AdminHeaderComponent } from "./headerComponent";
// import { CreatorSideBarComponent } from "../components";
import { useGeneralAppProvider } from "providers";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "store/auth";
import { AuthVerifyContainer } from "pages/auth/components";
import { AdminSideBarComponent } from "./sideBar";

export const AdminDashboardContainer = () => {
  const [hamburger, setHamburger] = useState(false);
  const { user } = useAuth();
  const match = useMatch("/backer/*");
  const navigate = useNavigate();
  const { setFullScreenLoading } = useGeneralAppProvider();
  const page = match ? match.params["*"] : undefined;
  const [active, setActive] = useState(false);
  const width = window.innerWidth;

  useEffect(() => {
    if (width > 1000) {
      setActive(false);
    }
  }, [width]);

  useEffect(() => {
    if (user?.role === "admin") {
      navigate("/admin/dashboard");
    }
  }, [navigate, user?.role]);

  return (
    <div className='exporter-dashboard-container'>
      {/*========== HEADER =========== */}
      <AdminHeaderComponent hamburger={hamburger} setHamburger={setHamburger} />
      {/*========== END OF HEADER =========== */}

      <section className='bg-grey-50' style={{ minHeight: "100vh" }}>
        <div className='h-100 d-flex align-items-center'>
          {/* <button
              onClick={() => setActive(!active)}
              className="exporter-menu-btn my-4"
            >
              <HiMenuAlt2 className="header-menu-icon" />
            </button> */}
          <AdminSideBarComponent
            page={page}
            active={active}
            width={width}
            close={setHamburger}
            className={`${!hamburger && "d-none d-md-block"}`}
          />
          {/* </div> */}
          <div className='right-dashboard bg-grey-50' style={{ minHeight: "100vh" }}>
            <div className='p-4 pb-0 bg-grey-50 h-100' style={{ minHeight: "100vh" }}>
              <div className='pt-2 h-100'>
                <Outlet />
                {/* <AuthVerifyContainer /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
