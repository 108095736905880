import React, { FC, useState } from "react";
import { User } from "store/auth";
import { useWindowWidth } from "utilities/windowWidth";
import { BsPencil } from "react-icons/bs";
import { useModalProvider } from "providers/modalProvider";
import { PrimaryButton } from "components/buttons";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useCancelProfileChangeRequestMutation } from "store/profile";
import { useGetRejectedDocumentsQuery } from "store/verificationCenter";
// import { resolveApiError } from "utilities";

interface BusinessInformationViewBoxProps {
  profile: User | null;
  isEditable?: boolean;
}

export const BusinessInformationViewBox: FC<
  BusinessInformationViewBoxProps
> = ({ profile }) => {
  const windowWidth = useWindowWidth();
  const { initModal } = useModalProvider();
  const handleEditToggle = () => {};
  const [cancelProfileRequestUpdate] = useCancelProfileChangeRequestMutation();
  const { data: documents } = useGetRejectedDocumentsQuery();

  const data = documents?.data;
  console.log("data", data);
  const [requestCanceled, setRequestCanceled] = useState(false);

  const userRequest = data?.dataRequestChange.some(
    (requestItem: any) =>
      requestItem.dataRequestDocument.request === "CACRegNo" ||
      requestItem.dataRequestDocument.request === "businessName" ||
      requestItem.dataRequestDocument.request === "TIN" ||
      requestItem.dataRequestDocument.request === "exportCertificate"
  );
  console.log("approv", userRequest);
  const handleCancelContactChangeRequest = () => {
    const targetRequest = data?.dataRequestChange.find((requestItem: any) =>
      ["CACRegNo", "businessName", "exportCertificate", "TIN"].includes(
        requestItem.dataRequestDocument.request
      )
    );

    if (targetRequest) {
      const { _id: payload } = targetRequest.dataRequestDocument;
      cancelProfileRequestUpdate(payload)
        .unwrap()
        .then((res: any) => {
          if (res.error) {
            initNotification({
              message: res.message,
              scheme: "error",
            });
            return;
          }
          initNotification({
            message: "Request Cancelled Successfully",
          });
          setRequestCanceled(true);
          // refetch();
        })
        .catch((error: any) => {
          console.log(error);
          initNotification({ message: error.message, scheme: "error" });
        });
    } else {
      initNotification({
        message: "No pending request found for name, gender, or dateOfBirth",
        scheme: "info",
      });
    }
  };
  const displayCancelButton = userRequest && !requestCanceled;
  const { initNotification } = usePageNotificationProvider();

  return (
    <>
      {profile?.role === "exporter" && (
        <div
          className={`details-box ${
            windowWidth >= 768 ? "border-1 border-grey-100 p-34-px" : ""
          } br-8`}
        >
          <div className="w-100 d-flex justify-content-between align-items-center mb-32-px">
            <h5 className="text-grey-900 fw-600 mb-0">Business Information</h5>
            {displayCancelButton ? (
              <PrimaryButton
                type="button"
                onClick={handleCancelContactChangeRequest}
                className="btn-lg secondary-error-btn"
              >
                Cancel Pending Request
              </PrimaryButton>
            ) : (
              <PrimaryButton
                type="button"
                onClick={() => {
                  if (profile?.isVerified === "accepted") {
                    initModal((prev) => ({
                      ...prev,
                      editBusinessInformation: true,
                    }));
                  } else {
                    initNotification({
                      message:
                        "Account not verified, please verify your account to proceed",
                      scheme: "error",
                    });
                  }
                }}
                className="btn-lg secondary-btn"
              >
                Request Business Information Change
              </PrimaryButton>
            )}
          </div>
          {windowWidth >= 768 && <hr className="border-1 border-grey-200" />}
          <div className="pt-4">
            <div className="row align-items-center justify-content-between mb-30-px ">
              <div className="col-md-4">
                <label className="text-grey-500 fs-15 fw-400" htmlFor="">
                  Business Name
                </label>
              </div>
              <div className="col-md-8">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-15 text-capitalize">
                    {profile.businessInformation?.businessName ?? "-"}
                  </p>
                  {/* <button
                    type="button"
                    onClick={() => {
                      initModal({
                        passwordConfirmation: {
                          payload: {
                            handleEditToggle,
                            title: "Change Business Name",
                          },
                        },
                      });
                    }}
                    className="border-0 bg-transparent text-primary-600 text-end"
                  >
                    <BsPencil />
                  </button> */}
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-between mb-30-px ">
              <div className="col-md-4">
                <label className="text-grey-500 fs-15 fw-400" htmlFor="">
                  Type of Goods
                </label>
              </div>
              <div className="col-md-8">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-15 text-capitalize">
                    {profile.businessInformation?.exportGoodType ?? "None"}
                  </p>
                  <p className="mb-0">&nbsp;</p>
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-between mb-30-px ">
              <div className="col-md-4">
                <label className="text-grey-500 fs-15 fw-400" htmlFor="">
                  TIN
                </label>
              </div>
              <div className="col-md-8">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-15 text-capitalize">
                    {profile.businessInformation?.TIN ?? "None"}
                  </p>
                  {/* <button
                    type='button'
                    onClick={() => {
                      initModal({
                        passwordConfirmation: {
                          payload: {
                            handleEditToggle,
                            title: "Change TIN",
                          },
                        },
                      });
                    }}
                    className='border-0 bg-transparent text-primary-600 text-end'
                  >
                    <BsPencil />
                  </button> */}
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-between mb-30-px ">
              <div className="col-md-4">
                <label className="text-grey-500 fs-15 fw-400" htmlFor="">
                  CAC Registration Number
                </label>
              </div>
              <div className="col-md-8">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-15 text-capitalize">
                    {profile.businessInformation?.CACRegNo ?? "-"}
                  </p>
                  {/* <button
                    type="button"
                    onClick={() => {
                      initModal({
                        passwordConfirmation: {
                          payload: {
                            handleEditToggle,
                            title: "Change CAC Registration Number",
                          },
                        },
                      });
                    }}
                    className="border-0 bg-transparent text-primary-600 text-end"
                  >
                    <BsPencil />
                  </button> */}
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-between mb-30-px ">
              <div className="col-md-4">
                <label className="text-grey-500 fs-15 fw-400" htmlFor="">
                  How long have you been exporting?
                </label>
              </div>
              <div className="col-md-8">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-15 text-capitalize">
                    {profile.businessInformation?.yearsInExport?.replace(
                      "_",
                      " "
                    ) ?? "None"}
                  </p>
                  <p className="mb-0">&nbsp;</p>
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-between mb-30-px ">
              <div className="col-md-4">
                <label className="text-grey-500 fs-15 fw-400" htmlFor="">
                  Frequency of export transactions
                </label>
              </div>
              <div className="col-md-8">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-15 text-capitalize">
                    {profile.businessInformation?.exportFrequency?.replace(
                      "_",
                      " "
                    ) ?? "None"}
                  </p>
                  <p className="mb-0">&nbsp;</p>
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-between mb-30-px ">
              <div className="col-md-4">
                <label className="text-grey-500 fs-15 fw-400" htmlFor="">
                  Export countries
                </label>
              </div>
              <div className="col-md-8">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-15 text-capitalize">
                    {profile.businessInformation?.countriesOfExport?.length
                      ? profile.businessInformation?.countriesOfExport?.join(
                          ", "
                        )
                      : "None"}
                  </p>
                  <p className="mb-0">&nbsp;</p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <label className="text-grey-500 fs-15 fw-400" htmlFor="">
                  ERC
                </label>
              </div>
              <div className="col-md-8">
                <div className="d-flex align-items-center justify-content-between">
                  {profile.businessInformation?.exportRegCertificateUrl ? (
                    <a
                      href={
                        profile.businessInformation?.exportRegCertificateUrl
                      }
                      target="blank"
                      className="border-0 bg-transparent text-decoration-none text-primary-600 fw-400"
                    >
                      View file
                    </a>
                  ) : (
                    <p className="mb-0 fs-15">None</p>
                  )}
                  {/* <button
                    type="button"
                    onClick={() => {
                      initModal({
                        passwordConfirmation: {
                          payload: {
                            handleEditToggle,
                            title: "Change ERC Document",
                          },
                        },
                      });
                    }}
                    className="border-0 bg-transparent text-primary-600 text-end"
                  >
                    <BsPencil />
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
