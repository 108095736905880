import { ChakraAlertDialog, PrimaryButton } from "components";
import React, { FC, useEffect, useState } from "react";
import { ForgotPayload, useForgotMutation, useLogout } from "store/auth";
import { ReactComponent as VerifiedIcon } from "assets/svg/verified-icon.svg";
import { useModalProvider } from "providers/modalProvider";
import { Link } from "react-router-dom";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities";

interface ForgetPasswordSuccessDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const ForgetPasswordSuccessDialog: FC<
  ForgetPasswordSuccessDialogProps
> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal, getModalPayload } = useModalProvider();
  const [request, { isLoading }] = useForgotMutation();
  const { initNotification } = usePageNotificationProvider();
  const email = getModalPayload("forgetPasswordSuccess");
  const payload = email?.email;
  const login = () => {
    window.location.replace("/login");
    onClose();
  };
  const [countdown, setCountdown] = useState(0);
  const [clickCount, setClickCount] = useState(0);

  const resendEmail = (event: React.MouseEvent<HTMLParagraphElement>) => {
    if (countdown > 0) {
      return;
    }
    setClickCount((prevCount) => prevCount + 1);
    if (clickCount === 2) {
      setCountdown(900); //15mins countdown
      setClickCount(0);
    }
    request(payload)
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "A link has been sent to your email.",
        });
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  useEffect(() => {
    let timerId: any;
    if (countdown > 0) {
      timerId = setTimeout(() => {
        setCountdown((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearTimeout(timerId);
  }, [countdown]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  return (
    <>
      <ChakraAlertDialog
        // isOpen={isOpen}
        useFooter={true}
        useCancelButton={false}
        useProceedButton={false}
        useHeader={false}
        proceedButtonDefaultChild={"Log In"}
        modalFooterProps={{
          className: "justify-content-center pb-0",
        }}
        title={"Email Sent"}
        size={"md"}
        closeOnOverlayClick={false}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center">
            <VerifiedIcon className="mb-3" />
            <h5 className="fw-600 text-grey-900 mb-3">Email Sent</h5>
            <p className="fw-400 text-grey-500 mb-2">
              You will get an email shortly, please check your spam folder if
              you don't see it in your inbox. Didn't get an email? Click the
              button below to resend the email.
            </p>

            <PrimaryButton onClick={login} className="primary-btn w-100 btn-sm">
              Back to Login
            </PrimaryButton>

            <p
              onClick={resendEmail}
              className={`cursor-pointer mt-3 fw-600 text-primary-500 mb-2 ${
                countdown > 0 ? "not-allowed" : ""
              }`}
            >
              {countdown > 0
                ? `You can resend Email after (${formatTime(countdown)})`
                : "Resend Email"}
            </p>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
