import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { useModalProvider } from "providers/modalProvider";
import { useWindowWidth } from "utilities/windowWidth";
import { useGetCampaignQuery } from "store/campaigns";
import queryString from "query-string";

interface EditCampaignGoalsDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const EditCampaignGoalsDialog: FC<EditCampaignGoalsDialogProps> = ({
  //   isOpen = false,

  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  const windowWidth = useWindowWidth();

  const { id } = queryString.parse(window.location.search) as {
    id: string;
  };
  const { data: campaign } = useGetCampaignQuery(id, {
    pollingInterval: 30000,
  });
  console.log(campaign);
  return (
    <>
      {campaign && (
        <ChakraAlertDialog
          useFooter={false}
          useCancelButton={false}
          onClose={() => {
            initModal((prev) => ({ ...prev, editCampaignGoals: false }));
          }}
          useProceedButton={false}
          useHeader={true}
          headerProps={{
            className:
              windowWidth <= 768 ? "px-0 pt-0 text-grey-600 fw-600 mt-3" : "px-0 pt-0 text-grey-600 fw-600",
          }}
          useFloatingCancel={false}
          title={"Campaign Goals"}
          size={windowWidth >= 768 ? "md" : "full"}
          closeOnOverlayClick={true}
          modalContentProps={{ className: "rounded-3 p-4" }}
          modalBodyProps={{ className: "p-0" }}
          {...rest}
        >
          <div className=''>
            <div className='text-center d-flex flex-column align-items-center gap-4'>
              <div className={`d-flex ${windowWidth >= 768 ? "flex-row" : "flex-column gap-4"} w-100`}>
                <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                  <p className='text-grey-400 fw-600 mb-2'>Funding Goal</p>
                  <p className='fw-400 text-grey-900 mb-2'>
                    ₦{Number(campaign.data.fundingGoalString).toLocaleString()}
                  </p>
                </div>

                {campaign.data.minimumAmountString > 0 && (
                  <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                    <p className='text-grey-400 fw-600 mb-2'>Minimum Amount</p>
                    <p className='fw-400 text-grey-900 mb-2'>
                      ₦{Number(campaign.data.minimumAmountString).toLocaleString()}
                    </p>
                  </div>
                )}
              </div>

              <div className={`d-flex ${windowWidth >= 768 ? "flex-row" : "flex-column gap-4"} w-100`}>
                <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                  <p className='text-grey-400 fw-600 mb-2'>Interest Rate</p>
                  <p className='fw-400 text-grey-900 mb-2 text-start'>{campaign.data.interestRate}%</p>
                </div>
                <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                  <p className='text-grey-400 fw-600 mb-2'>Reward</p>
                  <p className='fw-400 text-grey-900 mb-2 text-start'>
                    ₦{Number(campaign.data.reward).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                <p className='text-grey-400 fw-600 mb-2'>Campaign End Date</p>
                <p className='fw-400 text-grey-900 mb-2'>
                  {new Date(campaign.data.campaignEndDate).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        </ChakraAlertDialog>
      )}
    </>
  );
};
