import { ChakraAlertDialog } from "components";
import { FC, useEffect, useState } from "react";
import { ReactComponent as WarningIcon } from "assets/svg/modal-warning-icon.svg";
import { useModalProvider } from "providers/modalProvider";

interface InactivityDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const InactivityDialog: FC<InactivityDialogProps> = ({ onClose = () => {}, ...rest }) => {
  const { initModal, getModalPayload } = useModalProvider();
  const modalPayload = getModalPayload("inactivity");
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (modalPayload && modalPayload.timer) {
      setCounter(modalPayload.timer);

      const intervalId = setInterval(() => {
        setCounter((prev: number) => prev - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [modalPayload]);

  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={false}
        useProceedButton={true}
        useHeader={false}
        proceedButtonDefaultChild={"Stay Signed In"}
        proceedButtonProps={{
          className: "btn-md primary-btn ms-0",
          onClick: () => {
            modalPayload && modalPayload.reset && modalPayload.reset();
            initModal((prev) => ({ ...prev, inactivity: false }));
          },
        }}
        modalFooterProps={{
          className: "justify-content-center pb-0",
        }}
        title={"Inactivity Alert"}
        size={"md"}
        closeOnOverlayClick={true}
        onClose={() => {
          modalPayload && modalPayload.reset && modalPayload.reset();
          initModal((prev) => ({ ...prev, inactivity: false }));
          onClose();
        }}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className=''>
          <div className='text-center d-flex flex-column align-items-center'>
            <WarningIcon className='mb-3' />
            <h5 className='fw-600 text-grey-900 mb-3'>Anyone there?</h5>
            <p className='fw-400 text-grey-500 mb-2'>
              You would soon be logged out due to inactivity in {counter} seconds.
            </p>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
