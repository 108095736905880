import React from "react";
import { PrimaryButton } from "components/buttons";
import { Link } from "react-router-dom";
import { AdminDashboard } from "./components";

export const AdminDashboardPage = () => {
  return (
    <section className='exporter-dashboard px-3'>
      <div className='d-flex flex-row justify-content-between align-items-center mb-4'>
        <h3 className='fw-600'>Dashboard</h3>
        <PrimaryButton className='btn-md primary-btn'>
          <Link to='#'>TBD</Link>
        </PrimaryButton>
      </div>
      <AdminDashboard />
    </section>
  );
};
