import { Flex, VStack, Box, HStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronRight } from "assets/svg/chevron-right.svg";
import { ReactComponent as FundWalletIcon } from "assets/svg/fund-wallet-icon.svg";
import { ReactComponent as ChangeWalletPinIcon } from "assets/svg/change-wallet-pin-icon.svg";
import { ReactComponent as WithdrawFundsIcon } from "assets/svg/withdraw-funds-icon.svg";
import { ReactComponent as WalletBalanceIcon } from "assets/svg/wallet-bal-icon.svg";
import { TransactionHistory } from "./transactionHistory";
import { useWindowWidth } from "utilities/windowWidth";

export const Wallet = () => {
  const windowWidth = useWindowWidth();

  return (
    <div>
      <HStack marginBottom={"56px"} gap={"20px"}>
        <Box
          className='border border-primary-100 bg-primary-25 d-flex flex-column gap-3'
          w={"390px"}
          borderRadius={"8px"}
          p={"24px"}
        >
          <HStack>
            <WalletBalanceIcon />
            <p className='fs-14 fw-400 text-primary-400 mb-0'>Wallet Balance</p>
          </HStack>
          <h2 className='mb-0 fs-24 fw-600 text-primary-600'>&#8358;0</h2>
        </Box>
        <Box
          className='border border-primary-100 bg-primary-25 d-flex flex-column gap-3'
          w={"390px"}
          borderRadius={"8px"}
          p={"24px"}
        >
          <HStack>
            <WalletBalanceIcon />
            <p className='fs-14 fw-400 text-primary-400 mb-0'>Wallet Balance</p>
          </HStack>
          <h2 className='mb-0 fs-24 fw-600 text-primary-600'>&#8358;0</h2>
        </Box>
      </HStack>
      <h5 className={`${windowWidth <= 768 ? "fs-16 mb-4" : "fs-14 mb-3"} fw-600`}>Quick Actions</h5>
      <Flex direction={windowWidth >= 768 ? "row" : "column"} gap={"40px"}>
        <VStack gap={"16px"}>
          <Link
            to='/backer/wallet/fund-wallet'
            className='d-flex flex-row justify-content-between align-items-center gap-4 p-4 border border-grey-100 campaign-item'
            style={{ height: "110px" }}
          >
            <Box className='h-100'>
              <FundWalletIcon />
            </Box>
            <div className='d-flex flex-column gap-1 max-w-240'>
              <h5 className='fw-600 fs-14'>Fund your Wallet</h5>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>
                Add money to your Krowdfi wallet so you can back campaigns swiftly
              </p>
            </div>
            <ChevronRight />
          </Link>
          <Link
            to=''
            className='d-flex flex-row justify-content-between align-items-center gap-4 p-4 border border-grey-100 campaign-item'
            style={{ height: "110px" }}
          >
            <Box className='h-100'>
              <WithdrawFundsIcon />
            </Box>
            <div className='d-flex flex-column gap-1 max-w-240'>
              <h5 className='fw-600 fs-14'>Withdraw Funds</h5>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>
                Move your money from Krowdfi wallet to your bank account
              </p>
            </div>
            <ChevronRight />
          </Link>
          <Link
            to=''
            className='d-flex flex-row justify-content-between align-items-center gap-4 p-4 border border-grey-100 campaign-item'
            style={{ height: "110px" }}
          >
            <Box className='h-100'>
              <ChangeWalletPinIcon />
            </Box>
            <div className='d-flex flex-column gap-1 max-w-240'>
              <h5 className='fw-600 fs-14'>Change Wallet PIN</h5>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>
                Make your Krowdfi wallet safer by updating your PIN when neccessary
              </p>
            </div>
            <ChevronRight />
          </Link>
        </VStack>
        <TransactionHistory />
      </Flex>
    </div>
  );
};
