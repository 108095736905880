import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { AuthHeaderText } from "components/custom";
import { PrimaryButton } from "components/buttons";
import { AccountInfoValidationSchema } from "validations/auth";
import { breadcrumbConfig } from "navigations";
import { PrimaryInput, PrimarySelect } from "components/inputs";
import { useModalProvider } from "providers/modalProvider";
import { useWindowWidth } from "utilities/windowWidth";
import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { IconButton } from "@chakra-ui/react";

export const ExporterUpdatePaymentInfo = () => {
  const windowWidth = useWindowWidth();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { bank: "", accountNumber: "", accountName: "" },
    validationSchema: AccountInfoValidationSchema(),
    onSubmit: (values) => console.log(values),
  });

  const { exporterUpdatePaymentInfo } = breadcrumbConfig;

  const isBtnDisabled = Boolean(errors.accountNumber || errors.accountName);
  const { initModal } = useModalProvider();
  return (
    <div className='d-flex flex-column max-w-500 align-items-center gap-5'>
      <form className={`w-100 ${windowWidth >= 768 ? "px-3" : ""}`} onSubmit={handleSubmit}>
        {windowWidth >= 768 && (
          <div className='breadcrumb mb-4' aria-label='breadcrumb'>
            {exporterUpdatePaymentInfo.map((item, index) => (
              <>
                <Link
                  className={`breadcrumb-item text-grey-600 ${
                    exporterUpdatePaymentInfo.length - 1 === index ? "active" : ""
                  }`}
                  to={item.to ?? "#"}
                >
                  {item.name}
                </Link>
              </>
            ))}
          </div>
        )}
        <div className={`d-flex flex-row align-items-center gap-3 ${windowWidth <= 768 && "mb-5"} `}>
          <IconButton
            aria-label='Back'
            icon={<BackIcon />}
            onClick={() => window.history.back()}
            border={"none"}
            bg={"transparent"}
          />
          <AuthHeaderText title='Update Payment Information' />
        </div>
        <div className={`mb-4 mt-4 ${windowWidth <= 768 ? "w-80" : ""}`}>
          <PrimarySelect
            label='Bank*'
            name='bank'
            options={[
              {
                text: "Select Bank",
              },
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.bank}
            bg={windowWidth <= 768 ? "white" : ""}
          />
        </div>
        <div className={`mb-4 mt-4 ${windowWidth <= 768 ? "w-80" : ""}`}>
          <PrimaryInput
            label='Account Number*'
            labelProps={{
              className: "text-grey-700",
            }}
            name='accountNumber'
            type='number'
            placeholder='Account Number'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.accountNumber}
            error={Boolean(touched.accountNumber && errors?.accountNumber)}
            bottomText={errors?.accountNumber}
            bg={windowWidth <= 768 ? "white" : ""}
          />
        </div>
        <div className={`mb-4 mt-4 ${windowWidth <= 768 ? "w-80" : ""}`}>
          <PrimaryInput
            label='Account Name*'
            name='accountName'
            type='text'
            placeholder='Account Name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.accountName}
            error={Boolean(touched.accountName && errors?.accountName)}
            bottomText={errors?.accountName}
            bg={windowWidth <= 768 ? "white" : ""}
          />
        </div>

        <div className='d-flex gap-5'>
          <PrimaryButton disabled={isBtnDisabled} type='submit' className='btn-lg primary-btn mt-4'>
            Save Changes
          </PrimaryButton>

          <PrimaryButton
            disabled={isBtnDisabled}
            type='submit'
            className='btn-lg secondary-error-btn mt-4'
            onClick={() => {
              initModal((prev) => ({
                ...prev,
                deletePaymentInfo: true,
              }));
            }}
          >
            {windowWidth >= 768 ? "Delete Payment Information" : "Delete"}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};
