import { useWindowWidth } from "utilities/windowWidth";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronRight } from "assets/svg/chevron-right.svg";
import { ReactComponent as FundWalletIcon } from "assets/svg/fund-wallet-icon.svg";
import { Box, VStack, Flex } from "@chakra-ui/react";

export default function QuickActions() {
  const windowWidth = useWindowWidth();
  return (
    <Box rounded={"8px"} p={"24px"} className='border border-grey-100' h={"fit-content"}>
      <h5 className={`${windowWidth <= 768 ? "fs-16 mb-4" : "fs-20 mb-3"} fw-600`}>Quick Actions</h5>
      <Flex direction={windowWidth >= 768 ? "row" : "column"} gap={"40px"}>
        <VStack gap={"24px"} w={"550px"} h={"fit-content"}>
          <Link
            to=''
            className='d-flex flex-row justify-content-between align-items-center gap-4 p-4 border border-grey-100 campaign-item'
            style={{ height: "110px" }}
          >
            <Box className='h-100'>
              <FundWalletIcon />
            </Box>
            <div className='d-flex flex-column gap-1'>
              <h5 className='fw-600 fs-14'>Verify KYC Information</h5>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>
                Verify and confirm that the Know Your Customer (KYC) information provided by users is accurate
              </p>
            </div>
            <ChevronRight />
          </Link>
          <Link
            to=''
            className='d-flex flex-row justify-content-between align-items-center gap-4 p-4 border border-grey-100 campaign-item'
            style={{ height: "110px" }}
          >
            <Box className='h-100'>
              <FundWalletIcon />
            </Box>
            <div className='d-flex flex-column gap-1'>
              <h5 className='fw-600 fs-14'>Review Campaigns</h5>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>
                Assess and approve campaigns to ensure they meet the requirements and standards before they go
                live
              </p>
            </div>
            <ChevronRight />
          </Link>
          <Link
            to=''
            className='d-flex flex-row justify-content-between align-items-center gap-4 p-4 border border-grey-100 campaign-item'
            style={{ height: "110px" }}
          >
            <Box className='h-100'>
              <FundWalletIcon />
            </Box>
            <div className='d-flex flex-column gap-1'>
              <h5 className='fw-600 fs-14'>Manage User Comments</h5>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>
                Control the user comment section, making sure that interactions comply with platform rules and
                guidelines
              </p>
            </div>
            <ChevronRight />
          </Link>
        </VStack>
      </Flex>
    </Box>
  );
}
