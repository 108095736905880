import { TableContainer, Table, Flex, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react";
import { PaginationChakra } from "components/custom";
import { ReactComponent as SortIcon } from "assets/svg/sort.svg";
import { ReactComponent as CreditIcon } from "assets/svg/credit-icon.svg";
import { ReactComponent as DebitIcon } from "assets/svg/debit-icon.svg";
import { useWindowWidth } from "utilities/windowWidth";
import { useGetAllTransactionsQuery } from "store/backCampaign";
import { useGeneralAppProvider } from "providers/generalAppProvider";

interface Transaction {
  _id: string;
  // backer: { firstName: string; lastName: string };
  campaign: { _id: string; title: string };
  transactionStatus: string;
  transactionId: string;
  amount: number;
  createdAt: string;
}
export const TransactionHistory = () => {
  const windowWidth = useWindowWidth();
  const { setPrimaryLoading } = useGeneralAppProvider();

  const { data: transaction, isLoading } = useGetAllTransactionsQuery();
  setPrimaryLoading(isLoading);

  const data = transaction?.data;
  return (
    <Flex
      direction={"column"}
      className={`${windowWidth >= 768 ? "border border-grey-100 py-4" : ""}`}
      borderRadius={"8px"}
    >
      <Flex
        justify={"space-between"}
        alignItems={"center"}
        paddingInline={"24px"}
        className={windowWidth >= 768 ? "mb-3" : "mb-0 border-bottom pb-3 me-3"}
      >
        <h4 className={`fw-600 mb-0 ${windowWidth >= 768 ? "" : "fs-16"}`}>
          {windowWidth >= 768 ? "Transaction History" : "History"}
        </h4>
        {windowWidth >= 768 ? (
          <Flex
            gap={"8px"}
            align={"center"}
            className='border border-grey-600 touchable'
            py={"8px"}
            px={"16px"}
            borderRadius={"8px"}
          >
            <SortIcon />
            <span className='text-grey-600'>Sort</span>
          </Flex>
        ) : (
          <p className='fs-14 text-primary-600'>View All</p>
        )}
      </Flex>
      <TableContainer overflow={"hidden"}>
        <Table variant='simple' size={"lg"} mb={windowWidth >= 768 ? "32px" : "0px"} overflow={"hidden"}>
          {windowWidth >= 768 && (
            <Thead>
              <Tr>
                <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                  Campaign Title
                </Th>
                <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                  Date
                </Th>
                <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                  Transaction ID
                </Th>
                <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                  Amount
                </Th>
                <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                  Status
                </Th>
              </Tr>
            </Thead>
          )}
          <Tbody>
            {windowWidth >= 768
              ? data?.map((item: Transaction) => (
                  <Tr key={item._id}>
                    <Td className='text-grey-600 fw-400'>{item.campaign.title}</Td>
                    <Td className='text-grey-400 fw-400'>
                      {" "}
                      {new Date(item.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </Td>
                    <Td className='text-grey-400 fw-400'>{item.transactionId}</Td>
                    <Td className='text-grey-400 fw-400'>{item.amount}</Td>

                    <Td>
                      <span
                        className={`fs-12 ${
                          item.transactionStatus.toLowerCase() === "pending"
                            ? "bg-error-50 text-error-700 text-capitalize"
                            : "bg-success-50 text-success-700 text-capitalize"
                        } fs-14 fw-500 rounded-pill`}
                        style={{ paddingBlock: "2px", paddingInline: "8px" }}
                      >
                        {item.transactionStatus}
                      </span>
                    </Td>
                  </Tr>
                ))
              : data?.map((item: Transaction) => (
                  <Tr key={item._id} overflow={"hidden"}>
                    <Td className='fw-400 d-flex flex-row gap-3 justify-content-start'>
                      {item.transactionStatus === "completed" ? <CreditIcon /> : <DebitIcon />}
                      <div>
                        <p className='text-grey-900 mb-1 fs-14'>{item.transactionStatus}</p>
                        <Td className='text-grey-400 fw-400'>
                          {" "}
                          {new Date(item.createdAt).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </Td>
                      </div>
                    </Td>
                    <Td className='text-grey-400 fw-400'>
                      <p className='text-grey-900 mb-1 fs-14 text-end'>{item.amount}</p>
                      <p className='text-grey-400 mb-0 fs-12 text-end'>ID- {item.transactionId}</p>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
        {windowWidth >= 768 && (
          <PaginationChakra totalPages={6} totalItems={6} itemsPerPage={6} onPageChange={() => {}} />
        )}
      </TableContainer>
    </Flex>
  );
};
