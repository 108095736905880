import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { PrimaryButton } from "components/buttons";
import { AuthHeaderText } from "components/custom";
import { PrimaryInput } from "components/inputs";
import { useFormik } from "formik";
import { useModalProvider, usePageNotificationProvider } from "providers";
import { Link } from "react-router-dom";
import { ForgotPayload, useForgotMutation } from "store/auth";
import { resolveApiError } from "utilities";
import { ForgotValidationSchema } from "validations";

export const ForgotPasswordPage = () => {
  const [request, { isLoading }] = useForgotMutation();
  const { initNotification } = usePageNotificationProvider();
  const { initModal } = useModalProvider();
  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { email: "" },
    onSubmit: () => initRequest(),
    validationSchema: ForgotValidationSchema(),
  });

  const initRequest = (payload?: ForgotPayload) => {
    request(payload ?? values)
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "A link has been sent to your email.",
        });

        initModal((prev) => ({
          ...prev,
          forgetPasswordSuccess: {
            payload: { email: values },
          },
        }));
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  const isBtnDisabled = Boolean(isLoading || errors.email);

  return (
    <section className="container-fluid min-vh-100 py-md-5 py-4 d-flex justify-content-center align-items-center">
      <div className="max-w-500 w-95">
        <div className="d-flex flex-column justify-content-center align-items-center gap-5">
          <Link to="/" className="d-flex flex-row gap-2 align-items-center">
            <Logo />
          </Link>
          <form
            className="auth-form-wrapper w-100 border-grey-100"
            onSubmit={handleSubmit}
          >
            <div className="w-100">
              {/* <h4 className="fw-semibold fs-1 lh-base">Forgot Password?</h4>
          <p className="fw-normal">Please enter your email address</p> */}
              <AuthHeaderText
                title="Forgot Password?"
                subTitle="Please enter your email address"
              />
              <div className="mt-4">
                <PrimaryInput
                  label="Email Address"
                  name="email"
                  placeholder="example@mail.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={Boolean(errors?.email)}
                  bottomText={errors?.email || " "}
                />
              </div>
              <div className="d-flex gap-4 mt-5 mt-md-5 justify-content-start">
                <PrimaryButton
                  type="submit"
                  isDisabled={isBtnDisabled}
                  isLoading={isLoading}
                  className="btn-lg primary-btn"
                >
                  Submit
                </PrimaryButton>
                <PrimaryButton className="btn-lg secondary-error-btn ">
                  <Link to="/login">Cancel</Link>
                </PrimaryButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
