import { Link, useParams } from "react-router-dom";
import { breadcrumbConfig } from "navigations";
import defaultCampaignImg from "assets/images/viewcampaign.png";
import { SliderMenu, PrimaryButton, AlertDefaultBox } from "components";
import { useState } from "react";
import {
  AboutCampaign,
  CampaignInfo,
  Comments,
  PostUpdates,
} from "../campaignDetails";
import {
  useGetCampaignQuery,
  useGetPaymentRequestStatusQuery,
} from "store/campaigns";
import { useGeneralAppProvider } from "providers";
import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import DefaultCampaignImg from "assets/images/viewcampaign.png";
import { useGetAllCommentsQuery } from "store/comments";
import { IconButton } from "@chakra-ui/react";
import { extractFileNameFromUrl, getDaysLeft, useWindowWidth } from "utilities";
import { useGetAllUpdatesQuery } from "store/campaignUpdates";
import { ReactComponent as NotificationIcon } from "assets/svg/alert-warning-circle.svg";
import { useAuth } from "store/auth";

export const ViewCampaigns = () => {
  const user = useAuth();
  const userRole = user?.user?.role;
  const windowWidth = useWindowWidth();
  const { campaignId } = useParams();
  const { setPrimaryLoading } = useGeneralAppProvider();
  const { data: campaign, isLoading } = useGetCampaignQuery(campaignId);
  setPrimaryLoading(isLoading);
  const { data: paymentRequestStatus } = useGetPaymentRequestStatusQuery({
    skip: "",
    limit: "",
    sort: "",
    exporterId: user?.user?._id,
  });
  console.log(paymentRequestStatus, "paymentRequestStatus");
  // const paymentStatus = paymentRequestStatus?.data[0]?.requestPaymentStatus;
  const { data: comments, refetch } = useGetAllCommentsQuery(campaignId);
  const { data: campaignUpdates, refetch: refetchUpdates } =
    useGetAllUpdatesQuery(campaignId, {
      pollingInterval: 30000,
    });

  const campaignData = campaign?.data;

  const commentsData = comments && comments?.data;
  const commentCount = commentsData?.length ?? 0;
  const campaignUpdatesData = campaignUpdates && campaignUpdates?.data;
  const updatesCount = campaignUpdatesData?.length ?? 0;

  const campaignImg = campaignData?.images[0] ?? defaultCampaignImg;

  const [activeSection, setActiveSection] = useState(0);

  const headings = [
    "About Campaign",
    `Comments(${commentCount})`,
    `Post Updates(${updatesCount})`,
  ];

  const { viewCampaigns } = breadcrumbConfig;
  const campaignEndDate = new Date(campaignData?.campaignEndDate);
  const formattedDate = campaignEndDate.toLocaleString("en-US", {
    weekday: "short",
    month: "long",
    day: "2-digit",
    year: "numeric",
  });
  return (
    <div className="text-black p-2">
      <div>
        <div className="text-black">
          {windowWidth >= 768 && (
            <>
              <div className="breadcrumb  mb-1" aria-label="breadcrumb">
                {viewCampaigns.map((item, index) => (
                  <Link
                    className={`breadcrumb-item text-grey-600 ${
                      viewCampaigns.length - 1 === index ? "active" : ""
                    }`}
                    to={item.to ?? "#"}
                    key={index}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className={`d-flex gap-3 align-items-center my-4`}>
                <h5 className="fs-24 fw-600 mb-0">{campaignData?.title}</h5>

                {!campaignData?.hasBeenReviewed ? (
                  <span className="px-2 py-1 rounded-5 bg-warning-50 text-warning-700 fw-500 fs-12 lh-1">
                    Under Review
                  </span>
                ) : null}
              </div>
              <div className="col-md-10 pb-4">
                <AlertDefaultBox
                  className="mt-3"
                  // type="outline"
                  scheme="warning"
                  icon={<NotificationIcon />}
                  body={
                    <span className={windowWidth >= 768 ? "fs-14" : "fs-12"}>
                      This campaign funds will only be disbursed if it reaches
                      its goal by <span>{formattedDate}</span> 11:59 PM WAT
                    </span>
                  }
                />
              </div>
            </>
          )}

          <div>
            {windowWidth < 768 && (
              <div
                className={`d-flex gap-3 align-items-center ${
                  windowWidth >= 768 ? "my-4" : "my-0 mb-4"
                } `}
              >
                <h5
                  className={`fw-600 ${
                    windowWidth >= 768 ? "fs-24" : "fs-20"
                  } text-grey-900 d-flex align-items-center gap-3`}
                >
                  {windowWidth <= 768 && (
                    <IconButton
                      aria-label="Back"
                      icon={<BackIcon />}
                      onClick={() => window.history.back()}
                      border={"none"}
                      bg={"transparent"}
                    />
                  )}
                  {campaignData?.title}
                </h5>
                {!campaignData?.hasBeenReviewed ? (
                  <span className="px-2 py-1 rounded-5 bg-warning-50 text-warning-700 fw-500 fs-12 lh-1">
                    Under Review
                  </span>
                ) : null}
              </div>
            )}
            <div className="row">
              <div className="col-lg-8">
                {extractFileNameFromUrl(
                  campaignData?.images[0] as string
                )?.includes("mp4") ||
                extractFileNameFromUrl(
                  campaignData?.images[0] as string
                )?.includes("mpeg") ? (
                  <>
                    <iframe
                      title={extractFileNameFromUrl(
                        campaignData?.images[0] as string as string
                      )}
                      src={campaignImg}
                      // width={"100%"}
                      // maxWidth={"100%"}
                      style={{
                        height: windowWidth < 768 ? "260px" : "434px",
                        borderRadius: "8px",
                        width: "100%",
                      }}
                      allowFullScreen
                    />
                  </>
                ) : (
                  <img
                    src={campaignData?.images[0] || DefaultCampaignImg}
                    style={{
                      objectFit: "cover",
                      objectPosition: "top",
                      height: windowWidth < 768 ? "260px" : "434px",
                      borderRadius: "8px",
                    }}
                    alt="Campaign"
                    className="w-100"
                  />
                )}
              </div>

              <div className="col-lg-4">
                <div className="bg-white shadow-sm rounded-4">
                  <CampaignInfo
                    campaign={campaignData}
                    orientation={windowWidth < 768 ? "row" : "column"}
                  />
                  <div className="p-3 mt-3">
                    {campaignData?.fundingGoal?.$numberDecimal ===
                    campaignData?.fundingGoalString ? (
                      <div>
                        <Link
                          to={`/exporter/campaign/request-payment/${campaignData?._id}`}
                        >
                          <PrimaryButton
                            className={`fw-semibold my-2 py-4 px-3 primary-btn ${
                              windowWidth >= 768 ? "w-100" : ""
                            }`}
                            disabled={
                              (campaignData &&
                                !(
                                  parseFloat(
                                    campaignData?.amountRaised?.$numberDecimal
                                  ) >=
                                    parseFloat(
                                      campaignData?.minimumAmount
                                        ?.$numberDecimal
                                    ) ||
                                  parseFloat(
                                    campaignData?.amountRaised?.$numberDecimal
                                  ) >=
                                    parseFloat(
                                      campaignData?.fundingGoal?.$numberDecimal
                                    )
                                )) ||
                              getDaysLeft(campaignData?.campaignEndDate) ===
                                "Expired"
                              // paymentStatus === "pending" ||
                              // paymentStatus === "approved"
                            }
                          >
                            Request Payment
                          </PrimaryButton>
                        </Link>
                      </div>
                    ) : (
                      <div>
                        <PrimaryButton
                          className={`fw-semibold my-2 py-4 px-3 primary-btn ${
                            windowWidth >= 768 ? "w-100" : ""
                          }`}
                          disabled={campaignData?.hasBeenReviewed}
                        >
                          Share Campaign
                        </PrimaryButton>
                      </div>
                    )}

                    <div>
                      <Link
                        to={campaignData?.riskReport}
                        target="__blank"
                        download
                      >
                        <PrimaryButton
                          className={`fw-semibold my-2 py-4 px-3 secondary-btn ${
                            windowWidth >= 768 ? "w-100" : ""
                          }`}
                          disabled={!campaignData?.hasBeenReviewed}
                        >
                          Download Risk Report
                        </PrimaryButton>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <SliderMenu
                headings={headings}
                setActiveMenu={setActiveSection}
                activeMenu={activeSection}
                userRole={userRole}
              />

              <div className="pt-2">
                {[
                  <AboutCampaign campaignData={campaignData} />,
                  <Comments
                    campaignData={campaignData}
                    comments={comments}
                    refetch={refetch}
                  />,
                  <PostUpdates
                    campaignData={campaignData}
                    updates={campaignUpdates?.data}
                    refetch={refetchUpdates}
                  />,
                ].map(
                  (item, index) =>
                    activeSection === index && <div key={index}>{item}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
