import { ReactComponent as Arrow } from "assets/svg/homepage/arrow-left.svg";
// import { ReactComponent as SeamlessImg } from "assets/svg/homepage/seamless-img.svg";
// import { ReactComponent as InterestImg } from "assets/svg/homepage/interest-img.svg";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import CampaignInterest from "assets/json/New Interest Slider.json";
import CampaignApproval from "assets/json/Quick Approval.json";
import SeamlessImg from "assets/images/seamless-img.png";
import InterestImg from "assets/images/campaign-goal-img.png";
import { useWindowWidth } from "utilities/windowWidth";

export const ExporterRevolutionize = () => {
  const windowWidth = useWindowWidth();

  return (
    <section className='content bg-grey-50'>
      <h2
        style={{
          maxWidth: windowWidth <= 768 ? "100%" : "915px",
        }}
        className={`${windowWidth <= 768 ? "fs-37 d-none" : "fs-47  block"} fw-600 text-grey-900`}
      >
        Revolutionizing the <br /> Agricultural Exports Landscape
      </h2>
      <h2 className={`${windowWidth <= 768 ? "fs-37 block w-100" : "fs-47 d-none  "} fw-600 text-grey-900`}>
        Revolutionizing the Agricultural Exports Landscape
      </h2>
      <div className='content-container'>
        {/* seamless campaign */}
        <div
          className={`${
            windowWidth <= 768 ? "" : "d-flex"
          } align-items-center justify-content-between content-container-sections`}
        >
          <div
            style={{
              maxWidth: windowWidth <= 768 ? "100%" : "603px",
            }}
            className={`bg-primary-50  section-borders`}
          >
            {" "}
            <img src={SeamlessImg} alt='seamless-img' />
          </div>
          <div
            style={{
              maxWidth: windowWidth <= 768 ? "100%" : "442px",
            }}
            className={`${windowWidth <= 768 ? " mt-5" : "mt-0"}`}
          >
            <p className='m-0 text-grey-400 fw-400 fs-14'>SEAMLESS CAMPAIGN</p>
            <h2
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "442px",
              }}
              className={`${windowWidth <= 768 ? "fs-37" : "fs-47 "} fw-600  text-grey-900 py-3`}
            >
              Campaign Creation Made Simple
            </h2>
            <h6
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "449px",
              }}
              className={`text-grey-700 fs-16 fw-400 lh-base `}
            >
              Creating a funding campaign is as easy as a few clicks. Showcase your export venture, and let
              our community of backers support your journey.
            </h6>
            <Link to='/campaign-creation'>
              <div className='d-flex gap-3 align-items-center text-primary-600 fs-16 fw-400 mt-3'>
                <h6 className='m-0'>Learn More</h6>
                <Arrow />
              </div>
            </Link>
          </div>
        </div>
        {/* seamless campaign ends */}

        {/* flexible interest rates */}
        <div
          className={`${
            windowWidth <= 768 ? "" : " d-flex flex-row-reverse"
          }  align-items-center justify-content-between content-container-sections`}
        >
          <div
            style={{
              maxWidth: windowWidth <= 768 ? "100%" : "603px",
              padding: "81px 48px 139px",
            }}
            className={`bg-primary-50 section-borders`}
          >
            {/* <InterestImg className="w-100" />{" "} */}
            <img src={InterestImg} alt='interest-img' width='507px' height='255px' />
            <div className='animated-content'>
              <Lottie animationData={CampaignInterest} />
            </div>
          </div>
          <div
            style={{
              maxWidth: windowWidth <= 768 ? "100%" : "442px",
            }}
            className={`${windowWidth <= 768 ? "mt-5" : "mt-0"}`}
          >
            <p className='m-0 text-grey-400 fw-400 fs-14'>FLEXIBLE INTEREST</p>
            <h2
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "442px",
              }}
              className={`${windowWidth <= 768 ? "fs-37" : "fs-47 "} fw-600 text-grey-900 py-3`}
            >
              Fair Interest Rates Set by You
            </h2>
            <h6
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "429px",
              }}
              className={`
              } text-grey-700 fs-16 fw-400 lh-base`}
            >
              Say goodbye to rigid interest rates dictated by banks. With Krowdfi, you decide the interest
              rate that makes sense for your business.
            </h6>
            <Link to='/login'>
              <div className='d-flex gap-3 align-items-center text-primary-600 fs-16 fw-400 mt-3'>
                <h6 className='m-0'>
                  <Link to='/register'>Get Started</Link>
                </h6>
                <Arrow />
              </div>
            </Link>
          </div>
        </div>
        {/* flexible interest rates ends*/}

        {/* zero delay */}
        <div
          className={`${
            windowWidth <= 768 ? "" : " d-flex "
          } align-items-center justify-content-between content-container-sections`}
        >
          <div
            style={{
              maxWidth: windowWidth <= 768 ? "100%" : "603px",
            }}
            className={`${windowWidth <= 768 ? " w-100" : " w-55 "}   bg-primary-50 section-borders`}
          >
            <Lottie animationData={CampaignApproval} />
          </div>
          <div
            style={{
              maxWidth: windowWidth <= 768 ? "100%" : "442px",
            }}
            className={`${windowWidth <= 768 ? "mt-5" : "mt-0"}`}
          >
            <p className='m-0 text-grey-400 fw-400 fs-14'>ZERO DELAY</p>
            <h2
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "442px",
              }}
              className={`${windowWidth <= 768 ? "fs-37" : "fs-47"} fw-600  text-grey-900 py-3`}
            >
              Quick Approval Process
            </h2>
            <h6
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "429px",
              }}
              className={`
              } text-grey-700 fs-16 fw-400 lh-base`}
            >
              Our streamlined approval process ensures you get the green light swiftly. No more waiting for
              weeks to get your export plans moving!
            </h6>
            <Link to='/approval-process'>
              <div className='d-flex gap-3 align-items-center text-primary-600 fs-16 fw-400 mt-3'>
                <h6 className='m-0'>Learn More</h6>
                <Arrow />
              </div>
            </Link>
          </div>
        </div>
        {/* zero delay ends*/}
      </div>
    </section>
  );
};
