import { ReactComponent as TransactionIcon } from "assets/svg/transaction-history.svg";
import { TransactionHistory } from "./components";
import { Flex } from "@chakra-ui/react";
import { useGetAllTransactionsQuery } from "store/backCampaign";

export const BackerTransactionHistory = () => {
  const { data: transaction, isLoading } = useGetAllTransactionsQuery((() => {})(), {
    pollingInterval: 30000,
  });
  console.log("transaction", transaction);

  const data = transaction?.data;
  return (
    <div className='mt-3 px-3'>
      <h3 className='fw-600 mb-5'>Transactions</h3>
      {!data && (
        <div className='d-flex flex-column justify-content-center align-items-center my-5 py-5'>
          <TransactionIcon />
          <div className='mt-4'>
            <p className='text-center fw-bold'>No Transaction History</p>
            <p className='mt-2 text-center text-grey-600'>Your transaction history will appear here!</p>
          </div>
        </div>
      )}
      {data && (
        <Flex gap={"40px"}>
          <TransactionHistory />
        </Flex>
      )}
    </div>
  );
};
