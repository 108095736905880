import { Badge, ProgressBar, PrimaryButton } from "components";
import { CampaignItemProps } from "./interface";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackersIcon } from "assets/svg/backers.svg";
import { ReactComponent as CalenderIcon } from "assets/svg/calender.svg";
import { getDaysLeft, formatDate, useWindowWidth, getPercentage } from "utilities";
import { CampaignData } from "store/campaigns";
import { useModalProvider } from "providers";

export const DraftCampaignsItems = ({ campaign }: CampaignItemProps) => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const { initModal } = useModalProvider();
  const formFields: (keyof CampaignData)[] = ["fundingGoal", "images", "purchaseOrder", "interestRate"];
  console.log("draft", campaign);
  const filledOutFormFields = formFields.filter(
    (field) => field in campaign && campaign[field as keyof CampaignData]
  ).length;

  const completionPercentage = (filledOutFormFields / formFields.length) * 100;
  return (
    <div className='position-relative border border-grey-100 mt-3 rounded-3 overflow-hidden'>
      <div
        className={`${
          windowWidth >= 768 ? "d-grid flex-row" : "d-flex flex-column gap-3"
        } justify-content-between align-items-center draftcampaign-item`}
      >
        <div className='d-flex flex-column gap-3 campaign-subitem'>
          <div className='d-flex align-items-center gap-3'>
            <h6 className='fw-600 text-grey-900 m-0'>{campaign.title}</h6>
            <Badge text={campaign.status} scheme={campaign.status} />
          </div>
          <p className='text-grey-600 fw-400 fs-12 m-0'>{campaign.description}</p>
        </div>

        <div className={`d-flex gap-3 flex-column ${windowWidth <= 768 ? "w-100 text-end" : ""}`}>
          <p className={`m-0 ${windowWidth ? "fs-12" : ""}`}>Date Created</p>
          <p className={`m-0 ${windowWidth ? "fs-12" : ""}`}>{formatDate(campaign.createdAt)}</p>
        </div>

        <div className={windowWidth <= 768 ? "w-100 mb-2" : ""}>
          <ProgressBar
            colorScheme='red'
            value={completionPercentage}
            width={windowWidth < 768 ? "auto" : "100%"}
          />
          <p className={`text-center text-grey-900 m-0 mt-2 ${windowWidth < 768 && "fs-12"}`}>
            {completionPercentage}% Completed
          </p>
        </div>
        <div className={`d-flex gap-4 ${windowWidth <= 768 ? "w-100 justify-content-between" : ""}`}>
          <PrimaryButton
            onClick={() => navigate(`/exporter/campaign/create?id=${campaign._id}`)}
            className='btn-sm primary-btn mt-0 px-3'
          >
            Continue Editing
          </PrimaryButton>
          <PrimaryButton
            onClick={() => {
              initModal((prev) => ({
                ...prev,
                deleteCampaign: {
                  payload: { id: campaign._id },
                },
              }));
            }}
            className='btn-sm secondary-error-btn mt-0'
          >
            Delete
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
