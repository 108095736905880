import { AlertDefaultBox } from "components/custom";
import { ReactComponent as WarningIcon } from "assets/svg/warning-icon.svg";
import { ReactComponent as NotificationIcon } from "assets/svg/noti.svg";
import { ReactComponent as FundsIcon } from "assets/svg/funds-icon.svg";
import { ReactComponent as CampaignIcon } from "assets/svg/total-campaign.svg";
import { ReactComponent as TimeIcon } from "assets/svg/time-icon.svg";
import { ReactComponent as OctIcon } from "assets/svg/octicon_tracked-by-closed-completed-24.svg";
import { Link } from "react-router-dom";
import { useAuth } from "store/auth";
import { useGetAllCampaignsQuery } from "store/campaigns";
import { useWindowWidth } from "utilities/windowWidth";
import { TableContainer, Table, Box, Flex, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react";
import { PaginationChakra } from "components/custom";
import { ReactComponent as SortIcon } from "assets/svg/sort.svg";
import { ReactComponent as CreditIcon } from "assets/svg/credit-icon.svg";
import { ReactComponent as DebitIcon } from "assets/svg/debit-icon.svg";

export const AdminUsers = () => {
  const windowWidth = useWindowWidth();
  const { user } = useAuth();
  const profileCompleted = user?.profileCompleted?.completionRate;
  const data: any = {};
  const users: any = [
    {
      name: "Emmanuel Oluwafemi",
      email: "emmanuel@oluwafemi.com",
      phone: "09087654321",
      country: "Nigeria",
      role: "Exporter",
      created_on: "12/12/2021",
    },
    {
      name: "Emmanuel Oluwafemi",
      email: "emmanuel@oluwafemi.com",
      phone: "09087654321",
      country: "Nigeria",
      role: "Backer",
      created_on: "12/12/2021",
    },
    {
      name: "Emmanuel Oluwafemi",
      email: "emmanuel@oluwafemi.com",
      phone: "09087654321",
      country: "Nigeria",
      role: "Backer",
      created_on: "12/12/2021",
    },
    {
      name: "Emmanuel Oluwafemi",
      email: "emmanuel@oluwafemi.com",
      phone: "09087654321",
      country: "Nigeria",
      role: "Exporter",
      created_on: "12/12/2021",
    },
  ];

  return (
    <div className='dashboard-wrapper'>
      <div className='dashboard-statistics'>
        {[
          {
            title: "Registered Exporters",
            icon: <FundsIcon />,
            value: data?.fundsRaised ?? "0",
          },
          {
            title: "Registered Backers",
            icon: <CampaignIcon />,
            value: data?.totalCampaigns ?? "0",
          },
          {
            title: "Pending Verification",
            icon: <TimeIcon />,
            value: data?.pendingCampaigns ?? "0",
          },
          {
            title: "Pending Campaigns",
            icon: <OctIcon />,
            value: data?.completedCampaigns ?? "0",
          },
        ].map((item, key) => (
          <Box className='statistic' key={key}>
            <div className='d-flex flex-row align-items-center justify-content-start gap-2 text-grey-400 w-100'>
              <div>{item.icon}</div>
              <p className='my-auto'>{item.title}</p>
            </div>
            <h3 className='value lh-1 mb-0 fw-600'>{item.value}</h3>
          </Box>
        ))}
      </div>

      <Flex
        direction={"column"}
        className={`${windowWidth >= 768 ? "border border-grey-100" : ""} py-4`}
        borderRadius={"8px"}
      >
        <Flex
          justify={"space-between"}
          px={windowWidth >= 768 ? "25px" : "0px"}
          mx={windowWidth <= 768 ? "24px" : "0px"}
          alignItems={"center"}
          className={windowWidth >= 768 ? "mb-3" : "mb-0 border-bottom pb-3"}
        >
          <h4 className={`fw-600 mb-0 ${windowWidth >= 768 ? "" : "fs-16"}`}>Users</h4>
          {windowWidth >= 768 ? (
            <Flex
              gap={"8px"}
              align={"center"}
              className='border border-grey-600 touchable'
              py={"8px"}
              px={"16px"}
              borderRadius={"8px"}
            >
              <SortIcon />
              <span className='text-grey-600'>Sort</span>
            </Flex>
          ) : (
            <p className='fs-14 text-primary-600'>View All</p>
          )}
        </Flex>
        <TableContainer overflow={"hidden"}>
          <Table variant='simple' size={"lg"} mb={windowWidth >= 768 ? "32px" : "0px"} overflow={"hidden"}>
            {windowWidth >= 768 && (
              <Thead>
                <Tr>
                  <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                    Name
                  </Th>
                  <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                    Email Address
                  </Th>
                  <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                    Phone Number
                  </Th>
                  <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                    Country
                  </Th>
                  <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                    Role
                  </Th>
                  <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                    Created On
                  </Th>
                </Tr>
              </Thead>
            )}
            <Tbody>
              {windowWidth >= 768
                ? users.map((item: any, index: number) => (
                    <Tr key={index} cursor={"pointer"}>
                      <Td className='text-grey-600 fw-400'>{item.name}</Td>
                      <Td className='text-grey-400 fw-400'>{item.email}</Td>
                      <Td className='text-grey-400 fw-400'>{item.phone}</Td>
                      <Td className='text-grey-400 fw-400'>{item.country}</Td>
                      <Td className='text-grey-400 fw-400'>{item.role}</Td>
                      <Td className='text-grey-400 fw-400'>{item.created_on}</Td>
                    </Tr>
                  ))
                : users.map((item: any, index: number) => (
                    <Tr key={index} overflow={"hidden"}>
                      <Td className='fw-400 d-flex flex-row gap-3 justify-content-start'>
                        <div>
                          <p className='text-grey-900 mb-1 fs-14'>{item.name}</p>
                          <p className='text-grey-400 mb-0 fs-12'>{item.email}</p>
                        </div>
                      </Td>
                      <Td className='text-grey-400 fw-400'>
                        <p className='text-grey-900 mb-1 fs-14 text-end'>{item.role}</p>
                        <p className='text-grey-400 mb-0 fs-12 text-end'>ID- {item.created_on}</p>
                      </Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
          {windowWidth >= 768 && (
            <PaginationChakra totalPages={6} totalItems={6} itemsPerPage={6} onPageChange={() => {}} />
          )}
        </TableContainer>
      </Flex>
    </div>
  );
};
