/* eslint-disable no-lone-blocks */
import {
  BackerDiversify,
  BackerJourneyProcess,
  BackerTradeSuccess,
  // BackersJourney,
  ExporterJourneyProcess,
  ExporterRevolutionize,
  ExporterStartJourney,
  // ExportersJourney,
  FaqSection,
  FooterComponent,
  HeaderComponent,
} from "./components";
import { PrimaryButton } from "components/buttons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/homepage/homepage.scss";
import { TabSlider } from "components/custom";
import { PrimaryInput } from "components/inputs";
import { useWindowWidth } from "utilities/windowWidth";
import { backerFaqs, exporterFaqs, resolveApiError } from "utilities";
import { useModalProvider } from "providers/modalProvider";
import { WaitlistSchema } from "validations/waitlist";
import { useFormik } from "formik";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useSubmitWaitListMutation } from "store/waitlist";
import ExportersJourney from "./components/exportersView/exportersJourney";

export const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const windowWidth = useWindowWidth();
  const [request, { isLoading }] = useSubmitWaitListMutation();
  const { initNotification } = usePageNotificationProvider();
  const { initModal } = useModalProvider();
  const [selectedTab, setSelectedTab] = useState(0);
  const heroSectionImg = selectedTab === 0 ? "hero-section-exporters" : "hero-section-backers";
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const { values, setValues, errors, touched, handleChange, handleBlur, resetForm, handleSubmit } = useFormik(
    {
      initialValues: initialValues,
      validationSchema: WaitlistSchema,
      onSubmit: () => submitWaitlist(),
    }
  );
  const submitWaitlist = () => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };
    request(payload)
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });

          return;
        }
        initNotification({
          message: "Success",
        });

        initModal((prev: any) => ({
          ...prev,
          waitListSuccessModal: true,
        }));
        resetForm();
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  return (
    <div>
      <section>
        <div>
          {selectedTab === 0 ? (
            <div className='bg-grey-50'>
              <div className={`hero-section ${heroSectionImg}`} style={{ paddingBottom: "150px" }}>
                <HeaderComponent selectedTab={selectedTab} />
                <div className='d-flex mb-4' style={{ marginTop: "150px" }}>
                  <TabSlider
                    items={["For Exporters", "For Backers"]}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                  />
                </div>
                <div className={`${windowWidth <= 768 ? "w-90" : " w-80"}`}>
                  <h2
                    style={{
                      maxWidth: windowWidth <= 768 ? "100%" : "918px",
                    }}
                    className={`${windowWidth <= 768 ? "fs-39 " : " fs-59  mx-auto"} fw-600 text-white`}
                  >
                    Empowering Export Businesses, One Campaign at a Time
                  </h2>
                  <h6
                    style={{
                      maxWidth: windowWidth <= 768 ? "" : "621px",
                    }}
                    className={`${
                      windowWidth <= 768 ? "lh-sm" : "lh-base"
                    } fs-20 fw-400 text-white pt-4  mx-auto`}
                  >
                    Rapidly Raise Capital, Fulfill International Orders, and Expand Your Export Ventures
                  </h6>

                  <div className='pt-4'>
                    <PrimaryButton
                      className={`${windowWidth <= 768 ? "w-100 mb-3" : " me-3"} btn-lg primary-btn`}
                    >
                      <Link to='/register'>Get Started Now</Link>
                    </PrimaryButton>
                    <PrimaryButton
                      className={`${windowWidth <= 768 ? "w-100" : " "} btn-outline-secondary px-5`}
                    >
                      <Link to='/login'>Log In</Link>
                    </PrimaryButton>
                  </div>
                </div>
              </div>
              <ExporterRevolutionize />
              <ExporterJourneyProcess />
              <FaqSection faqs={exporterFaqs} />

              <ExporterStartJourney />
            </div>
          ) : (
            <div className='bg-grey-50'>
              <div className={`hero-section ${heroSectionImg}`} style={{ paddingBottom: "150px" }}>
                <HeaderComponent selectedTab={selectedTab} />{" "}
                <div className='d-flex mb-4' style={{ marginTop: "150px" }}>
                  <TabSlider
                    items={["For Exporters", "For Backers"]}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                  />
                </div>
                <div className={`${windowWidth <= 768 ? "w-90" : " w-80"}`}>
                  {" "}
                  <h2 className={`${windowWidth <= 768 ? "fs-39" : " fs-59"} fw-600 text-white`}>
                    Invest In Opportunities
                  </h2>
                  <h6
                    style={{
                      maxWidth: windowWidth <= 768 ? "100%" : "621px",
                    }}
                    className={`${
                      windowWidth <= 768 ? "lh-sm" : "lh-base"
                    } fs-20 fw-400 text-white pt-4  mx-auto`}
                  >
                    Discover unique benefits by helping exporters access the resources they need for their
                    ventures
                  </h6>
                  <PrimaryButton
                    onClick={() => {
                      initModal((prev: any) => ({
                        ...prev,
                        waitListFormModal: true,
                      }));
                    }}
                    className='btn-md primary-btn mt-3 '
                  >
                    Join the Waitlist
                  </PrimaryButton>
                </div>
              </div>
              <BackerDiversify />
              <div id='how-it-works-section'>
                <BackerJourneyProcess />
              </div>
              <FaqSection faqs={backerFaqs} />
              <BackerTradeSuccess
                mainText='Ready to be a Catalyst for Global Agricultural Trade Success?'
                buttonText='Join the Waitlist Now'
                buttonOnClick={() => {
                  initModal((prev: any) => ({
                    ...prev,
                    waitListFormModal: true,
                  }));
                }}
              />
            </div>
          )}
        </div>
      </section>
      <FooterComponent />
    </div>
  );
};
