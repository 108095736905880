export const exporterFaqs = [
  {
    question: "How is my campaign approved?",
    answer:
      "Campaigns submitted for approval undergo review by a credit risk analyst. Prior to approval, the analyst conducts due diligence by examining supporting documents to verify the credibility of the export order.",
  },
  {
    question: "When are campaign funds disbursed?",
    answer:
      "The funds raised for a campaign are held in escrow and are not released to the exporter until the funding or flexible funding goal is achieved either before or by the campaign's deadline. In cases where campaigns do not reach their funding or flexible funding goals, the raised funds are returned to the campaign backers. ",
  },

  {
    question: "What should I do after receiving campaign funds?",
    answer:
      "Upon receiving the campaign funds, exporters are required to promptly initiate the fulfillment process for their international export orders. Additionally, exporters are obligated to provide updates by uploading Form Q and Bill of Lading documents on the platform through the 'update' section within their campaign view.",
  },
  {
    question: "How are campaign funds disbursed?",
    answer:
      "Once an exporter successfully meets a campaign's funding goal before or by the campaign deadline, they can utilize the 'Request Payment' feature to initiate the release of the campaign funds. The exporter provides bank information specifying the account for fund transfer. A credit risk analyst ensures the accuracy of the provided bank details matching the user information and approves the payment request. Subsequently, the funds are transferred directly to the exporter's designated bank account.",
  },
  {
    question: "Are my export order documents kept private?",
    answer:
      "Absolutely. Documents submitted during campaign creation remain confidential and are not disclosed to backers. These documents are essential for a credit risk analyst to evaluate the legitimacy and feasibility of an export order. Instead of sharing the documents directly, backers receive a risk report based on the credit risk analyst's assessment of the exporter's profile and campaign information.",
  },
  {
    question: "Why do I need to be verified before creating an export campaign?",
    answer:
      "Verification is essential due to the nature of our platform's services. We conduct a KYC (Know Your Customer) process to evaluate customer risk and establish a risk profile. This involves gathering information about a customer's identity, financial activities, and associated risks. Users are required to inform us of any changes to their KYC information to ensure accuracy.",
  },
];

export const backerFaqs = [
  {
    question: "Who is a backer?",
    answer:
      "A backer is an individual or entity that supports an exporter's campaign by contributing funds towards the fulfillment of the export order. Backers can pledge any amount towards a campaign and receive rewards based on their contribution level.",
  },
  {
    question: "When are campaign funds disbursed?",
    answer:
      "The funds raised for a campaign are held in escrow and are not released to the exporter until the funding or flexible funding goal is achieved either before or by the campaign's deadline. In cases where campaigns do not reach their funding or flexible funding goals, the raised funds are returned to the campaign backers. ",
  },

  {
    question: "What happens after receiving campaign funds?",
    answer:
      "Upon receiving the campaign funds, exporters are required to promptly initiate the fulfillment process for their international export orders. Additionally, exporters are obligated to provide updates by uploading Form Q and Bill of Lading documents on the platform through the 'update' section within their campaign view.",
  },
  {
    question: "How are campaign funds disbursed?",
    answer:
      "Once an exporter successfully meets a campaign's funding goal before or by the campaign deadline, they can utilize the 'Request Payment' feature to initiate the release of the campaign funds. The exporter provides bank information specifying the account for fund transfer. A credit risk analyst ensures the accuracy of the provided bank details matching the user information and approves the payment request. Subsequently, the funds are transferred directly to the exporter's designated bank account.",
  },
];
