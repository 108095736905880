import {
  AlertDefaultBox,
  AuthHeaderText,
  PrimaryButton,
  PrimaryInput,
  PrimaryPhone,
  PrimarySelect,
} from "components";
import React, { FC, useEffect, useState } from "react";
import { ReactComponent as NotVerifiedIcon } from "assets/svg/not-allowed-icon.svg";
import { ReactComponent as VerifiedIcon } from "assets/svg/account-verified-icon.svg";
import { useModalProvider } from "providers";
import { User, useAuth } from "store/auth";
import {
  UpdateProfilePayload,
  useGetProfileInformationQuery,
} from "store/profile";
import { BsPencil } from "react-icons/bs";
import { UploadAvatarButton } from "./uploadAvatarButton";
import { useFormik } from "formik";
import { PersonalDetailsViewBox } from "./personalDetailsViewBox";
import { ContactAddressViewBox } from "./contactAddressViewBox";
import { BusinessInformationViewBox } from "./businessInformationViewBox";

interface ProfileDetailsGroupProps {
  profile: User | null;
}

export const ProfileDetailsGroup: FC<ProfileDetailsGroupProps> = ({
  profile,
}) => {
  const { initModal } = useModalProvider();

  const isEditable = profile?.role === "backer";
  // const isEditable = true;

  const u = profile;

  const initialProfileValues = {
    firstName: u!.firstName,
    // avatar: u!.avatar,
    // image: u!.image,
    lastName: u!.lastName,
    email: u!.email,
    phoneNumber: u!.phoneNumber,
    address: u!.address,
    country: u!.country,
    city: u!.city,
    state: u!.state,
    postalCode: u!.postalCode,
    sex: u!.sex,
    dateOfBirth: u!.dateOfBirth,
    signature: u!.signature,
    businessInformation: {
      exportGoodType: u!.businessInformation?.exportGoodType,
      TIN: u!.businessInformation?.TIN,
      yearsInExport: u!.businessInformation?.yearsInExport,
      exportFrequency: u!.businessInformation?.exportFrequency,
      countriesOfExport: u!.businessInformation?.countriesOfExport,
      exportRegCertificateUrl: u!.businessInformation?.exportRegCertificateUrl,
    },
    userIdentity: {
      identificationMode: u!.userIdentity?.identificationMode,
      identificationUrl: u!.userIdentity?.identificationUrl,
      utilityBillUrl: u!.userIdentity?.utilityBillUrl,
      bvn: u!.userIdentity?.bvn,
    },
  };

  const p: any = { ...profile };

  return (
    <>
      <PersonalDetailsViewBox
        profile={profile}
        initialProfileValues={initialProfileValues}
        isEditable={isEditable}
      />

      <ContactAddressViewBox
        profile={profile}
        initialProfileValues={initialProfileValues}
        isEditable={isEditable}
      />

      {profile?.role === "exporter" && (
        <BusinessInformationViewBox profile={profile} />
      )}
    </>
  );
};
