import { ChakraAlertDialog } from "components";
import React from "react";
import { ReactComponent as ShieldSecurityIcon } from "assets/svg/shield-security.svg";
import { useModalProvider } from "providers";
import { redirectTo } from "utilities/general";

interface ProvideKycDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
  role: string;
}

export const ProvideKycDialog: React.FC<ProvideKycDialogProps> = ({ role, onClose = () => {}, ...rest }) => {
  const { initModal } = useModalProvider();
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, provideExporterKyc: false, provideBackerKyc: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Provide KYC Information"}
        proceedButtonProps={{
          className: "btn-lg primary-btn w-100 ms-0",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              provideExporterKyc: false,
              provideBackerKyc: false,
            }));
            redirectTo(`/${role}/profile/create`);
          },
        }}
        useHeader={false}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className: "d-flex flex-column-reverse gap-3 align-items-center justify-content-center w-100",
        }}
        cancelButtonDefaultChild={"Later"}
        cancelButtonProps={{
          className: "btn-lg secondary-btn w-100",
          onClick: () => {
            redirectTo(`/${role}/dashboard`);
          },
        }}
        title={"Two-factor authentication"}
        size={"md"}
        closeOnOverlayClick={false}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className=''>
          <div className='text-center d-flex flex-column align-items-center gap-5'>
            <ShieldSecurityIcon />
            <div className='mb-4'>
              <h5 className='fs-20 fw-600 mb-2'>Provide KYC Information to proceed! </h5>
              <p className='fs-14 text-grey-600 fw-400 m-0'>
                To keep things safe and sound, you're required to provide your KYC information
              </p>
            </div>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
