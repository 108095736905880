import { ModalViewStateProps } from "providers";
import { BackCampaignSuccessModal, PaymentDetailsModal } from "../campaign";
import { BackCampaignFailedModal } from "../campaign";
import { CreatePinModal } from "../wallet/components/createPinModal";
import { WaitListFormModal, WaitListSuccessModal } from "pages/home";

export interface BackerDialogsProps {
  modal?: ModalViewStateProps;
  onInitModal?: React.Dispatch<
    React.SetStateAction<ModalViewStateProps | undefined>
  >;
}
export const BackerDialogs: React.FC<BackerDialogsProps> = ({
  modal,
  onInitModal = () => {},
}) => {
  return (
    <>
      <BackCampaignSuccessModal
        isOpen={Boolean(modal?.successCampaign)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, successCampaign: false }))
        }
      />
      <BackCampaignFailedModal
        isOpen={Boolean(modal?.failedCampaign)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, failedCampaign: false }))
        }
      />
      <CreatePinModal
        isOpen={Boolean(modal?.createPin)}
        onClose={() => onInitModal((prev) => ({ ...prev, createPin: false }))}
      />
      <PaymentDetailsModal
        isOpen={Boolean(modal?.paymentDetails)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, paymentDetails: false }))
        }
      />
      <WaitListSuccessModal
        isOpen={Boolean(modal?.waitListSuccessModal)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, waitListSuccessModal: false }))
        }
      />
      <WaitListFormModal
        isOpen={Boolean(modal?.waitListFormModal)}
        onClose={() =>
          onInitModal((prev) => ({ ...prev, waitListsFormModal: false }))
        }
      />
    </>
  );
};
