import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { CreatePersonalDetailsValidationSchema } from "validations";
import { DropzoneFile, PrimaryButton, PrimaryDropzone, TabSlider } from "components";
import queryString from "query-string";
import { resolveApiError, useWindowWidth } from "utilities";
import {
  CreateCampaignPayload,
  useGetCampaignQuery,
  useUploadCampaignMediaFilesMutation,
} from "store/campaigns";
import { usePageNotificationProvider } from "providers";

export type CampaignFilesProps = {
  next: () => void;
  previous: () => void;
  initialCampaign: CreateCampaignPayload;
};

export const CampaignFiles: FC<CampaignFilesProps> = ({ next, previous, initialCampaign }) => {
  const fileClass = "campaignImages";
  const [files, setFiles] = useState<DropzoneFile[]>([]);
  const [uploadedImgs, setUploadedImgs] = useState<string[]>([]);
  const { id } = queryString.parse(window.location.search) as {
    id: string;
  };
  const { data: campaign, refetch } = useGetCampaignQuery(id, {
    pollingInterval: 30000,
  });
  const windowWidth = useWindowWidth();
  const { initNotification } = usePageNotificationProvider();
  const [selectedTab, setSelectedTab] = useState(0);
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      uploaded: "",
    },
    validationSchema: CreatePersonalDetailsValidationSchema,
    onSubmit: (values) => {
      console.log("clicked");
    },
  });
  const [uploadMediaFiles, { isLoading: loading }] = useUploadCampaignMediaFilesMutation();

  useEffect(() => {
    refetch();
  }, []);

  const handleFileUpload = (file: File) => {
    // if (errorMsg) return;
    const form = new FormData();
    form.append("files", file);

    uploadMediaFiles({
      files: form,
      fileClass: fileClass,
      campaignId: id ?? ("6560776458feac1f7b90bd57" as string),
    })
      .unwrap()
      .then((res: { error: any; message: any; data: { images: React.SetStateAction<string[]> } }) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }
        setUploadedImgs(res.data.images);
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({
          message: resolveApiError(error),
          scheme: "error",
        });
      });
  };

  const handleNext = () => {
    if (files.length === 0 && uploadedImgs.length === 0) {
      initNotification({ message: "No files uploaded!", scheme: "error" });
      return;
    }
    files.forEach((file) => {
      handleFileUpload(file.data);
    });
    console.log(`Uploaded ${files.length} files`);
    next();
  };

  return (
    <section className=' bg-transparent'>
      <h6 className={`text-grey-900 fs-20 fw-semibold mt-4 ${windowWidth >= 768 ? "mb-5" : "mb-3"}`}>
        {windowWidth >= 768 ? "Upload Media Files" : "Upload Campaign Media Files"}
      </h6>
      {windowWidth <= 768 && (
        <p className='text-grey-500 fw-400 fs-14 mb-4'>Provide Campaign feature image</p>
      )}
      <div className='row'>
        <div className='col-md-12'>
          <form onSubmit={handleSubmit} className=''>
            {/* <div className="d-flex mb-5 ">
              <TabSlider
                items={["Image", "Video"]}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </div> */}
            {selectedTab === 0 ? (
              <>
                <div className='col-md-12 mb-4'>
                  <PrimaryDropzone
                    fileClass={fileClass}
                    campaignId={id}
                    campaign={campaign}
                    refetch={refetch}
                    dropzoneText='Supported formats: JPEG, JPG, PNG'
                    accept={{
                      "image/jpeg": [],
                      "image/png": [],
                    }}
                    files={files}
                    setFiles={setFiles}
                    uploadedImgs={uploadedImgs}
                    setUploadedImgs={setUploadedImgs}
                  />
                </div>
              </>
            ) : (
              <>
                <h6 className='mb-3'>Video Files</h6>
                <div className='col-md-12 mb-4'>
                  <PrimaryDropzone
                    fileClass={fileClass}
                    campaignId={id}
                    campaign={campaign}
                    refetch={refetch}
                    files={files}
                    setFiles={setFiles}
                    uploadedImgs={uploadedImgs}
                    setUploadedImgs={setUploadedImgs}
                  />
                </div>
              </>
            )}

            <div>
              <PrimaryButton
                onClick={previous}
                className='btn-lg secondary-btn mt-3 me-3 align-self-baseline'
              >
                {windowWidth >= 768 ? "Back" : "Cancel"}
              </PrimaryButton>
              <PrimaryButton onClick={handleNext} className='btn-lg primary-btn mt-3 align-self-baseline'>
                Next
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
