import { ReactComponent as TransactionIcon } from "assets/svg/transaction-history.svg";
import { PaymentInformation, TransactionHistory } from "./components";
import { Flex } from "@chakra-ui/react";
import { useWindowWidth } from "utilities/windowWidth";
import { useAuth } from "store/auth";
import { useEffect } from "react";
import { ModalViewStateProps, useModalProvider } from "providers";
import { kycNotProvided } from "../components/sideBar";

export const ExporterTransactionHistory = () => {
  const windowWidth = useWindowWidth();
  const { user } = useAuth();
  const { initModal } = useModalProvider();
  const data = true;
  const paymentInfo = {
    bank: "Oshuporu Bank",
    accountNumber: "9284859392",
    accountName: "Martins Johnson",
  };

  // useEffect(() => {
  //   if (
  //     kycNotProvided(
  //       "Transactions",
  //       user!.profileCompleted?.completionRate < 96
  //     )
  //   ) {
  //     initModal((prev: ModalViewStateProps | undefined) => ({
  //       ...prev,
  //       provideKyc: true,
  //     }));
  //   }
  // }, [user!.profileCompleted?.completionRate]);

  return (
    <div className={`mt-3 ${windowWidth >= 768 && "px-3"} `}>
      <h3 className='fw-600 mb-5'>Transactions</h3>
      {!data && (
        <div className='d-flex flex-column justify-content-center align-items-center my-5 py-5'>
          <TransactionIcon />
          <div className='mt-4'>
            <p className='text-center fw-bold'>No Transaction History</p>
            <p className='mt-2 text-center text-grey-600'>Your transaction history will appear here!</p>
          </div>
        </div>
      )}
      {data && (
        <Flex gap={"40px"} direction={windowWidth >= 768 ? "row" : "column-reverse"}>
          <TransactionHistory />
          <PaymentInformation paymentInfo={paymentInfo} />
        </Flex>
      )}
    </div>
  );
};
