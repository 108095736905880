import { DiscoverAllCampaigns } from "./components";
import { useWindowWidth } from "utilities/windowWidth";
import { Flex } from "@chakra-ui/react";
import { ReactComponent as SortIcon } from "assets/svg/sort.svg";

import { useGetAllCampaignsQuery } from "store/campaigns";

export const BackerDiscoverCampaigns = () => {
  const windowWidth = useWindowWidth();
  const { data: campaigns, isLoading } = useGetAllCampaignsQuery(
    {},
    {
      pollingInterval: 30000,
    }
  );

  return (
    <section className='bg-transparent px-2 pb-5'>
      <div className={`d-flex justify-content-between align-items-center mb-3`}>
        <h1 className={`${windowWidth >= 768 ? "fs-24" : "fs-20"} mb-0 fw-600 text-grey-900`}>
          {windowWidth >= 768 ? "Discover Campaigns" : "Discover"}
        </h1>
        {/* {windowWidth <= 786 && (
          <Flex
            gap={"8px"}
            align={"center"}
            className="border border-grey-600 touchable"
            py={"8px"}
            px={"16px"}
            borderRadius={"8px"}
            h={"30px"}
          >
            <SortIcon />
            <span className="text-grey-600">Sort Campaign</span>
          </Flex>
        )} */}

        <Flex
          gap={"8px"}
          align={"center"}
          className='border border-grey-600 touchable'
          py={"10px"}
          px={"16px"}
          borderRadius={"5px"}
          h={"30px"}
        >
          <SortIcon />
          <span className='text-grey-600'>Sort Campaign</span>
        </Flex>
      </div>

      <div className='row'>
        <DiscoverAllCampaigns data={campaigns} />
      </div>
    </section>
  );
};
