import { CampaignInfoProps } from "./interface";
import { ReactComponent as MuscleIcon } from "assets/svg/muscle-icon.svg";
import { ReactComponent as ClockIcon } from "assets/svg/time-icon.svg";
import { ProgressBar } from "components";
import { getDaysLeft, getPercentage } from "utilities";

export const CampaignInfo = ({ campaign, orientation }: CampaignInfoProps) => {
  return (
    <div className='d-flex flex-column align-items-start p-3 pt-4 gap-2'>
      <div>
        <h5 className='mb-2 text-primary-600 fw-semibold fs-24'>{` ${"\u20A6"}${Number(
          campaign?.amountRaisedString || "0"
        ).toLocaleString()}`}</h5>
        <p className='text-grey-600 fs-14 fw-normal'>
          {campaign?.hasBeenReviewed
            ? `Contributed of ₦ ${Number(campaign?.fundingGoalString).toLocaleString()} goal`
            : "--"}
        </p>
      </div>
      <div className='loading-container mb-4 w-100 '>
        <ProgressBar
          value={getPercentage(Number(campaign?.fundingGoalString), Number(campaign?.amountRaisedString))}
          width='100%'
        />
      </div>
      <div className={orientation === "row" ? "d-flex flex-row w-100 align-items-center gap-5" : ""}>
        <div className='mb-0'>
          <h6 className='fs-20 fw-600 mb-1'>
            {campaign?.hasBeenReviewed ? campaign?.interestRate + "%" : "--"}
          </h6>
          <p className={` ${orientation === "row" ? "mb-0" : ""} text-grey-600 fs-14 fw-normal`}>
            Interest Rate
          </p>
        </div>

        <div className={` ${orientation === "row" ? "mt-0" : "mt-5"} d-flex align-items-center gap-5`}>
          <div>
            <h6 className='mb-1 fs-20 fw-600'>{campaign?.backers?.length ?? "--"}</h6>
            <div className='d-flex gap-2 align-items-center'>
              <MuscleIcon />
              <p className='text-grey-600 mb-0 fs-14'>Backers</p>
            </div>
          </div>
          <div className='d-flex gap-2 align-items-center'>
            <ClockIcon />
            <h6
              className={`fw-600 mb-1 fs-20 ${
                getDaysLeft(campaign?.campaignEndDate) === "Expired" && "text-error-500"
              }`}
            >
              {campaign?.hasBeenReviewed ? getDaysLeft(campaign?.campaignEndDate) : "--"}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
