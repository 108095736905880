import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { useModalProvider } from "providers/modalProvider";
import { PrimaryInput } from "components";
import { PhoneValidationSchema } from "validations/auth";
import { useFormik } from "formik";

interface EnterPhoneNoDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const EnterPhoneNoDialog: FC<EnterPhoneNoDialogProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { phoneNumber: "" },
    validationSchema: PhoneValidationSchema(),
    onSubmit: () => {},
  });
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, enterPhoneNo: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Continue"}
        proceedButtonProps={{
          className: "btn-lg primary-btn ms-0 mx-4",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              enterPhoneNo: false,
              verifyCodeModal: true,
            }));
          },
        }}
        cancelButtonDefaultChild={"Cancel"}
        cancelButtonProps={{
          className: "btn-lg border ms-0 bg-white",
          onClick: () => {
            initModal((prev) => ({ ...prev, enterPhoneNo: false }));
          },
        }}
        useHeader={true}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className: "d-flex flex-row-reverse w-100 px-0 mt-2",
        }}
        title={"Two-factor authentication"}
        size={"md"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className=''>
          <div className='text-center d-flex flex-column align-items-center gap-4'>
            <p className='fs-16 text-grey-600 fw-400 m-0'>To continue, please enter your phone number</p>
            <form className='col-md-12 mb-2'>
              <PrimaryInput
                type='text'
                label='Phone number'
                name='phoneNumber'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                error={Boolean(touched.phoneNumber && errors?.phoneNumber)}
                bottomText={errors?.phoneNumber}
                leftComponent={
                  <select className='mx-1 p-0 fw-400 bg-transparent text-grey-900 cursor-pointer'>
                    <option value='NGN'>NGN</option>
                  </select>
                }
                leftComponentProps={{
                  className: "primary-input-component",
                }}
              />
            </form>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
