import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { useModalProvider } from "providers/modalProvider";
import { CustomPasswordInput } from "components";
import { useFormik } from "formik";
import { PasswordValidationSchema } from "validations/auth";
import { AlertDefaultBox } from "components";
import { ReactComponent as ErrorIcon } from "assets/svg/password-error-icon.svg";

interface DisableTwoFacModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const DisableTwoFacModal: FC<DisableTwoFacModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { password: "" },
    validationSchema: PasswordValidationSchema(),
    onSubmit: () => {},
  });
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, disableTwoFac: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Deactivate"}
        proceedButtonProps={{
          className: "btn-lg primary-btn ms-0 mx-4",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              disableTwoFac: false,
            }));
          },
        }}
        cancelButtonDefaultChild={"Cancel"}
        cancelButtonProps={{
          className: "btn-lg border ms-0 bg-white border-grey-300",
          onClick: () => {
            initModal((prev) => ({ ...prev, disableTwoFac: false }));
          },
        }}
        useHeader={true}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className: "d-flex flex-row-reverse w-100 px-0 mt-2",
        }}
        title={"Disable two-factor authentication?"}
        size={"lg"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className=''>
          <div className='text-center d-flex flex-column align-items-center gap-4'>
            <p className='fs-16 text-grey-600 fw-400 m-0 w-100 text-start mb-1'>
              To continue, please enter your password
            </p>
            <form className='col-md-12 mb-2'>
              <CustomPasswordInput
                label='Password'
                name='password'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={Boolean(touched.password && errors?.password)}
              />
              {errors?.password && (
                <AlertDefaultBox
                  marginTop={"20px"}
                  scheme='error'
                  icon={<ErrorIcon />}
                  body={errors?.password}
                />
              )}
            </form>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
