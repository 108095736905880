import { PrimaryButton } from "components/buttons";
import { ReactComponent as FullSpeakerIcon } from "assets/svg/full-speaker-icon.svg";
// import { SliderMenu } from "./sliderMenu";
import { CampaignItem } from "./campaignItem";
import { Link } from "react-router-dom";
import { SliderMenu } from "components/custom";
import { useEffect, useState } from "react";

export const Campaigns = ({ data }: { data: any }) => {
  console.log(data);
  const [activeMenu, setActiveMenu] = useState(0);
  const [campaignsData, setCampaignsData] = useState<any>(data);

  useEffect(() => {
    setCampaignsData(data);
  }, [data]);

  useEffect(() => {
    if (data) {
      switch (activeMenu) {
        case 0:
          setCampaignsData(data);
          break;
        case 1:
          setCampaignsData(
            data.filter((campaign: any) => campaign.status === "ongoing")
          );
          break;
        case 2:
          setCampaignsData(
            data.filter((campaign: any) => campaign.status === "pending")
          );
          break;
        case 3:
          setCampaignsData(
            data.filter((campaign: any) => campaign.status === "completed")
          );
          break;
        case 4:
          setCampaignsData(
            data.filter((campaign: any) => campaign.status === "draft")
          );
          break;
        default:
          setCampaignsData(data);
      }
    } else {
      setCampaignsData(undefined);
    }
  }, [activeMenu, data]);
  return (
    <div>
      <h4 className="mb-4 fw-600">Backed Campaigns</h4>
      <SliderMenu
        data={data}
        headings={["All", "Ongoing", "Pending", "Completed"]}
        setActiveMenu={setActiveMenu}
        activeMenu={activeMenu}
      />
      {campaignsData && campaignsData.length > 0 ? (
        <div className="mb-4">
          {campaignsData.map((campaign: any) => (
            <CampaignItem key={campaign.id} campaign={campaign} />
          ))}
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center m-5">
          <FullSpeakerIcon />
          <div className="mt-4">
            <p className="text-center fw-bold">
              You don't have any backed campaigns yet
            </p>
            <p className="mt-2 text-center text-grey-600">
              Support your first campaign
            </p>
          </div>
          <PrimaryButton className="btn-md primary-btn mt-4">
            <Link to="/backer/discover">Browse Campaigns</Link>
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};
