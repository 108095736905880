import React, { useRef, useState } from "react";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { ReactComponent as WarningIcon } from "assets/svg/caution.svg";
import { PrimaryInput, PrimarySelect } from "components/inputs";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PrimaryButton } from "components/buttons";
import { useFormik } from "formik";
import { resolveApiError } from "utilities";
import { RegisterValidationSchema } from "validations/auth";
import { AlertDefaultBox, AuthHeaderText, CustomPasswordInput } from "components/custom";
import { RegisterPayload, setCredential, useRegisterMutation } from "store/auth";
import { usePageNotificationProvider } from "providers";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Recaptcha } from "./components";

export const RegisterPage = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const partner = queryParams.get("partner");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [request, { isLoading }] = useRegisterMutation();
  const [captchaValue, setCaptchaValue] = useState<string | null>("");
  const { initNotification } = usePageNotificationProvider();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: partner ? "backer" : "exporter",
      password: "",
      // confirmPassword: "",
    },
    validationSchema: RegisterValidationSchema,
    onSubmit: () => initRequest(),
  });

  const reCaptchaRef: any = useRef();

  const handleCaptchaChange = (value: any) => {
    setCaptchaValue(value);
  };

  const initRequest = (payload?: RegisterPayload) => {
    if (!captchaValue) {
      initNotification({
        message: "Please confirm you're not a robot",
        scheme: "error",
      });
      return;
    }
    request(payload ?? values)
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "You have successfully registered.",
        });
        dispatch(
          setCredential({
            user: res.data.newUser,
            access_token: res.data.token,
          })
        );
        navigate(`/verify-email?email=${res.data.newUser.email}`);
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  return (
    <section className='container-fluid min-vh-100 py-md-5 py-4 d-flex justify-content-center align-items-center'>
      <div className='max-w-560 w-95 py-3'>
        <div className='d-flex flex-column justify-content-center align-items-center gap-5'>
          <div className='d-flex flex-row gap-2 align-items-center'>
            <Logo />
          </div>
          <form onSubmit={handleSubmit} className='auth-form-wrapper w-100'>
            <AuthHeaderText
              title='Register'
              subTitle='Welcome to Krowdfi. Please enter your details to create your
              account'
            />

            <div className='mt-3 d-flex flex-column w-100 gap-4'>
              <div className='row'>
                <div className='col-md-6 my-md-0 my-3'>
                  <PrimaryInput
                    type='text'
                    label='First Name'
                    name='firstName'
                    placeholder='Enter Your First Name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={Boolean(errors?.firstName && touched?.firstName)}
                    bottomText={errors?.firstName || ""}
                  />
                </div>
                <div className='col-md-6 my-md-0 my-2'>
                  <PrimaryInput
                    type='text'
                    label='Last Name'
                    name='lastName'
                    placeholder='Enter Your Last Name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={Boolean(errors?.lastName && touched?.lastName)}
                    bottomText={errors?.lastName || ""}
                  />
                </div>
              </div>
              <PrimaryInput
                type='email'
                label='Email Address'
                name='email'
                placeholder='example@mail.com'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={Boolean(errors?.email && touched?.email)}
                bottomText={errors?.email || ""}
              />
              <PrimarySelect
                name='role'
                label='Are you an Exporter or a Backer?'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.role}
                error={Boolean(errors?.role && touched?.role)}
                bottomText={errors?.role || ""}
              >
                <option value=''>Select option</option>
                {partner && partner === "true" ? (
                  <option value='backer' selected={true}>
                    Backer
                  </option>
                ) : (
                  <option value='exporter' selected={true}>
                    Exporter
                  </option>
                )}
              </PrimarySelect>
              <div className='row'>
                <div className='col-md-12'>
                  <CustomPasswordInput
                    label='Password'
                    name='password'
                    placeholder='********'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={Boolean(touched.password && errors?.password)}
                    // bottomText={errors?.password}
                  />
                </div>
                {/* <div className="col-md-6">
                  <CustomPasswordInput
                    label="Confirm Password"
                    name="confirmPassword"
                    placeholder="********"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    error={Boolean(
                      touched.confirmPassword && errors?.confirmPassword
                    )}
                    // bottomText={errors?.confirmPassword}
                  />
                </div> */}
              </div>
            </div>

            {errors.password && touched.password && (
              <AlertDefaultBox
                scheme='warning'
                icon={<WarningIcon />}
                body={errors?.password}
                className='mt-3 mb-2'
              />
            )}

            {/* <p className="mt-4 text-grey-500">
              By creating an account, you agree and consent to our{" "}
              <a
                className='text-primary-600 text-decoration-none'
                href={Terms}
                target='_blank'
                rel='noreferrer'
              >
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a
                className='text-primary-600 text-decoration-none'
                href={Privacy}
                target='_blank'
                rel='noreferrer'
              >
                Privacy Policy
              </a>
            </p> */}
            <p className='mt-4 text-grey-500'>
              By creating an account, you agree and consent to our{" "}
              <Link className='text-primary-600 text-decoration-none' target='_blank' to='/terms-of-use'>
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link className='text-primary-600 text-decoration-none' target='_blank' to='/privacy-policy'>
                Privacy Policy
              </Link>
            </p>
            {/* <ReCAPTCHA
              sitekey={"6LcBotApAAAAAL33eg_6Jn6ut34My2daJ6vodrqU"}
              ref={reCaptchaRef}
              onChange={handleCaptchaChange}
            /> */}
            <GoogleReCaptchaProvider reCaptchaKey='6LcBotApAAAAAL33eg_6Jn6ut34My2daJ6vodrqU'>
              <Recaptcha captchaValue={captchaValue} setCaptchaValue={setCaptchaValue} />
            </GoogleReCaptchaProvider>

            <PrimaryButton
              type='submit'
              className='btn-lg primary-btn mt-3 align-self-baseline'
              isLoading={isLoading}
            >
              Create Account
            </PrimaryButton>
          </form>

          <p className='mt-1 text-grey-500'>
            Already have an account?{" "}
            <Link to='/login' className='text-primary-500 fw-700 text-decoration-none'>
              Log In
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};
