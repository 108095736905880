import { ChakraAlertDialog } from "components";
import React, { FC, useState } from "react";
import { useModalProvider } from "providers/modalProvider";
import { ReactComponent as QrAuthIcon } from "assets/svg/qr-authentication-icon.svg";
import { VerificationCodeInput } from "components/custom";
import { useVerify2faTokenOnClickMutation } from "store/2fa";
import { usePageNotificationProvider } from "providers";
import { resolveApiError } from "utilities/index";
import { useLogout } from "store/auth";

interface TwoFaVerificationModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const TwoFaVerificationModal: FC<TwoFaVerificationModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initNotification } = usePageNotificationProvider();
  const { initModal, getModalPayload } = useModalProvider();
  const modalPayload = getModalPayload("verify2fa");
  const [value, setValue] = useState("");
  const [verify2faToken, { isLoading: isVerifying }] =
    useVerify2faTokenOnClickMutation();
  const logout = useLogout();

  const initVerifytwoFaToken = () => {
    verify2faToken({ token: value })
      .unwrap()
      .then((res: boolean) => {
        if (res) {
          initNotification({ message: "Verified", scheme: "success" });
          setValue("");
          localStorage.setItem("2faModal", "false");
          if (modalPayload.url) {
            window.location.replace(
              `${process.env.REACT_APP_BASE_URL}/${modalPayload.url}`
            );
            return;
          }
          initModal((prev) => ({
            ...prev,
            verify2fa: false,
          }));
        } else {
          initNotification({
            message: "Something went wrong. Please ensure the code is valid",
            scheme: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.data.statusCode === 403) initModal({ sessionExpired: true });
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  const initLogout = () => {
    setTimeout(() => {
      window.location.replace("/login");
    }, 100);
    window.localStorage.clear();
    logout();
    onClose();
  };

  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, verify2fa: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Confirm"}
        proceedButtonProps={{
          className: "btn-lg primary-btn ms-0 w-50",
          onClick: () => initVerifytwoFaToken(),
          isDisabled: isVerifying,
          isLoading: isVerifying,
        }}
        cancelButtonDefaultChild={"Cancel"}
        cancelButtonProps={{
          className: "btn-lg border ms-0 bg-white w-50 border-grey-300",
          onClick: () => {
            initModal((prev) => ({ ...prev, verify2fa: false }));
            initLogout();
          },
          // isDisabled: JSON.parse(localStorage.getItem("2faModal")!) || false,
        }}
        useHeader={false}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className:
            "d-flex flex-row justify-content-start w-100 px-0 mt-2 gap-3",
        }}
        title={"Two-factor authentication"}
        size={"2xl"}
        closeOnOverlayClick={false}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center gap-4">
            <div className="w-100">
              <QrAuthIcon />
            </div>
            <div>
              <h5 className="fs-18 fw-600 w-100 text-start mb-2">
                Two-factor verification
              </h5>
              <p className="fs-14 text-grey-600 fw-400 m-0 w-100 text-start">
                To continue to your dashboard, please open your Google
                Authenticator App and enter the verification code below.
              </p>
            </div>

            <div className="mb-3">
              <p className="fw-500 fs-14 w-100 text-start mb-2">
                Verification code
              </p>
              <VerificationCodeInput
                codeLength={6}
                colorTheme="grey"
                wrapperProps={{
                  className: "text-grey-300 mb-2",
                }}
                value={value}
                setValue={setValue}
              />
              {/* <p className="fs-14 text-grey-600 fw-400 m-0 text-start w-100">
                Didn’t get a code?{" "}
                <button
                  type="button"
                  onClick={() => {
                    qrCode = "";
                    handleGenerate2faToken();
                  }}
                  className="text-decoration-underline border-0 cursor-pointer"
                >
                  {isGenerating ? "Please wait..." : "Click to resend."}
                </button>
              </p> */}
            </div>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
