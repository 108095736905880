import { CustomizedHeader, FooterComponent } from "pages/home";
import "../../assets/css/privacyAndTerms/privacyAndTerms.scss";

export const PrivacyPolicy = () => {
  return (
    <section>
      <CustomizedHeader />
      <main className='privacy-container'>
        <div>
          <h1 className='headings'>KROWDFI PRIVACY POLICY</h1>
          {/*No 1 introduction */}
          <div>
            <h5 className=' sub-headings'>
              1. <span>Introduction</span>
            </h5>
            <p className='content'>
              This privacy policy explains how personal information is collected, used, stored, and handled,
              as well as user rights in relation to that information. Krowdfi (“we”, “our” or “us”) is
              committed to protecting and respecting your privacy. We also refer to “exporters” and “backers”
              as “you”, “your” or “users” throughout this document. For more information regarding this,
              please see our Terms of Use.
            </p>
            <p className='content'>
              Our primary objective is to develop and implement a feature within our platform that empowers
              Nigerian exporters of agricultural produce to create crowdfunding campaigns and undergo a review
              and approval process. This would enable potential backers to discover, explore, engage, and
              support export campaigns.
            </p>
            <p className='content'>
              This policy explains why and how we will use the personal information that we have obtained from
              you or others, with whom we share it and the rights you have in connection with the information
              we use. It also describes the way we handle and use the personal information that we obtain from
              all the different interactions you may have with us as a business, including when you visit our
              offices, social media pages, our website, or our web application. Thus, we advise that you read
              the following carefully.
            </p>
          </div>
          {/* core tenets of our privacy */}
          <div>
            <h1 className=' sub-headings'>Core Tenets of our Privacy Policy</h1>
            <p className='content'>In our use of your personal data, we uphold the following principles:</p>

            <ol type='a' className='ms-4'>
              <li className='content'>
                Empowering User Control: We believe in empowering you to have control over your personal data.
                This means that you have the freedom to make choices about how your data is used, and we
                respect your decisions without imposing any obligations;
              </li>
              <li className='content'>
                Safeguarding your Personal Data: We regard your personal data as invaluable, and we handle it
                with the utmost care and responsibility. We understand the trust you place in us by sharing
                your data, and we take this trust seriously by implementing robust security measures to
                protect your information from unauthorised access, misuse, or breaches;{" "}
              </li>
              <li className='content'>
                Promoting Transparency: We are committed to being transparent about our data practices by
                providing clear and understandable explanations about what type of personal data we collect
                and the purposes for which it is used. This also includes informing you about third parties
                with whom we may share your data and the safeguards in place to protect it;
              </li>
              <li className='content'>
                Compliance with Local Law: Our privacy practices adhere to the laws and regulations of the
                Nigerian Data Protection Act (NDPA), 2023, as it concerns the collection, processing, and
                storage of personal (sensitive) data. This ensures that your data is handled in accordance
                with applicable legal requirements, providing you with legal protection;
              </li>
              <li className='content'>
                Minimizing Data Collection and Retention: We believe in collecting and retaining only the
                minimum amount of personal data necessary to fulfil the purposes for which it was collected.
                This means that we do not collect more data than is required, and we update our data retention
                policy to ensure compliance with relevant laws; and
              </li>
              <li className='content'>
                Ensuring Data Security: Our goal is to maintain the confidentiality and integrity of your data
                at all times, safeguarding it from potential threats and vulnerabilities.
              </li>
            </ol>
          </div>
          {/* data controller */}
          <div>
            <h5 className='sub-headings'>Data Controller</h5>

            <p className='content'>
              With regards to the Nigerian Data Protection Act, Krowdfi is the controller of your personal
              information and is responsible for its use and protection.
            </p>
            <p className='content'>
              This means that we decide why and how your personal information is processed. If you have any
              questions in relation to this, please refer to the section at the end of this policy for our
              contact information.
            </p>
            <p className='content'>
              In the operation of this platform, exporters initiate crowdfunding campaigns from our website or
              web application, and in doing so, may collect personal information from you if you, as a backer,
              contribute funds to their campaign. Similarly, backers support crowdfunding campaigns by
              contributing funds for exporters to export agricultural produce, and in doing so, they may
              collect information from you as an exporter if you accept their contributed funds. The way in
              which backers and exporters utilise this information (for example, to stay in touch regarding
              the progress of their campaigns and accomplishments) is something that users, not us, decide.
              Users will thus be independent controllers of one another’s personal data.
            </p>
          </div>
          {/* No 2 how we collect data */}
          <div className='mt-5'>
            <h5 className='sub-headings'>2. How and when we collect personal information</h5>
            <p className='content'>This refers to personal information about you that you give to us when:</p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                Providing personal information through our website or web application when setting up an
                account;
              </li>
              <li className='content'>
                Engaging with our brand by following us, interacting, and posting on our official social media
                pages, including but not limited to Facebook, Instagram, Twitter, Tiktok, and LinkedIn, as
                well as other platforms;
              </li>
              <li className='content'>You visit our physical offices; and </li>
              <li className='content'>
                You communicate with us via telephone, email, or any other means of correspondence.
              </li>
            </ol>

            <p className='content'>This personal information is disclosed entirely at your discretion.</p>

            <p className='content'>
              Personal data also includes information provided on the website or web application at the time
              of registering as a user, posting details of crowdfunding campaigns, using any of the
              communication tools we provide for our users, providing funds to a campaign on the website or
              cancelling a fund contributed, and when you generally use our website or web application. For
              example, you may give us your personal information by filling out forms, providing identity
              documents, uploading profile information and other content to the website, managing your account
              online, or engaging in correspondence with us by phone or email. We may also ask you for
              information and copies of your identity documents when you report a problem with our website or
              when you exercise your legal rights.
            </p>

            <p className='content'>
              Furthermore, if we do not receive this information, you may be unable to register with the
              website, create campaigns or contribute funds to campaigns, communicate with other users or
              communicate with us effectively, or allow us to comply with our own obligations.
            </p>
          </div>
          {/* No 3 information we collect */}
          <div className='mt-5'>
            <h5 className='sub-headings'> 3. Information we collect </h5>
            <p className='content'>
              We process different types of information about you that you provide to us when you visit, use,
              or interact with any of our services. The implication of providing us with this information is
              that you expressly agree to our collection, use, storage, and disclosure of such information as
              described in this policy. To understand the data we use about you, we have provided a concise
              explanation outlining the categories of such data. We may collect the following categories of
              personal information when you use our web application or web app:
            </p>

            <ol type='a' className='ms-4'>
              <li className='content'>
                Personal identifiable information necessary to create a user account on our Platform,
                including but not limited to name, email address, phone number, gender, etc.;
              </li>

              <li className='content'>
                Information required to verify your identity in order to validate users’ accounts, such as
                your home address, a picture of your face (selfie), National Identification Number (NIN), Bank
                Verification Number (BVN), Tax Identification Number (TIN), KYC information, etc.;
              </li>

              <li className='content'>
                Information necessary for financial processes such as billing, including but not limited to
                credit or debit card information, city, and state;
              </li>

              <li className='content'>
                Details of your visits to our website, including, but not limited to, traffic data, location
                data, weblogs, communication data, and the resources you access;
              </li>
              <li className='content'>
                Technical information, including anonymous data collected by the hosting server for
                statistical purposes, the Internet protocol (IP) address used to connect your computer or
                device to the Internet, browser type and version, time zone setting, browser plug-in types and
                versions, operating system, and platform;
              </li>
              <li className='content'>
                Cookies and other necessary tracking technologies (like pixels) are used to access or store
                information. Some cookies are necessary to run the Platform; however, you retain the option to
                give or withhold consent in the case of optional cookies.
              </li>
              <li className='content'>
                Information contained in our correspondence or other communications with you about
                crowdfunding campaigns and other activities on our website, our services, or our business;
              </li>
              <li className='content'>
                Personal information that you allow to be shared, such as the information you upload to the
                website or web application (such as your Krowdfi profile), and information you share on third
                party social networks.
              </li>

              <li className='content'>
                Information relating to the occurrence, investigation, or prevention of fraud to safeguard
                against fraudulent activities and maintain the integrity of our services.
              </li>

              <li className='content'>
                Information relating to legal claims made by you or against you for the purpose of resolving
                disputes, enforcing legal rights, and ensuring compliance with applicable laws.
              </li>
            </ol>
            <p className='content'>
              Additionally, web applications and web apps use third-party services that may gather information
              capable of identifying you.
            </p>

            <div>
              <h5 className='sub-headings'>Personal information we may receive from other sources</h5>
              <p className='content'>
                We may obtain certain personal information about you from other sources (including those
                outside of our business), which may include our suppliers and clients. The third parties that
                may send us personal information about you are as follows:
              </p>
              <ol type='a' className='ms-4'>
                <li className='content'>
                  Users of the website who may share personal information about you with us, for example, if
                  you misuse the website or breach our guidelines;
                </li>

                <li className='content'>
                  Third party applications, where we will only collect the data stated and will only use this
                  data for the purposes stated at the point of your consent;
                </li>
                <li className='content'>
                  Governmental and other public authorities, such as the police, Federal Inland Revenue
                  Service (FIRS), and other public authorities may access and share your personal information
                  during investigations. We may also receive your personal information from a third-party
                  sharing information originating from a court or otherwise relating to legal or regulatory
                  proceedings; and
                </li>
                <li className='content'>Social media networks, which are made publicly available.</li>
              </ol>
            </div>

            <div>
              <h5 className='sub-headings'>Sensitive Data</h5>
              <p className='content'>
                It is worthy to note that while we use all the above-stated information, we understand and
                recognise that most of this information includes personal sensitive data, which is inherently
                private and can expose individuals to risk.
              </p>
              <p className='content'>
                However, it is important that we collect this sensitive personal information on our platform
                to help personalise your user experiences, implement security measures to safeguard your data,
                ensure the security of your financial transactions, and ensure compliance with legal and
                regulatory requirements such as anti-money laundering laws.
              </p>
              <p className='content'>
                For instance, as an exporter, it is essential to collect your data when you sign up with our
                platform and while your campaign is in progress. This is necessary to process your
                crowdfunding campaigns, verify the accuracy of the information you provide, assess the
                credibility of your campaign, and prevent any occurrence of fraud. This would also serve to
                assure backers of the credibility and transparency of your campaigns and help maintain
                accountability and integrity on our platform.
              </p>
              <p className='content'>
                As a backer, it is essential that we collect information such as KYC (Know Your Customer) data
                to verify your financial capabilities, ensure the legality of the funds you contribute, and
                comply with anti-money laundering laws. Verifying your identity and understanding your
                financial activities helps us uphold regulatory requirements and maintain the security and
                integrity of our crowdfunding platform
              </p>
            </div>
          </div>
          {/* No 4 use of personal info */}
          <div className='mt-5'>
            <h5 className='sub-headings'>4. Use of your personal information</h5>
            <p className='content'>
              We use your personal information for various purposes while employing different legal grounds
              for processing your personal information. This is dependent on the nature of our use and the
              potential impact on your privacy.
            </p>
            <p className='content'>Your personal information is used in the following ways:</p>
            <ol type='a' className='ms-4'>
              <li className='ps-3 content'>Where you have provided consent</li>
              <p className='content mt-3'>
                We may use and process your personal information for the following purposes, where you have
                consented for us to do so:
              </p>
              <ol type='i'>
                <li className='content ps-3'>
                  To reach out to you via email with marketing information about our crowdfunding campaigns,
                  funding opportunities, and business updates. Therefore, you will only receive marketing
                  communications from us if you have consented, and you can opt out of receiving them at any
                  time. You can be assured that we do not share your personal information with companies that
                  send their marketing emails to you; and
                </li>
                <li className='content ps-3'>To use any personal data obtained using cookies.</li>
              </ol>

              <p className='content mt-3'>
                You may withdraw your consent for us to use your information in any of these ways at any time
              </p>
              <li className='content'>Where necessary to comply with our legal obligations</li>
              <p className='content'>
                We will use your personal information to fulfil our legal responsibilities for the following
                purposes:
              </p>
              <ol type='i' className='ms-4'>
                <li className='content'>
                  To maintain records concerning your rights as it relates to our processing of your personal
                  data;
                </li>
                <li className='content'>
                  To conduct anti-money laundering checks and other required verifications as required by law;
                </li>
                <li className='content'>
                  To anonymise, pseudonymise, and dispose of your personal information as per our retention
                  policy and data protection regulation; and
                </li>
                <li className='content'>
                  To manage and resolve any complaints received regarding the services we offer.
                </li>
              </ol>
              <li className='content mt-3'>
                Where it is necessary for us to perform our contract with you or carry out any pre-contract
                steps for the following purposes:
              </li>
              <ol type='i' className='ms-4'>
                <li className='content'>Enroll and establish your membership on our website;</li>
                <li className='content'>
                  To display information about your campaign details, and returns on investments you wish to
                  offer;
                </li>
                <li className='content'>
                  To facilitate financial transactions for crowdfunding campaigns and share your information
                  with our payment processors for payment processing;
                </li>
                <li className='content'>
                  To set up your recurring contributions; and v. To share your personal information, as an
                  exporter, with backers to obtain funding for your export campaigns;
                </li>
              </ol>
              <li className='content mt-3'>Where it is necessary for us to pursue a legitimate interest</li>
              <p className='content'>
                We may use and process your personal information where it is necessary for us to pursue our
                legitimate interests as a business for the following purposes:
              </p>
              <ol type='i' className='ms-4'>
                <li className='content'>
                  Processing your personal information for us to promote our business, brand, and activities,
                  and assess the impact and efficiency of our promotional campaigns;{" "}
                </li>
                <li className='content'>
                  For conducting analysis and gaining insights to inform our marketing strategies and enhance
                  your user experience;{" "}
                </li>
                <li className='content'>
                  To identify and record when you have received, opened, or engaged with our website or
                  electronic communications;
                </li>
                <li className='content'>
                  To send you emails about your crowdfunding campaigns and contributed funds; and
                </li>
                <li className='content'>
                  To supply your details to an exporter where you have contributed to their crowdfunding
                  campaign;
                </li>
              </ol>

              <li className='content mt-3'>
                Where processing is necessary for us to support our users with their enquiries
              </li>
              <li className='content mt-3'>
                Where processing is necessary for us to respond to changing market conditions and the needs of
                our guests and visitors to achieve the following:{" "}
              </li>
              <ol type='i' className='mt-3'>
                <li className='content'>
                  To analyse, evaluate, and improve our website and other services so that your visit and use
                  of our website, crowdfunding support, and other services and social media pages, are more
                  useful and enjoyable (we will generally use data amalgamated from many people so that it
                  does not identify you personally);
                </li>
                <li className='content'>
                  To undertake market analysis and research (including contacting you with surveys) so that we
                  can better understand you as a user; and{" "}
                </li>
                <li className='content'>
                  For the purposes of developing new initiatives and features on our website.{" "}
                </li>
              </ol>
              <li className='content mt-3'>
                Where processing is necessary for us to operate the administrative and technical aspects of
                our business efficiently and effectively. This also extends to the following:
              </li>
              <ol type='i' className='ms-4 mt-3'>
                <li className='content'>
                  To administer our website and our social media pages and for internal operations, including
                  troubleshooting, testing, and statistical purposes;{" "}
                </li>
                <li className='content'>
                  To evaluate whether your campaigns as an exporter should receive funding from backers,
                  including by sharing your relevant personal information with backers;
                </li>
                <li className='content'>
                  For the prevention or detection of fraud and other criminal activities or to assist in the
                  apprehension of offenders, including by sharing your personal information with the relevant
                  authorities;
                </li>
                <li className='content'>
                  To verify the accuracy of the data that we hold about you and create a better understanding
                  of you as an account holder or visitor;
                </li>
                <li className='content'>
                  For network and information security to take steps to protect your information against loss
                  or damage, theft, or unauthorised access, including to archive, destroy, pseudonymise, or
                  anonymise your personal information;{" "}
                </li>
                <li className='content'>
                  To comply with a request from you in connection with the exercise of your rights (for
                  example, if you have asked us not to contact you for marketing purposes, we will keep a
                  record of this to be able to comply with your request);{" "}
                </li>
                <li className='content'>
                  For the purposes of corporate restructure, or reorganization, or sale of our business or
                  assets;
                </li>
                <li className='content'>
                  For efficiency, accuracy, or other improvements to our databases and systems, for example,
                  by combining systems or consolidating records we hold about you;
                </li>
                <li className='content'>
                  ix. To enforce or protect our contractual or other legal rights, or to bring or defend legal
                  proceedings;
                </li>
                <li className='content'>
                  To inform you of updates to our terms, conditions and policies; and xi. For other general
                  administration, including managing your queries, complaints, or claims and sending service
                  messages to you.
                </li>
              </ol>
            </ol>

            <p className='content'>
              It is noteworthy that if you give your consent, we may use your personal information to contact
              you by email, to send you newsletters, or to notify you with details of crowdfunding campaigns
              and services. If you do not wish to receive email communications from us, please inform us by
              using the unsubscribe link inside the email messages we send, and if you have a registered
              account on our website, you can unsubscribe by changing your profile settings from within your
              account.
            </p>
            <p className='content'>
              We provide tools that allow exporters to send backers news and updates on their projects.
              Exporters can access contact details for the backers relating to their campaigns, and vice
              versa. Any communications sent using these details will be sent solely based on your decision as
              a user, for which you will be acting as the controller of one another’s personal information
            </p>
            <p className='content'>
              If you opt out of receiving marketing communications from us, we will keep your email address on
              our suppression list for a defined period to ensure that we comply with your wishes.{" "}
            </p>
          </div>
          {/* 5. Disclosure and sharing of your personal information by us */}
          <div className='mt-5'>
            <h5 className='sub-headings'>5. Disclosure and sharing of your personal information by us</h5>
            <p className='content'>
              We only disclose and share your personal information outside of our business in limited
              circumstances. If we do, we will put in place a contract that requires recipients to protect
              their personal information, unless we are legally required to share that information. Any
              supplier(s) that work for us will be obliged to follow our instructions. We do not sell your
              personal information to third parties.
            </p>
            <p className='content'>
              As the controller of your personal information, we decide why and how it is processed. Our
              responsibility for that processing extends to processing by our service providers if they
              process your personal information based on our instructions. We also work with other
              organisations in connection with some of the processing activities described in this statement,
              such as social media platforms and certain suppliers, such as the payment service providers we
              work with.
            </p>
            <p className='content'>
              Where that personal information is collected and sent to other organisations for a processing
              purpose that is in both our and their interests, or where we make decisions together in relation
              to that processing, we will be “joint controllers” with the organisations involved. Where this
              applies, we and the other organisation will be jointly responsible to you under the Nigeria Data
              Protection Act (or just NDPA since it has been mentioned before) for this processing. If,
              however, we pass your personal information to an organisation that independently decides why and
              how to use your personal information, then it will be separately responsible to you for that
              processing and the use of your personal information in the ways described in its privacy policy
              (and not ours).
            </p>
            <p className='content'>
              Furthermore, when we use third party organisations or providers. We only disclose to them any
              personal information that is necessary for them to provide their services, and only where we
              have a contract in place that requires them to keep your information safe and secure. Thus, our
              third party organisation or provider can be categorised as follows:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>Accountants, legal and security advisers, and consultants; </li>
              <li className='content'>Advertising, PR, digital, and creative agencies;</li>
              <li className='content'>Banks, payment processors, and financial services providers;</li>
              <li className='content'>Finance (Banking and Payment Processing);</li>
              <li className='content'>Business intelligence and performance services; </li>
              <li className='content'>
                Cloud software system providers, including database, email, and document management and
                monitoring providers;
              </li>
              <li className='content'>Customer support services tool;</li>
              <li className='content'>
                Facilities and technology service providers, including scanning and data destruction
                providers;
              </li>
              <li className='content'>Fraud and identity verification services;</li>
              <li className='content'>Tax administration; and</li>
              <li className='content'>Social media platforms.</li>
            </ol>
            <p className='content'>
              In respect to third parties, we may disclose your personal information to the following:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                any third party who is restructuring, selling, or acquiring some or all of our business or
                assets, or otherwise in the event of a merger, acquisition, re-organisation, or similar event;
                and
              </li>
              <li className='content'>
                if we are under duty to disclose or share your information to comply with any legal or
                regulatory obligation or request, including by the police, courts, tribunals, regulators, or
                similar authorities, or if we voluntarily decide to cooperate with such authorities.
              </li>
            </ol>
          </div>
          {/* No 6 data security */}
          <div className='mt-5'>
            <h5 className='sub-headings'>6. Data Security</h5>
            <p className='content'>
              We are committed to protecting your personal data. Therefore, Users are also encouraged to
              regularly review their privacy settings and update their personal information as necessary to
              ensure its accuracy and relevance. Additionally, they should promptly notify the platform of any
              unauthorised access or suspected security breaches involving their account.
            </p>
          </div>
          {/* 7. The periods for which we retain your personal information */}
          <div className='mt-5'>
            <h5 className='sub-headings'> 7. The periods for which we retain your personal information</h5>
            <p className='content'>
              We will not hold your personal information in an identifiable format for any longer than is
              necessary for the purposes for which we collected it. For certain purposes, we retain your
              personal information for a very short period, while for others, we retain it for a period of 6
              years after the information is no longer required for business reasons so that we can deal with
              any legal proceedings that could arise. The only exceptions to the period of retention of your
              data include where:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                You exercise your right to have the information erased (where it applies), and we do not need
                to hold it in connection with any of the reasons permitted or required under the law;
              </li>
              <li className='content'>
                You exercise your right to require us to retain your personal information for a period longer
                than our stated retention period;
              </li>
              <li className='content'>
                We bring or defend a legal claim or other proceedings during the period we retain your
                personal information, in which case we will retain your personal information until those
                proceedings have concluded and no further appeals are possible;
              </li>
              <li className='content'>
                We archive the information; in which case we will delete it in accordance with our deletion
                cycle; or
              </li>
              <li className='content'>
                In limited cases, existing or future law, regulation, or a court requires us to keep your
                personal information for a longer or shorter period.
              </li>
            </ol>
          </div>
          {/* 8. Your rights in relation to your personal information */}
          <div className='mt-5'>
            <h5 className='sub-headings'> 8. Your rights in relation to your personal information </h5>
            <p className='content'>
              You have several rights in relation to your personal information under the Nigerian Data
              Protection Act. In relation to certain rights, we may ask you for information to verify your
              identity and, where applicable, to help us search for your personal information. Except in rare
              cases, we will respond to you within 30 days after we have received this information or, where
              no such information is required, after we have received full details of your request. You have
              the following rights, some of which may only apply in certain circumstances:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>To be informed about the processing of your personal information;</li>
              <li className='content'>
                To have your personal information corrected if it is inaccurate and to have incomplete
                personal information completed;
              </li>
              <li className='content'>To object to the processing of your personal information;</li>
              <li className='content'>
                To withdraw your consent to processing your personal information at any time;
              </li>
              <li className='content'>To restrict the processing of your personal information;</li>
              <li className='content'>To have your personal information erased;</li>
              <li className='content'>
                To request access to your personal information and information about how we process it;
              </li>
              <li className='content'>
                To electronically move, copy, or transfer your personal information in a standard,
                machinereadable form; and
              </li>
              <li className='content'>
                To claim rights relating to the prevention of automated decision-making, including profiling
              </li>
            </ol>
          </div>
          {/* 9. Changes to our Privacy Policy */}
          <div className='mt-5'>
            <h5 className='sub-headings'> 9. Changes to our Privacy Policy </h5>
            <p className='content'>
              {" "}
              We may review this policy from time to time, and any changes will be notified to you by email
              and by posting an updated version on our website. We recommend that you regularly check this
              page for changes and review this policy each time you visit our website
            </p>
          </div>
          {/* 10. Contact and Legal Information*/}
          <div className='mt-5'>
            <h5 className='sub-headings'> 10. Contact and Legal Information</h5>
            <p className='content'>
              You can contact us with your queries in relation to this policy or for any other reason using
              our website by email. To contact us in relation to this policy, including to exercise any of
              your rights in relation to your personal information, please contact us or write to us by email
              at <span className='text-primary-500'> hello@krowdfi.com.</span>
            </p>
            <p className='content'>
              By using the web app or website, you agree to the terms of this privacy policy. If you do not
              agree with these terms, please do not use the website or web application.
            </p>
            <p className='content'>Krowdfi</p>
          </div>
        </div>
      </main>
      <FooterComponent />
    </section>
  );
};
