import { TableContainer, Table, Flex, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react";
import { PaginationChakra } from "components/custom";
import { ReactComponent as SortIcon } from "assets/svg/sort.svg";
import { ReactComponent as CreditIcon } from "assets/svg/credit-icon.svg";
import { ReactComponent as DebitIcon } from "assets/svg/debit-icon.svg";
import { useWindowWidth } from "utilities/windowWidth";

export const TransactionHistory = () => {
  const windowWidth = useWindowWidth();
  const data = [
    {
      transaction: "Withdrawal",
      date: "07/11/23",
      transaction_id: 1234567890,
      amount: "\u20A62,000",
      status: "Successful",
    },
    {
      transaction: "Fund Wallet",
      date: "07/11/23",
      transaction_id: 1234567890,
      amount: "\u20A643,910",
      status: "Successful",
    },
    {
      transaction: "Campaign Contribution",
      date: "07/11/23",
      transaction_id: 1234567890,
      amount: "\u20A62,000",
      status: "Successful",
    },
    {
      transaction: "Fund Wallet",
      date: "07/11/23",
      transaction_id: 1234567890,
      amount: "\u20A643,910",
      status: "Failed",
    },
    {
      transaction: "Withdrawal",
      date: "07/11/23",
      transaction_id: 1234567890,
      amount: "\u20A62,000",
      status: "Successful",
    },
    {
      transaction: "Fund Wallet",
      date: "07/11/23",
      transaction_id: 1234567890,
      amount: "\u20A643,910",
      status: "Failed",
    },
  ];
  return (
    <Flex
      direction={"column"}
      className={`${windowWidth >= 768 ? "border border-grey-100" : ""} py-4`}
      borderRadius={"8px"}
    >
      <Flex
        justify={"space-between"}
        px={windowWidth >= 768 ? "25px" : "0px"}
        mx={windowWidth <= 768 ? "24px" : "0px"}
        alignItems={"center"}
        className={windowWidth >= 768 ? "mb-3" : "mb-0 border-bottom pb-3"}
      >
        <h4 className={`fw-600 mb-0 ${windowWidth >= 768 ? "" : "fs-16"}`}>Transaction History</h4>
        {windowWidth >= 768 ? (
          <Flex
            gap={"8px"}
            align={"center"}
            className='border border-grey-600 touchable'
            py={"8px"}
            px={"16px"}
            borderRadius={"8px"}
          >
            <SortIcon />
            <span className='text-grey-600'>Sort</span>
          </Flex>
        ) : (
          <p className='fs-14 text-primary-600'>View All</p>
        )}
      </Flex>
      <TableContainer overflow={"hidden"}>
        <Table variant='simple' size={"lg"} mb={windowWidth >= 768 ? "32px" : "0px"} overflow={"hidden"}>
          {windowWidth >= 768 && (
            <Thead>
              <Tr>
                <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                  Transaction
                </Th>
                <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                  Date
                </Th>
                <Th
                  fontFamily='Inter'
                  isNumeric
                  textTransform={"none"}
                  className='fs-14 text-grey-900 fw-600'
                >
                  Transaction ID
                </Th>
                <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                  Amount
                </Th>
                <Th fontFamily='Inter' textTransform={"none"} className='fs-14 text-grey-900 fw-600'>
                  Status
                </Th>
              </Tr>
            </Thead>
          )}
          <Tbody>
            {windowWidth >= 768
              ? data.map((item, index) => (
                  <Tr key={index}>
                    <Td className='text-grey-600 fw-400'>{item.transaction}</Td>
                    <Td className='text-grey-400 fw-400'>{item.date}</Td>
                    <Td className='text-grey-400 fw-400' isNumeric>
                      {item.transaction_id}
                    </Td>
                    <Td className='text-grey-400 fw-400'>{item.amount}</Td>
                    <Td>
                      <span
                        className={`fs-12 ${
                          item.status.toLowerCase() === "failed"
                            ? "bg-error-50 text-error-700"
                            : "bg-success-50 text-success-700"
                        } fs-14 fw-500 rounded-pill`}
                        style={{ paddingBlock: "2px", paddingInline: "8px" }}
                      >
                        {item.status}
                      </span>
                    </Td>
                  </Tr>
                ))
              : data.map((item, index) => (
                  <Tr key={index} overflow={"hidden"}>
                    <Td className='fw-400 d-flex flex-row gap-3 justify-content-start'>
                      {item.status === "Successful" ? <CreditIcon /> : <DebitIcon />}
                      <div>
                        <p className='text-grey-900 mb-1 fs-14'>{item.transaction}</p>
                        <p className='text-grey-400 mb-0 fs-12'>{item.date}</p>
                      </div>
                    </Td>
                    <Td className='text-grey-400 fw-400'>
                      <p className='text-grey-900 mb-1 fs-14 text-end'>{item.amount}</p>
                      <p className='text-grey-400 mb-0 fs-12 text-end'>ID- {item.transaction_id}</p>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
        {windowWidth >= 768 && (
          <PaginationChakra totalPages={6} totalItems={6} itemsPerPage={6} onPageChange={() => {}} />
        )}
      </TableContainer>
    </Flex>
  );
};
