import { ReactComponent as HomeIcon } from "assets/svg/sidebar/home.svg";
import { ReactComponent as CampaignIcon } from "assets/svg/sidebar/campaign.svg";
// import { ReactComponent as WalletIcon } from "assets/svg/sidebar/wallet.svg";
import { ReactComponent as ProfileIcon } from "assets/svg/sidebar/profile.svg";
import { ReactComponent as SettingsIcon } from "assets/svg/sidebar/settings.svg";
import { ReactComponent as ActiveHomeIcon } from "assets/svg/sidebar/home-active.svg";
import { ReactComponent as ActiveCampaignIcon } from "assets/svg/sidebar/campaign-active.svg";
// import { ReactComponent as ActiveWalletIcon } from "assets/svg/sidebar/wallet.svg";
import { ReactComponent as ActiveProfileIcon } from "assets/svg/sidebar/profile-active.svg";
import { ReactComponent as ActiveSettingsIcon } from "assets/svg/sidebar/settings-active.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/sidebar/logout.svg";
import { ReactComponent as TransactionIcon } from "assets/svg/sidebar/transaction.svg";
import { ReactComponent as ActiveTransactionIcon } from "assets/svg/sidebar/transaction-active.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useModalProvider } from "providers";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { useWindowWidth } from "utilities/windowWidth";
import { ReactComponent as HamburgerClose } from "assets/svg/hamburger-close.svg";
import React, { useEffect } from "react";
import { IconButton } from "@chakra-ui/react";
import { useAuth } from "store/auth";

interface SideBarComponentProps {
  page?: string | null;
  active?: boolean;
  width?: number;
  className: string;
  close?: React.Dispatch<React.SetStateAction<boolean>>;
  profileInfo: any;
}

export const kycNotProvided = (label: string, completionRate: boolean) => {
  return (label === "Campaign" || label === "Transactions" || label === "Profile") && completionRate;
};
export const SideBarComponent: React.FC<SideBarComponentProps> = ({
  page,
  active,
  width,
  className,
  close,
  profileInfo,
}) => {
  const minCompletionRate = process.env.REACT_APP_PROFILE_COMPLETION ?? 88;
  const pageSplit = page ? page.split("/") : [];
  const { user } = useAuth();
  const { initModal } = useModalProvider();
  const location = useLocation();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    document.addEventListener("click", () => {
      close!(false);
    });
    return () => document.removeEventListener("click", () => {});
  }, []);

  return (
    <div
      className={`sidebar position-fixed bg-white py-4 px-4 h-100 border-end ${className}`}
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      <div className='pt-2'>
        {windowWidth <= 768 && (
          <div className='d-flex flex-row justify-content-between align-items-center mb-5'>
            <Logo width={"110px"} />
            <IconButton
              aria-label='Close'
              icon={<HamburgerClose />}
              border={"none"}
              bg={"transparent"}
              onClick={() => close!(false)}
            />
          </div>
        )}
        <div className='g-4 link-group'>
          {[
            {
              label: "Dashboard",
              to: "dashboard",
              icon: <HomeIcon />,
              iconActive: <ActiveHomeIcon />,
            },
            {
              label: "Campaign",
              to: "campaign",
              icon: <CampaignIcon />,
              iconActive: <ActiveCampaignIcon />,
            },
            // {
            //   label: "Transactions",
            //   to: "transactions",
            //   icon: <TransactionIcon />,
            //   iconActive: <ActiveTransactionIcon />,
            // },
          ].map((item, key) => (
            <NavLink
              key={key}
              className={({ isActive }) => {
                return isActive
                  ? "navlink bg-primary-25 text-primary-600 fw-600 text-decoration-none"
                  : "navlink text-grey-900 fw-400 text-decoration-none";
              }}
              to={`/exporter/${item.to}`}
              // onClick={() => {
              //   close!(false);
              //   if (
              //     kycNotProvided(
              //       item.label,
              //       (user?.profileCompleted?.completionRate ||
              //         profileInfo?.data?.profileCompleted?.completionRate) <
              //         96 ?? false
              //     )
              //   ) {
              //     initModal((prev) => ({
              //       ...prev,
              //       provideKyc: true,
              //     }));
              //   }
              // }}
            >
              {/* <Box className={getNavClassName(item)}> */}
              {pageSplit[0] === item.to ? item.iconActive : item.icon}
              <div className='nav-text'>{item.label}</div>
              {/* </Box> */}
            </NavLink>
          ))}
          <hr className='border-grey-200' />
          {[
            {
              label: "Profile",
              to: "profile",
              icon: <ProfileIcon />,
              iconActive: <ActiveProfileIcon />,
            },
            {
              label: "Settings",
              to: "settings",
              icon: <SettingsIcon />,
              iconActive: <ActiveSettingsIcon />,
            },
          ].map((item, key) => (
            // <div key={key} className="col-sm-12 col-3 touchable">
            <NavLink
              key={key}
              className={({ isActive }) => {
                return isActive
                  ? "navlink bg-primary-25 text-primary-600 fw-600 text-decoration-none"
                  : "navlink text-grey-900 fw-400 text-decoration-none";
              }}
              to={
                kycNotProvided(
                  item.label,
                  (user?.profileCompleted?.completionRate ||
                    profileInfo?.data?.profileCompleted?.completionRate) < 88 ?? false
                )
                  ? `/${user?.role ?? profileInfo?.role}/profile/create`
                  : `/${user?.role ?? profileInfo?.role}/${item.to}`
              }
              onClick={() => {
                close!(false);
              }}
            >
              {/* <Box className={getNavClassName(item)}> */}
              {pageSplit[0] === item.to ? item.iconActive : item.icon}
              <div className='nav-text'>{item.label}</div>
              {/* </Box> */}
            </NavLink>
          ))}
          <button
            onClick={() => {
              location.state = null;
              initModal((prev) => ({
                ...prev,
                logout: true,
              }));
            }}
            className='text-error-600 w-100 navlink border-0 logout-btn position-relative top-60 '
          >
            <LogoutIcon />
            <div className='nav-text fw-400'>Logout</div>
          </button>
        </div>
      </div>
    </div>
  );
};
