import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { useModalProvider } from "providers/modalProvider";
import { ReactComponent as VerifiedIcon } from "assets/svg/verified-icon.svg";
import { redirectTo } from "utilities/general";

interface TwoFacAuthSuccessModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const TwoFacAuthSuccessModal: FC<TwoFacAuthSuccessModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={false}
        onClose={() => {
          initModal((prev) => ({ ...prev, verifyCodeModal: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Close"}
        proceedButtonProps={{
          className: "btn-lg primary-btn ms-0",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              twoFacSuccess: false,
            }));
            // redirectTo("/exporter/dashboard")
          },
        }}
        useHeader={false}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className: "d-flex align-items-center justify-content-center w-100",
        }}
        title={"Two-factor authentication"}
        size={"md"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center gap-5">
            <VerifiedIcon />
            <div className="mb-4">
              <h5 className="fs-20 fw-600 mb-2">2FA Verified!</h5>
              <p className="fs-14 text-grey-600 fw-400 m-0">
                Two-factor authentication has been successfully enabled for your
                account
              </p>
            </div>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
