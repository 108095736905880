import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { ReactComponent as FailedIcon } from "assets/svg/modal-warning-icon.svg";
// import { useNavigate } from "react-router";
import { useModalProvider } from "providers/modalProvider";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useDeleteCampaignMutation } from "store/campaigns";
interface DeleteCampaignModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const DeleteCampaignModal: FC<DeleteCampaignModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal, getModalPayload } = useModalProvider();
  const { initNotification } = usePageNotificationProvider();
  const campaignId = getModalPayload("deleteCampaign");
  const [request, { isLoading }] = useDeleteCampaignMutation();
  console.log("campaignidmodal", campaignId);

  // const handleProceed = () => {
  //   // window.location.replace(`/exporter/dashboard`);
  //   onClose();
  // };
  const handleDelete = () => {
    const payload = {
      id: campaignId.id,
    };

    request(String(payload.id))
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });

          return;
        }

        initNotification({
          message: "Campaign Deleted Successfully",
        });

        initModal((prev) => ({
          ...prev,
          deleteCampaign: false,
        }));
        window.location.reload();
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({ message: error.message, scheme: "error" });
      });
  };

  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, deleteCampaign: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Cancel"}
        proceedButtonProps={{
          className: "btn-lg secondary-btn ms-0 w-100  mx-4",
          onClick: () => {
            initModal((prev) => ({ ...prev, deleteCampaign: false }));
          },
        }}
        cancelButtonDefaultChild={"Delete"}
        cancelButtonProps={{
          className: "btn-lg border ms-0 primary-error-btn w-100 text-white",
          onClick: handleDelete,
        }}
        useHeader={true}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className: "d-flex flex-row-reverse w-100 pb-0 px-0 mt-2",
        }}
        title={""}
        size={"md"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center">
            <FailedIcon className="mb-3" />
            <h5 className="fw-600 text-grey-900 mb-3">Delete Campaign</h5>
            <p className="fw-400 text-grey-500 mb-2">
              Are you sure you want to delete this campaign?
            </p>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
