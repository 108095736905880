import { PrimaryButton, PrimaryInput, SimpleFileUploadComponent } from "components";
import React, { FC } from "react";
import { useFormik } from "formik";
import { CreatePersonalDetailsValidationSchema } from "validations";
import queryString from "query-string";
import { useWindowWidth } from "utilities";
import { CreateCampaignPayload, useGetCampaignQuery } from "store/campaigns";
import { usePageNotificationProvider } from "providers";

export type CampaignDocumentProps = {
  next: () => void;
  previous: () => void;
};

export const CampaignDocument: FC<CampaignDocumentProps> = ({ next, previous }) => {
  const { id } = queryString.parse(window.location.search) as {
    id: string;
  };

  const { data: campaign, refetch } = useGetCampaignQuery(id, {
    pollingInterval: 30000,
  });

  const windowWidth = useWindowWidth();
  const { initNotification } = usePageNotificationProvider();

  const { handleSubmit } = useFormik({
    initialValues: {
      purchaseOrder: "",
      proformaInvoice: "",
      letterOfCredit: "",
      accountStatement: "",
    },
    validationSchema: CreatePersonalDetailsValidationSchema,
    onSubmit: (values) => {
      console.log("clicked");
    },
  });

  function extractFileNameFromUrl(url: string | URL | any) {
    if (url) {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      const pathSegments = pathname.split("/");
      const filenameWithParams = pathSegments[pathSegments.length - 1];
      const filenameSegments = filenameWithParams.split("?");
      const filename = filenameSegments[0];
      console.log("URL:", url);
      console.log("Extracted Filename:", filename);
      return filename;
    }
  }

  const handleNext = () => {
    refetch();
    if (
      !campaign?.data?.proformaInvoice ||
      !campaign?.data?.letterOfCredit ||
      !campaign?.data?.purchaseOrder ||
      !campaign?.data?.accountStatement
    ) {
      initNotification({
        message: "Please upload all documents!",
        scheme: "error",
      });
      return;
    }
    next();
  };

  return (
    <section className=' bg-transparent '>
      <h6 className='text-grey-900 fs-20 fw-semibold my-3'>Export Documents</h6>
      {windowWidth <= 768 && (
        <p className='text-grey-500 fw-400 fs-14 mb-2'>Provide documents required to fulfil an order</p>
      )}
      <p className={`text-warning-700 ${windowWidth <= 768 && "fs-12"}`}>
        Supported file formats: PDF, JPEG, PNG
      </p>

      <div className='row pt-3'>
        <div className='col-md-12'>
          <form onSubmit={handleSubmit} className=''>
            <div className='col-md-12 mb-4'>
              <SimpleFileUploadComponent
                fileClass='purchaseOrder'
                label='Purchase Order*'
                category='campaign'
                campaignId={id}
                allowedFIleType='application/pdf, image/jpg, image/jpeg, image/png'
                fileName={extractFileNameFromUrl(campaign?.data.purchaseOrder) ?? ""}
                refetch={refetch}
              />
            </div>

            <div className='col-md-12 mb-4'>
              <SimpleFileUploadComponent
                fileClass='proformaInvoice'
                label='Proforma Invoice*'
                category='campaign'
                campaignId={id}
                allowedFIleType='application/pdf,  image/jpg, image/jpeg, image/png'
                fileName={extractFileNameFromUrl(campaign?.data.proformaInvoice) ?? ""}
                refetch={refetch}
              />
            </div>

            <div className='col-md-12 mb-4'>
              <SimpleFileUploadComponent
                fileClass='letterOfCredit'
                label='Letter of Credit*'
                category='campaign'
                campaignId={id}
                allowedFIleType='application/pdf,  image/jpg, image/jpeg, image/png'
                fileName={extractFileNameFromUrl(campaign?.data.letterOfCredit) ?? ""}
                refetch={refetch}
              />
            </div>

            <div className='col-md-12 mb-5'>
              <SimpleFileUploadComponent
                fileClass='accountStatement'
                label='Statement of Account* (Please upload your account statement for the past 6 months)'
                category='campaign'
                campaignId={id}
                allowedFIleType='application/pdf,  image/jpg, image/jpeg, image/png'
                fileName={extractFileNameFromUrl(campaign?.data.accountStatement) ?? ""}
                refetch={refetch}
              />
            </div>

            <div>
              <PrimaryButton
                onClick={previous}
                className='btn-lg secondary-btn mt-3 me-3 align-self-baseline'
              >
                {windowWidth >= 768 ? "Back" : "Cancel"}
              </PrimaryButton>
              <PrimaryButton onClick={handleNext} className='btn-lg primary-btn mt-3 align-self-baseline'>
                Next
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
