import React from "react";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { Link } from "react-router-dom";
import { useWindowWidth } from "utilities/windowWidth";
import Privacy from "assets/file/privacy-policy.pdf";
import Terms from "assets/file/terms.pdf";
const footerLinks = [
  {
    title: "Company",
    items: [
      { name: "About Us", url: "#" },
      // { name: "Newsletters", url: "/newsletters" },
      { name: "Career", url: "#" },
    ],
  },
  {
    title: "Support",
    items: [
      { name: "Help Centre", url: "#" },
      // { name: "FAQ", url: "/faq" },
      { name: "Contact Us", url: "#" },
    ],
  },
  {
    title: "Follow Us",
    items: [
      { name: "LinkedIn", url: "https://www.linkedin.com/company/krowdfi" },
      { name: "Twitter", url: "https://twitter.com/krowdfi" },
      { name: "Instagram", url: "https://www.instagram.com/krowdfi" },
      { name: "Facebook", url: "#" },
    ],
  },
];
export const FooterComponent = () => {
  const windowWidth = useWindowWidth();

  return (
    <footer className='footer w-90 mx-auto pb-3'>
      <div
        className='row border-bottom'
        style={{
          paddingBottom: "90px",
        }}
      >
        <div className='col-lg-4'>
          <Link to='/'>
            <Logo />
          </Link>
          <p className={`${windowWidth <= 768 ? "w-100" : "w-70"} text-grey-700 fw-400 fs-18 lh-base pt-5`}>
            Empowering Export Businesses, One Campaign at a Time
          </p>
        </div>

        <div className='col-lg-8 pt-3'>
          <div className={`${windowWidth <= 768 ? "gap-3 " : "gap-5"} d-flex flex-wrap `}>
            {footerLinks.map((linkGroup, index) => (
              <div className={`${windowWidth <= 768 ? "w-45 " : "w-25"}`}>
                <div key={index}>
                  <h3 className='text-grey-900 fs-20 fw-600'>{linkGroup.title}</h3>
                  {linkGroup.items.map((item, itemIndex) => (
                    <ul key={itemIndex} className='p-0'>
                      <li className=' text-grey-700 fs-16 py-3'>
                        <Link
                          to={item.url}
                          target={item.url.startsWith("http") ? "_blank" : "_self"}
                          rel={item.url.startsWith("http") ? "noopener noreferrer" : ""}
                        >
                          {item.name}
                        </Link>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-between pt-3 text-grey-700 fs-16 fw-400 mb-3'>
        <div className='d-flex align-items-center gap-3 '>
          <p className='border-end pe-3'>
            <Link to='/privacy-policy' target='_blank'>
              Privacy Policy
            </Link>
          </p>
          <p>
            <Link to='/terms-of-use' target='_blank'>
              Terms & Conditions
            </Link>
          </p>
        </div>
        <div>© Krowdfi 2024</div>
      </div>
    </footer>
  );
};
