import { AlertDefaultBox } from "components/custom";
import { ReactComponent as WarningIcon } from "assets/svg/warning-icon.svg";
import { ReactComponent as NotificationIcon } from "assets/svg/noti.svg";
import { ReactComponent as FundsIcon } from "assets/svg/funds-icon.svg";
import { ReactComponent as CampaignIcon } from "assets/svg/total-campaign.svg";
import { ReactComponent as TimeIcon } from "assets/svg/time-icon.svg";
import { ReactComponent as OctIcon } from "assets/svg/octicon_tracked-by-closed-completed-24.svg";
import { Link } from "react-router-dom";
import { useAuth } from "store/auth";
import { useGetAllCampaignsQuery } from "store/campaigns";
import { Box, Flex } from "@chakra-ui/react";
import { useWindowWidth } from "utilities/windowWidth";
import QuickActions from "./quickActions";
import RecentActivity from "./recentActivity";

export const AdminDashboard = () => {
  const windowWidth = useWindowWidth();
  const { user } = useAuth();
  const profileCompleted = user?.profileCompleted?.completionRate;
  const data: any = {};

  return (
    <div className='dashboard-wrapper'>
      <div className='dashboard-statistics'>
        {[
          {
            title: "Registered Exporters",
            icon: <FundsIcon />,
            value: data?.fundsRaised ?? "0",
          },
          {
            title: "Registered Backers",
            icon: <CampaignIcon />,
            value: data?.totalCampaigns ?? "0",
          },
          {
            title: "Pending Verification",
            icon: <TimeIcon />,
            value: data?.pendingCampaigns ?? "0",
          },
          {
            title: "Pending Campaigns",
            icon: <OctIcon />,
            value: data?.completedCampaigns ?? "0",
          },
        ].map((item, key) => (
          <Box className='statistic' key={key}>
            <div className='d-flex flex-row align-items-center justify-content-start gap-2 text-grey-400 w-100'>
              <div>{item.icon}</div>
              <p className='my-auto'>{item.title}</p>
            </div>
            <h3 className='value lh-1 mb-0 fw-600'>{item.value}</h3>
          </Box>
        ))}
      </div>
      <Flex className='mt-4' gap={"60px"} justifyContent={"start"}>
        <RecentActivity />
        <QuickActions />
      </Flex>
    </div>
  );
};
