import { SliderMenu } from "components";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronRight } from "assets/svg/chevron-right.svg";
import { Switch } from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { useGeneralAppProvider, useModalProvider, usePageNotificationProvider } from "providers";
import { useStateWithCallback } from "store/hooks";
import { resolveApiError, useWindowWidth } from "utilities";
import { useNavigate } from "react-router-dom";
import { useGetProfileInformationQuery, useUpdateProfileMutation } from "store/profile";
import { setCredential, useAuth } from "store/auth";
import { useDispatch } from "react-redux";

export const Security = ({
  activeMenu,
  setActiveMenu,
}: {
  activeMenu: number;
  setActiveMenu: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const { user, token: access_token } = useAuth();
  const dispatch = useDispatch();
  const [request, { isLoading: isUpdating }] = useUpdateProfileMutation();
  const { initNotification } = usePageNotificationProvider();
  const isMounted = useRef(false);
  const { initModal } = useModalProvider();
  const [isChecked, setIsChecked] = useStateWithCallback(false);
  const {
    data: profileInfo,
    isLoading,
    refetch,
  } = useGetProfileInformationQuery((() => {})(), {
    pollingInterval: 30000,
  });
  const { setPrimaryLoading } = useGeneralAppProvider();
  setPrimaryLoading(isLoading ?? false);

  const profile = profileInfo?.data || user;

  const initialProfileValues = {
    firstName: profile!.firstName,
    // avatar: profile!.avatar,
    // image: profile!.image,
    lastName: profile!.lastName,
    email: profile!.email,
    phoneNumber: profile!.phoneNumber,
    address: profile!.address,
    country: profile!.country,
    city: profile!.city,
    state: profile!.state,
    postalCode: profile!.postalCode,
    sex: profile!.sex,
    dateOfBirth: profile!.dateOfBirth,
    signature: profile!.signature,
    businessInformation: {
      exportGoodType: profile!.businessInformation?.exportGoodType,
      TIN: profile!.businessInformation?.TIN,
      yearsInExport: profile!.businessInformation?.yearsInExport,
      exportFrequency: profile!.businessInformation?.exportFrequency,
      countriesOfExport: profile!.businessInformation?.countriesOfExport,
      exportRegCertificateUrl: profile!.businessInformation?.exportRegCertificateUrl,
    },
    userIdentity: {
      identificationMode: profile!.userIdentity?.identificationMode,
      identificationUrl: profile!.userIdentity?.identificationUrl,
      utilityBillUrl: profile!.userIdentity?.utilityBillUrl,
      bvn: profile!.userIdentity?.bvn,
    },
  };
  useEffect(() => {
    setIsChecked(profile?.enable2fa);
  }, [profile?.enable2fa]);

  const handleChange = () => {
    setIsChecked(!isChecked, () => {
      if (!isChecked) {
        if (windowWidth >= 768) {
          initModal((prev) => ({
            ...prev,
            enterPhoneNo: true,
          }));
        } else {
          navigate("two-fac-auth/phone");
        }
      } else {
        if (windowWidth >= 768) {
          initModal((prev) => ({
            ...prev,
            verifyPhoneModal: true,
          }));
        } else {
          navigate("two-fac-auth/disable");
        }
      }
    });
  };

  const initToggle2fa = () => {
    request({ ...initialProfileValues, enable2fa: !isChecked })
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }
        if (res.data.enable2fa) {
          if (windowWidth >= 768) {
            initModal((prev) => ({
              ...prev,
              twoFacAuth: true,
            }));
          } else {
            navigate("two-fac-auth/phone");
          }
        }
        // else {
        //   if (windowWidth >= 768) {
        //     initModal((prev) => ({
        //       ...prev,
        //       disableTwoFac: true,
        //     }));
        //   } else {
        //     navigate("two-fac-auth/disable");
        //   }
        // }

        // initNotification({
        //   message: "Personal detail saved",
        // });
        refetch();
        dispatch(
          setCredential({
            user: res.data,
            access_token,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        if (error.data.statusCode === 403) initModal({ sessionExpired: true });
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  const handle2faToggle = () => {
    if (profile!.enable2fa) {
      initModal({
        confirm2faPassword: {
          payload: {
            initToggle2fa,
            title: "Disable two-factor authentication",
            proceedButtonText: "Deactivate",
          },
        },
      });
    } else {
      initModal({
        confirm2faPassword: {
          payload: {
            initToggle2fa,
            title: "Enable two-factor authentication",
            modalInit: "twoFacAuth",
            proceedButtonText: "Activate",
          },
        },
      });
    }
  };

  return (
    <div className='settings-content-wrapper'>
      <SliderMenu
        headings={["Security", "Notifications", "Verification Centre"]}
        setActiveMenu={setActiveMenu}
        activeMenu={activeMenu}
      />
      <div className='d-flex flex-column settings-content'>
        <label
          htmlFor='twofa'
          className='d-flex flex-row justify-content-between align-items-center border border-grey-100 security-item'
        >
          <div className='d-flex flex-column gap-2 max-w-380'>
            <h5 className={`fw-600 mb-1 ${windowWidth <= 768 && "fs-14"}`}>Two-factor authentication</h5>
            <p className={`text-grey-600 fw-400 w-80 ${windowWidth >= 768 ? "fs-14" : "fs-12"} mb-0`}>
              {isChecked
                ? "Enabled"
                : "Set up your two-factor authentication to add an extra layer of protection to your account"}
            </p>
          </div>
          <Switch
            id='twofa'
            colorScheme='brand-primary'
            size={windowWidth >= 768 ? "lg" : "md"}
            isChecked={isChecked}
            onChange={handle2faToggle}
            // disabled={profile?.enable2fa}
          />
        </label>
        <div
          className='d-flex flex-row justify-content-between align-items-center border border-grey-100 security-item campaign-item'
          onClick={() => {
            navigate("change-password");
          }}
        >
          <div className='d-flex flex-column gap-2 max-w-380'>
            <h5 className={`fw-600 mb-1 ${windowWidth <= 768 && "fs-14"}`}>Change Password</h5>
            <p className={`text-grey-600 fw-400 ${windowWidth >= 768 ? "fs-14" : "fs-12"} mb-0`}>
              Click here to change your account's password
            </p>
          </div>
          <ChevronRight />
        </div>
      </div>
    </div>
  );
};
