import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { ReactComponent as FailedIcon } from "assets/svg/modal-warning-icon.svg";
// import { useNavigate } from "react-router";
import { useModalProvider } from "providers/modalProvider";

interface DeletePaymentModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const DeletePaymentModal: FC<DeletePaymentModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={true}
        onClose={() => {
          initModal((prev) => ({ ...prev, deletePaymentInfo: false }));
        }}
        useProceedButton={true}
        proceedButtonDefaultChild={"Go Back"}
        proceedButtonProps={{
          className: "btn-lg primary-btn ms-0 w-100  mx-4",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              deletePaymentInfo: false,
            }));
          },
        }}
        cancelButtonDefaultChild={"Delete"}
        cancelButtonProps={{
          className: "btn-lg border ms-0 primary-error-btn w-100 text-white",
          onClick: () => {
            initModal((prev) => ({ ...prev, deletePaymentInfo: false }));
          },
        }}
        useHeader={true}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        useHeaderCancel={false}
        modalFooterProps={{
          className: "d-flex flex-row-reverse w-100 pb-0 px-0 mt-2",
        }}
        title={""}
        size={"md"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center">
            <FailedIcon className="mb-3" />
            <h5 className="fw-600 text-grey-900 mb-3">
              Delete Payment Information
            </h5>
            <p className="fw-400 text-grey-500 mb-2">
              Are you sure you want to delete payment information?
            </p>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
