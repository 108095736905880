import { PrimaryButton } from "components/buttons";
import { Link } from "react-router-dom";
import { useWindowWidth } from "utilities/windowWidth";

export const ExporterStartJourney = () => {
  const windowWidth = useWindowWidth();
  return (
    <section
      className="start-journey-bg"
      style={{
        maxHeight: "586px",
      }}
    >
      <div className="text-center">
        <h1
          style={{
            maxWidth: windowWidth <= 768 ? "100%" : "848px",
          }}
          className={`${
            windowWidth <= 768 ? " fs-47  lh-sm " : " fs-59  "
          }text-white fw-600 mx-auto`}
        >
          Ready to Revolutionize Your Export Journey?
        </h1>
        <PrimaryButton className="btn-lg secondary-btn mt-5">
          <Link to="/register">Start Your Krowdfi Journey</Link>
        </PrimaryButton>
      </div>
    </section>
  );
};
