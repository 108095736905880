import { PrimaryButton } from "components/buttons";
import { PrimaryCheckBox, PrimaryInput } from "components/inputs";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { CampaignGoalsValidationSchema } from "validations";

import { CreateCampaignPayload, useUpdateCampaignMutation } from "store/campaigns";
import { resolveApiError } from "utilities/errorHandling";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import numeral from "numeral";

import { useWindowWidth } from "utilities/windowWidth";

export type CampaignGoalsProps = {
  next: () => void;
  previous: () => void;
  initialCampaign: CreateCampaignPayload;
  refetch: any;
};
export const CampaignGoals: FC<CampaignGoalsProps> = ({ next, previous, initialCampaign, refetch }) => {
  const [request, { isLoading }] = useUpdateCampaignMutation();
  const [update, { isLoading: loading }] = useUpdateCampaignMutation();
  const { initNotification } = usePageNotificationProvider();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { id } = queryString.parse(window.location.search) as {
    id: string;
  };
  const [isFlexible, setIsFlexible] = useState(initialCampaign.minimumAmount ? true : false);
  const [commission, setCommission] = useState(0);
  const [totalAfterCommission, setTotalAfterCommission] = useState(0);

  const initialValues = useMemo(() => {
    return {
      title: initialCampaign.title ?? "",
      description: initialCampaign.description ?? "",
      exportingCountry: initialCampaign.exportingCountry ?? "",
      fundingGoal: initialCampaign.fundingGoal ?? "",
      minimumAmount: initialCampaign.minimumAmount ?? "",
      interestRate: initialCampaign.interestRate ?? 0,
      reward: initialCampaign.reward ?? "",
      campaignEndDate: initialCampaign.campaignEndDate ?? "",
      termsAndConditions: "",
    };
  }, [initialCampaign]);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const { values, errors, touched, setValues, setFieldValue, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CampaignGoalsValidationSchema,
      onSubmit: () => {
        if (id) {
          if (isUpdated) {
            updateCampaign();
          } else {
            next();
          }
        } else {
          updateCampaign();
        }
      },
    });

  const isUpdated = useMemo(() => {
    return JSON.stringify(initialValues) !== JSON.stringify(values);
  }, [initialValues, values]);

  const windowWidth = useWindowWidth();
  const updateCampaign = () => {
    // if (values.minimumAmount === "") {
    //   delete values?.minimumAmount;
    // }
    update({
      data: {
        ...values,
        minimumAmount: values.minimumAmount === "" ? null : values.minimumAmount.replace(/,/g, ""),
        fundingGoal: values.fundingGoal.replace(/,/g, ""),
        reward: values.reward.replace(/,/g, ""),
      },
      id: id,
    })
      .unwrap()
      .then((res: any) => {
        // console.log(res);
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        refetch();
        initNotification({
          message: "Campaign information saved",
        });
        next();
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  // calculate total after commission of 1%
  useEffect(() => {
    const fundingGoal = Number(values.fundingGoal.replace(/,/g, ""));
    const total = fundingGoal - commission;
    setTotalAfterCommission(total);
  }, [values.fundingGoal, commission]);

  // calculate reward
  useEffect(() => {
    if (values.interestRate && values.fundingGoal) {
      let reward = (values.interestRate / 100) * Number(values.fundingGoal.replace(/,/g, ""));
      if (values.fundingGoal) {
        reward += Number(values.fundingGoal.replace(/,/g, ""));
      }
      setFieldValue("reward", String(reward));
    }
  }, [values.interestRate, values.fundingGoal, setFieldValue]);

  // calculate 1% commission
  useEffect(() => {
    const fundingGoal = Number(values.fundingGoal.replace(/,/g, ""));
    const commission = fundingGoal * 0.01;
    setCommission(commission);
  }, [values.fundingGoal]);

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <section className='bg-transparent py-2  '>
      <h6 className={`text-grey-900 fs-20 fw-semibold ${windowWidth >= 768 ? "mt-4 mb-5" : "my-3"}`}>
        Campaign Goals
      </h6>
      {windowWidth <= 768 && (
        <p className='text-grey-500 fw-400 fs-14 mb-5'>Set campaign goals and funding target</p>
      )}
      <div className='row'>
        <div className='col-md-10 '>
          <form onSubmit={handleSubmit} className=''>
            <div className='col-md-12 mb-2'>
              <PrimaryInput
                type='text'
                label='Funding Goal*'
                name='fundingGoal'
                leftComponent={
                  <select className='fw-400 text-grey-900'>
                    <option value='NGN'>NGN</option>
                  </select>
                }
                leftComponentProps={{
                  className: "primary-input-component",
                }}
                onChange={(e) => {
                  const formattedValue = numeral(e.target.value).format(`0,0`);
                  handleChange(e);
                  setFieldValue("fundingGoal", formattedValue);
                }}
                onBlur={handleBlur}
                value={values.fundingGoal.toLocaleString()}
                error={Boolean(errors?.fundingGoal && touched?.fundingGoal)}
                bottomText={errors?.fundingGoal || ""}
              />
            </div>

            {/* <div className="col-md-12 my-4">
              <PrimaryCheckBox
                inlineText="Flexible Funding Goal"
                onChange={() => setIsFlexible(!isFlexible)}
                isChecked={isFlexible ? true : false}
              />
            </div> */}

            {isFlexible && (
              <div className='col-md-12 mb-4'>
                <PrimaryInput
                  type='text'
                  label='Minimum Amount'
                  name='minimumAmount'
                  leftComponent={
                    <select className='fw-400 text-grey-900'>
                      <option value='NGN'>NGN</option>
                    </select>
                  }
                  leftComponentProps={{
                    className: "primary-input-component",
                  }}
                  onChange={(e) => {
                    const formattedValue = numeral(e.target.value).format(`0,0`);
                    handleChange(e);
                    setFieldValue("minimumAmount", formattedValue);
                  }}
                  onBlur={handleBlur}
                  value={values.minimumAmount}
                  error={Boolean(errors?.minimumAmount && touched?.minimumAmount)}
                  bottomText={errors?.minimumAmount || ""}
                />
              </div>
            )}

            <div className={`my-4 ${windowWidth <= 768 ? "d-flex flex-row gap-5" : "row"}`}>
              <div className={windowWidth >= 768 ? "col-md-6" : "w-40"}>
                <PrimaryInput
                  label='Interest Rate (%)*'
                  type='number'
                  name='interestRate'
                  min={8}
                  max={20}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.interestRate}
                  error={Boolean(errors?.interestRate && touched?.interestRate)}
                  bottomText={errors?.interestRate || ""}
                />
              </div>
              <div className={windowWidth >= 768 ? "col-md-6" : "w-40"}>
                <PrimaryInput
                  type='text'
                  label='Returns*'
                  name='reward'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={numeral(values.reward).format(`0,0.00`)}
                  error={Boolean(errors?.reward && touched?.reward)}
                  bottomText={errors?.reward || ""}
                />
              </div>
            </div>

            <div>
              <p className='text-grey-900 fw-500'>
                1% commission fee would be deducted from your funding goal
              </p>
            </div>
            <div className='d-flex align-items-center mb-2'>
              <div className='col-md-6 mb-2'>
                <label className='text-grey-500 fs-14 fw-400'>Commission fee (1% of Funding Goal)</label>
              </div>

              <div className='col-md-6 mb-2'>
                <PrimaryInput
                  type='text'
                  name='commission'
                  value={numeral(commission).format(`0,0`)}
                  disabled
                />
              </div>
            </div>

            <div className='d-flex align-items-center mb-2'>
              <div className='col-md-6 mb-2'>
                <label className='text-grey-500 fs-14 fw-400'>You will receive</label>
              </div>

              <div className='col-md-6 mb-2'>
                <div className='col-md-12 mb-2'>
                  <PrimaryInput
                    type='text'
                    name='totalAfterCommission'
                    value={numeral(totalAfterCommission).format(`0,0`)}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className='col-md-12 mb-4'>
              <PrimaryInput
                type='date'
                min={tomorrow.toISOString().split("T")[0]}
                label='Campaign End Date*'
                name='campaignEndDate'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.campaignEndDate.substring(0, 10)}
                error={Boolean(errors?.campaignEndDate && touched?.campaignEndDate)}
                bottomText={errors?.campaignEndDate || ""}
              />
            </div>

            {/* <div className="col-md-12 my-4">
              <PrimaryCheckBox
                name="termsAndConditions"
                inlineText="I agree with the terms and conditions"
                onChange={handleChange}
                onBlur={handleBlur}
                // checked={values.termsAndConditions === "true"}
                // isChecked={values.campaignEndDate ? true : false}
                error={Boolean(
                  errors?.termsAndConditions && touched?.termsAndConditions
                )}
                bottomText={
                  touched.termsAndConditions && errors?.termsAndConditions
                    ? errors.termsAndConditions
                    : ""
                }
              />
            </div> */}

            <div>
              <PrimaryButton
                onClick={previous}
                className='btn-lg secondary-btn mt-3 me-3 align-self-baseline'
              >
                {windowWidth >= 768 ? "Back" : "Cancel"}
              </PrimaryButton>

              <PrimaryButton
                type='submit'
                isLoading={isLoading || loading}
                className='btn-lg primary-btn mt-3 align-self-baseline'
                // onClick={next}
              >
                Next
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
