import { Link, useParams } from "react-router-dom";
// import { ReactComponent as BackIcon } from "assets/svg/chevron-left.svg";
import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import ProfileIcon from "assets/svg/profile-circle.svg";
import campaignImg from "assets/images/viewcampaign.png";
import { PrimaryButton } from "components/buttons";
import "../../../../assets/css/dashboard/back-campaign.scss";
import { DropdownSelect } from "pages/backer/dashboard/components/selectComponent.";
import { useAuth } from "store/auth";
import { useGetProfileInformationQuery } from "store/profile";
import { useWindowWidth } from "utilities/windowWidth";
import { Box, IconButton } from "@chakra-ui/react";
import { ProgressBar } from "components";
import { useEffect, useState } from "react";
import { useBackCampaignMutation, useGetCampaignQuery } from "store/campaigns";
// import { resolveApiError } from "utilities/errorHandling";
import { getPercentage } from "utilities";
import { ModalViewStateProps, useModalProvider } from "providers/modalProvider";

export const BackCampaign = () => {
  const [amountError, setAmountError] = useState("");
  const windowWidth = useWindowWidth();
  const { campaignId } = useParams();
  const [amount, setAmount] = useState<number | null>(null);
  const { user } = useAuth();
  const { data: profileInfo } = useGetProfileInformationQuery((() => {})(), {
    pollingInterval: 30000,
  });
  const profile = profileInfo?.data || user;
  const profileImage = user?.image ?? profileInfo?.data.image ?? ProfileIcon;
  const { initModal } = useModalProvider();
  const { data: campaign, isLoading } = useGetCampaignQuery(campaignId, {
    pollingInterval: 30000,
  });
  const [backCampaign, { isLoading: Loading }] = useBackCampaignMutation();
  const campaignData = campaign?.data;
  console.log("backcampaign", campaignData);
  console.log("campaignId", campaignData?._id);
  const percentage = campaignData?.interestRate ?? 0;

  useEffect(() => {
    if (user!.profileCompleted?.completionRate < 67) {
      initModal((prev: ModalViewStateProps | undefined) => ({
        ...prev,
        provideBackerKyc: true,
      }));
    }
  }, [user!.profileCompleted?.completionRate]);

  console.log("amount", amount);

  // const handleBackCampaign = async () => {
  //   if (amount !== null && campaignData?._id) {
  //     const payload = {
  //       campaignId: campaignData._id,
  //       amount: amount.toString(),
  //     };
  //     backCampaign(payload)
  //       .unwrap()
  //       .then((res: any) => {
  //         console.log(res);
  //         if (res.error) {
  //           initModal((prev) => ({
  //             ...prev,
  //             successCampaign: true,
  //             failedCampaign: false,
  //           }));
  //         } else if (res) {
  //           initModal((prev) => ({
  //             ...prev,
  //             successCampaign: false,
  //             failedCampaign: true,
  //           }));
  //         }
  //       })
  //       .catch((error: any) => {
  //         console.log(error);
  //         // initNotification({
  //         //   message: resolveApiError(error),
  //         //   scheme: "error",
  //         // });
  //         initModal((prev) => ({
  //           ...prev,
  //           successCampaign: false,
  //           failedCampaign: true,
  //         }));
  //       });
  //   }
  // };
  return (
    <section>
      {windowWidth > 768 && (
        <div className='d-flex justify-content-between align-items-center py-5 px-5'>
          <div className='d-flex gap-2 align-items-center '>
            <IconButton
              aria-label='Back'
              icon={<BackIcon />}
              onClick={() => window.history.back()}
              border={"none"}
              bg={"transparent"}
            />
            <Link to={`/backer/discover/${campaignId}`}>Return to Campaign</Link>
          </div>
          <Link to='/'>
            <Logo />
          </Link>
          <div className='d-flex gap-3 align-items-center'>
            <img
              src={profileImage || ProfileIcon}
              alt={"Avatar"}
              className='rounded rounded-circle'
              style={{
                width: 48,
                height: 48,
                objectFit: "cover",
                objectPosition: "top",
              }}
            />
            <h6 className='mb-0 text-grey-900'>
              {profile?.firstName} {profile?.lastName}
            </h6>
          </div>
        </div>
      )}
      {windowWidth <= 768 && (
        <h5 className={`fs-24 fw-600 d-flex flex-row align-items-center gap-3 px-4 my-4`}>
          {windowWidth <= 768 && (
            <IconButton
              aria-label='Back'
              icon={<BackIcon />}
              onClick={() => window.history.back()}
              border={"none"}
              bg={"transparent"}
            />
          )}{" "}
          Back This Campaign
        </h5>
      )}

      <div className='d-flex justify-content-center align-items-center flex-column pb-3'>
        <div className={windowWidth >= 768 ? "border w-50 p-5 rounded-4" : " px-4"}>
          {windowWidth > 768 ? (
            <div className='d-flex gap-2 ' style={{ height: "210px" }}>
              <div className='w-50'>
                <img
                  src={campaignData?.images[0] || campaignImg}
                  alt={"CampaignData img"}
                  width='251px'
                  height='211px'
                  className='rounded-4 h-100 object-fit-cover'
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = campaignImg;
                  }}
                />
              </div>
              <div className='w-50'>
                <div>
                  <p className='text-grey-600 fw-400 fs-14 m-0'>You are backing</p>
                  <h5 className='text-grey-900 fw-600 fs-24'>{campaignData?.title}</h5>
                  <p className='fs-14 m-0'>
                    by{" "}
                    <span className='text-primary-500'>
                      {campaignData?.exporter.firstName} {campaignData?.exporter.lastName}{" "}
                    </span>{" "}
                  </p>
                </div>

                <div className='mt-5'>
                  <h5 className='fw-600 fs-24 text-primary-600'>
                    ₦{Number(campaignData?.amountRaisedString).toLocaleString()}
                  </h5>
                  <p className='text-grey-600 fs-14'>
                    contributed of ₦{Number(campaignData?.fundingGoalString).toLocaleString()} goal
                  </p>
                  <div className='loading-container '>
                    <ProgressBar
                      value={getPercentage(
                        Number(campaignData?.fundingGoalString),
                        Number(campaignData?.amountRaisedString)
                      )}
                      width='100%'
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='d-flex flex-column gap-3'>
              <div>
                <p className='text-grey-600 fw-400 fs-14 m-0'>You are backing</p>
                <h5 className='text-grey-900 fw-600 fs-24'>{campaignData?.title}</h5>
                <p className='fs-14 m-0'>
                  by{" "}
                  <span className='text-primary-500'>
                    {" "}
                    {campaignData?.exporter.firstName} {campaignData?.exporter.lastName}{" "}
                  </span>{" "}
                </p>
              </div>

              <div>
                <img
                  src={campaignData?.images[0] || campaignImg}
                  alt=''
                  width='100%'
                  height='211px'
                  className='rounded-4'
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = campaignImg;
                  }}
                />
              </div>

              <div className='mt-2'>
                <h5 className='fw-600 fs-24 text-primary-600'>
                  ₦{Number(campaignData?.amountRaisedString).toLocaleString()}
                </h5>
                <p className='text-grey-600 fs-14'>
                  contributed of ₦{Number(campaignData?.fundingGoalString).toLocaleString()} goal
                </p>

                <div className='loading-container '>
                  <ProgressBar
                    value={getPercentage(
                      Number(campaignData?.fundingGoalString),
                      Number(campaignData?.amountRaisedString)
                    )}
                    width='100%'
                  />
                </div>
              </div>
            </div>
          )}

          <p className='fs-14 fw-600 text-grey-900 pt-5'>Enter your contribution</p>
          <div className={`rounded-4 ${windowWidth >= 768 ? "border p-3" : "py-3"}`}>
            <Box
              className={`border ${
                amountError ? "border-error-300" : "border-primary-100"
              } d-flex align-items-center justify-content-between w-100 ${
                windowWidth >= 768 ? "p-3" : "p-4"
              }`}
              rounded={windowWidth >= 768 ? "8px" : "16px"}
            >
              <div className={windowWidth >= 768 ? "w-80" : "w-70"}>
                <p className='fs-14 text-grey-500 fw-400'> Your Contribution</p>
                <h5
                  className={`${
                    windowWidth >= 768 ? "fs-33" : "fs-24"
                  } fw-600 text-grey-900 d-flex align-items-center`}
                >
                  <span>₦</span>
                  <input
                    type='text'
                    value={amount !== null ? amount.toLocaleString() : ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(/,/g, "");
                      if (!isNaN(Number(value))) {
                        if (
                          Number(value) >
                          Number(campaignData?.fundingGoalString) - campaignData?.amountRaisedString
                        ) {
                          setAmountError("Amount cannot be more than the remaining funding goal");
                        } else {
                          setAmountError("");
                        }
                        setAmount(Number(value));
                      }
                    }}
                    className='fs-33 fw-600 text-grey-900 border-0 bg-transparent w-100 shadow-none ml-2'
                  />
                </h5>
              </div>
              <div className={windowWidth >= 768 ? "w-20" : "w-30"}>
                <DropdownSelect options={["NGN", "USD", "EUR"]} disabled />
              </div>
            </Box>

            {amountError && <p className='text-danger fs-14'>{amountError}</p>}

            <Box
              className={`border border-primary-100 d-flex align-items-center justify-content-between w-100 mt-3 ${
                windowWidth >= 768 ? "p-3" : "p-4"
              }`}
              rounded={windowWidth >= 768 ? "8px" : "16px"}
            >
              <div className={windowWidth >= 768 ? "w-80" : "w-70"}>
                <p className='fs-14 text-grey-500 fw-400'>Potential Earnings</p>
                <h5
                  className={`${
                    windowWidth >= 768 ? "fs-33" : "fs-24"
                  } fw-600 text-grey-900 d-flex align-items-center`}
                >
                  <span>₦</span>
                  <p className='fs-33 fw-600 text-grey-900 border-0 bg-transparent w-100 shadow-none ms-3 mb-0'>
                    {amount
                      ? (amount + (amount * percentage) / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </p>
                </h5>
                {/* <p className="fs-14 text-grey-500 fw-400 mb-0">
                  9,919,950.00 NGN
                </p> */}
              </div>
              <div className={windowWidth >= 768 ? "w-20" : "w-30"}>
                <DropdownSelect options={["NGN", "USD", "EUR"]} disabled />
              </div>
            </Box>
            <p className='text-warning-900 fw-400 fs-14 pt-3'>
              Interest Rate = {campaignData?.interestRate}%
            </p>
            {/* <p className='text-grey-500 fw-400 fs-14'>1 USD = 806 NGN </p> */}
            <p className='text-grey-500 fw-400 fs-14'>
              Any additional information e.g interest rate breakdown{" "}
            </p>

            <p className='fw-500 fs-14'>1% Commission fee would be deducted from your potential earnings </p>

            <p className='fs-14 text-grey-500 fw-400'>After 1% Deduction</p>
            <h5
              className={`${
                windowWidth >= 768 ? "fs-33" : "fs-24"
              } fw-600 text-grey-900 d-flex align-items-center`}
            >
              <span>₦</span>
              <p className='fs-33 fw-600 text-grey-900 border-0 bg-transparent w-100 shadow-none ms-3 mb-0'>
                {amount
                  ? ((amount + (amount * percentage) / 100) * 0.99).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </p>
            </h5>
          </div>

          {/* <p
            className={`text-grey-900 ${
              windowWidth >= 768 ? "fs-14" : "fs-18"
            } fw-600 pt-5`}
          >
            How would you like to pay?
          </p> */}

          {/* <div
            className={`border-bottom pb-4 ${
              windowWidth <= 768 && "pt-2"
            } d-flex align-items-center justify-content-between`}
          >
            <div className="d-flex gap-3 align-items-center">
              <div className="bg-primary-50 p-2 rounded-circle">
                <Wallet />
              </div>
              <p className="m-0">Pay with Wallet - Bal:₦0</p>
            </div>
            <div>
              <label>
                <input type="radio" name="wallet" />
                <span></span>
              </label>
            </div>
          </div> */}

          {/* <div className="border-bottom py-4 d-flex align-items-center justify-content-between">
            <div className="d-flex gap-3 align-items-center">
              <div className="bg-primary-50 p-2 rounded-circle">
                <Wallet />
              </div>
              <p className="m-0">Pay with Paystack</p>
            </div>
            <div>
              <label>
                <input type="radio" name="wallet" />
                <span></span>
              </label>
            </div>
          </div> */}

          <p className={`m-0 fs-14 text-grey-600 ${windowWidth >= 768 ? "py-5" : "py-3"}`}>
            You are backing this campaign with{" "}
            <span className='text-primary-600 fs-16 fw-600'>₦ {amount ? amount.toLocaleString() : 0}</span>{" "}
          </p>

          <div>
            <PrimaryButton
              className={`text-white fs-16 fw-600 primary-btn w-100 p-4 ${
                windowWidth >= 768 ? "my-3" : "my-3 mt-5"
              }`}
              type='submit'
              // onClick={handleBackCampaign}
              onClick={() => {
                initModal((prev) => ({
                  ...prev,
                  paymentDetails: {
                    payload: { amount: amount, campaignId: campaignData?._id },
                  },
                }));
              }}
              isLoading={Loading}
              disabled={Boolean(amountError || !amount)}
            >
              Back This Campaign
            </PrimaryButton>
          </div>

          <p className='fs-14 text-grey-600 fw-400 pt-3'>
            By continuing, you agree with Krowdfi's{" "}
            <span className='text-primary-600'>
              <Link to='/privacy-policy' target='_blank'>
                Privacy Policy
              </Link>
            </span>{" "}
            and{" "}
            <span className='text-primary-600'>
              <Link to='/terms-of-use' target='_blank'>
              Terms
            </Link>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};
