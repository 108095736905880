import { PrimaryButton } from "components/buttons";
import { ReactComponent as SuccessIcon } from "assets/svg/verified-icon.svg";
import { useEffect, useState } from "react";
import { useTransactionStatusMutation } from "store/backCampaign";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities/errorHandling";
import { useWindowWidth } from "utilities/windowWidth";
import { useGeneralAppProvider } from "providers/generalAppProvider";
import { Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";
interface TransactionData {
  paymentType: string;
  bank: string;
  email: string;
  transactionId: string;
  amount: {
    $numberDecimal: string;
  };
  referenceId: string;
  transactionStatus: string;
}

export const TransactionStatus = () => {
  const windowWidth = useWindowWidth();

  const { initNotification } = usePageNotificationProvider();
  const [loading, setLoading] = useState(true);
  const [transactionStatus, { isLoading }] = useTransactionStatusMutation();
  const [transactionData, setTransactionData] =
    useState<TransactionData | null>(null);
  // const { setPrimaryLoading } = useGeneralAppProvider();

  console.log("transactiondata", transactionData);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referenceId = urlParams.get("reference");
    const payload = {
      reference: referenceId,
    };
    console.log("referenceid", payload);
    transactionStatus(payload)
      .unwrap()
      .then((res: any) => {
        console.log(res);
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        } else {
          console.log(res.data);
          setTransactionData(res.data);
          initNotification({
            message: "Successfull!",
          });
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({
          message: resolveApiError(error),
          scheme: "error",
        });
      });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size="md" color="green.500" />
      </div>
    );
  }

  return (
    <section className="w-100 bg-white">
      <div
        className=" mx-auto d-flex flex-column align-items-center rounded-3 p-4 shadow-lg  mt-5"
        style={{
          width: windowWidth <= 768 ? "90%" : "50%",
        }}
      >
        <SuccessIcon className="mb-3" />
        <p className="text-primary-500 fs-24 fw-700 mt-4 text-capitalize">
          Payment {transactionData?.transactionStatus}
        </p>
        <div className=" d-flex justify-content-between w-100 mt-3">
          <div
            className=" d-flex justify-content-center "
            style={{ width: "50%" }}
          >
            <div>
              <p className="text-black fs-16 fw-600">Payment Type:</p>
              <p className="text-black fs-16 fw-600">Amount:</p>
              {/* <p className="text-black fs-16 fw-600">Bank:</p> */}
              <p className="text-black fs-16 fw-600">Email:</p>
              <p className="text-black fs-16 fw-600">Reference ID:</p>
              <p className="text-black fs-16 fw-600">Transaction ID:</p>
            </div>
          </div>
          <div
            className=" d-flex justify-content-center "
            style={{ width: "50%" }}
          >
            <div className="text-black fs-16 fw-500">
              <p>Transfer</p>
              <p>₦{transactionData?.amount.$numberDecimal}</p>
              {/* <p>Krowdfi</p> */}
              <p>{transactionData?.email}</p>
              <p>{transactionData?.referenceId}</p>
              <p>{transactionData?.transactionId}</p>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <Link to="/backer/discover">
            <PrimaryButton className="btn-lg primary-btn">
              Back to Campaign
            </PrimaryButton>
          </Link>
        </div>
      </div>
    </section>
  );
};
