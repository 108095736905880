import { setCredential, useAuth } from "store/auth";
import { usePageNotificationProvider } from "providers";
import { resolveApiError } from "utilities";
import { useDispatch } from "react-redux";
import { FileHandlerBox } from "components";
import { FC } from "react";
import React from "react";
import { useUploadFileMutation } from "store/profile";

export interface UploadAvatarButtonProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  className?: string;
}
//LabelHTMLAttributes<HTMLLabelElement>
export const UploadAvatarButton: FC<UploadAvatarButtonProps> = ({
  children,
  className,
}) => {
  const { user, token: access_token } = useAuth();
  const dispatch = useDispatch();
  const { initNotification } = usePageNotificationProvider();
  const [request, { isLoading }] = useUploadFileMutation();

  const handleUpload = (file: File) => {
    const form = new FormData();
    form.append("file", file);

    request({ file: form, fileClass: "image" })
      .unwrap()
      .then((res) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "Your profile avatar has been updated successfully",
        });
        dispatch(
          setCredential({
            user: {
              ...user,
              profileCompleted: res?.data?.profileCompleted,
              image: res?.data?.image,
            },
            access_token,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        initNotification({
          message: resolveApiError(error),
          scheme: "error",
        });
      });
  };

  return (
    <FileHandlerBox
      customID="avatar"
      inputProps={{ accept: "image/*" }}
      autoUploadFiles={false}
      isManuallyUploading={isLoading}
      className={className}
      onFilesSelected={(files) => {
        handleUpload(files[0]);
      }}
    >
      {children}
    </FileHandlerBox>
  );
};
