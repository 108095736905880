import React, { FC, useState } from "react";
import { ReactComponent as PendingIcon } from "assets/svg/pending-icon.svg";
import { ReactComponent as DocumentIcon } from "assets/svg/document.svg";
import { PrimaryButton } from "components/buttons";
import { CampaignData } from "store/campaigns";
import { useWindowWidth } from "utilities/windowWidth";
import FilePreviewDialog from "components/custom/filePreview";

interface CampaignProps {
  campaignData: CampaignData | undefined;
}

export const AboutCampaign: FC<CampaignProps> = ({ campaignData }) => {
  const [dialogState, setDialogState] = useState({
    letterOfCredit: false,
    purchaseOrder: false,
    proformaInvoice: false,
    formQ: false,
  });

  const windowWidth = useWindowWidth();
  return (
    <div>
      {!campaignData?.hasBeenReviewed ? (
        <div
          className={`d-flex justify-content-center align-items-center ${
            windowWidth >= 768 ? "my-5" : "my-3"
          } py-5`}
        >
          <div className='d-flex flex-column align-items-center gap-2 justify-content-center'>
            <PendingIcon />
            <p className='text-grey-900 fw-600'>Your campaign is under review</p>
            <p className={`text-grey-600 ${windowWidth >= 768 ? "w-50" : "w-80"} text-center fw-400`}>
              We are currently reviewing your campaign. Please be patient, as this process typically takes up
              to 48 hours.
            </p>
            <p className={`text-grey-600 ${windowWidth >= 768 ? "w-50" : "w-80"} text-center fw-400`}>
              We will notify you of your campaign's status after the review.
            </p>
          </div>
        </div>
      ) : (
        <div className='pb-5 d-flex flex-row w-100'>
          <div className='w-75'>
            <div id='description' className='mb-5'>
              <p className='text-grey-900 fs-14 fw-600'>Description</p>
              <p className='fw-400 fs-16 lh-base'>{campaignData?.description ?? "No description"}</p>
            </div>
            <div id='country'>
              <p className='text-grey-900 fw-600'>Exporting Country</p>
              <div className='d-flex gap-3'>
                <h5 className='text-grey-900 fs-24 fw-400'>{campaignData?.exportingCountry}</h5>
              </div>
            </div>

            <div className='mt-5' id='documents'>
              <p className='text-grey-900 fw-600'>Documents</p>

              <div className=' d-flex flex-wrap gap-3'>
                {campaignData && campaignData.formQ && (
                  <PrimaryButton className='btn secondary-btn border-primary-500 d-flex gap-2 p-4'>
                    <FilePreviewDialog
                      fileUrl={campaignData.formQ}
                      isOpen={dialogState.formQ}
                      title={"Form Q"}
                      onClose={() => {
                        setDialogState({ ...dialogState, formQ: false });
                      }}
                    />
                    <DocumentIcon />
                    Form Q
                  </PrimaryButton>
                )}

                {campaignData && campaignData.letterOfCredit && (
                  <PrimaryButton
                    className='btn secondary-btn border-primary-500 d-flex gap-2 p-4'
                    onClick={() => {
                      setDialogState({ ...dialogState, letterOfCredit: true });
                    }}
                  >
                    <FilePreviewDialog
                      fileUrl={campaignData.letterOfCredit}
                      isOpen={dialogState.letterOfCredit}
                      title={"Letter of Credit"}
                      onClose={() => {
                        setDialogState({
                          ...dialogState,
                          letterOfCredit: false,
                        });
                      }}
                    />
                    <DocumentIcon />
                    Letter of Credit
                  </PrimaryButton>
                )}

                {campaignData && campaignData.purchaseOrder && (
                  <PrimaryButton
                    className='btn secondary-btn border-primary-500 d-flex gap-2 p-4'
                    onClick={() => {
                      setDialogState({ ...dialogState, purchaseOrder: true });
                    }}
                  >
                    <FilePreviewDialog
                      fileUrl={campaignData.purchaseOrder}
                      isOpen={dialogState.purchaseOrder}
                      title={"Purchase Order"}
                      onClose={() => {
                        setDialogState({
                          ...dialogState,
                          purchaseOrder: false,
                        });
                      }}
                    />
                    <DocumentIcon />
                    Purchase Order
                  </PrimaryButton>
                )}

                {campaignData && campaignData.proformaInvoice && (
                  <PrimaryButton
                    className='btn secondary-btn border-primary-500 d-flex gap-2 p-4'
                    onClick={() => {
                      setDialogState({ ...dialogState, proformaInvoice: true });
                    }}
                  >
                    <FilePreviewDialog
                      fileUrl={campaignData.proformaInvoice}
                      isOpen={dialogState.proformaInvoice}
                      title={"Proforma Invoice"}
                      onClose={() => {
                        setDialogState({
                          ...dialogState,
                          proformaInvoice: false,
                        });
                      }}
                    />
                    <DocumentIcon />
                    Proforma Invoice
                  </PrimaryButton>
                )}
              </div>
            </div>
          </div>
          <div className='w-25 bg-white rounded-3 px-3 py-4 shadow-sm' style={{ height: "fit-content" }}>
            <a
              href='#description'
              className='text-primary-600 pb-3 ps-3 fw-400 border-bottom border-primary-600 d-block text-decoration-none '
            >
              Description
            </a>
            <a
              href='#country'
              className='py-3 text-grey-600 ps-3 border-bottom d-block text-decoration-none '
            >
              Exporting Country
            </a>
            <a
              href='#documents'
              className='py-3 text-grey-600 ps-3  border-bottom d-block text-decoration-none '
            >
              Documents
            </a>
            {/* <a
              href='#rewards'
              className='py-3 text-grey-600 ps-3 border-bottom d-block text-decoration-none '
            >
              Rewards
            </a> */}
            <a
              href='#risk-report'
              className='py-3 text-grey-600 ps-3 border-bottom d-block text-decoration-none'
            >
              Risk Report
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
