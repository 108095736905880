import { ChakraAlertDialog, PrimarySelect } from "components";
import React, { FC } from "react";
import { useFormik } from "formik";
import { useModalProvider } from "providers/modalProvider";
import { resolveApiError } from "utilities";

import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useChangeProfileMutation } from "store/profile";
import { ChangeProfileInfoValidationSchema } from "validations/profile";
interface ContactAddressChangeModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const ContactAddressChangeModal: FC<ContactAddressChangeModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  const [request, { isLoading }] = useChangeProfileMutation();
  const { initNotification } = usePageNotificationProvider();
  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: { reason: " " },
    validationSchema: ChangeProfileInfoValidationSchema,
    onSubmit: () => changeAddress(),
  });

  const changeAddress = () => {
    const payload = {
      request: "address",
      reason: values.reason,
    };
    console.log("before", payload);
    request(payload)
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });

          return;
        }
        console.log("after", payload);
        initNotification({
          message: "Success",
        });

        initModal((prev) => ({
          ...prev,
          editContactDetails: false,
          successModal: true,
        }));
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: error.message, scheme: "error" });
      });
  };

  return (
    <>
      <ChakraAlertDialog
        // isOpen={isOpen}
        useFooter={true}
        useCancelButton={true}
        useProceedButton={true}
        useHeader={true}
        useHeaderCancel={false}
        cancelButtonDefaultChild={"Cancel"}
        proceedButtonDefaultChild={"Submit"}
        title={"Request Contact Address Change"}
        size={"md"}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        onClose={() => {
          initModal((prev) => ({ ...prev, editContactDetails: false }));
        }}
        cancelButtonProps={{
          className: "btn-lg ms-0 px-5 w-50 bg-white border-primary-50",
          onClick: () => {
            initModal((prev) => ({ ...prev, editContactDetails: false }));
          },
        }}
        proceedButtonProps={{
          className: "btn-lg primary-btn ms-0 px-5 w-50",
          onClick: changeAddress,
        }}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        modalFooterProps={{
          className:
            "d-flex flex-row justify-content-between gap-5  w-100 px-0 mt-2",
        }}
        closeOnOverlayClick={true}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center gap-4">
            <p className="fs-16 lh-5 text-grey-600 fw-400 m-0 w-100 text-start mb-1">
              To continue, please select a reason below.
            </p>
            <form className="col-md-12 mb-2 w-100">
              <div className=" mb-4">
                <PrimarySelect
                  name="reason"
                  label="Reason for Change "
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.reason}
                  error={Boolean(errors?.reason && touched?.reason)}
                  bottomText={errors?.reason || ""}
                >
                  <option hidden>Select </option>
                  <option value="relocation">Relocation</option>
                  <option value="error during registration">
                    Error during registration
                  </option>
                </PrimarySelect>
              </div>
            </form>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
