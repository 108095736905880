import { IconButton } from "@chakra-ui/react";
import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { PrimaryButton } from "components/buttons";
import { VerificationCodeInput } from "components/custom";
import { useState } from "react";
import { useNavigate } from "react-router";

export const ExporterVerifyCodeMobile = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  return (
    <div className="d-flex flex-column align-items-center">
      <h5
        className="fw-semibold fs-20 text-grey-900 py-4 d-flex align-items-center gap-5 justify-content-center w-100"
        style={{ position: "relative" }}
      >
        <div style={{ position: "absolute", left: 0 }}>
          <IconButton
            aria-label="Back"
            icon={<BackIcon />}
            onClick={() => window.history.back()}
            border={"none"}
            bg={"transparent"}
          />
        </div>
        Two-Factor Authentication
      </h5>
      <p className="text-grey-500 fs-12 fw-400 text-center mb-5 w-80 mx-auto">
        A verification code has been sent to 081* *** 4377. Enter the code to
        continue
      </p>
      <div className="w-100 d-flex justify-content-center">
        <VerificationCodeInput
          rounded={true}
          width="40px"
          codeLength={6}
          bg={"transparent"}
          value={value}
          setValue={setValue}
        />
      </div>
      <p className="text-grey-500 fs-14 text-center w-80 mt-4">
        Didn’t get a code?{" "}
        <span style={{ textDecoration: "underline" }}>Click to resend.</span>
      </p>
      <PrimaryButton
        className="btn-lg primary-btn mt-4 mx-auto"
        onClick={() => {
          navigate("/exporter/settings/two-fac-auth/success", {
            replace: true,
          });
        }}
      >
        Verify
      </PrimaryButton>
    </div>
  );
};
