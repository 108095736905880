import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { ReactComponent as WarningIcon } from "assets/svg/password-warning.svg";
import { ReactComponent as ErrorIcon } from "assets/svg/password-error-icon.svg";
import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { AlertDefaultBox, AuthHeaderText, CustomPasswordInput } from "components/custom";
import { PrimaryButton } from "components/buttons";
import { ChangePasswordValidationSchema } from "validations/auth";
import { breadcrumbConfig } from "navigations";
import { useWindowWidth } from "utilities/windowWidth";
import { Heading, IconButton } from "@chakra-ui/react";
import { useChangePasswordMutation } from "store/profile";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities/errorHandling";

export const ChangePassword = () => {
  const windowWidth = useWindowWidth();
  const [request, { isLoading }] = useChangePasswordMutation();
  const { initNotification } = usePageNotificationProvider();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
    initialValues: { password: "", newPassword: "", confirmPassword: "" },
    validationSchema: ChangePasswordValidationSchema(),
    onSubmit: (values) => {
      initRequest(values);
      resetForm();
    },
  });
  const initRequest = (values: any) => {
    request({
      oldPassword: values.password,
      newPassword: values.newPassword,
    })
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "Password updated successfully.",
        });
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  const { exporterChangePassword } = breadcrumbConfig;

  const isBtnDisabled = Boolean(errors.password || errors.confirmPassword);

  return (
    <div className='d-flex flex-column max-w-500 align-items-center gap-5'>
      <form className='' onSubmit={handleSubmit}>
        <div className={`w-100 ${windowWidth >= 768 ? "px-3" : ""}`}>
          {windowWidth >= 768 && (
            <div className='breadcrumb mb-5' aria-label='breadcrumb'>
              {exporterChangePassword.map((item, index) => (
                <>
                  <Link
                    className={`breadcrumb-item text-grey-600 ${
                      exporterChangePassword.length - 1 === index ? "active" : ""
                    }`}
                    to={item.to ?? "#"}
                  >
                    {item.name}
                  </Link>
                </>
              ))}
            </div>
          )}
          <div className={`d-flex flex-row align-items-center gap-3 mb-3 ${windowWidth <= 768 && "mb-4"} `}>
            <IconButton
              aria-label='Back'
              icon={<BackIcon />}
              onClick={() => window.history.back()}
              border={"none"}
              bg={"transparent"}
            />
            <Heading
              as='h1'
              className='fw-600 text-grey-900 mb-0'
              size='lg'
              fontFamily='Inter'
              color='MenuText'
            >
              Change Password
            </Heading>
          </div>
          <AlertDefaultBox
            scheme='warning'
            icon={<WarningIcon />}
            body='Your password must consist of a minimum of 8 characters
                  including an upper case letter, a lower case letter, a special
                  character, and a number'
          />

          <div className='mb-4 mt-4'>
            <CustomPasswordInput
              label='Enter Current Password'
              name='password'
              placeholder='********'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              bg='white'
            />
          </div>
          <div className='mb-4 mt-4'>
            <CustomPasswordInput
              label='Enter New Password'
              name='newPassword'
              placeholder='********'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
              bg='white'
            />
          </div>
          <div className='mb-4'>
            <CustomPasswordInput
              label='Confirm New Password'
              name='confirmPassword'
              placeholder='********'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors?.confirmPassword)}
              bg='white'
            />
          </div>

          {errors?.confirmPassword && (
            <AlertDefaultBox
              scheme='error'
              icon={<ErrorIcon />}
              body='New password and confirmation password must match'
            />
          )}

          <PrimaryButton
            disabled={isBtnDisabled}
            type='submit'
            onClick={() => {
              // console.log("clicked");
              handleSubmit();
            }}
            className='btn-lg primary-btn mt-4'
            isDisabled={isLoading}
          >
            Change Password
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};
