import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utilities/axiosQuery/axiosBaseQuery";
import { baseUrl } from "../../utilities/requests";
import { IResponse } from "../auth/interface";
import { Generate2faSecretResponse, Verify2faTokenPayload } from "./interface";
import { convertObjectToURLParams } from "utilities/general";

export const twofaApi = createApi({
  refetchOnReconnect: true,
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  reducerPath: "twofaApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${baseUrl}/twofa-auth`,
  }),
  endpoints: (builder) => ({
    generate2faSecret: builder.query<Generate2faSecretResponse, string | void>({
      query: (payload) => ({
        url: "/generateSecret",
        method: "GET",
        body: payload,
      }),
    }),
    generate2faSecretOnClick: builder.mutation<Generate2faSecretResponse, string | void>({
      query: (payload) => ({
        url: "/generateSecret",
        method: "GET",
        body: payload,
      }),
    }),
    verify2faToken: builder.query<boolean, Verify2faTokenPayload>({
      query: (payload) => ({
        url: `/verifyToken?${convertObjectToURLParams(payload)}`,
        method: "GET",
        body: payload,
      }),
    }),
    verify2faTokenOnClick: builder.mutation<boolean, Verify2faTokenPayload>({
      query: (payload) => ({
        url: `/verifyToken?${convertObjectToURLParams(payload)}`,
        method: "GET",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGenerate2faSecretQuery,
  useGenerate2faSecretOnClickMutation,
  useVerify2faTokenQuery,
  useVerify2faTokenOnClickMutation,
} = twofaApi;
