import { ReactComponent as WalletKeyIcon } from "assets/svg/wallet-key-icon.svg";
import { PrimaryButton } from "components/buttons";
import { useModalProvider } from "providers/modalProvider";
import { Wallet } from "./components";
import { useWindowWidth } from "utilities/windowWidth";

export const BackerWalletPage = () => {
  const { initModal } = useModalProvider();
  const windowWidth = useWindowWidth();
  const data = true;

  return (
    <div className='mt-3 px-3'>
      <h3 className='fw-600 mb-5'>Wallet</h3>
      {!data && (
        <div className='d-flex flex-column justify-content-center align-items-center my-5 py-5'>
          <WalletKeyIcon />
          <div
            className='mt-4 fs-14 d-flex flex-column justify-content-center align-items-center'
            style={{ maxWidth: windowWidth < 768 ? "70%" : "195px" }}
          >
            <p className='text-center fw-600 mb-0'>Activate your Wallet</p>
            <p className='mt-2 text-center text-grey-600 fw-400'>
              First things first! Create a PIN to secure your wallet
            </p>
            <PrimaryButton
              className='btn-lg primary-btn mt-3'
              onClick={() => {
                initModal((prev) => ({
                  ...prev,
                  createPin: true,
                }));
              }}
            >
              Create PIN
            </PrimaryButton>
          </div>
        </div>
      )}
      {data && <Wallet />}
    </div>
  );
};
