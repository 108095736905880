import React, { FC } from "react";
import {
  PrimaryInput,
  PrimarySelect,
  PrimaryButton,
  AlertDefaultBox,
  SimpleFileUploadComponent,
} from "components";
import { useFormik } from "formik";
import { VerifyIdentityValidationSchema } from "validations";
import { ReactComponent as ShieldIcon } from "assets/svg/shield.svg";
import { ReactComponent as SecureIcon } from "assets/svg/unallowed.svg";
import { UpdateProfilePayload, useGetProfileInformationQuery, useUpdateProfileMutation } from "store/profile";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useDispatch } from "react-redux";
import { useAuth, setCredential, User } from "store/auth";
import { resolveApiError } from "utilities";
import { useModalProvider } from "providers";
import { useWindowWidth } from "utilities";

export type VerifyIdentityFormProps = {
  next: () => void;
  previous: () => void;
  initialProfile: User | null | any;
};

export const VerifyIdentityForm: FC<VerifyIdentityFormProps> = ({ next, previous, initialProfile }) => {
  const windowWidth = useWindowWidth();
  const { user, token: access_token } = useAuth();
  const dispatch = useDispatch();
  const [request, { isLoading }] = useUpdateProfileMutation();
  const { data: profileInfo } = useGetProfileInformationQuery((() => {})(), {
    pollingInterval: 30000,
  });
  const { initNotification } = usePageNotificationProvider();
  // const { setFullScreenLoading } = useGeneralAppProvider();
  const { initModal } = useModalProvider();
  const identityInfo = user?.userIdentity ?? initialProfile?.userIdentity;
  const initialValues = {
    identificationMode: (identityInfo?.identificationMode as string) ?? "",
    // identificationUrl: (identityInfo?.identificationUrl as string) ?? "",
    // utilityBillUrl: (identityInfo?.utilityBillUrl as string) ?? "",
    bvn: (identityInfo?.bvn as string) ?? "",
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: VerifyIdentityValidationSchema,
    onSubmit: () => initRequest(),
  });

  const initRequest = () => {
    // if (!identityInfo?.identificationUrl || identityInfo?.utilityBillUrl) {
    //   initNotification({
    //     message: "Please upload all documents!",
    //     scheme: "error",
    //   });
    //   return;
    // }
    request({
      userIdentity: {
        ...values,
        // identificationUrl: initialValues.identificationUrl,
        // utilityBillUrl: initialValues.utilityBillUrl,
      },
    })
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "Saved",
        });
        dispatch(
          setCredential({
            user: res.data,
            access_token,
          })
        );
        if (user?.role === "exporter") {
          setTimeout(() => {
            next();
          }, 500);
        } else {
          initModal({ profileCompleted: true });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.data.statusCode === 403) initModal({ sessionExpired: true });
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  // const handleNext = () => {
  //   // refetch();
  //   if (!identityInfo?.identificationUrl || identityInfo?.utilityBillUrl) {
  //     initNotification({
  //       message: "Please upload all documents!",
  //       scheme: "error",
  //     });
  //     return;
  //   }
  //   next();
  // };

  return (
    <section className={`container-fluid ${windowWidth >= 768 ? "py-5" : "pt-3 pb-5"} bg-transparent`}>
      <div className='row'>
        <div className='col-md-12'>
          <form onSubmit={handleSubmit}>
            {windowWidth <= 768 && (
              <>
                <h6 className='text-grey-900 fs-20 fw-semibold'>Verify Identity</h6>
                <p className='mb-4 text-grey-400'>Verify your identity to proceed</p>
              </>
            )}
            <div className={`row mb-4 ${windowWidth <= 768 ? "w-80" : ""}`}>
              <div className='col-12 mb-4'>
                <PrimarySelect
                  name='identificationMode'
                  label='Mode of Identification*'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.identificationMode}
                  error={Boolean(errors?.identificationMode && touched?.identificationMode)}
                  bottomText={errors?.identificationMode ?? ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                >
                  <option hidden>Select ID</option>
                  <option value='nationalId'>National ID Card</option>
                  <option value='driversLicense'>Drivers License</option>
                </PrimarySelect>
              </div>
              <div className='col-12 mb-4'>
                <SimpleFileUploadComponent
                  fileClass='idcard'
                  fileName={identityInfo?.identificationUrl ?? ""}
                  label='Upload ID Card*'
                  bottomText='File Format must be in PNG, JPEG, JPG or PDF (max. 15mb)'
                  category='profile'
                  allowedFIleType='application/pdf, .pdf, image/jpg, image/jpeg, image/png'
                />
              </div>
              <div className='col-12 mb-4'>
                <SimpleFileUploadComponent
                  fileClass='utilityBill'
                  fileName={identityInfo?.utilityBillUrl ?? ""}
                  label='Upload Utility Bill*'
                  bottomText='File Format must be in PNG, JPEG, JPG or PDF (max. 15mb)'
                  category='profile'
                  allowedFIleType='application/pdf, .pdf, image/jpg, image/jpeg, image/png'
                />
              </div>
              <div className='col-12 mb-4'>
                <PrimaryInput
                  type='text'
                  label='BVN*'
                  name='bvn'
                  placeholder='Enter Your BVN'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bvn}
                  error={Boolean(errors?.bvn && touched?.bvn)}
                  bottomText={errors?.bvn ?? ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                />
              </div>
            </div>

            <AlertDefaultBox
              scheme='success'
              type={windowWidth >= 768 ? "outline" : "solid"}
              //   icon={<LockIcon />}
              title='Why do we need your BVN?'
              className='mb-4'
              titleProps={{ className: "text-grey-900 fs-16 mb-4 pt-1" }}
              body={
                <>
                  <div className='d-flex gap-3 align-items-center mb-4'>
                    <ShieldIcon className='w-13-px h-auto flex-shrink-0' />
                    <p className='mb-0 text-grey-600 fw-400'>
                      To ensure security by making sure that your account belongs to you
                    </p>
                  </div>
                  <div className='d-flex gap-3 align-items-center mb-1'>
                    <SecureIcon className='w-15-px h-auto flex-shrink-0' />
                    <p className='mb-0 text-grey-600 fw-400'>
                      Your Bank information will never be made available to Krowdfi
                    </p>
                  </div>
                </>
              }
            />

            <div>
              <PrimaryButton
                type='button'
                onClick={previous}
                className={`btn-lg secondary-btn mt-3 ${
                  windowWidth >= 768 ? "me-3" : "me-4"
                } align-self-baseline`}
              >
                {windowWidth >= 768 ? "Back" : "Cancel"}
              </PrimaryButton>
              <PrimaryButton
                type='submit'
                className='btn-lg primary-btn mt-3 align-self-baseline'
                isLoading={isLoading}
              >
                {user?.role === "exporter" ? "Next" : "Submit"}
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
