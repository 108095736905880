import { HeaderComponent } from "pages/home/components";
// import { CreatorSideBarComponent } from "../components";
import { useGeneralAppProvider, useModalProvider } from "providers";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "store/auth";
import { AuthVerifyContainer } from "pages/auth/components";
import { SideBarComponent } from "./sideBar";
import { useGetProfileInformationQuery } from "store/profile";

export const BackerDashboardContainer = () => {
  const [hamburger, setHamburger] = useState(false);
  const { user, token: access_token } = useAuth();
  const { initModal } = useModalProvider();
  const match = useMatch("/backer/*");
  const navigate = useNavigate();
  const { setFullScreenLoading } = useGeneralAppProvider();
  const page = match ? match.params["*"] : undefined;
  const [active, setActive] = useState(false);
  const width = window.innerWidth;
  const { data: profileInfo, error } = useGetProfileInformationQuery(
    (() => {})(),
    {
      pollingInterval: 30000,
    }
  );

  useEffect(() => {
    if (width > 1000) {
      setActive(false);
    }
  }, [width]);

  useEffect(() => {
    if (user?.role === "exporter") {
      navigate("/exporter/dashboard");
    }
  }, [navigate, user?.role]);

  useEffect(() => {
    initModal((prev) => ({
      ...prev,
      verify2fa: JSON.parse(localStorage.getItem("2faModal")!) || false,
    }));
  }, [initModal]);

  useEffect(() => {
    let err: any = error;

    if (access_token && err?.data?.statusCode === 403) {
      initModal({ sessionExpired: true });
      err = null;
    }
  }, [access_token, error, initModal]);

  return (
    <div className="exporter-dashboard-container">
      {/*========== HEADER =========== */}
      <HeaderComponent hamburger={hamburger} setHamburger={setHamburger} />
      {/*========== END OF HEADER =========== */}

      <section className="bg-grey-50" style={{ minHeight: "100vh" }}>
        <div className="h-100 d-flex align-items-center">
          {/* <button
              onClick={() => setActive(!active)}
              className="exporter-menu-btn my-4"
            >
              <HiMenuAlt2 className="header-menu-icon" />
            </button> */}
          <SideBarComponent
            page={page}
            active={active}
            width={width}
            close={setHamburger}
            profileInfo={profileInfo}
            className={`${!hamburger && "d-none d-md-block"}`}
          />
          {/* </div> */}
          <div
            className="right-dashboard bg-grey-50"
            style={{ minHeight: "100vh" }}
          >
            <div
              className="p-4 pb-0 bg-grey-50 h-100"
              style={{ minHeight: "100vh" }}
            >
              <div className="pt-2 h-100">
                {/* <Outlet /> */}
                <AuthVerifyContainer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
