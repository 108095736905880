import { Link, useParams } from "react-router-dom";
import { breadcrumbConfig } from "navigations";
// import campaignImg from "assets/images/viewcampaign.png";
import { SliderMenu } from "components/custom";
import { useEffect, useState } from "react";

import { PrimaryButton } from "components/buttons";
import { CampaignInfo } from "pages/exporter";
import {
  BackerAboutCampaign,
  BackerComments,
  BackerExporterProfile,
  BackerUpdates,
} from "./backersCampaignDetails";
// import { campaign } from "pages/backer/dashboard/components/interface";
import { useWindowWidth } from "utilities/windowWidth";
import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { IconButton } from "@chakra-ui/react";
import { useExporterProfileStatsQuery, useGetAllCampaignsQuery, useGetCampaignQuery } from "store/campaigns";
import { useGeneralAppProvider } from "providers/generalAppProvider";
import { useGetAllCommentsQuery } from "store/comments";
import { useGetAllUpdatesQuery } from "store/campaignUpdates";
import { ModalViewStateProps, useModalProvider } from "providers/modalProvider";
import { useAuth } from "store/auth";
import { kycNotProvided } from "pages/exporter/components/sideBar";
import defaultCampaignImg from "assets/images/viewcampaign.png";
import { getDaysLeft } from "utilities/general";

export const BackerViewCampaigns = () => {
  const user = useAuth();
  console.log("User", user);
  const userRole = user?.user?.role;
  console.log("User Role", userRole);
  const windowWidth = useWindowWidth();
  const { initModal } = useModalProvider();
  const { campaignId } = useParams();
  const { setPrimaryLoading } = useGeneralAppProvider();
  const { data: campaign, isLoading } = useGetCampaignQuery(campaignId, {
    pollingInterval: 30000,
  });
  setPrimaryLoading(isLoading);

  const { data: exporterProfile } = useExporterProfileStatsQuery(campaignId);

  const exporterProfileData = exporterProfile && exporterProfile?.data;

  const campaignData = campaign?.data;
  const { data: comments, refetch } = useGetAllCommentsQuery(campaignId, {
    pollingInterval: 30000,
  });
  const commentsData = comments && comments?.data;
  const commentCount = commentsData?.length ?? 0;
  const { data: campaignUpdates, refetch: refetchUpdates } = useGetAllUpdatesQuery(campaignId, {
    pollingInterval: 30000,
  });
  const campaignUpdatesData = campaignUpdates && campaignUpdates?.data;
  const updatesCount = campaignUpdatesData?.length ?? 0;
  const [activeSection, setActiveSection] = useState(0);
  const headings = [
    `About Campaign`,
    `Comments(${commentCount})`,
    `Exporter Profile`,
    // `Updates(${updatesCount})`,
  ];

  const { discoverCampaigns } = breadcrumbConfig;

  return (
    <div className='text-black px-2'>
      <div className='breadcrumb mb-1' aria-label='breadcrumb'>
        {windowWidth >= 768 &&
          discoverCampaigns.map((item, index) => (
            <>
              <Link
                className={`breadcrumb-item text-grey-600 ${
                  discoverCampaigns.length - 1 === index ? "active" : ""
                }`}
                to={item.to ?? "#"}
              >
                {item.name}
              </Link>
            </>
          ))}
      </div>

      <div>
        <h5
          className={`fs-24 fw-600 ${
            windowWidth >= 768 ? "py-4" : "pb-4"
          } d-flex flex-row align-items-center gap-3`}
        >
          {windowWidth <= 768 && (
            <IconButton
              aria-label='Back'
              icon={<BackIcon />}
              onClick={() => window.history.back()}
              border={"none"}
              bg={"transparent"}
            />
          )}{" "}
          {campaignData?.title}
        </h5>

        <div className='row'>
          <div className='col-lg-8'>
            <img
              src={campaignData?.images[0] || defaultCampaignImg}
              alt='Campaign'
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = defaultCampaignImg;
              }}
              style={{
                objectFit: "cover",
                objectPosition: "top",
                height: windowWidth < 768 ? "260px" : "434px",
                borderRadius: "8px",
              }}
              className='w-100 rounded-4 shadow-sm'
            />
          </div>

          <div className='col-lg-4 bg-white shadow-sm rounded-4'>
            <CampaignInfo orientation={windowWidth >= 768 ? "column" : "row"} campaign={campaignData} />
            <div className={`p-3 mt-3 ${windowWidth <= 768 && "d-flex flex-row gap-4"}`}>
              <div className={windowWidth <= 768 ? "w-50" : ""}>
                <Link to={`/back-campaign/${campaignId}`}>
                  <PrimaryButton
                    className='fw-semibold my-2 py-4 px-3 primary-btn w-100'
                    disabled={
                      getDaysLeft(campaignData?.campaignEndDate) === "Expired" ||
                      Number(campaignData?.amountRaisedString) >= Number(campaignData?.fundingGoalString)
                    }
                  >
                    Back This Campaign
                  </PrimaryButton>
                </Link>
              </div>

              <div className={windowWidth <= 768 ? "w-50" : ""}>
                <PrimaryButton
                  className='fw-semibold mt-2 py-4  px-3 secondary-btn w-100'
                  disabled={campaignData?.riskReport ? false : true}
                >
                  <Link to={campaignData?.riskReport} target='__blank' download>
                    Download Risk Report
                  </Link>
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <SliderMenu
            headings={headings}
            userRole={userRole}
            setActiveMenu={setActiveSection}
            activeMenu={activeSection}
            className='discover-slider-menu'
          />

          <div className='pt-2'>
            {[
              <BackerAboutCampaign campaignData={campaignData} />,
              <BackerComments campaignData={campaignData} comments={comments} refetch={refetch} />,
              <BackerExporterProfile exporterProfileData={exporterProfileData} />,
              // <BackerUpdates
              //   campaignData={campaignData}
              //   updates={campaignUpdates?.data}
              //   refetch={refetchUpdates}
              // />
            ].map((item, index) => activeSection === index && <>{item}</>)}
          </div>
        </div>
      </div>
    </div>
  );
};
