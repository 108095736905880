import { PrimaryButton } from "components/buttons";
import React, { FC, useEffect } from "react";
import "../../../../../assets/css/dashboard/campaign.scss";
import { useModalProvider } from "providers/modalProvider";
import { useAuth } from "store/auth";
import { useGetAllCampaignsQuery, useGetCampaignQuery, useSubmitCampaignMutation } from "store/campaigns";
import { useWindowWidth } from "utilities/windowWidth";
import queryString from "query-string";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities/errorHandling";
import { useParams } from "react-router";

export type CampaignReviewsProps = {
  next: () => void;
  previous: () => void;
  goto: (step: number) => void;
  refetch: any;
};

export const CampaignReviews: FC<CampaignReviewsProps> = ({ previous, goto, refetch }) => {
  const { user } = useAuth();
  const windowWidth = useWindowWidth();
  const { initModal } = useModalProvider();

  const { data: campaigns } = useGetAllCampaignsQuery(
    {
      exporterId: user?._id,
    },
    {
      pollingInterval: 30000,
    }
  );

  const { initNotification } = usePageNotificationProvider();
  const { id } = queryString.parse(window.location.search) as {
    id: string;
  };

  const { data: campaign } = useGetCampaignQuery(id, {
    pollingInterval: 30000,
  });
  const campaignData = campaign?.data;
  console.log("camp", campaignData._id);
  const [submitCampaign, { isLoading }] = useSubmitCampaignMutation();

  const handleSubmit = async () => {
    const payload = {
      campaignId: campaignData._id,
    };
    console.log(payload);

    submitCampaign(payload)
      .unwrap()
      .then((res: any) => {
        console.log(res);
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }
        initNotification({
          message: "Campaign Submitted successfully!",
        });
        initModal((prev) => ({
          ...prev,
          submitCampaign: true,
        }));
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({
          message: resolveApiError(error),
          scheme: "error",
        });
      });
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <section className=' bg-transparent'>
      <h6 className='text-grey-900 fs-20 fw-semibold my-3'>{windowWidth >= 768 ? "Reviews" : "Review"}</h6>
      {windowWidth <= 768 && (
        <p className='text-grey-500 fw-400 fs-14 mb-5'>Take a final look at your campaign</p>
      )}
      <div className='row'>
        <div className='col-md-12  '>
          <form className='review'>
            <div
              className={`col-md-12 ${
                windowWidth >= 768 ? "mb-5" : "mb-4"
              } review-border p-4 align-items-center d-flex justify-content-between`}
            >
              <div className='review-text'>
                <p>{campaign?.data.title}</p>
                <div
                  className='review-link cursor-pointer'
                  onClick={() => {
                    initModal((prev) => ({
                      ...prev,
                      editCampaignInfo: true,
                    }));
                  }}
                >
                  View Full Description
                </div>
              </div>

              <div>
                <PrimaryButton
                  onClick={() => {
                    goto(0);
                  }}
                  className='btn-lg secondary-btn align-self-baseline'
                >
                  Edit Information
                </PrimaryButton>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                windowWidth >= 768 ? "mb-5" : "mb-4"
              } review-border p-4 align-items-center d-flex justify-content-between`}
            >
              <div className='review-text'>
                <p>{campaign?.data.images.length} Images Uploaded</p>
                <div
                  className='review-link cursor-pointer'
                  onClick={() => {
                    initModal((prev) => ({
                      ...prev,
                      editCampaignMedia: { payload: campaign?.data.images },
                    }));
                  }}
                >
                  Preview Files
                </div>
              </div>

              <div>
                <PrimaryButton
                  onClick={() => {
                    goto(1);
                  }}
                  className='btn-lg secondary-btn align-self-baseline'
                >
                  Edit Media Files
                </PrimaryButton>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                windowWidth >= 768 ? "mb-5" : "mb-4"
              } review-border p-4 align-items-center d-flex justify-content-between`}
            >
              <div className='review-text'>
                <p className=''>Export Documents</p>
                <div
                  className='review-link cursor-pointer'
                  onClick={() => {
                    initModal((prev) => ({
                      ...prev,
                      previewCampaignDocument: {
                        payload: campaignData._id,
                      },
                    }));
                  }}
                >
                  Preview Files
                </div>
              </div>

              <div>
                <PrimaryButton
                  onClick={() => {
                    goto(2);
                  }}
                  className='btn-lg secondary-btn align-self-baseline'
                >
                  Modify Document
                </PrimaryButton>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                windowWidth >= 768 ? "mb-5" : "mb-4"
              } review-border p-4 align-items-center d-flex justify-content-between`}
            >
              <div className='review-text'>
                <p>Campaign Goals</p>
                <div
                  className='review-link cursor-pointer'
                  onClick={() => {
                    initModal((prev) => ({
                      ...prev,
                      editCampaignGoals: true,
                    }));
                  }}
                >
                  View Campaign Goals
                </div>
              </div>

              <div>
                <PrimaryButton
                  onClick={() => {
                    goto(3);
                  }}
                  className='btn-lg secondary-btn align-self-baseline'
                >
                  Edit Campaign Goals
                </PrimaryButton>
              </div>
            </div>

            <div>
              <PrimaryButton
                onClick={previous}
                className='btn-lg secondary-btn mt-3 me-3 align-self-baseline'
              >
                {windowWidth >= 768 ? "Back" : "Cancel"}
              </PrimaryButton>
              <PrimaryButton
                isLoading={isLoading}
                isDisabled={isLoading}
                className='btn-lg primary-btn mt-3 align-self-baseline'
                onClick={handleSubmit}
              >
                Submit for Approval
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
