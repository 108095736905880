import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { PrimaryInput } from "components/inputs";
import { PhoneValidationSchema } from "validations/auth";
import { useFormik } from "formik";
import { PrimaryButton } from "components/buttons";
import { useNavigate } from "react-router";
import { IconButton } from "@chakra-ui/react";

export const EnterPhoneNoMobile = () => {
  const navigate = useNavigate();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: { phoneNumber: "" },
      validationSchema: PhoneValidationSchema(),
      onSubmit: () => {},
    });

  return (
    <div>
      <h5
        className="fw-semibold fs-20 text-grey-900 py-4 d-flex align-items-center gap-5 justify-content-center w-100"
        style={{ position: "relative" }}
      >
        <div style={{ position: "absolute", left: 0 }}>
          <IconButton
            aria-label="Back"
            icon={<BackIcon />}
            onClick={() => window.history.back()}
            border={"none"}
            bg={"transparent"}
          />
        </div>
        Two-Factor Authentication
      </h5>
      <p className="text-grey-500 fs-12 fw-400 text-center mb-5">
        To continue, please enter your phone number
      </p>
      <form className="mb-2 w-80 mx-auto d-flex flex-column align-items-center">
        <PrimaryInput
          type="text"
          name="phoneNumber"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phoneNumber}
          error={Boolean(touched.phoneNumber && errors?.phoneNumber)}
          bottomText={errors?.phoneNumber}
          bg="white"
          leftComponent={
            <select className="mx-1 p-0 fw-400 bg-transparent text-grey-900 cursor-pointer">
              <option value="NGN">NGN</option>
            </select>
          }
          leftComponentProps={{
            className: "primary-input-component",
          }}
        />
        <PrimaryButton
          className="btn-lg primary-btn mt-5 mx-auto"
          onClick={() => {
            navigate("/exporter/settings/two-fac-auth/verify", {
              replace: true,
            });
          }}
        >
          Continue
        </PrimaryButton>
      </form>
    </div>
  );
};
