import { Link, useLocation, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from "assets/svg/chevron-left.svg";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { ReactComponent as Modify } from "assets/svg/modify.svg";
import { ReactComponent as Naira } from "assets/svg/naira-icon.svg";
import { ReactComponent as WarningIcon } from "assets/svg/warning-icon.svg";
import ProfileIcon from "assets/svg/profile-circle.svg";
import { useGetProfileInformationQuery } from "store/profile";
import { useAuth } from "store/auth";
import { PrimaryButton } from "components/buttons";
import { FC, useEffect, useState } from "react";
import {
  PrimaryCheckBox,
  PrimaryInput,
  PrimarySelect,
} from "components/inputs";
import { useWindowWidth } from "utilities/windowWidth";
import { AccountInfoValidationSchema } from "validations/auth";
import { useFormik } from "formik";
import { AlertDefaultBox } from "components/custom";
import { useModalProvider } from "providers/modalProvider";
import { useGetBankListQuery } from "store/bankData";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import {
  useGetCampaignQuery,
  useRequestPaymentMutation,
  useUpdateCampaignMutation,
} from "store/campaigns";
import { resolveApiError } from "utilities/errorHandling";

export const RequestPayment = () => {
  const { campaignId } = useParams();
  console.log(campaignId);

  const windowWidth = useWindowWidth();
  const { user } = useAuth();
  const { data: profileInfo } = useGetProfileInformationQuery();
  const { data: banks } = useGetBankListQuery();
  const { data: campaign, isLoading } = useGetCampaignQuery(campaignId, {
    pollingInterval: 30000,
  });
  const { initNotification } = usePageNotificationProvider();
  const profile = profileInfo?.data || user;
  console.log("prof", profile);
  const profileImage = user?.image ?? profileInfo?.data.image ?? ProfileIcon;
  const [showForm, setShowForm] = useState(true);
  const {
    values,
    setValues,
    errors,
    touched,
    resetForm,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      bankName: "",
      bankCode: "",
      accountNumber: "",
      accountName: "",
    },
    validationSchema: AccountInfoValidationSchema(),
    onSubmit: () => {
      initModal((prev) => ({
        ...prev,
        requestPayment: {
          payload: {
            id: campaignId,
            values,
            availableFunds: campaign?.data?.amountRaisedString,
          },
        },
      }));
    },
  });

  useEffect(() => {
    values["bankCode"] = banks?.data.filter(
      (bank) => bank.name === values.bankName
    )[0]?.code as string;
  }, [banks?.data, values]);

  // useEffect(() => {
  //   if (!profileInfo?.data) {
  //     initModal({ sessionExpired: true });
  //   }
  // }, [campaign]);

  console.log(values, campaignId, campaign, profileInfo);

  const { initModal } = useModalProvider();
  return (
    <section className="pb-5">
      <div className="d-flex justify-content-between align-items-center py-5 px-5">
        <div className="d-flex gap-2 align-items-center ">
          <BackIcon />
          <Link to={`/exporter/campaign/${campaignId}`}>
            Return to Campaign
          </Link>
        </div>

        <Link to="/">
          <Logo />
        </Link>

        <div className="d-flex gap-3 align-items-center">
          <img
            src={profileImage}
            alt={"Avatar"}
            className="rounded rounded-circle"
            style={{
              width: 48,
              height: 48,
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
          <h6 className="mb-0 text-grey-900">
            {profile?.firstName} {profile?.lastName}
          </h6>
        </div>
      </div>

      <div className="d-flex flex-column  mx-auto  border rounded-4  w-70 p-5">
        <div>
          <h3 className="fw-600 fs-24">Request Payment</h3>
        </div>
        <hr />
        <div className="d-flex justify-content-between w-100 pt-5">
          <div className="w-50">
            <p className="text-grey-600  fs-14 fw-400">Available Funds</p>
            <div className="d-flex gap-1 align-items-center pt-2">
              <Naira />
              <h5 className="fw-400 fs-24 m-0">
                {Number(
                  campaign?.data?.amountRaisedString ?? 0
                ).toLocaleString()}
              </h5>
            </div>
          </div>
          <div className="w-50 border rounded p-4">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="fw-600 fs-20 m-0">Payment Information</p>
              {/* <div
                onClick={() => setShowForm(!showForm)}
                className="d-flex gap-1 align-items-center cursor-pointer"
              >
                <Modify />
                <p className="fw-400 fs-16 text-primary-600 m-0">Modify</p>
              </div> */}
            </div>

            <div className="pt-3">
              {!showForm && (
                <>
                  <div>
                    <p className="text-grey-400 fs-14 fw-400">Bank</p>
                    <h5 className="text-grey-900 fs-14 fw-400">
                      Oshuporu Bank
                    </h5>
                  </div>

                  <div className="d-flex gap-3  pt-3">
                    <div>
                      <p className="text-grey-400 fs-14 fw-400">
                        Account Number
                      </p>
                      <h5 className="text-grey-900 fs-14 fw-400">1234567890</h5>
                    </div>
                    <div>
                      <p className="text-grey-400 fs-14 fw-400">Account Name</p>
                      <h5 className="text-grey-900 fs-14 fw-400">
                        Martins Johnson
                      </h5>
                    </div>
                  </div>
                </>
              )}

              {showForm && (
                <form onSubmit={handleSubmit}>
                  <AlertDefaultBox
                    scheme="warning"
                    icon={<WarningIcon />}
                    body="Kindly ensure that the bank details provided are accurate and that your bank account name matches your KROWDFI account name"
                  />
                  <div
                    className={`mb-4 mt-4 ${windowWidth <= 768 ? "w-80" : ""}`}
                  >
                    <PrimarySelect
                      label="Bank*"
                      name="bankName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bankName}
                      bg={windowWidth <= 768 ? "white" : ""}
                    >
                      <option value="">Select bank</option>
                      {banks?.data.map((bank) => (
                        <option value={bank.name} selected={true}>
                          {bank.name}
                        </option>
                      ))}
                    </PrimarySelect>
                  </div>
                  <div
                    className={`mb-4 mt-4 ${windowWidth <= 768 ? "w-80" : ""}`}
                  >
                    <PrimaryInput
                      label="Account Number*"
                      labelProps={{
                        className: "text-grey-700",
                      }}
                      name="accountNumber"
                      type="text"
                      maxLength={10}
                      placeholder="Account Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.accountNumber}
                      error={Boolean(
                        touched.accountNumber && errors?.accountNumber
                      )}
                      bottomText={errors?.accountNumber}
                      bg={windowWidth <= 768 ? "white" : ""}
                    />
                  </div>
                  <div
                    className={`mb-4 mt-4 ${windowWidth <= 768 ? "w-80" : ""}`}
                  >
                    <PrimaryInput
                      label="Account Name (First Name first)*"
                      name="accountName"
                      type="text"
                      placeholder="Account Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.accountName}
                      error={Boolean(
                        touched.accountName && errors?.accountName
                      )}
                      bottomText={errors?.accountName}
                      bg={windowWidth <= 768 ? "white" : ""}
                    />
                  </div>

                  {/* <PrimaryCheckBox inlineText="Save Payment Information" /> */}
                  <PrimaryButton
                    type="submit"
                    // isDisabled={loading}
                    // isLoading={loading}
                    className="btn-lg primary-btn mt-4"
                  >
                    Request payment
                  </PrimaryButton>
                  {/* <PrimaryButton
                    // onClick={() => {
                    //   initModal((prev) => ({
                    //     ...prev,
                    //     requestPayment: {
                    //       payload: { id: campaignId },
                    //     },
                    //   }));
                    // }}
                    type="submit"
                    className="btn primary-btn mt-4"
                    isLoading={true}
                    isDisabled={loading}
                  >
                    Request payment
                  </PrimaryButton> */}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
