import { PaginationChakra, PrimaryButton, SliderMenu } from "components";
import { ReactComponent as FullSpeakerIcon } from "assets/svg/full-speaker-icon.svg";
// import { SliderMenu } from "./sliderMenu";
import { CampaignItem } from "./campaignItem";
import { Link } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { AllCampaignsResponse, CampaignData, useGetAllCampaignsQuery } from "store/campaigns";
import { useFormik } from "formik";
import { useAuth } from "store/auth";
import { useGeneralAppProvider } from "providers";
import { DraftCampaignsItems } from "./draftCampaignsItems";
import { getDaysLeft } from "utilities/general";

interface CampaignProps {
  campaigns: AllCampaignsResponse | undefined;
  isLoading?: boolean;
}

export const Campaigns: FC<CampaignProps> = ({ campaigns, isLoading }) => {
  console.log("campaigns", campaigns);
  const { user } = useAuth();
  const [activeMenu, setActiveMenu] = useState(0);
  const [campaignsData, setCampaignsData] = useState<AllCampaignsResponse | undefined>(campaigns);
  useEffect(() => {
    setCampaignsData(campaigns);
  }, [campaigns]);

  const initialValues: any = { skip: 0, limit: 6 };
  const { setPrimaryLoading } = useGeneralAppProvider();
  const { values, setValues, handleChange, handleBlur } = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
  });
  setPrimaryLoading(isLoading ?? false);
  console.log("user", campaigns, isLoading);

  useEffect(() => {
    if (campaigns) {
      switch (activeMenu) {
        case 0:
          setCampaignsData(campaigns);
          break;
        case 1:
          setCampaignsData(
            campaigns?.filter(
              (campaign: CampaignData) =>
                campaign.status === "ongoing" && getDaysLeft(campaign.campaignEndDate) !== "Expired"
            )
          );
          break;
        case 2:
          setCampaignsData(
            campaigns?.filter(
              (campaign: CampaignData) =>
                campaign.status === "pending" && getDaysLeft(campaign.campaignEndDate) !== "Expired"
            )
          );
          break;
        case 3:
          setCampaignsData(
            campaigns?.filter(
              (campaign: CampaignData) =>
                campaign.status === "completed" && getDaysLeft(campaign.campaignEndDate) !== "Expired"
            )
          );
          break;
        case 4:
          setCampaignsData(campaigns?.filter((campaign: CampaignData) => campaign.status === "draft"));
          break;
        case 5:
          setCampaignsData(
            campaigns?.filter(
              (campaign: CampaignData) =>
                campaign.status !== "draft" && getDaysLeft(campaign.campaignEndDate) === "Expired"
            )
          );
          break;
      }
    } else {
      setCampaignsData(undefined);
    }
  }, [activeMenu, isLoading]);

  console.log("values", campaigns);

  const draftCampaigns =
    campaigns?.filter((campaign: CampaignData) => campaign.status === "draft").length || 0;

  const expiredCampaignsLength = campaigns?.filter(
    (campaign: CampaignData) =>
      campaign.status !== "draft" && getDaysLeft(campaign.campaignEndDate) === "Expired"
  ).length;

  console.log("draft", campaigns);

  return (
    <div>
      <SliderMenu
        data={campaignsData}
        headings={[
          `All`,
          "Ongoing",
          "Pending",
          "Completed",
          `Draft (${draftCampaigns})`,
          `Expired (${expiredCampaignsLength})`,
        ]}
        setActiveMenu={setActiveMenu}
        activeMenu={activeMenu}
      />
      {campaignsData?.length === 0 && campaignsData?.length === 0 ? (
        <div className='d-flex flex-column justify-content-center align-items-center m-5'>
          <FullSpeakerIcon />
          <div className='mt-4'>
            <p className='text-center fw-bold'>You don't have any campaigns yet</p>
            <p className='mt-2 text-center text-grey-600'>Create your first campaign</p>
          </div>
          <PrimaryButton className='btn-md primary-btn mt-4'>
            <Link to='/exporter/campaign/create'>Create Campaign</Link>
          </PrimaryButton>
        </div>
      ) : null}
      {campaignsData?.length === 0 && campaignsData?.length && campaignsData?.length > 0 ? (
        <div className='d-flex flex-column justify-content-center align-items-center m-5'>
          <FullSpeakerIcon />
          <div className='mt-4'>
            <p className='text-center fw-bold'>No campaign to view here</p>
            <p className='mt-2 text-center text-grey-600'>Create campaign</p>
          </div>
          <PrimaryButton className='btn-md primary-btn mt-4'>
            <Link to='/exporter/campaign/create'>Create Campaign</Link>
          </PrimaryButton>
        </div>
      ) : null}
      {campaignsData?.length ? (
        <div className='my-5'>
          {campaignsData?.map((campaign: CampaignData, index: number) => {
            if (campaign.status === "draft") {
              return <DraftCampaignsItems campaign={campaign} key={index} />;
            } else {
              return <CampaignItem campaign={campaign} key={index} />;
            }
          })}
          <div className='mt-4'>
            <PaginationChakra
              totalPages={5}
              totalItems={campaignsData?.length as number}
              itemsPerPage={2}
              onPageChange={(val) =>
                setValues((prev: any) => {
                  return { ...prev, skip: 6 * val, limit: 6 };
                })
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
