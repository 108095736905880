import { ReactComponent as Icon } from "assets/svg/campaign-outline.svg";
import React, { useEffect, useState } from "react";
import { AllCampaignsResponse, CampaignData } from "store/campaigns";
import { useWindowWidth } from "utilities/windowWidth";
import { BackerCampaignItem } from "./backerCampaignItem";
import { useGeneralAppProvider } from "providers/generalAppProvider";
import { getDaysLeft } from "utilities/general";

interface CampaignProps {
  data: AllCampaignsResponse | undefined;
}

export const DiscoverAllCampaigns: React.FC<CampaignProps> = ({ data }) => {
  const windowWidth = useWindowWidth();
  const { setPrimaryLoading } = useGeneralAppProvider();

  useEffect(() => {
    setPrimaryLoading(!data);
  }, [data]);

  if (!data) {
    return null;
  }

  const campaigns = data
    ?.filter((i: CampaignData) => i.hasBeenReviewed === true)
    .filter((i: CampaignData) => getDaysLeft(i?.campaignEndDate) !== "Expired");
  console.log("campaigns", campaigns);

  return (
    <div className=''>
      {campaigns && campaigns.length > 0 ? (
        <div className='row mb-4'>
          {campaigns.map((campaign: CampaignData, index: number) => (
            <div className='col-md-4' key={index}>
              <BackerCampaignItem campaign={campaign} />
            </div>
          ))}
        </div>
      ) : (
        <div
          className={`d-flex justify-content-center align-items-center ${
            windowWidth >= 768 ? "mt-5" : "mt-4"
          }`}
        >
          <div className='d-flex flex-column align-items-center gap-3 justify-content-center'>
            <Icon />
            <div>
              <p className='text-grey-900 fw-semibold mb-2'>No campaigns are available at this time.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
