import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as FaqOpenIcon } from "assets/svg/homepage/faq-open-icon.svg";
import { ReactComponent as FaqCloseIcon } from "assets/svg/homepage/faq-close-icon.svg";
import { useWindowWidth } from "utilities/windowWidth";

interface Faq {
  question: string;
  answer: string;
}

interface FaqSectionProps {
  faqs: Faq[];
}

export const FaqSection: React.FC<FaqSectionProps> = ({ faqs }) => {
  const windowWidth = useWindowWidth();

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <section className='bg-grey-50 py-5 my-5'>
      <div className={`${windowWidth <= 768 ? "" : " d-flex"}  w-90  mx-auto py-5`}>
        <div className={`${windowWidth <= 768 ? "w-100 " : "w-50"}`}>
          <h2 className={`${windowWidth <= 768 ? "fs-37 text-center" : "fs-59 "} fw-600"`}>
            Frequently Asked Questions
          </h2>
          <p className={`${windowWidth <= 768 ? " text-center" : " "} fs-16 fw-400 pt-3`}>
            Got more questions? <span className='fw-600 text-primary-600'>View All FAQs</span>{" "}
          </p>
        </div>

        <div className={`${windowWidth <= 768 ? "w-100 mt-5 " : "w-50"}`}>
          {faqs.map((faq, index) => (
            <div
              key={index}
              className='mb-3 border-bottom cursor-pointer'
              onClick={() => setOpenIndex(openIndex === index ? null : index)}
            >
              <div className='d-flex flex-row-reverse align-items-center justify-content-between w-100 pb-3'>
                {openIndex === index ? (
                  <FaqCloseIcon
                    className='cursor-pointer'
                    style={{ width: windowWidth >= 768 ? "100px" : "70px" }}
                  />
                ) : (
                  <FaqOpenIcon
                    className='cursor-pointer'
                    style={{ width: windowWidth >= 768 ? "100px" : "70px" }}
                  />
                )}
                <p
                  className={`pl-5 m-0 p-3 fs-20 fw-600 lh-sm ${
                    openIndex === index ? "text-primary-600" : "text-grey-900"
                  } ${windowWidth <= 768 ? "w-75" : ""}`}
                >
                  {faq.question}
                </p>
              </div>
              <CSSTransition in={openIndex === index} timeout={300} classNames='faq' unmountOnExit>
                <p className='m-0 fs-16 fw-400 text-grey-900 lh-base px-3 pb-2'>{faq.answer}</p>
              </CSSTransition>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
