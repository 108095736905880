import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { useModalProvider } from "providers/modalProvider";
import { useWindowWidth } from "utilities/windowWidth";
import queryString from "query-string";
import { useGetCampaignQuery } from "store/campaigns";

interface EditCampaignInformationDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const EditCampaignInformationDialog: FC<EditCampaignInformationDialogProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  const windowWidth = useWindowWidth();
  const { id } = queryString.parse(window.location.search) as {
    id: string;
  };
  const { data: campaign } = useGetCampaignQuery(id, {
    pollingInterval: 30000,
  });
  return (
    <>
      {campaign && (
        <ChakraAlertDialog
          useFooter={false}
          useCancelButton={false}
          useProceedButton={false}
          useHeader={true}
          headerProps={{
            className:
              windowWidth <= 768 ? "px-0 pt-0 text-grey-600 fw-600 mt-3" : "px-0 pt-0 text-grey-600 fw-600",
          }}
          useFloatingCancel={false}
          title={"Campaign Information"}
          size={windowWidth >= 768 ? "lg" : "full"}
          onClose={() => {
            initModal((prev) => ({ ...prev, editCampaignInfo: false }));
          }}
          closeOnOverlayClick={true}
          modalContentProps={{ className: "rounded-3 p-4" }}
          modalBodyProps={{ className: "p-0" }}
          {...rest}
        >
          <div className=''>
            <div className='text-center d-flex flex-column align-items-center gap-3'>
              <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                <p className='text-grey-400 fw-600 mb-2'>Campaign Title</p>
                <p className='fw-400 text-grey-900 mb-2'>{campaign.data.title}</p>
              </div>
              <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                <p className='text-grey-400 fw-600 mb-2'>Description</p>
                <p className='fw-400 text-grey-900 mb-2 text-start'>{campaign.data.description}</p>
              </div>
              <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                <p className='text-grey-400 fw-600 mb-2'>Exporting Country</p>
                <p className='fw-400 text-grey-900 mb-2'>{campaign.data.exportingCountry}</p>
              </div>
            </div>
          </div>
        </ChakraAlertDialog>
      )}
    </>
  );
};
