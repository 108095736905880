import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { ReactComponent as SuccessIcon } from "assets/svg/verified-icon.svg";
import { useNavigate } from "react-router";
import { useAuth } from "store/auth";

interface ProfileCompletedModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const ProfileCompletedModal: FC<ProfileCompletedModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { user } = useAuth();
  const handleViewProfile = () => {
    window.location.replace(`/${user?.role}/profile`);
    onClose();
  };
  return (
    <>
      <ChakraAlertDialog
        // isOpen={isOpen}
        onClose={onClose}
        useFooter={true}
        useCancelButton={false}
        useProceedButton={true}
        useHeader={true}
        useHeaderCancel={true}
        proceedButtonDefaultChild={"View profile"}
        proceedButtonProps={{
          className: "btn-md primary-btn ms-0 w-100",
          onClick: handleViewProfile,
        }}
        headerProps={{
          className: "pt-0 px-0 text-grey-600 fw-600 fs-20",
        }}
        modalFooterProps={{
          className: "justify-content-center pb-0 px-0",
        }}
        title={""}
        size={"md"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className=''>
          <div className='text-center d-flex flex-column align-items-center'>
            <SuccessIcon className='mb-3' />
            <h5 className='fw-600 text-grey-900 mb-3'>Profile Completed</h5>
            <p className='fw-400 text-grey-500 mb-2'>
              You have successfully updated your profile. Proceed to view.
            </p>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
