import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utilities/axiosQuery/axiosBaseQuery";
import { baseUrl } from "../../utilities/requests";
import { IResponse, User } from "../auth/interface";
import { WaitListResponse, WaitListPayload } from "./interface";

export const waitListApi: any = createApi({
  reducerPath: "waitListApi",
  baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/wait-lists` }),
  endpoints: (builder) => ({
    submitWaitList: builder.mutation<WaitListResponse, WaitListPayload>({
      query: (payload) => ({
        url: "/create-wait-List",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useSubmitWaitListMutation } = waitListApi;
