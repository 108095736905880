import { ChakraAlertDialog, PrimaryButton } from "components";
import React, { FC, useState } from "react";
import { Image } from "@chakra-ui/react";
import { useModalProvider } from "providers/modalProvider";
import { useWindowWidth } from "utilities/windowWidth";
import FilePreviewDialog from "components/custom/filePreview";
import { ReactComponent as DocumentIcon } from "assets/svg/document.svg";
import { useGetCampaignQuery } from "store/campaigns";

interface PreviewCampaignDocumentModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const PreviewCampaignDocumentModal: FC<PreviewCampaignDocumentModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const [dialogState, setDialogState] = useState({
    letterOfCredit: false,
    purchaseOrder: false,
    proformaInvoice: false,
    formQ: false,
    accountStatement: false,
  });
  const windowWidth = useWindowWidth();
  const { initModal, getModalPayload } = useModalProvider();
  const campaignId = getModalPayload("previewCampaignDocument");
  console.log("campaignId", campaignId);

  const { data: campaignData } = useGetCampaignQuery(campaignId, {
    pollingInterval: 30000,
  });
  console.log("campmodal", campaignData);

  return (
    <>
      <ChakraAlertDialog
        useFooter={false}
        useCancelButton={false}
        onClose={() => {
          initModal((prev) => ({ ...prev, previewCampaignDocument: false }));
        }}
        useProceedButton={false}
        useHeader={true}
        headerProps={{
          className:
            windowWidth <= 768 ? "px-0 pt-0 text-grey-600 fw-600 mt-3" : "px-0 pt-0 text-grey-600 fw-600",
        }}
        useFloatingCancel={false}
        title={"Campaign Documents "}
        size={windowWidth >= 768 ? "4xl" : "full"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div
          className='max-h-500 overflow-auto'
          style={{
            maxHeight: windowWidth >= 768 ? "400px" : "600px",
            marginTop: windowWidth >= 768 ? "0" : "40px",
          }}
        >
          <div className='mt-1' id='documents'>
            <div className='d-flex flex-wrap gap-3 ps-1 pb-1'>
              {campaignData?.data && campaignData?.data?.letterOfCredit && (
                <PrimaryButton
                  className='btn secondary-btn border-primary-500 d-flex gap-2 p-4'
                  onClick={() => {
                    setDialogState({ ...dialogState, letterOfCredit: true });
                  }}
                >
                  <FilePreviewDialog
                    fileUrl={campaignData.data.letterOfCredit}
                    isOpen={dialogState.letterOfCredit}
                    title={"Letter of Credit"}
                    onClose={() => {
                      setDialogState({ ...dialogState, letterOfCredit: false });
                    }}
                  />
                  <DocumentIcon />
                  Letter of Credit
                </PrimaryButton>
              )}

              {campaignData?.data && campaignData.data?.purchaseOrder && (
                <PrimaryButton
                  className='btn secondary-btn border-primary-500 d-flex gap-2 p-4'
                  onClick={() => {
                    setDialogState({ ...dialogState, purchaseOrder: true });
                  }}
                >
                  <FilePreviewDialog
                    fileUrl={campaignData.data.purchaseOrder}
                    isOpen={dialogState.purchaseOrder}
                    title={"Purchase Order"}
                    onClose={() => {
                      setDialogState({ ...dialogState, purchaseOrder: false });
                    }}
                  />
                  <DocumentIcon />
                  Purchase Order
                </PrimaryButton>
              )}

              {campaignData?.data && campaignData?.data?.proformaInvoice && (
                <PrimaryButton
                  className='btn secondary-btn border-primary-500 d-flex gap-2 p-4'
                  onClick={() => {
                    setDialogState({ ...dialogState, proformaInvoice: true });
                  }}
                >
                  <FilePreviewDialog
                    fileUrl={campaignData.data.proformaInvoice}
                    isOpen={dialogState.proformaInvoice}
                    title={"Proforma Invoice"}
                    onClose={() => {
                      setDialogState({
                        ...dialogState,
                        proformaInvoice: false,
                      });
                    }}
                  />
                  <DocumentIcon />
                  Proforma Invoice
                </PrimaryButton>
              )}

              {campaignData?.data && campaignData?.data?.accountStatement && (
                <PrimaryButton
                  className='btn secondary-btn border-primary-500 d-flex gap-2 p-4'
                  onClick={() => {
                    setDialogState({ ...dialogState, accountStatement: true });
                  }}
                >
                  <FilePreviewDialog
                    fileUrl={campaignData.data.accountStatement}
                    isOpen={dialogState.accountStatement}
                    title={"Statement of Account"}
                    onClose={() => {
                      setDialogState({
                        ...dialogState,
                        accountStatement: false,
                      });
                    }}
                  />
                  <DocumentIcon />
                  Statement of Account
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
