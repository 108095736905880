import { useModalProvider } from "providers/modalProvider";
import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useAuth, useLogout } from "store/auth";
import {
  DISABLED_INACTIVITY_ROUTES,
  INACTIVITY_MAX_TIME,
  INACTIVITY_MIN_TIME,
} from "utilities/constants";

const useIdleTimeout = () => {
  const { user } = useAuth();
  const { initModal } = useModalProvider();
  const [isIdle, setIdle] = useState(false);
  const logout = useLogout();

  const initLogout = () => {
    setTimeout(() => {
      initModal((prev) => ({ ...prev, inactivity: false }));
      window.location.replace("/login");
    }, 100);
    window.localStorage.clear();
    logout();
  };

  const shouldDisableIdle =
    !(
      DISABLED_INACTIVITY_ROUTES.some((route) =>
        window.location.pathname.startsWith(route)
      ) || window.location.pathname === "/"
    ) && Boolean(user);
  const handleIdle = () => {
    if (shouldDisableIdle) {
      setIdle(true);
      initLogout();
    }
  };
  const onIdle = () => {
    if (shouldDisableIdle) {
      initModal((prev) => ({
        ...prev,
        inactivity: {
          payload: {
            reset: idleTimer.reset,
            timer: INACTIVITY_MIN_TIME / 1000,
          },
        },
      }));
    }
  };
  const idleTimer = useIdleTimer({
    timeout: INACTIVITY_MAX_TIME,
    promptBeforeIdle: INACTIVITY_MIN_TIME,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleTimeout;
