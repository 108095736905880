import { PrimaryButton } from "components/buttons";
import { Link } from "react-router-dom";
import { useWindowWidth } from "utilities/windowWidth";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { useEffect, useState } from "react";
import surprisedMan from "assets/images/surprised_man.png";
import maleHeadshot from "assets/images/male_headshot.png";
import { ReactComponent as NoHassle } from "assets/svg/no_hassle_.svg";
import { ReactComponent as RepeatIcon } from "assets/svg/repeat.svg";
import { ReactComponent as HelpIcon } from "assets/svg/help.svg";
import { ReactComponent as SupportIcon } from "assets/svg/support.svg";
import { ReactComponent as SpeedIcon } from "assets/svg/speed.svg";
import { ReactComponent as ApprovalSquiggle } from "assets/svg/approval_squiggle.svg";
import { ReactComponent as FasterSquiggle } from "assets/svg/faster_squiggle.svg";

import { Image } from "@chakra-ui/react";
import { FooterComponent } from "../footerComponent";
import { BackerTradeSuccess } from "../backersView";
import { FaqSection } from "./faqSection";
import { exporterFaqs } from "utilities/faqQuestions";

export const ApprovalProcess = () => {
  const windowWidth = useWindowWidth();
  const [isScrolled, setIsScrolled] = useState(false);

  const checkScroll = () => {
    const offset = window.scrollY > 760;
    setIsScrolled(offset);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <section className='bg-grey-50'>
      <div
        className={`bg-primary-25 hero-section ${windowWidth >= 768 && "px-5"}`}
        style={{ height: windowWidth >= 768 ? "95vh" : "auto", minHeight: "100vh" }}
      >
        {/* Header */}
        <div
          className={`d-flex align-items-center justify-content-between z-9 text-black position-fixed top-0 w-98 mx-auto py-4 ${
            isScrolled ? "navbar-scrolled w-90" : "bg-transparent"
          } ${windowWidth <= 768 ? "px-3" : "px-5"}`}
        >
          <div>
            <Link to='/'>
              <Logo />
            </Link>
          </div>
          <div>
            {windowWidth >= 768 && (
              <PrimaryButton className='btn-lg primary-btn me-3'>
                <Link to='/register'>Get Started</Link>
              </PrimaryButton>
            )}
            <PrimaryButton className={`btn-lg secondary-btn`}>
              <Link to='/login'>Log In</Link>
            </PrimaryButton>
          </div>
        </div>
        {/* End of Header  */}

        {/* Hero Section */}
        <div
          className={`d-flex ${
            windowWidth >= 768 ? "flex-row w-94" : "flex-column w-100 gap-5"
          } align-items-center justify-content-between`}
        >
          <div
            className={`d-flex flex-column align-items-start gap-5 ${
              windowWidth >= 768 ? "w-672-px" : "w-90"
            }`}
          >
            <div className='d-flex flex-column gap-4 mb-0'>
              <h2
                className={`${
                  windowWidth <= 768 ? "fs-36 w-100" : "fs-59"
                } fw-600 text-grey-900 d-flex flex-column lh-3`}
                style={{ textAlign: "left" }}
              >
                <span className={`mb-0 ${windowWidth <= 768 && "text-center"}`}>Your Export Journey</span>
                <div className={`d-flex ${windowWidth >= 965 ? "flex-row gap-3" : "flex-column"} mb-0`}>
                  <span className={`mb-0 ${windowWidth <= 768 && "text-center"}`}>Begins in</span>
                  <span
                    className={`mb-0 underline_content ${windowWidth <= 768 && "mx-auto"}`}
                    style={{ width: "fit-content" }}
                  >
                    <span className={`mb-0`}>a Blink</span>
                    <NoHassle className='underline_svg' />
                  </span>
                </div>
              </h2>
              <p
                className={`${windowWidth >= 768 ? "w-80" : ""} fs-16 mb-0 lh-base`}
                style={{ textAlign: windowWidth >= 768 ? "left" : "center" }}
              >
                With our quick approval process, no more waiting for weeks to get your export plans moving!
              </p>
            </div>
            <PrimaryButton className={`btn-lg primary-btn w-fit ${windowWidth <= 768 && "mx-auto"}`}>
              <Link to='/register'>Get Started Now</Link>
            </PrimaryButton>
          </div>
          <div
            className={`bg-warning-200 ps-2 rounded-5 ${
              windowWidth >= 768 ? "w-525-px" : "w-320-px"
            } underline_content`}
          >
            <Image src={maleHeadshot} alt='Smiling man' className='w-100' />
            <ApprovalSquiggle className='img_underline_svg' />
          </div>
        </div>
        {/* End of Hero Section */}
      </div>

      {/* Info Section  */}
      <section
        className={`text-grey-50 ${windowWidth >= 768 ? "w-90 py-5" : "w-85 py-3"} mx-auto bg-grey-50`}
      >
        <h1
          className={`fw-600 text-grey-900 w-65 text-left lh-base ${
            windowWidth <= 768 ? "fs-32 w-100" : "fs-47 py-5"
          } my-5`}
        >
          We have streamlined our approval process to ensure you get the funding you need quickly and
          hassle-free.
        </h1>

        <div className='d-flex flex-column gap-5 mb-5 pb-5'>
          <div className={`d-flex ${windowWidth >= 768 ? "flex-row" : "flex-column"} gap-4`}>
            <div
              className={`${
                windowWidth >= 768 ? "w-50" : "w-100"
              } rounded-4 border border-primary-100 d-flex flex-column bg-white`}
              style={{ gap: "32px", padding: "32px" }}
            >
              <RepeatIcon />
              <div>
                <h2 className='text-grey-900 fs-24 fw-600 mb-3'>Simple Application Process</h2>
                <p className='text-grey-700 fs-16 lh-base mb-0'>
                  Simply fill out our online application form with your project details, funding goals, and
                  other relevant information. Our team will review your application promptly and provide you
                  with a decision in a timely manner.
                </p>
              </div>
            </div>
            <div
              className={`${
                windowWidth >= 768 ? "w-50" : "w-100"
              } rounded-4 border border-primary-100 d-flex flex-column bg-white`}
              style={{ gap: "32px", padding: "32px" }}
            >
              <SpeedIcon />
              <div>
                <h2 className='text-grey-900 fs-24 fw-600 mb-3'>Speed and Efficiency</h2>
                <p className='text-grey-700 fs-16 lh-base mb-0'>
                  With Krowdfi, you can expect quick access to funding, so you can focus on growing your
                  business without delay. We know that time is of the essence when it comes to funding your
                  export projects.
                </p>
              </div>
            </div>
          </div>
          <div className={`d-flex ${windowWidth >= 768 ? "flex-row" : "flex-column"} gap-4`}>
            <div
              className={`${
                windowWidth >= 768 ? "w-50" : "w-100"
              } rounded-4 border border-primary-100 d-flex flex-column bg-white`}
              style={{ gap: "32px", padding: "32px" }}
            >
              <HelpIcon />
              <div>
                <h2 className='text-grey-900 fs-24 fw-600 mb-3'>Real-Time Updates</h2>
                <p className='text-grey-700 fs-16 lh-base mb-0'>
                  With Krowdfi, you can trust that you're always in the loop. You will receive regular updates
                  on the status of your application and have access to our team for any additional information
                  or assistance you may need.
                </p>
              </div>
            </div>
            <div
              className={`${
                windowWidth >= 768 ? "w-50" : "w-100"
              } rounded-4 border border-primary-100 d-flex flex-column bg-white`}
              style={{ gap: "32px", padding: "32px" }}
            >
              <SupportIcon />
              <div>
                <h2 className='text-grey-900 fs-24 fw-600 mb-3'>Dedicated Support</h2>
                <p className='text-grey-700 fs-16 lh-base mb-0'>
                  Whether you have questions about the application process, need guidance on preparing your
                  funding request, our team is ready to help you navigate the process and ensure a smooth
                  approval experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Info Section  */}

      {/* Attention Section  */}
      <section className='bg-primary-25 w-100 py-5'>
        <div
          className={`d-flex ${
            windowWidth >= 768 ? "flex-row w-80" : "flex-column w-100"
          } align-items-center justify-content-center mx-auto py-5 my-5`}
          style={{ gap: windowWidth >= 768 ? "86px" : "48px" }}
        >
          <div
            className={`bg-primary-50 ps-2 pb-2 rounded-5 ${windowWidth >= 768 ? "w-540-px" : "w-320-px"}`}
          >
            <Image src={surprisedMan} alt='Happy Businessman' className='w-100' />
          </div>
          <div
            className={`${windowWidth >= 768 ? "w-50" : "w-80"} d-flex flex-column align-items-start`}
            style={{ gap: "32px" }}
          >
            <div className='d-flex flex-column gap-4'>
              <h2
                className={`${
                  windowWidth <= 768 ? "fs-39 w-100" : "fs-59"
                } fw-600 text-grey-900 d-flex flex-column mb-0 lh-base`}
                style={{ textAlign: "left" }}
              >
                <div className='mb-0'>
                  <div
                    className={` mb-0
                      ${
                        windowWidth <= 768 && windowWidth >= 457
                          ? "d-flex flex-row justify-content-center align-items-center text-center"
                          : ""
                      }span
                        ${windowWidth <= 457 ? "d-flex flex-column align-items-center" : ""}
                    `}
                  >
                    <span className='mb-0'>Get Funded</span>
                    <span className='underline_content ms-2'>
                      <span>Faster</span>
                      <FasterSquiggle className='underline_svg' />
                    </span>{" "}
                  </div>
                </div>
                <span className={`mb-0 ${windowWidth <= 768 && "text-center"}`}>with Krowdfi</span>
              </h2>
              <p
                className={`${
                  windowWidth >= 768 ? "text-left w-80" : "text-center w-100"
                } mb-0 text-grey-700 fs-16 lh-base`}
                style={{ textAlign: "left" }}
              >
                Ready to experience the benefits of our quick approval process? Join Krowdfi today and get
                funded faster, allowing you to seize opportunities, expand your export ventures, and drive
                growth in your business engagements.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End of Attention Section  */}

      <FaqSection faqs={exporterFaqs} />
      <BackerTradeSuccess
        mainText='Ready to Revolutionize Your Export Journey?'
        buttonText='Start Your Krowdfi Journey'
        bgType='backer-tractor-bg'
      />

      {/*========== FOOTER =========== */}
      <FooterComponent />
      {/*========== END OF FOOTER =========== */}
    </section>
  );
};
