import { useEffect, useMemo, useState } from "react";
import { All, Completed, Ongoing, Pending } from "./campaignType";
import { SliderMenu } from "components/custom";
import { useGetAllCampaignsQuery } from "store/campaigns";
import { useFormik } from "formik";
import {
  ModalViewStateProps,
  useGeneralAppProvider,
  useModalProvider,
} from "providers";
import { useWindowWidth } from "utilities/windowWidth";
import { useAuth } from "store/auth";
import { Campaigns } from "../dashboard/components";
import { PrimaryButton } from "components/buttons";
import { Link, useNavigate } from "react-router-dom";
import { kycNotProvided } from "../components/sideBar";

export const ExporterCampaignPage = () => {
  const windowWidth = useWindowWidth();
  const { user } = useAuth();
  const { initModal } = useModalProvider();
  const [activeSection, setActiveSection] = useState(0);
  const { setPrimaryLoading } = useGeneralAppProvider();
  const headings = ["All", "Ongoing", "Pending", "Completed", "Draft"];
  const status = ["", "ongoing", "pending", "completed", "Draft"];
  // const initialValues: any = {};
  // const { values, setValues, handleChange, handleBlur } = useFormik({
  //   initialValues: initialValues,
  //   onSubmit: () => {},
  // });

  // useEffect(() => {
  //   if (
  //     kycNotProvided("Campaign", user!.profileCompleted?.completionRate < 96)
  //   ) {
  //     initModal((prev: ModalViewStateProps | undefined) => ({
  //       ...prev,
  //       provideKyc: true,
  //     }));
  //   }
  // }, [user!.profileCompleted?.completionRate]);

  const { data: campaigns, isLoading } = useGetAllCampaignsQuery(
    {
      skip: 0,
      sort: "",
      exporterId: user?._id,
      status: "",
    },
    {
      pollingInterval: 30000,
    }
  );

  const c = useMemo(() => campaigns, [campaigns]);

  console.log("values", c);
  // setPrimaryLoading(isLoading);

  // headings.forEach((item, index) => {
  //   if (activeSection === index) {
  //     values["status"] = status[index];
  //   }
  //   // setValues((prev) => {
  //   //   return { ...prev };
  //   // });
  // });

  return (
    <div className="p-2">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3 className="text-grey-900 fw-600">
          {windowWidth >= 768 ? "Campaigns" : "My Campaigns"}
        </h3>

        <PrimaryButton className="btn-md primary-btn">
          <Link
            to="/exporter/campaign/create"
            className={useWindowWidth() >= 768 ? "fs-14" : "fs-12"}
          >
            Create New Campaign
          </Link>
        </PrimaryButton>
      </div>

      <div>
        {/* <SliderMenu
          headings={headings}
          setActiveMenu={setActiveSection}
          activeMenu={activeSection}
        /> */}

        <div className="pt-2">
          <Campaigns campaigns={campaigns} isLoading={isLoading} />
          {/* <All data={campaigns} /> */}
        </div>
      </div>
    </div>
  );
};
