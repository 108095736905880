import { PrimaryButton, StepperComponent } from "components";
import { ReactComponent as CampaignInfoActive } from "assets/svg/campaign-info-active.svg";
import { ReactComponent as CampaignMedia } from "assets/svg/campaign-media.svg";
import { ReactComponent as CampaignMediaActive } from "assets/svg/campaign-media-active.svg";
import { ReactComponent as CampaignDocs } from "assets/svg/campaign-docs.svg";
import { ReactComponent as CampaignDocsActive } from "assets/svg/campaign-docs-active.svg";
import { ReactComponent as CampaignGoal } from "assets/svg/campaign-goals.svg";
import { ReactComponent as CampaignGoalActive } from "assets/svg/campaign-goals-active.svg";
import { ReactComponent as CampaignReview } from "assets/svg/campaign-review.svg";
import { ReactComponent as CampaignReviewActive } from "assets/svg/campaign-review-active.svg";
import { IconButton, useSteps } from "@chakra-ui/react";
import {
  CampaignDocument,
  CampaignFiles,
  CampaignGoals,
  CampaignInformation,
  CampaignReviews,
} from "./components";
import "../../../../assets/css/dashboard/campaign.scss";
import { breadcrumbConfig } from "navigations";
import { Link } from "react-router-dom";
import { useWindowWidth } from "utilities/windowWidth";
import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { CampaignData, useGetCampaignQuery, useUpdateCampaignMutation } from "store/campaigns";
import queryString from "query-string";
import { ModalViewStateProps, useModalProvider } from "providers/modalProvider";
import { useEffect } from "react";
import { kycNotProvided } from "pages/exporter/components/sideBar";
import { useAuth } from "store/auth";

const steps = [
  {
    title: "Campaign Information",
    description: "Enter campaign title and description",
    icon: <CampaignInfoActive />,
    activeIcon: <CampaignInfoActive />,
  },
  {
    title: "Campaign Media Files",
    description: "Provide campaign feature image",
    icon: <CampaignMedia />,
    activeIcon: <CampaignMediaActive />,
  },
  {
    title: "Export Documents",
    description: "Provide documents required to fulfil an order",
    icon: <CampaignDocs />,
    activeIcon: <CampaignDocsActive />,
  },
  {
    title: "Campaign Goals",
    description: "Set campaign goals and funding target",
    icon: <CampaignGoal />,
    activeIcon: <CampaignGoalActive />,
  },
  {
    title: "Review",
    description: "A final look at your campaign!",
    icon: <CampaignReview />,
    activeIcon: <CampaignReviewActive />,
  },
];

export const NewCampaign = () => {
  const { user } = useAuth();
  const { initModal } = useModalProvider();
  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const { createCampaign } = breadcrumbConfig;
  const windowWidth = useWindowWidth();
  const { id } = queryString.parse(window.location.search) as {
    id: string;
  };
  const { data: campaign, refetch } = useGetCampaignQuery(id, {
    pollingInterval: 30000,
  });
  const initialCampaign = {
    title: campaign?.data.title as string,
    description: campaign?.data.description as string,
    exportingCountry: campaign?.data.exportingCountry as string,
    fundingGoal: campaign?.data.fundingGoalString as string,
    minimumAmount: campaign?.data.minimumAmountString as string,
    interestRate: campaign?.data.interestRate as number,
    reward: campaign?.data.reward as string,
    campaignEndDate: campaign?.data.campaignEndDate as string,
  };

  useEffect(() => {
    if (kycNotProvided("Campaign", user!.profileCompleted?.completionRate < 88)) {
      initModal((prev: ModalViewStateProps | undefined) => ({
        ...prev,
        provideExporterKyc: true,
      }));
    }
  }, [user!.profileCompleted?.completionRate]);

  return (
    <div className='new-campaign p-2'>
      {windowWidth >= 768 && (
        <div className='breadcrumb mb-1' aria-label='breadcrumb'>
          {createCampaign.map((item, index) => (
            <>
              <Link
                className={`breadcrumb-item text-grey-600 ${
                  createCampaign.length - 1 === index ? "active" : ""
                }`}
                to={item.to ?? "#"}
              >
                {item.name}
              </Link>
            </>
          ))}
        </div>
      )}
      <div className='d-flex justify-content-between align-items-center'>
        <h5
          className={`fw-semibold ${
            windowWidth >= 768 ? "fs-24 py-4" : "fs-20 pb-4 mb-4"
          } text-grey-900 d-flex align-items-center gap-3`}
        >
          {windowWidth <= 768 && (
            <IconButton
              aria-label='Back'
              icon={<BackIcon />}
              onClick={() => window.history.back()}
              border={"none"}
              bg={"transparent"}
            />
          )}
          Create New Campaign
        </h5>

        {campaign?.data?.id ? (
          <PrimaryButton
            className='btn-lg secondary-error-btn'
            type='submit'
            onClick={() => {
              initModal((prev) => ({
                ...prev,
                cancelCampaign: {
                  payload: { id: campaign?.data?.id },
                },
              }));
            }}
          >
            Cancel Campaign
          </PrimaryButton>
        ) : (
          <PrimaryButton
            className='btn-lg secondary-error-btn'
            type='submit'
            onClick={() => {
              window.history.back();
            }}
          >
            Go Back
          </PrimaryButton>
        )}
      </div>

      {windowWidth <= 768 && (
        <div className=''>
          <StepperComponent steps={steps} activeStep={activeStep} />
        </div>
      )}
      <div className={`new-campaign-container pb-0 ${windowWidth >= 768 && "border-top"} m-0 d-flex`}>
        <div
          className={`new-campaign-content ${
            windowWidth >= 768 ? "w-50 border-end pe-5" : "w-100 border-0 ps-0"
          } py-5`}
        >
          <div className=''>
            <span className='px-2 py-1 rounded-4 bg-warning-50 text-warning-700 fw-500'>
              Step {activeStep + 1}/{steps.length}
            </span>
          </div>

          <div>
            {[
              <CampaignInformation initialCampaign={initialCampaign} next={goToNext} refetch={refetch} />,
              <CampaignFiles next={goToNext} previous={goToPrevious} initialCampaign={initialCampaign} />,
              <CampaignDocument next={goToNext} previous={goToPrevious} />,
              <CampaignGoals
                initialCampaign={initialCampaign}
                next={goToNext}
                previous={goToPrevious}
                refetch={refetch}
              />,
              <CampaignReviews
                next={goToNext}
                previous={goToPrevious}
                goto={(step) => {
                  setActiveStep(step);
                }}
                refetch={refetch}
              />,
            ].map((item, index) => activeStep === index && <div key={index}>{item}</div>)}
          </div>
        </div>
        {windowWidth >= 768 && (
          <div className='new-campaign-sidebar w-50 p-5 border-end-0'>
            <div className='sidebar-container'>
              <StepperComponent steps={steps} activeStep={activeStep} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
