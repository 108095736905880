import { PrimaryButton } from "components/buttons";
import { PrimaryInput, PrimaryTextarea, CountryListSelect } from "components";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { CampaignInformationValidationSchema } from "validations";
import { resolveApiError } from "utilities";
import {
  CreateCampaignPayload,
  useCreateCampaignMutation,
  useGetCampaignQuery,
  useUpdateCampaignMutation,
} from "store/campaigns";
import { usePageNotificationProvider } from "providers";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { useWindowWidth } from "utilities";
import { useGetProfileInformationQuery } from "store/profile";

export type CampaignInformationProps = {
  next: () => void;
  initialCampaign: CreateCampaignPayload;
  refetch: any;
};

export const CampaignInformation: FC<CampaignInformationProps> = ({
  next,
  initialCampaign,
  refetch,
}) => {
  const windowWidth = useWindowWidth();
  const [request, { isLoading }] = useCreateCampaignMutation();
  const [update, { isLoading: loading }] = useUpdateCampaignMutation();
  const { data: profileInfo } = useGetProfileInformationQuery((() => {})(), {
    pollingInterval: 30000,
  });
  const { initNotification } = usePageNotificationProvider();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { id } = queryString.parse(window.location.search) as {
    id: string;
  };

  // const initialValues = {
  //   // ...initialProfile,
  //   title: initialCampaign.title ?? "",
  //   description: initialCampaign.description ?? "",
  //   exportingCountry: initialCampaign.exportingCountry ?? "",
  //   // fundingGoal: initialCampaign.fundingGoal ?? "30000",
  //   // minimumAmount: initialCampaign.minimumAmount ?? "25000",
  //   // interestRate: initialCampaign.interestRate ?? 7,
  //   // reward: initialCampaign.reward ?? "",
  //   // campaignEndDate:
  //   //   initialCampaign.campaignEndDate ?? "2023-12-12T14:00:08.214Z",
  // };

  const initialValues = useMemo(() => {
    return {
      title: initialCampaign?.title || "",
      description: initialCampaign?.description || "",
      exportingCountry: initialCampaign?.exportingCountry || "",
    };
  }, [initialCampaign]);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: CampaignInformationValidationSchema,
    onSubmit: () => {
      // if (!profileInfo?.data.isVerified)
      //   return initNotification({
      //     message: "Your account is not verified",
      //     scheme: "error",
      //   });
      if (id) {
        if (isUpdated) {
          updateCampaign();
        } else {
          next();
        }
      } else {
        createCampaign();
      }
    },
  });

  const isUpdated = useMemo(() => {
    return JSON.stringify(initialValues) !== JSON.stringify(values);
  }, [values]);

  const updateCampaign = () => {
    update({
      data: { ...values },
      id: id,
    })
      .unwrap()
      .then((res: any) => {
        // console.log(res);
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        refetch();
        initNotification({
          message: "Campaign Updated Successfully",
        });
        setTimeout(() => {
          queryParams.set("id", res.data._id);
          navigate({ search: queryParams.toString() });
          next();
        }, 500);
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  const createCampaign = () => {
    request({ ...values })
      .unwrap()
      .then((res: any) => {
        // console.log(res);
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        if (res) {
          initNotification({
            message: "Campaign information saved",
          });
          setTimeout(() => {
            queryParams.set("id", res?.data?._id);
            navigate({ search: queryParams.toString() });
            next();
          }, 500);
        }
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  return (
    <section className="bg-transparent">
      <h6 className="text-grey-900 fs-20 fw-semibold mt-4 mb-5">
        Campaign Information
      </h6>

      <div className="row">
        <div className="col-md-12 ">
          <form onSubmit={handleSubmit} className="ps-1">
            <div className="col-md-12 mb-5">
              <PrimaryInput
                type="text"
                label="Title"
                labelInfo="Add a title for your campaign"
                name="title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                error={Boolean(errors?.title && touched?.title)}
                bottomText={errors?.title || ""}
                bg={"white"}
              />
            </div>

            <div className="col-md-12 mb-5">
              <PrimaryTextarea
                label="Description"
                name="description"
                labelInfo="Add a description for your campaign"
                rows={10}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                error={Boolean(errors?.description && touched?.description)}
                bottomText={errors?.description || ""}
              />
            </div>

            <div className="col-md-12">
              <CountryListSelect
                name="exportingCountry"
                label="Exporting Country"
                labelInfo="Add the country you are exporting to"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.exportingCountry}
                error={Boolean(
                  errors?.exportingCountry && touched?.exportingCountry
                )}
                bottomText={errors?.exportingCountry || ""}
                bg={windowWidth <= 768 ? "white" : ""}
              />
            </div>

            <PrimaryButton
              type="submit"
              className="btn-lg primary-btn mt-5 align-self-baseline"
              isLoading={isLoading || loading}
            >
              Next
            </PrimaryButton>
          </form>
        </div>
      </div>
    </section>
  );
};
