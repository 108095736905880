import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import UserIcon from "assets/svg/profile-circle.svg";
import { HStack, Icon, IconButton } from "@chakra-ui/react";
import { useGetProfileInformationQuery } from "store/profile";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "store/auth";
import { NotificationComponent } from "pages/home/components/notificationComponent";
import { useModalProvider } from "providers";
import { ReactComponent as HamburgerIcon } from "assets/svg/hamburger.svg";
import { useWindowWidth } from "utilities/windowWidth";

interface HeaderComponentProps {
  hamburger: boolean;
  setHamburger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdminHeaderComponent = ({ hamburger, setHamburger }: HeaderComponentProps) => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { initModal } = useModalProvider();
  const { data: profileInfo, error } = useGetProfileInformationQuery((() => {})(), {
    pollingInterval: 30000,
  });
  const profileImage = user?.image ?? profileInfo?.data.image ?? UserIcon;
  const profile = profileInfo?.data || user;
  // const [err, setErr] = useState<any>({});
  // console.log(profile);

  // useEffect(() => {
  //   setErr(error);
  // }, [error])

  useEffect(() => {
    let err: any = error;
    console.log(err);

    if (err?.data?.statusCode === 403) {
      //   initModal({ sessionExpired: true });
      err = null;
    }
  }, [error]);

  return (
    <>
      <header className='top-site-header bg-white'>
        <div className='header-container'>
          <div
            className={`d-flex justify-content-between align-items-center ${windowWidth <= 768 && "px-2"}`}
          >
            <Logo className='me-2' />
            {/* User profile component */}
            <div
              className={`${
                windowWidth <= 768 ? "gap-3" : "gap-4"
              } d-flex align-items-center justify-content-between`}
            >
              {profile ? (
                <>
                  {/* <button className="bg-transparent border-0 mb-0 me-2 position-relative touchable" onClick={()=>{
                    console.log("Notification icon clicked");
                    setIsNotification(!isNotification)
                  }}>
                    <NotificationIcon className="h-auto w-18-px" />
                  </button> */}

                  {windowWidth <= 768 && (
                    <IconButton
                      aria-label='Back'
                      icon={<HamburgerIcon />}
                      border={"none"}
                      bg={"transparent"}
                      onClick={(e: React.SyntheticEvent) => {
                        e.stopPropagation();
                        setHamburger!((prev) => !prev);
                      }}
                    />
                  )}

                  <NotificationComponent />
                  <Link to={`/${profile?.role}/profile`} className='text-decoration-none'>
                    <HStack
                      display={{ base: "flex" }}
                      alignItems='center'
                      spacing='8px'
                      // role="button"
                      // onClick={() => navigate(`/${profile?.role}/profile`)}
                    >
                      {/* <div className="auth-user"> */}
                      <div className={`${windowWidth <= 768 ? "border-start ps-3" : "border-0"} user-icon`}>
                        <img
                          src={profileImage}
                          alt={"Avatar"}
                          className='rounded rounded-circle'
                          style={{
                            width: windowWidth <= 768 ? 26 : 48,
                            height: windowWidth <= 768 ? 26 : 48,
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                        />
                      </div>
                      <div className='mb-0 me-3 d-none d-md-block'>
                        <div className='text-grey-900 fw-400'>
                          {profile?.firstName} {profile?.lastName}
                        </div>
                        <small className='text-grey-400 text-capitalize'>{profile?.role}</small>
                      </div>
                      {/* </div> */}
                    </HStack>
                  </Link>
                </>
              ) : (
                <div className='d-flex justify-content-center align-items-center gap-3'>
                  <Link to='/login' className='secondary-btn btn-md'>
                    Log In
                  </Link>
                  <Link to='/register' className='primary-btn btn-md'>
                    Register
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
