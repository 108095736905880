import { CustomizedHeader, FooterComponent } from "pages/home";

export const Terms = () => {
  return (
    <section>
      <CustomizedHeader />
      <main className='privacy-container'>
        <div>
          <h1 className='headings'>TERMS OF USE</h1>
          <h1 className='fw-600 fs-14 text-secondary'> This policy is effective as of March, 2024.</h1>

          <h1 className='headings'>AGREEMENT TO TERMS </h1>
          <p className='content'>
            These Terms of Use (these “Terms”), together with any additional documents incorporated by
            reference in these Terms, constitute a legal agreement between Krowdfi (“we”, “our”, “us”,
            “platform”) and users of the platform (“user”, “exporters”, “backers”, “you”). They set out the
            terms upon which you may use our website or web application and you are deemed to have accepted
            these terms upon use of the platform.
          </p>
          <h1 className='headings'>
            PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SERVICE BY ACCESSING, REGISTERING OR
            OPENING AN ACCOUNT (“ACCOUNT”):
          </h1>
          <ol type='A'>
            <li className='content'>
              YOU ACKNOWLEDGE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT AT ALL TIMES TO THESE
              TERMS AND OUR PRIVACY POLICY.
            </li>
            <li className='content'>
              YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREED TO BE BOUND BY THESE TERMS OF USE,
              WHETHER ON YOUR BEHALF (AN INDIVIDUAL), OR ON BEHALF OF THE COMPANY, BUSINESS, OR ORGANIZATION
              YOU REPRESENT.
            </li>
            <li className='content'>
              YOU FURTHER AGREE THAT YOU WILL BE RESPONSIBLE FOR THE ACTS AND OMISSIONS OF ANY OTHER USER WHO
              ACCESS AND USE OUR SERVICES THROUGH YOUR ACCOUNT.
            </li>
            <li className='content'>
              IF YOU DO NOT AGREE TO BE BOUND BY ALL THE TERMS OF THIS AGREEMENT, DO NOT ACCEPT THESE TERMS,
              REGISTER, OR USE THE PLATFORM.
            </li>
          </ol>
          <h1 className='sub-headings mt-5'>DEFINITION </h1>
          <p className='content'>In these Terms;</p>
          <div>
            <p className='content'>
              <span className='sub-headings'> Account- </span>
              means a unique digital account registered in the name of the User and containing details of the
              User’s transactions or operations on the Platform.
            </p>
            <p className='content'>
              <span className='sub-headings'>Backer- </span>
              any individual or entity utilizing the platform to fund the export of goods and services.
            </p>
            <p className='content'>
              <span className='sub-headings'> Campaign- </span>
              refers to a specific agricultural project or initiative by an exporter seeking funding on the
              platform.
            </p>
            <p className='content'>
              <span className='sub-headings'> Dashboard– </span>
              displays key data insights, including but not limited to information on the User’s available
              campaigns. It serves as your personalized hub, where you can access a comprehensive view of key
              account metrics.
            </p>
            <p className='content'>
              <span className='sub-headings'> Escrow- </span>
              campaign funds from Backers held in trust by Us. Funds collected from Backers are held until
              campaign goals are met or specific conditions are fulfilled by the Exporters.
            </p>
            <p className='content'>
              <span className='sub-headings'> Exporter- </span>
              any individual or business seeking funding through the platform for exporting goods.
            </p>
            <p className='content'>
              <span className='sub-headings'> Platform- </span>
              including but not limited to our website and web application.
            </p>
            <p className='content'>
              <span className='sub-headings'> Wallet- </span>
              including but not limited to our website and web application. It is a secure repository for
              funds, enabling contributions, withdrawals, and financial interactions within the platform.
            </p>
          </div>

          <div className='mt-5'>
            <h1 className='sub-headings'>1. ACCESSING AND USING KROWDFI</h1>
            <p className='content'>
              1.1 Anyone can access Krowdfi.com. However, the requirements to post a campaign, become an
              Exporter, or contribute as a Backer include:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>registration as a User by signing up and creating an account;</li>
              <li className='content'>
                be at least 18 years of age or be legally recognized as an adult in your jurisdiction;
              </li>
              <li className='content'>
                not be involved in any fraudulent or criminal activities or be declared bankrupt; and
              </li>
              <li className='content'>
                for our compliance purposes and to provide our services to you, you express your willingness
                to provide certain information intended to meet the standard KYC and AML requirements, hereby
                authorizing us to, directly or through a third party, obtain, verify, and record information
                and documentation that helps us verify your identity and bank account information.
              </li>
            </ol>
            <p className='content'>
              1.2 You are not eligible to use Krowdfi.com if we have suspended or previously terminated your
              access to Krowdfi.com and we have not expressly authorized you in writing to resume using
              Krowdfi.com.
            </p>
            <p className='content'>
              1.3. Krowdfi reserves the right to reject an application to register or withdraw a user’s access
              where we discover that the information or identity submitted is false or misrepresented or due
              to legal restrictions, a user is barred from receiving our services.
            </p>
          </div>
          {/* No 2. PRIVACY POLICY  */}
          <div className='mt-5'>
            <h1 className='sub-headings'>2. PRIVACY POLICY </h1>
            <p className='content'>
              2.1 We take your privacy very seriously. Please read our Privacy Policy to see how we use your
              personal information
            </p>
          </div>
          {/* 3. OUR SERVICES  */}
          <div className='mt-5'>
            <h1 className='sub-headings'>3. OUR SERVICES </h1>
            <p className='sub-headings'>What we do</p>
            <p className='content'>
              3.1 Krowdfi is a crowd funding platform designed to revolutionize the landscape of Nigerian
              agricultural exports. It aims to provide a seamless solution for exporters to crowdfund and
              fulfil international orders to actualize their exporting goals. While providing Backers with an
              opportunity to grow their wealth by investing in a viable industry.
            </p>
            <p className='content'>
              3.2. We exclusively facilitate transactions, specifically creating a secure, business
              environment where funds needed to actualize viable export campaigns can be actualized between
              exporters and backers, subject to these Terms. We are committed to fostering transparency,
              trust, and collaboration between exporters and backers, thereby bridging the funding gap and
              contributing significantly to the growth of Nigerian agricultural exports.
            </p>
            <p className='sub-headings'>What we do not do </p>
            <p className='content'>
              3.3 Krowdfi <span className='fw-800'> DOES NOT </span> function as an investment or commercial
              bank or a financial platform. It operates strictly as a non-financial institution.
            </p>
            <p className='content'>
              3.4 We are not financial advisers, and we <span className='fw-800'> DO NOT </span> offer
              guidance or recommendations regarding any aspect of transactions conducted outside the Platform.
              This entails that we are unable to provide investment, legal, tax, or other advice related to
              any investments made outside the Platform. In the event where you require advice, it is
              advisable to seek assistance from qualified professionals such as financial, legal, or tax
              advisors.
            </p>
            <p className='content'>
              3.5 Except as explicitly stated above, we are not involved in any agreements or transactions
              that users may enter with each other because of a connection arising from our Platform.
            </p>
            <p className='sub-headings'> How we deliver our services </p>

            <p className='content'>
              For our Services, we charge a 1% commission / management fee on the total fund raised by an
              Exporter and a 1% Commission from the return on investment earned by the Backer.
            </p>
          </div>
          {/*  4. YOUR ACCOUNT  */}
          <div className='mt-5'>
            <h1 className='sub-headings'>4. YOUR ACCOUNT</h1>
            <p className='content fw-800'>User Registration</p>
            <p className='content'>
              4.1 In order to utilize our Services, users will need to register on the Platform and establish
              an Account by furnishing details, including but not limited to, email address, phone number,
              date of birth, home address, password, and financial details, amongst others. Krowdfi retains
              the authority to seek additional information from you regarding yourself or the setup of your
              account during and after registration. In the event of your failure to furnish such information
              within the stipulated time, it may lead to the refusal of your request or a delay in the
              creation of your Account.
            </p>
            <p className='content'>4.2 By registering, you make the following representations: </p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                you are of legal age and have the legal capacity to enter into an agreement in the
                jurisdiction in which you reside;
              </li>
              <li className='content'>
                you will provide accurate, current, and complete information in any registration or other
                account related forms on our platform;
              </li>
              <li className='content'>
                you will provide accurate, current, and complete information in any registration or other
                account related forms on our platform;
              </li>
              <li className='content'>
                you will maintain the accuracy of such documents and update them as necessary;
              </li>
              <li className='content'>
                you authorize us to, directly or through a third party, obtain, verify, and record information
                and documentation that helps us verify your identity and bank account information
              </li>
              <li className='content'> you fully understand the risks involved in using the platform;</li>
              <li className='content'>
                you will not use any of our services for any unlawful or unauthorized purpose;
              </li>
              <li className='content'>
                if you register or use the Platform or its services on behalf of another person or business,
                you have authority to accept these Terms on their behalf.
              </li>
              <li className='content'>
                you will fulfill all obligations to be performed on the platform with reasonable care, skill,
                and due diligence; and
              </li>
              <li className='content'>
                you will comply with all applicable laws and regulations of any jurisdiction in which you as a
                user, act.
              </li>
            </ol>
            <p className='content'>
              4.3 You must promptly notify us of changes to your User information by making an update
              application on the platform. Kindly note that updates to personal information are subject to
              review by our compliance team, and therefore, updates may take some time before they reflect on
              the user’s dashboard. If we approve your registration, you will be authorized to use the
              services, subject to these Terms.
            </p>
            <p className='content'>
              In the event that you have, or we have evidence that you have breached these Terms, either
              personally or through a third party acting on your authority, we may suspend your registration
              and/or access to Krowdfi.com and/or to any content made available on Krowdfi.com. Upon further
              investigation of your breach to these terms, we may further terminate your registration with
              Krowdfi.com. Termination is subject to the following rules:
            </p>
            <p className='content'>
              In the event of a breach by a Backer with an active campaign, invested funds will be refunded
              and access to the platform terminated.
            </p>
            <p className='content'>
              In the event of a breach by an Exporter with an active campaign, (a warning will be issued to
              the defaulting party, and upon completion of the campaign term, access to the platform may be
              terminated).
            </p>
            <p className='sub-headings'>Authentication and Login</p>
            <p className='content'>
              4.4 Upon successful completion of the registration process, users will promptly receive a
              verification email containing a unique link. Clicking on this verification link serves to
              confirm the user's email address and activate their account. Subsequently, users can access the
              platform by entering their registered email and password credentials. Following successful
              authentication, users will receive a confirmation email welcoming them to the platform.
            </p>
            <p className='content'>
              4.5 Accessing the login page is conveniently facilitated through the platform's landing page,
              where users can securely log in using their registered email and password combination.
            </p>
            <p className='sub-headings'> Password Recovery</p>
            <p className='content'>
              4.6 Users who have forgotten their passwords have the option to initiate the password recovery
              process. Upon request, a secure password reset link is dispatched to the user's designated email
              address. Through this link, users can securely reset their passwords, ensuring the
              confidentiality and integrity of their account credentials. Upon successful password recovery or
              change, an additional notification of same is sent to the User’s email.
            </p>
            <p className='sub-headings'> Users Profile</p>
            <p className='content'>
              4.7 User profiles are automatically generated upon registration, with information updated
              accordingly. Required fields are clearly delineated, ensuring essential information is provided,
              while optional fields allow users to furnish additional details as desired.
            </p>
            <p className='content'>
              4.8 Users can access their profile page via the navigation sidebar. Within their profile, users
              can modify personal details such as their name, contact information, and profile image.
            </p>
            <p className='content'>
              4.9 For exporters, there is the added functionality to oversee business-related data, including
              business names, registration numbers, statement of account, export license, and pertinent
              documents.
            </p>
            <p className='sub-headings'> Account Security </p>
            <p className='content'>
              4.10 You must maintain the security of your account credentials. Therefore, do not share your
              login details with anyone you do not know and trust or who is not involved in your Campaign (nor
              leave your device unattended while logged into Krowdfi.com) as you will be held responsible for
              all activities that occur on your account (with or without your knowledge) as a result of doing
              so. If you become aware of any misuse or unauthorized use of your login details, then you must
              inform us immediately by sending an email to us via{" "}
              <span className='text-primary-500'>hello@krowdfi.com.</span>
            </p>
            <p className='sub-headings'> Inclusion of two-factor authentication </p>
            <p className='content'>
              Access to our Platform, including login, wallet access, password management, and other pertinent
              user functions, is made secure using a two-factor authentication system. Users are required to
              set up the authentication process during the registration process.
            </p>
            <p className='content'>
              You accept all risks of unauthorized access to your account based on your sharing or loss of
              your registered email and password.
            </p>
            <p className='sub-headings'> Termination of Account </p>
            <p className='content'>
              4.11 You can request deletion of your account at any time as long as you do not have any active
              Campaign or any outstanding funds relating to unexpired Campaign you have backed. Please email
              us via: <span className='text-primary-500'> hello@krowdfi.com </span> to request deletion of
              your account.
            </p>
            <p className='content'>
              4.12 To the extent permitted by law, we may exercise our sole discretion and, without liability,
              terminate (or suspend access to) your use of our Services and Platforms for any reason,
              including but not limited to, your breach of these Terms.
            </p>
          </div>
          {/*  5. USE OF SERVICES */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 5. USE OF SERVICES </h1>
            <p className='sub-headings'>5.1 Campaign Creation (EXPORTERS)</p>
            <p className='content'>
              Your role as an exporter involves the creation of a crowdfunding campaign and achieving the
              following:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                Exporters have the capability to effortlessly initiate campaigns using an intuitive,
                step-by-step interface. They can enrich campaign pages with multimedia elements to captivate
                and engage potential Backers effectively;{" "}
              </li>
              <li className='content'>
                Exporters are required to establish funding goals, determine campaign duration, and define
                rewards for Backers. Additionally, they must showcase order details, product images, and
                business background information to educate potential Backers. It is also essential to provide
                expected delivery dates and any other pertinent information relevant to the campaign;
              </li>
              <li className='content'>
                Upon submission of all relevant information, campaigns undergo a meticulous approval process
                supervised by our platform administrators. These administrators have the authority to approve,
                reject, or request modifications to ensure compliance with the platform’s policies and
                standards;
              </li>
              <li className='content'>
                Exporters must maintain visibility during the review process, thereby ensuring transparency in
                the approval of campaigns. Additionally, exporters will receive feedback and communication
                from platform administrators regarding the approval status of their campaigns and any
                necessary adjustments that may be required;
              </li>
              <li className='content'>
                Upon the approval of a campaign, pivotal campaign details such as its name, funding goal, and
                progress are prominently showcased on your dashboard. Exporters are afforded the opportunity
                to observe a dynamic progress bar associated with each campaign, offering insight into its
                proximity to reaching its funding objective. Progress updates occur in real-time as backers
                contribute, ensuring transparency and accountability
              </li>
              <li className='content'>
                Exporters retain the capability to oversee and manage their campaigns, however, they cannot
                make modifications to campaign specifics, provide updates, or cancel campaigns without
                Krowdfi’s approval;
              </li>
              <li className='content'>
                Clear and prompt communication with platform administrators enables Exporters to promptly
                address any approval-related concerns. Exporters can actively engage with backers, fostering
                stronger relationships and building trust. However, in your engagements with backers, you are
                responsible for safeguarding your sensitive information and exercising discretion when
                discussing your personal details; and
              </li>
              <li className='content'>
                Exporters have access to a range of tools and features designed to facilitate the promotion of
                campaigns and keep Backers informed.
              </li>
            </ol>
            <p className='sub-headings'> 5.2 Exporter’s Obligations</p>
            <p className='content'>
              {" "}
              As an exporter, by agreeing to these Terms you make the following representations:
            </p>

            <ol type='a' className='ms-4'>
              <li className='content'>
                You may not raise funds via another online crowdfunding platform or similar platform (whether
                a website or app) during the funding Period of your campaign, and all funds from Backers must
                be generated through Krowdfi;{" "}
              </li>
              <li className='content'>
                You will apply any funds you receive from the Backer(s) solely and directly for the purpose of
                achieving the campaign goals and for no other purposes;
              </li>
              <li className='content'>
                You will provide relevant information on your business’ registration, including but not
                limited to the business name, registration number, statement(s) of account, letter of credit,
                pro forma invoice, CAC certificate, export license, purchase order, and any other relevant
                document.
              </li>
              <li className='content'>
                You will meet all commitments you make in your campaign, including, but not limited to,
                delivering all rewards offered to Backer(s);{" "}
              </li>
              <li className='content'>
                You will promptly and accurately respond in full and to our satisfaction to all queries,
                clarifications, or requests made by us and/or any Backer;{" "}
              </li>
              <li className='content'>
                You will provide regular updates on the status of the Campaign to the Backers and Krowdfi.
                Campaigns will have a minimum fulfillment time frame of three (3) months and a maximum
                timeframe of one (1) year;
              </li>
              <li className='content'>
                You will promptly contact and work with the Backer(s) and Krowdfi Team to reach a mutually
                satisfactory resolution, which may include refunding their (Backers) contributions if you are
                unable to fulfill any of your commitments;
              </li>
              <li className='content'>
                You will comply with all applicable laws and regulations in relation to your campaign, your
                use of funds, your offer, and the fulfillment of your reward; and{" "}
              </li>
              <li className='content'>
                You will provide all assistance and co-operation required by us from time to time, including
                the provision of copies of any records or information you hold, in respect of any regulatory
                investigation, audit, or inquiry.
              </li>
            </ol>

            <p className='sub-headings'> 5.3 Campaign Exploration (BACKERS)</p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                Backers have convenient access to browse and search for active crowdfunding campaigns. These
                campaigns are organized, tagged for ease of discovery, and categorized to streamline
                navigation;
              </li>
              <li className='content'>
                Detailed information regarding each campaign, including goals, funding targets, campaign
                descriptions, multimedia content, campaign updates, exporter profiles, and risk reports, is
                readily accessible to Backers;
              </li>
              <li className='content'>
                Backers are provided with access to a comprehensive overview of all campaigns they have
                supported. Pertinent campaign particulars such as title, funding objectives, campaign
                timeline, and current progress are displayed;
              </li>
              <li className='content'>
                Backers are afforded the opportunity to examine detailed campaign analytics, facilitating a
                deeper comprehension of campaign efficacy. These analytics encompass contribution patterns and
                engagement metrics;
              </li>
              <li className='content'>
                Backers can observe a dynamic progress indicator for each campaign, effectively illustrating
                its proximity to attaining its funding goal;
              </li>
              <li className='content'>
                Active engagement with campaigns is facilitated in a community of Backers and Exporters
                through commenting, and a "Back This Campaign" button enables users to contribute support;
              </li>
              <li className='content'>
                Only Backers who have verified information and details should be able to view detailed
                campaign information and back a campaign;
              </li>
              <li className='content'>
                Backers can securely contribute funds (in different currencies) to campaigns through a
                reliable payment gateway. They can choose to support campaigns of interest by selecting
                varying amounts of funds and corresponding rewards.{" "}
              </li>
              <li className='content'>Backers will be entitled to receive interest on Campaigns.</li>
            </ol>

            <p className='sub-headings'> 5.4 Backers Obligations</p>
            <p className='content'>
              As a Backer(s), by agreeing to these terms, you make the following representations:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                You will ensure that you have sufficient funds or credit available for payment of the campaign
                funds;{" "}
              </li>
              <li className='content'>
                You will comply with the terms and conditions for the payment processing services provided by
                our third-party payment providers;
              </li>
              <li className='content'>
                You will ensure that any funds used to back a campaign will not result in a breach of any
                applicable law;
              </li>
              <li className='content'>
                You will agree to provide relevant evidence that your funds (personal and business) are
                generated from legitimate sources. This can include, but is not limited to, submitting
                evidence of salary from a recognized employer, investments, or relevant bank records.
              </li>
            </ol>
          </div>
          {/*  6. ANTI-MONEY LAUNDERING */}
          <div className='mt-5'>
            <h1 className='sub-headings'>6. ANTI-MONEY LAUNDERING</h1>
            <p className='content'>
              You represent and warrant the following and shall promptly notify us if any of the following
              ceases to be true and accurate:
            </p>

            <ol type='a' className='ms-4'>
              <li className='content'>
                To the best of your knowledge, and following thorough and complete investigations, none of the
                funds transferred to, withdrawn from, or invested via the Platform in respect to a campaign
                shall originate from or be affiliated with any activity that is deemed to be in violation of
                Applicable Laws;
              </li>
              <li className='content'>
                Your contribution or payment to us as a Backer shall not result in the violation of any
                Applicable Law on our part; and
              </li>
              <li className='content'>
                Your campaign funds received as an Exporter shall not be used to service any campaign contrary
                to that on the Platform or that is in violation of any Applicable Law.
              </li>
            </ol>

            <p className='content'>
              Where we ascertain the falsity or inaccuracy of the aforementioned representations or identify
              the misuse of our website and/or Services in violation of Applicable Law or involving
              suspicious, malicious, fraudulent, or illegal transactions, as determined by us, you agree to
              indemnify us against any resulting loss, damages, or expenses incurred by us.
            </p>
            <p className='content'>
              Furthermore, you acknowledge our obligation to report such activities to the appropriate
              authorities without recourse to you and our authority to halt your ability to access your
              account regardless of the status of your campaign(s).
            </p>
          </div>
          {/*    7. CONFIDENTIALITY */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 7. CONFIDENTIALITY</h1>
            <p className='content'>
              7.1 Krowdfi and users, are herein referred to as the "Receiving Party" and the "Disclosing
              Party" respectively. Where necessary, the Disclosing Party shall disclose relevant business,
              technical, or financial information (referred to as "Proprietary Information" of the Disclosing
              Party). The Receiving Party agrees:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                to undertake rigorous precautions to safeguard such Proprietary Information; and
              </li>
              <li className='content'>
                b. not to utilise or disclose such Proprietary Information to any third party, except as
                necessary for the performance of the Services, where such disclosure is required by law, or as
                otherwise authorized herein.
              </li>
            </ol>
            <p className='content'>
              7.2 The Disclosing Party acknowledges that the above shall not apply to any information that the
              Receiving Party can demonstrate:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>is generally available to the public; </li>
              <li className='content'>
                was in its possession or known to it prior to receiving it from the Disclosing Party,
                supported by appropriate written documentation;
              </li>
              <li className='content'>was lawfully disclosed without restriction by a third party; or</li>
              <li className='content'>
                was independently developed without the use of any Proprietary Information of the Disclosing
                Party, as evidenced by appropriate written documentation.
              </li>
            </ol>
            <p className='content'>
              Each party may also disclose Proprietary Information in responsli to a valid court order or
              other governmental mandate, or as otherwise mandated by law, provided that the responding Party
              provides adequate notice to the disclosing party to enable protective measures to be taken
              and/or only discloses the exact Proprietary Information, or portion thereof, specifically
              requested.
            </p>
          </div>
          {/*    8. PAYMENTS, RETURN ON INVESTMENT, AND REFUNDS*/}
          <div className='mt-4'>
            <h1 className='sub-headings'> 8. PAYMENTS, RETURN ON INVESTMENT, AND REFUNDS</h1>
            <p className='sub-headings'> Wallet Funding and Payment Processing</p>
            <ol type='a' className='ms-4'>
              <li className='content'>Exporters and Backers will have their respective wallets;</li>
              <li className='content'>
                Users can deposit funds into their wallets using various payment methods, including credit and
                debit cards, mobile wallets, and bank transfers, to ease the process of crowdfunding
                campaigns;
              </li>
              <li className='content'>
                Backers can initiate contributions to campaigns directly from their wallets, and these amounts
                are deducted from the wallet balance;
              </li>
              <li className='content'>
                Exporters can request withdrawals from their wallets to their linked bank accounts. Withdrawal
                requests undergo a review process for security and compliance;{" "}
              </li>
              <li className='content'>
                Users can access a transaction history detailing all wallet-related activities, including
                deposits, contributions, withdrawals, and fund transfers; and
              </li>
              <li className='content'>
                The wallet balance is prominently displayed to users, providing real-time information about
                available funds.
              </li>
            </ol>
            <p className='sub-headings'>Escrow Management</p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                Funds collected from backers are held in escrow until campaign goals are met or specific
                conditions are fulfilled, ensuring that funds are available for order fulfilment. The escrow
                process is transparent, providing reassurance to backers and exporters alike;
              </li>
              <li className='content'>
                Upon successful campaign completion or order fulfilment, funds are disbursed to the Exporters'
                or backers’ accounts; and
              </li>
              <li className='content'>
                The platform provides tools to monitor payment transactions, ensuring transparency and
                accountability in financial dealings.
              </li>
            </ol>
            <p className='sub-headings'>Return on Investment (ROI)</p>
            <p className='content'>
              Backers are entitled to a return on investment (ROI) from their campaigns. The standard (ROI)
              for each campaign is between 8 – 20%. The exact percentage is determined by the Exporter before
              the campaign is posted on the Platform. However, Users can also generate an estimate of the
              interest they can earn on their contributions using our Interest Rate Calculator. The calculator
              is designed to provide financial support for campaigns and empowers Backers to understand the
              potential returns on their fundings.
            </p>
            <p className='sub-headings'>Refunds</p>
            <p className='content'>Users may be eligible for a refund in one of the following instances:</p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                In the event of a breach by a Backer, with an active campaign, invested funds will be refunded
                and access to the platform terminated.
              </li>
              <li className='content'>
                Where an Exporter is unable to meet the agreed Campaign deadline, projected ROI, or both, the
                Krowdfi Team and the Exporter will provide a resolution for Backers, which may include
                refunding their (Backers) contributions.
              </li>
            </ol>
          </div>
          {/*    9. THIRD PARTY APPLICATIONS AND LINKS */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 9. THIRD PARTY APPLICATIONS AND LINKS </h1>
            <p className='content'>
              Krowdfi may include links to third-party websites and/or applications, which we have no control
              over. Thus, any use of such third-party websites or links is subject to their own terms and
              privacy policies, including the personal information you provided. Therefore, we advise that you
              carefully review their terms and conditions, as well as their privacy policies, before utilizing
              their websites or disclosing any personal information.
            </p>
          </div>
          {/*  10. INTELLECTUAL PROPERTY RIGHTS  */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 10. INTELLECTUAL PROPERTY RIGHTS</h1>
            <p className='content'>
              Unless explicitly stated otherwise, the Platform (including the website and web app) is solely
              owned by us and does not transfer ownership to you or any third party. This encompasses all
              elements such as source code, databases, functionality, software, website designs, audio, video,
              text, photographs, campaigns, and graphics on the site (referred to collectively as the
              "Content"), as well as the trademarks, service marks, and logos (referred to as the "Marks")
              contained therein, which are either owned or controlled by us or licensed to us.
            </p>
            <p className='content'>
              Unless expressly permitted in these Terms of Use, no part of the Site, Content, or Marks may be
              copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded,
              translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial
              purpose without our explicit prior written consent.
            </p>

            <p className='content'>
              If you are eligible to use the website, you are granted a limited license to access and utilize
              the website and to download or print a copy of any portion of the content for which you have
              proper access, solely for your personal, non-commercial use. We reserve all rights not
              explicitly granted to you regarding the website and its content.
            </p>
          </div>
          {/*   11. ADDITIONAL DETAILS/ MODIFICATIONS   */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 11. ADDITIONAL DETAILS/ MODIFICATIONS </h1>
            <p className='content'>
              11.1 We reserve the right to update or modify these terms of use by posting the updated terms on
              our Platform and sending a notification about same to user(s). Should you object to such
              changes, your sole recourse will be to discontinue using our Platform. By continuing to use our
              Platform after such changes have been posted, you acknowledge such changes and agree to be bound
              by them.
            </p>
            <p className='content'>
              11.2 In addition, we reserve the right to share your personal information and any other
              information relating to you and your transactions with us with, service providers, including
              financial service providers and non-financial partners such as email service providers that
              perform marketing services on our behalf.
            </p>

            <p className='content'>
              11.3 It is pertinent to note that the use of our Platform is contingent upon acceptance of all
              terms and conditions outlined in these Terms as well as any other operational rules and policies
              published on the Platform, which are incorporated by reference.
            </p>
            <p className='content'>
              11.4 To continue utilizing the platform, you must consent to these updated terms and conditions.
              Declining to accept these terms and conditions necessitates the discontinuation of platform use.
            </p>
          </div>
          {/*   12. INDEMNIFICATION   */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 12. INDEMNIFICATION</h1>
            <p className='content'>
              12.1 By utilizing Krowdfi, you commit to adhering to the terms outlined herein. You agree to
              indemnify us, along with our employees, officers, agents, and suppliers, against any damages,
              losses, costs, and expenses, including reasonable legal fees, incurred. As a consequence of your
              violation of these terms:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>
                any liability arising from your use of Krowdfi, including any actions taken by others using
                your account with your consent or due to your negligence;
              </li>

              <li className='content'>
                any third-party claims asserting infringement of intellectual property or publicity rights due
                to customer materials, except where such claims solely result from alterations made to the
                customer materials by Krowdifi without User’s approval or not at the customer's direction; and
              </li>
              <li className='content'>
                third party customer's violation of applicable laws or regulations, gross negligence, or
                wilful misconduct.
              </li>
            </ol>
          </div>
          {/*    13. WARRANTY DISCLAIMER   */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 13. WARRANTY DISCLAIMER</h1>
            <p className='content'>
              To the maximum extent allowed by law and unless explicitly specified otherwise in these terms,
              it is expressly recognized and mutually agreed that your use of the services, along with all
              information, items, and supplementary content (including that sourced from third parties)
              accessible through or incorporated in the services, is exclusively at your own discretion and
              risk
            </p>
          </div>
          {/*     14. LIMITATION TO LIABILITY   */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 14. LIMITATION TO LIABILITY </h1>
            <p className='content'>
              14.1 To the maximum extent permitted by law, we will not be liable for any damage, tort or
              negligence, loss of profit or business, wasted time, or depletion of goodwill, amongst others,
              arising from a breach of contract or your use or inability to use our platform. As such, users
              are advised to conduct their due diligence.
            </p>
            <p className='content'>
              14.2 By using Krowdfi, you acknowledge and accept the inherent risks, characteristics, and
              limitations of the internet, particularly in terms of the technical performance of Krowdfi, our
              response times to view, verify, or transfer information, and the risks inherent in all
              third-party links, connections, and transfers via the internet. Accordingly:
            </p>

            <ol type='a' className='ms-4'>
              <li className='content'>
                users may experience instances of interrupted access to our Platform;
              </li>
              <li className='content'>
                we do not guarantee protection against potential harm caused by the User’s computer viruses,
                worms, time bombs, Trojan horses, cancelbots, logic bombs, or any other malicious programming
                routines that could disrupt or impair computer functionality or operation. This includes
                transmissions resulting from downloading content, using software to download content,
                accessing Krowdfi, or connecting to the server(s) hosting it; and
              </li>
              <li className='content'>
                you acknowledge that it is your responsibility to install appropriate anti-virus and security
                software on your computer hardware and other devices to safeguard against such threats. Any
                content downloaded or obtained via Krowdfi is at your own risk, and you are solely accountable
                for any damage to your computer system or loss of data resulting from such downloads.
              </li>
            </ol>
          </div>
          {/*  15. SEVERABILITY  */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 15. SEVERABILITY </h1>
            <p className='content'>
              Where any of these conditions or other provisions of the Terms of Use are deemed to be invalid,
              illegal, or unenforceable in any way, that specific condition, or provision will be deleted to
              the extent necessary. However, the remaining, conditions, and provisions remain legal and
              enforceable to the fullest extent permitted by law.
            </p>
          </div>
          {/*   16. MISCELLANEOUS  */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 16. MISCELLANEOUS </h1>
            <p className='content'>
              16.1 The present Terms of Use, together with any policies or operational regulations published
              by us on the website or pertaining to it, constitute the comprehensive agreement between you and
              us. Our failure to exercise or implement any right or provision outlined in these Terms of Use
              shall not be construed as a waiver of said right or provision.
            </p>
            <p className='content'>
              16.2 These Terms of Use operate to the maximum extent permitted by law. We reserve the right to
              assign any or all of our rights and obligations to third parties at any given time.
            </p>
          </div>
          {/*     17. FORCE MAJEURE */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 17. FORCE MAJEURE </h1>
            <p className='content'>
              We will not be held accountable for any failure to comply or delay in fulfilling our obligations
              if such occurrences are beyond our reasonable control ("force majeure events"). These events
              encompass acts, events, non-occurrences, omissions, or accidents beyond our control, including
              but not limited to:
            </p>
            <ol type='a' className='ms-4'>
              <li className='content'>any form of cyberattack or system hacking;</li>
              <li className='content'>
                strikes, failures, or accidents affecting shipping, postal, or other relevant transportation
                systems;
              </li>
              <li className='content'>
                governmental acts, decrees, legislation, regulations, or restrictions imposed by any
                government or public authority;
              </li>
              <li className='content'>war or acts of terrorism; and adverse acts of nature.</li>
            </ol>
          </div>
          {/*   18. DISPUTE RESOLUTION  */}
          <div className='mt-5'>
            <h1 className='sub-headings'>18. DISPUTE RESOLUTION </h1>
            <p className='content'>
              18.1 Any disputes related to the validity, interpretation, and execution of these Terms, whether
              between you and Krowdfi or Krowdfi and any third party, that cannot be settled through
              negotiation within 30 days will be subject to arbitration at the Lagos Court of Arbitration
              (LCA). The arbitration process will be governed by the Arbitration and Mediation Act, 2023, Cap.
              A18, Laws of the Federation of Nigeria.
            </p>
            <p className='content'>
              18.2 The Parties shall endeavor in good faith to mutually agree on the selection of an
              arbitrator. If the Parties cannot mutually agree on the selection of an arbitrator within ten
              (10) days of the request, they shall apply to the LCA to appoint an arbitrator. Arbitration
              proceedings shall be conducted in Lagos. The arbitrator will be requested to render an award
              within ninety (90) days and to provide, in writing, the reasoning for the award. The decision of
              any such arbitrator shall be final and binding on the parties.
            </p>
            <p className='content'>
              18.3 Each party shall bear its own cost in connection with the Arbitration, and the
              arbitrator(s) fees shall be split equally between both parties.
            </p>
          </div>
          {/*   18. DISPUTE RESOLUTION  */}
          <div className='mt-5'>
            <h1 className='sub-headings'> 19. FEEDBACK AND COMPLAINTS </h1>
            <p className='content'>
              We endeavour to provide excellent customer service, yet occasionally, unforeseen circumstances
              may arise. However, we strive to promptly address any issues that may arise and encourage you to
              reach out to us via email to outline your concerns and seek assistance.
            </p>
            <p className='content'>
              We welcome and encourage you to provide feedback, comments, and suggestions for improvements to
              Krowdfi’s Platform or Services. You may submit your feedback by emailing us at{" "}
              <span className='text-primary-500'> hello@krowdfi.com. </span>
              Any feedback you submit to us will be considered non-confidential and non-proprietary to you. By
              submitting your feedback to us, you grant us a non-exclusive, worldwide, royalty-free,
              irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for
              any purpose, without compensation to you.
            </p>
            <p className='sub-headings'>Contact Us</p>
            <p className='content'>
              If you have any questions about these Terms, please contact us at
              <span className='text-primary-500'> hello@krowdfi.com. </span>
            </p>
          </div>
        </div>
      </main>
      <FooterComponent />
    </section>
  );
};
