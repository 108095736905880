import { ChakraAlertDialog, CustomPasswordInput, PrimaryInput } from "components";
import React, { FC } from "react";
import { LoginPayload, setCredential, useAuth, useLoginMutation } from "store/auth";
import { useFormik } from "formik";
import { LoginValidationSchema, PhoneValidationSchema } from "validations/auth";
import { AlertDefaultBox } from "components";
import { ReactComponent as ErrorIcon } from "assets/svg/password-error-icon.svg";
import { useModalProvider, usePageNotificationProvider } from "providers";
import { resolveApiError } from "utilities";
import { useDispatch } from "react-redux";

interface PasswordConfirmationModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  //   confirmPassword?: () => void;
}

export const TwoFaPasswordConfirmationModal: FC<PasswordConfirmationModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  //   confirmPassword,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { initModal, getModalPayload } = useModalProvider();
  const modalPayload = getModalPayload("confirm2faPassword");
  const [request, { isLoading }] = useLoginMutation();
  const { initNotification } = usePageNotificationProvider();
  const { values, errors, setValues, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { password: "", email: user?.email as string },
    validationSchema: LoginValidationSchema,
    onSubmit: () => confirmPassword(),
  });

  const confirmPassword = () => {
    request(values)
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });

          return;
        }

        initNotification({
          message: "Success",
        });
        dispatch(
          setCredential({
            user: res.data.user,
            access_token: res.data.token,
            refresh_token: res.data.refreshToken,
          })
        );
        initModal((prev) => ({
          ...prev,
          confirm2faPassword: false,
        }));
        if (modalPayload.modalInit) {
          initModal((prev) => ({
            ...prev,
            [modalPayload.modalInit]: { payload: { mounted: true } },
          }));
        }
        setValues((prev) => {
          return { ...prev, password: "" };
        });
        if (modalPayload.initToggle2fa) {
          modalPayload.initToggle2fa();
        }
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  // const handleViewProfile = () => {
  //   window.location.replace(`/${user?.role}/profile`);
  //   onClose();
  // };
  return (
    <>
      <ChakraAlertDialog
        // isOpen={isOpen}
        useFooter={true}
        useCancelButton={true}
        useProceedButton={true}
        useHeader={true}
        useHeaderCancel={false}
        proceedButtonDefaultChild={modalPayload.proceedButtonText}
        cancelButtonDefaultChild={"Cancel"}
        title={modalPayload.title}
        size={"md"}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        onClose={() => onClose()}
        proceedButtonProps={{
          className: "btn-lg w-100 primary-btn ms-0",
          onClick: () => handleSubmit(),
          isLoading: isLoading,
          type: "submit",
        }}
        cancelButtonProps={{
          className: "btn-lg w-100 ms-0 bg-white border-primary-50 text-grey-700",
          onClick: () => onClose(),
        }}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        modalFooterProps={{
          className: "d-flex justify-content-between gap-3 w-100 px-0 mt-2 pb-0",
        }}
        closeOnOverlayClick={true}
        {...rest}
      >
        <div className=''>
          <div className='text-center d-flex flex-column align-items-center gap-4'>
            <p className='fs-16 text-grey-600 fw-400 m-0 w-100 text-start mb-1'>
              To continue, please enter your password
            </p>
            <form onSubmit={handleSubmit} className='col-md-12 mb-2'>
              <CustomPasswordInput
                label='Password'
                name='password'
                // placeholder="********"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={Boolean(touched.password && errors?.password)}
                // bottomText={errors?.password}
              />
              {errors?.password && (
                <AlertDefaultBox
                  marginTop={"20px"}
                  scheme='error'
                  icon={<ErrorIcon />}
                  body={errors?.password}
                />
              )}
            </form>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
