import { AnyAction } from "@reduxjs/toolkit";
import { PrimaryButton } from "components/buttons";
import { SimpleFileUploadComponent, SliderMenu } from "components/custom";
import { SimpleFileUploadComponentButton } from "components/custom/simpleFileUploadComponentButton";
import { PrimaryInput } from "components/inputs";
import { useFormik } from "formik";
import { useGeneralAppProvider } from "providers/index";
import { useModalProvider } from "providers/modalProvider";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useEffect, useState } from "react";
import { useAuth } from "store/auth";
// import { useUpdateProfileMutation } from "store/profile";
import { useGetRejectedDocumentsQuery, useUpdateInputsFieldsMutation } from "store/verificationCenter";
// import { resolveApiError } from "utilities/errorHandling";
import { useWindowWidth } from "utilities/windowWidth";
type DocumentType = {
  documentType: string;
  section: string;
  fileId: string;
  uploadQuery: string;
  isPendingReview: boolean;
  dataRequestChange?: {
    documentsToUpload: string[];
    dataRequestDocument: {
      _id: string;
      userId: string;
      request: string;
      reason: string;
      adminApprovalStatus: string;
      createdAt: string;
      updatedAt: string;
      __v: number;
    };
  };
  inputFieldsToUpdate?: { reason: string; inputFieldName: string }[];
};
export const VerificationCenter = ({
  activeMenu,
  setActiveMenu,
}: {
  activeMenu: number;
  setActiveMenu: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const windowWidth = useWindowWidth();
  const { user } = useAuth();
  console.log(user);
  const { data: documents } = useGetRejectedDocumentsQuery();

  const [uploadInputFields, { isLoading }] = useUpdateInputsFieldsMutation();
  // const { setPrimaryLoading } = useGeneralAppProvider();
  // setPrimaryLoading(isLoading ?? false);
  const { initNotification } = usePageNotificationProvider();

  const documentToUpload = documents?.data;
  console.log("documentToUpload", documentToUpload);

  const inputFieldsToUpdate = documentToUpload?.userProfileReapproval?.inputFieldsToUpdate;
  const fieldToInput = documentToUpload?.dataRequestChange[0]?.fieldToInput;
  console.log("fieldToInput", fieldToInput);

  const [rejectedDocuments, setRejectedDocuments] = useState<DocumentType[]>([]);

  useEffect(() => {
    if (documentToUpload) {
      const sectionToUploadQuery = {
        dataRequestChange: "UserDataChange",
        userProfileReapproval: "UserKycRejection",
        campaignReapproval: "CampaignKycRejection",
      };
      const rejectedDocuments = [];

      if (documentToUpload.dataRequestChange && Array.isArray(documentToUpload.dataRequestChange)) {
        const section = "dataRequestChange";
        const uploadQuery = sectionToUploadQuery[section];
        documentToUpload.dataRequestChange.forEach((change: any) => {
          const documentsToUpload = change.documentsToUpload;
          const dataRequestDocument = change.dataRequestDocument;

          if (documentsToUpload) {
            const documents = documentsToUpload.map((doc: any) => {
              const isPendingReview = dataRequestDocument.uploadedDocumentDetails?.some(
                (uploadedDoc: any) =>
                  uploadedDoc.documentFileClass === "identificationUrl" ||
                  uploadedDoc.documentFileClass === "utilityBillUrl"
              );
              return {
                documentType: doc,
                section,
                uploadQuery,
                fileId: dataRequestDocument._id,
                isPendingReview,
                uploadedDocumentDetails: isPendingReview
                  ? dataRequestDocument.uploadedDocumentDetails.filter(
                      (uploadedDoc: any) => uploadedDoc.documentFileClass === doc
                    )
                  : [],
              };
            });
            rejectedDocuments.push(...documents);
          }
        });
      }

      if (
        documentToUpload.userProfileReapproval &&
        documentToUpload.userProfileReapproval.documentsAndReasonsToUpload
      ) {
        const section = "userProfileReapproval";
        const uploadQuery = sectionToUploadQuery[section];
        const documentsAndReasonsToUpload =
          documentToUpload.userProfileReapproval.documentsAndReasonsToUpload;

        if (documentsAndReasonsToUpload) {
          const documents = documentsAndReasonsToUpload.map((doc: any) => {
            const isPendingReview = documentToUpload.userProfileReapproval.uploadedDocumentDetails?.some(
              (uploadedDoc: any) =>
                (uploadedDoc.documentFileClass === "identificationUrl" &&
                  doc.documentFileClass === "idcard") ||
                (uploadedDoc.documentFileClass === "exportCertificate" &&
                  doc.documentFileClass === "exportRegistrationCertificate") ||
                (uploadedDoc.documentFileClass === "utilityBillUrl" &&
                  doc.documentFileClass === "utilityBill")
            );
            return {
              documentType:
                doc.documentFileClass === "exportRegistrationCertificate"
                  ? "exportCertificate"
                  : doc.documentFileClass,
              section,
              uploadQuery,
              fileId: documentToUpload.userProfileReapproval._id,
              isPendingReview,
              uploadedDocumentDetails: isPendingReview
                ? documentToUpload.userProfileReapproval.uploadedDocumentDetails.filter(
                    (uploadedDoc: any) =>
                      (uploadedDoc.documentFileClass === "identificationUrl" &&
                        doc.documentFileClass === "idcard") ||
                      (uploadedDoc.documentFileClass === "exportCertificate" &&
                        doc.documentFileClass === "exportRegistrationCertificate") ||
                      (uploadedDoc.documentFileClass === "utilityBillUrl" &&
                        doc.documentFileClass === "utilityBillUrl")
                  )
                : [],
            };
          });
          rejectedDocuments.push(...documents);
        }
      }

      if (
        documentToUpload.campaignReapproval &&
        documentToUpload.campaignReapproval.documentsAndReasonsToUpload
      ) {
        const section = "campaignReapproval";
        const uploadQuery = sectionToUploadQuery[section];
        const documentsAndReasonsToUpload = documentToUpload.campaignReapproval.documentsAndReasonsToUpload;

        if (documentsAndReasonsToUpload) {
          const documents = documentsAndReasonsToUpload.map((doc: any) => {
            const isPendingReview = documentToUpload.campaignReapproval.uploadedDocumentDetails?.some(
              (uploadedDoc: any) => uploadedDoc.documentFileClass === doc.documentFileClass
            );

            return {
              documentType: doc.documentFileClass,
              section,
              uploadQuery,
              fileId: documentToUpload.campaignReapproval._id,
              isPendingReview,
              uploadedDocumentDetails: isPendingReview
                ? documentToUpload.campaignReapproval.uploadedDocumentDetails.filter(
                    (uploadedDoc: any) => uploadedDoc.documentFileClass === doc.documentFileClass
                  )
                : [],
            };
          });
          rejectedDocuments.push(...documents);
        }
      }

      console.log("reject", rejectedDocuments);
      setRejectedDocuments(rejectedDocuments);
    }
  }, [documentToUpload]);

  const formatDocumentType = (documentType: string) => {
    if (documentType?.toLowerCase() === "idcard") {
      return "Id Card";
    }

    return documentType
      ?.split(/(?=[A-Z])/)
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleSubmit = (values: any) => {
    const inputFieldNames = inputFieldsToUpdate?.map((field: any) => field.inputFieldName);
    const valueKeys = Object.keys(values);

    if (inputFieldNames?.some((fieldName: any) => valueKeys.includes(fieldName))) {
      handleInputFieldSubmit(values);
    } else {
      handleFieldToInputSubmit(values);
    }
  };

  //userProfileReapproval submission
  const handleInputFieldSubmit = (values: any) => {
    console.log("values", values);
    if (inputFieldsToUpdate?.length > 0) {
      inputFieldsToUpdate.forEach((field: any) => {
        if (field.inputFieldName.trim() !== "") {
          const fieldValue = (values as Record<string, string>)[field.inputFieldName];
          console.log("fieldValue", fieldValue);
          if (inputFieldsToUpdate?.length > 0) {
            let fileId;
            let uploadQuery;

            if (documentToUpload.userProfileReapproval) {
              fileId = documentToUpload.userProfileReapproval?._id;
              uploadQuery = "UserKycRejection";
            }
            const payload = {
              payload: {
                updatedInput: fieldValue,
                inputFieldName: field.inputFieldName,
              },
              fileId,
              uploadQuery,
            };
            console.log("payload", payload);
            uploadInputFields(payload)
              .unwrap()
              .then((res: any) => {
                if (res.error) {
                  initNotification({
                    message: res.message,
                    scheme: "error",
                  });
                  return;
                }
                initNotification({
                  message: "Request Successful",
                  scheme: "success",
                });
                window.location.reload();
                console.log(res);
              })
              .catch((error: any) => {
                console.log(error);
                initNotification({
                  message: error.message,
                  scheme: "error",
                });
              });
          }
        }
      });
    }
  };

  //userdatachange submission
  const handleFieldToInputSubmit = (values: any) => {
    console.log("values", values);
    if (fieldToInput?.length > 0) {
      fieldToInput.forEach((field: string) => {
        console.log("field", field);
        if (field?.trim() !== "") {
          const fieldValue = (values as Record<string, string>)[field];
          console.log("fieldValue", fieldValue);

          let fileId;
          let uploadQuery;

          if (documentToUpload.dataRequestChange?.length > 0) {
            fileId = documentToUpload.dataRequestChange[0]?.dataRequestDocument?._id;
            uploadQuery = "UserDataChange";
          }
          const payload = {
            payload: {
              updatedInput: fieldValue,
              inputFieldName: field,
            },
            fileId,
            uploadQuery,
          };
          console.log("payload", payload);
          uploadInputFields(payload)
            .unwrap()
            .then((res: any) => {
              if (res.error) {
                initNotification({
                  message: res.message,
                  scheme: "error",
                });
                return;
              }
              initNotification({
                message: "Request Successful",
                scheme: "success",
              });
              window.location.reload();
              console.log(res);
            })
            .catch((error: any) => {
              console.log(error);
              initNotification({
                message: error.message,
                scheme: "error",
              });
            });
        }
      });
    }
  };

  const { values, handleChange, handleBlur } = useFormik({
    initialValues: {},
    onSubmit: handleSubmit,
  });

  return (
    <div className='settings-content-wrapper'>
      <SliderMenu
        headings={["Security", "Notifications", "Verification Centre"]}
        setActiveMenu={setActiveMenu}
        activeMenu={activeMenu}
      />
      <div className='d-flex flex-column settings-content'>
        <div>
          <h1>Upload Documents</h1>
          <p>Update and Verify your Krowdfi profile by uploading your documents here </p>
        </div>

        <div className='col-md-12'>
          <form>
            <div className={`row d-flex flex-column my-4 ${windowWidth <= 768 ? "w-100 mx-auto" : ""}`}>
              {rejectedDocuments && rejectedDocuments.length > 0 ? (
                rejectedDocuments.map((document) => (
                  <div key={`${document.section}-${document.documentType}`}>
                    <div
                      className={`col-6 mb-4  border border-grey-100 rounded-4 py-3 px-4 ${
                        windowWidth < 768 && "w-100"
                      }`}
                    >
                      {!document.isPendingReview ? (
                        <SimpleFileUploadComponentButton
                          fileClass={document.documentType}
                          label={`Upload ${formatDocumentType(document.documentType)}`}
                          bottomText='File Format must be in PDF, PNG, JPEG, or JPG (max. 15mb)'
                          category='verification'
                          fileId={document.fileId}
                          uploadQuery={document.uploadQuery}
                          allowedFIleType='application/pdf, .pdf, image/jpg, image/jpeg, image/png'
                        />
                      ) : (
                        <p className='mb-0 text-primary-200 fw-600'>{`${formatDocumentType(
                          document.documentType
                        )} was uploaded and awaiting review`}</p>
                      )}
                    </div>
                    {document.dataRequestChange &&
                      document.dataRequestChange.documentsToUpload.map((doc: any) => (
                        <div
                          key={doc}
                          className={`col-6 mb-4  border border-grey-100 rounded-4 py-3 px-4 ${
                            windowWidth < 768 && "w-100"
                          }`}
                        >
                          {!(
                            document.isPendingReview &&
                            (doc === "utilityBillUrl" || doc === "identificationUrl")
                          ) ? (
                            <SimpleFileUploadComponentButton
                              fileClass={doc}
                              label={`Upload ${formatDocumentType(doc)}`}
                              bottomText='File Format must be in PDF, PNG, JPEG, or JPG (max. 15mb)'
                              category='verification'
                              fileId={document.fileId}
                              uploadQuery={document.uploadQuery}
                              allowedFIleType='application/pdf, .pdf, image/jpg, image/jpeg, image/png'
                            />
                          ) : (
                            <p className='mb-0 text-primary-200 fw-600'>{`${formatDocumentType(
                              doc
                            )} was uploaded and awaiting review`}</p>
                          )}
                        </div>
                      ))}
                  </div>
                ))
              ) : (
                <p className='mb-0'>No document requiring upload was rejected.</p>
              )}
            </div>
          </form>
          {/* input field to update for user profile reapproval */}
          {inputFieldsToUpdate?.length > 0 && (
            <div
              className={`col-6 mb-4  border border-grey-100 rounded-4 py-3 px-4 ${
                windowWidth < 768 && "w-100"
              }`}
            >
              {inputFieldsToUpdate?.map((field: { reason: string; inputFieldName: string }) => {
                if (field.inputFieldName?.trim() === "") return null;
                const isFieldUpdated =
                  documentToUpload?.userProfileReapproval?.inputFieldsUpdatedByUser?.some(
                    (updatedField: { inputFieldName: string }) =>
                      updatedField.inputFieldName === field.inputFieldName
                  );
                return (
                  <div key={field.inputFieldName}>
                    {isFieldUpdated ? (
                      <p className='text-primary-200 fw-600'>{`${formatDocumentType(
                        field.inputFieldName
                      )} has been updated and awaiting Review`}</p>
                    ) : (
                      <form
                        key={field.inputFieldName}
                        className='mb-3'
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit({
                            [field.inputFieldName]: (values as Record<string, unknown>)[field.inputFieldName],
                          });
                        }}
                      >
                        <PrimaryInput
                          type='text'
                          label={formatDocumentType(field.inputFieldName)}
                          name={field.inputFieldName}
                          placeholder={formatDocumentType(field.inputFieldName)}
                          value={(values as Record<string, string>)[field.inputFieldName] || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isRequired
                        />

                        <PrimaryButton
                          isLoading={isLoading}
                          className='btn-md primary-btn mt-2'
                          type='submit'
                        >
                          Submit
                        </PrimaryButton>
                      </form>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {/* field to update for datarequest change */}
          {fieldToInput?.length > 0 && (
            <div
              className={`col-6 mb-4  border border-grey-100 rounded-4 py-3 px-4 ${
                windowWidth < 768 && "w-100"
              }`}
            >
              {fieldToInput?.map((field: string) => {
                if (field?.trim() === "") return null;
                const isFieldUpdated =
                  documentToUpload?.dataRequestChange[0]?.dataRequestDocument?.inputFieldsUpdatedByUser?.some(
                    (updatedField: { inputFieldName: string }) => updatedField.inputFieldName === field
                  );
                const fieldValue = (values as Record<string, string>)[field] || "";

                return (
                  <div key={field}>
                    {isFieldUpdated ? (
                      <p className='text-primary-200 fw-600'>{`${formatDocumentType(
                        field
                      )} has been updated and awaiting Review`}</p>
                    ) : (
                      <form
                        key={field}
                        className='mb-3'
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit({ [field]: fieldValue });
                        }}
                      >
                        <PrimaryInput
                          type='text'
                          label={formatDocumentType(field)}
                          name={field}
                          placeholder={formatDocumentType(field)}
                          value={fieldValue}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isRequired
                        />

                        <PrimaryButton
                          isLoading={isLoading}
                          className='btn-md primary-btn mt-2'
                          type='submit'
                        >
                          Submit
                        </PrimaryButton>
                      </form>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
