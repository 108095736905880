import { ChakraAlertDialog, PrimaryInput } from "components";
import React, { FC } from "react";
import { useAuth } from "store/auth";
import { useFormik } from "formik";
import { EmailValidationSchema } from "validations/auth";
import { AlertDefaultBox } from "components";
import { ReactComponent as ErrorIcon } from "assets/svg/password-error-icon.svg";
import { useModalProvider } from "providers/modalProvider";

interface ChangeEmailModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const ChangeEmailModal: FC<ChangeEmailModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { email: "" },
    validationSchema: EmailValidationSchema,
    onSubmit: () => {},
  });
  const { user } = useAuth();
  const handleViewProfile = () => {
    window.location.replace(`/${user?.role}/profile`);
    onClose();
  };
  return (
    <>
      <ChakraAlertDialog
        // isOpen={isOpen}
        useFooter={true}
        useCancelButton={true}
        useProceedButton={true}
        useHeader={true}
        useHeaderCancel={false}
        proceedButtonDefaultChild={"Change"}
        cancelButtonDefaultChild={"Cancel"}
        title={"Change Email"}
        size={"md"}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        onClose={() => {
          initModal((prev) => ({ ...prev, profileChangeEmail: false }));
        }}
        proceedButtonProps={{
          className: "btn-lg primary-btn ms-0 mx-4",
          onClick: () => {
            initModal((prev) => ({
              ...prev,
              profileChangeEmail: false,
            }));
          },
        }}
        cancelButtonProps={{
          className: "btn-lg ms-0 bg-white border-grey-300",
          onClick: () => {
            initModal((prev) => ({ ...prev, profileChangeEmail: false }));
          },
        }}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        modalFooterProps={{
          className: "d-flex flex-row-reverse w-100 px-0 mt-2",
        }}
        closeOnOverlayClick={true}
        {...rest}
      >
        <div className=''>
          <div className='text-center d-flex flex-column align-items-center gap-4'>
            <p className='fs-16 text-grey-600 fw-400 m-0 w-100 text-start mb-1'>
              To continue, please enter your email
            </p>
            <form className='col-md-12 mb-2 w-100'>
              <PrimaryInput
                label='Email'
                name='email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={Boolean(touched.email && errors?.email)}
              />
              {errors?.email && (
                <AlertDefaultBox
                  marginTop={"20px"}
                  scheme='error'
                  icon={<ErrorIcon />}
                  body={errors?.email}
                />
              )}
            </form>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
