import { StepperComponent } from "components";
import React, { useEffect } from "react";
import { BsPencil } from "react-icons/bs";
import { ReactComponent as PersonalDetailsIconActive } from "assets/svg/personal-details-icon-active.svg";
import { ReactComponent as ContactDetails } from "assets/svg/contact-details.svg";
import { ReactComponent as ContactDetailsActive } from "assets/svg/contact-details-active.svg";
import { ReactComponent as VerifyIdentity } from "assets/svg/verify-identity-icon.svg";
import { ReactComponent as VerifyIdentityActive } from "assets/svg/verify-identity-icon-active.svg";
import { ReactComponent as BusinessInformation } from "assets/svg/business-info-icon.svg";
import { ReactComponent as BusinessInformationActive } from "assets/svg/business-info-icon-active.svg";
import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { IconButton, useSteps } from "@chakra-ui/react";
import { useWindowWidth } from "utilities/windowWidth";
import {
  BusinessInformationForm,
  ContactAddressForm,
  PersonalDetailsForm,
  UploadAvatarButton,
  VerifyIdentityForm,
} from "./components";
import { User, useAuth } from "store/auth";
import { useGetProfileInformationQuery } from "store/profile";

const backerSteps = [
  {
    title: "Personal Details",
    description: "Enter in your personal details",
    icon: <PersonalDetailsIconActive />,
    activeIcon: <PersonalDetailsIconActive />,
  },
  {
    title: "Contact Address",
    description: "Provide your contact address",
    icon: <ContactDetails />,
    activeIcon: <ContactDetailsActive />,
  },
  {
    title: "Verify Identity",
    description: "Verify your identity to proceed",
    icon: <VerifyIdentity />,
    activeIcon: <VerifyIdentityActive />,
  },
];

const exporterSteps = [
  ...backerSteps,
  {
    title: "Business Information",
    description: "Enter in business information",
    icon: <BusinessInformation />,
    activeIcon: <BusinessInformationActive />,
  },
];

export const CreateProfilePage = () => {
  const windowWidth = useWindowWidth();
  const { user } = useAuth();
  const steps = user?.role === "exporter" ? exporterSteps : backerSteps;
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  const u: User | null = user;

  const initialProfile = {
    firstName: u!.firstName,
    // avatar: u!.avatar,
    // image: u!.image,
    lastName: u!.lastName,
    email: u!.email,
    phoneNumber: u!.phoneNumber,
    address: u!.address,
    country: u!.country,
    city: u!.city,
    state: u!.state,
    postalCode: u!.postalCode,
    sex: u!.sex,
    dateOfBirth: u!.dateOfBirth,
    signature: u!.signature,
    businessInformation: {
      exportGoodType: u!.businessInformation?.exportGoodType,
      TIN: u!.businessInformation?.TIN,
      yearsInExport: u!.businessInformation?.yearsInExport,
      exportFrequency: u!.businessInformation?.exportFrequency,
      countriesOfExport: u!.businessInformation?.countriesOfExport,
      exportRegCertificateUrl: u!.businessInformation?.exportRegCertificateUrl,
    },
    userIdentity: {
      identificationMode: u!.userIdentity?.identificationMode,
      identificationUrl: u!.userIdentity?.identificationUrl,
      utilityBillUrl: u!.userIdentity?.utilityBillUrl,
      bvn: u!.userIdentity?.bvn,
    },
  };

  return (
    <div className='create-profile h-100'>
      {windowWidth >= 768 ? (
        <div className='create-profile-header border-bottom'>
          <div className='header-container'>
            <div className='d-flex justify-content-between align-items-center'>
              <h4 className='text-grey-900 fw-600 mb-0'>My Profile</h4>
              <UploadAvatarButton className='border-0 bg-transparent text-primary-600 d-flex align-items-center fw-600'>
                <BsPencil className='me-2' />
                Upload profile picture
              </UploadAvatarButton>
            </div>
          </div>
        </div>
      ) : (
        <h5
          className={`fw-600 ${
            windowWidth >= 768 ? "fs-24" : "fs-20"
          } text-grey-900 py-4 d-flex align-items-center gap-3`}
        >
          <IconButton
            aria-label='Back'
            icon={<BackIcon />}
            onClick={() => window.history.back()}
            border={"none"}
            bg={"transparent"}
          />
          Create Profile
        </h5>
      )}
      <div className={`create-profile-container pb-0 d-flex h-100 ${windowWidth <= 768 && "flex-column"}`}>
        <div className='create-profile-sidebar'>
          <div className='sidebar-container'>
            <StepperComponent steps={steps} activeStep={activeStep} />
          </div>
        </div>
        <div className={`create-profile-content pt-3 ${windowWidth >= 768 ? "max-w-490 w-95" : "w-100"}`}>
          <div className={windowWidth >= 768 ? "ps-4" : ""}>
            <div className=''>
              <span className='p-2 rounded-2 bg-warning-50 text-warning-700 fw-4 align-self-baseline'>
                Step {activeStep + 1}/{steps.length}
              </span>
            </div>
            {[
              <PersonalDetailsForm next={goToNext} initialProfile={initialProfile} />,
              <ContactAddressForm next={goToNext} previous={goToPrevious} initialProfile={initialProfile} />,
              <VerifyIdentityForm next={goToNext} previous={goToPrevious} initialProfile={initialProfile} />,
              user!.role === "exporter" && (
                <BusinessInformationForm previous={goToPrevious} initialProfile={initialProfile} />
              ),
            ].map((item, index) => activeStep === index && <div key={index}>{item}</div>)}
          </div>
        </div>
      </div>
    </div>
  );
};
