import React from "react";
import XMLViewer from "react-xml-viewer";

export const Sitemap = () => {
  return (
    <div>
      <h1>sitemap</h1>
      <XMLViewer
        collapsible={true}
        xml={` <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      <url>
        <loc>https://tukab2b.com</loc>
        <lastmod>2024-01-24T16:22:07.125Z</lastmod>
        <changefreq>daily</changefreq>
        <priority>0.7</priority>
      </url>
      <url>
        <loc>https://tukab2b.com/about</loc>
        <lastmod>2024-01-24T16:22:07.125Z</lastmod>
        <changefreq>daily</changefreq>
        <priority>0.7</priority>
      </url>
      <url>
        <loc>https://tukab2b.com/admin/claims</loc>
        <lastmod>2024-01-24T16:22:07.125Z</lastmod>
        <changefreq>daily</changefreq>
        <priority>0.7</priority>
      </url>
      <url>
        <loc>https://tukab2b.com/admin/companies</loc>
        <lastmod>2024-01-24T16:22:07.125Z</lastmod>
        <changefreq>daily</changefreq>
        <priority>0.7</priority>
      </url>
      <url>
        <loc>https://tukab2b.com/admin/companies/edited-companies</loc>
        <lastmod>2024-01-24T16:22:07.125Z</lastmod>
        <changefreq>daily</changefreq>
        <priority>0.7</priority>
      </url>
      <url>
        <loc>https://tukab2b.com/admin/companies/user-added-companies</loc>
        <lastmod>2024-01-24T16:22:07.125Z</lastmod>
        <changefreq>daily</changefreq>
        <priority>0.7</priority>
      </url>
    </urlset>`}
      />
    </div>
  );
};
