import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utilities/axiosQuery/axiosBaseQuery";
import { baseUrl } from "../../utilities/requests";
import { BankDataResponse } from "./interface";

export const bankDataApi = createApi({
  reducerPath: "bankDataApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${baseUrl}/bank-data`,
  }),
  endpoints: (builder) => ({
    getBankList: builder.query<BankDataResponse, string | void>({
      query: (payload) => ({
        url: "/getAllBanksList",
        method: "GET",
        body: payload,
      }),
    }),
  }),
});

export const { useGetBankListQuery } = bankDataApi;
