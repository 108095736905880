import { AlertDefaultBox, AuthHeaderText, PrimaryButton } from "components";
import React, { useEffect } from "react";
import { ReactComponent as NotVerifiedIcon } from "assets/svg/not-allowed-icon.svg";
import { ReactComponent as VerifiedIcon } from "assets/svg/account-verified-icon.svg";
import UserIcon from "assets/svg/profile-circle.svg";
import {
  ModalViewStateProps,
  useGeneralAppProvider,
  useModalProvider,
} from "providers";
import { useAuth } from "store/auth";
import { useGetProfileInformationQuery } from "store/profile";
import { BsPencil } from "react-icons/bs";
import { ProfileDetailsGroup, UploadAvatarButton } from "./components";
import { kycNotProvided } from "../components/sideBar";
import { useWindowWidth } from "utilities/windowWidth";
import { useGetRejectedDocumentsQuery } from "store/verificationCenter";
import { ReactComponent as NotificationIcon } from "assets/svg/noti.svg";
import { Link } from "react-router-dom";

export const UserProfilePage = () => {
  const windowWidth = useWindowWidth();
  const { user } = useAuth();
  const { initModal } = useModalProvider();
  const { data: profileInfo, isLoading } = useGetProfileInformationQuery(
    (() => {})(),
    {
      pollingInterval: 30000,
    }
  );
  const { setPrimaryLoading } = useGeneralAppProvider();
  setPrimaryLoading(isLoading);
  const profile = profileInfo?.data || user;

  useEffect(() => {
    if (
      kycNotProvided("Profile", user!.profileCompleted?.completionRate < 88)
    ) {
      initModal((prev: ModalViewStateProps | undefined) => ({
        ...prev,
        provideKyc: true,
      }));
    }
  }, [user!.profileCompleted?.completionRate]);
  const { data: documents } = useGetRejectedDocumentsQuery((() => {})(), {
    pollingInterval: 30000,
  });

  const showVerificationCenterAlert =
    //checks for request documents to upload
    documents?.data?.dataRequestChange?.some(
      (request: any) =>
        request.documentsToUpload?.length !==
          request.dataRequestDocument?.uploadedDocumentDetails?.length &&
        !(
          Array.isArray(
            request.dataRequestDocument?.inputFieldsUpdatedByUser
          ) && request.dataRequestDocument?.inputFieldsUpdatedByUser.length > 0
        )
    );

  return (
    <div
      className={`user-profile-page px-md-3 pt-1 ${
        windowWidth < 768 ? "px-4" : "px-2"
      }`}
    >
      <div className="profile-page-container">
        <div className="mb-40-px">
          <h4 className="fw-600 text-grey-900">My Profile</h4>
          <p className="fw-400 text-grey-600">
            Update and Manage all your information on Krowdfi here
          </p>
        </div>
        <AlertDefaultBox
          scheme={profile?.isVerified === "accepted" ? "success" : "warning"}
          type="outline"
          icon={
            profile?.isVerified === "accepted" ? (
              <VerifiedIcon />
            ) : (
              <NotVerifiedIcon />
            )
          }
          body={
            profile?.isVerified === "accepted"
              ? "Account Verified"
              : "Account Verification pending"
          }
          className="mb-10-px"
        />

        {showVerificationCenterAlert && (
          <div className="row mb-10-px">
            <div className="col-md-12">
              <AlertDefaultBox
                className="mt-3"
                type="outline"
                scheme="neutral"
                icon={<NotificationIcon />}
                body={
                  <span className={windowWidth >= 768 ? "fs-14" : "fs-12"}>
                    To complete your request, head over to the{" "}
                    <Link
                      className="text-primary-600 fw-bold"
                      to={`/${user!.role}/settings?active=2`}
                    >
                      Verification Centre
                    </Link>{" "}
                    to submit the necessary documents. Once we receive your
                    info, it will take up to five working days to review it.
                  </span>
                }
              />
            </div>
          </div>
        )}
        <ProfileDetailsGroup profile={profile} />
      </div>
    </div>
  );
};
