import { PrimaryButton } from "components/buttons";
import { Link } from "react-router-dom";
import { useWindowWidth } from "utilities/windowWidth";

interface BackerTradeSuccessProps {
  mainText: string;
  buttonText: string;
  buttonOnClick?: () => void;
  bgType?: "backer-tractor-bg" | "backer-trade-bg";
}

export const BackerTradeSuccess: React.FC<BackerTradeSuccessProps> = ({
  mainText,
  buttonText,
  buttonOnClick,
  bgType,
}) => {
  const windowWidth = useWindowWidth();

  return (
    <section className={bgType ?? "backer-trade-bg"}>
      <div className="text-center">
        <h1
          style={{
            maxWidth: windowWidth <= 768 ? "100%" : "954px",
          }}
          className={`${
            windowWidth <= 768 ? " fs-40 lh-sm " : " fs-59 "
          } text-white fw-600 mx-auto`}
        >
          {mainText}
        </h1>
        <PrimaryButton
          onClick={buttonOnClick}
          className="btn-lg secondary-btn mt-5"
        >
          {buttonText}
        </PrimaryButton>
      </div>
    </section>
  );
};
