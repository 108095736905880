import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { ReactComponent as Logoi } from "assets/svg/homepage/logo-variant-white.svg";
import { useWindowWidth } from "utilities/windowWidth";
import { Link } from "react-router-dom";
import { PrimaryButton } from "components/buttons";

export const CustomizedHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const windowWidth = useWindowWidth();

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const checkScroll = () => {
    const offset = window.scrollY > 0;

    setIsScrolled(offset);
    setIsVisible(!(window.scrollY > 0 && window.scrollY < 0));
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <div>
      <>
        {isVisible && (
          <div
            className={`mx-auto d-flex align-items-center justify-content-between z-3  text-black w-100 py-4 ${
              isScrolled ? "navbar-scrolled  text-black" : "bg-success text-dark bg-opacity-50 "
            } ${windowWidth <= 768 ? "px-3" : "px-5"}`}
          >
            <div>
              <Link to='/'>{isScrolled ? <Logo /> : <Logoi />} </Link>
            </div>
          </div>
        )}
      </>
    </div>
  );
};
