import { Campaigns } from "./campaigns";
import { AlertDefaultBox } from "components/custom";
import { ReactComponent as WarningIcon } from "assets/svg/warning-icon.svg";
import { ReactComponent as NotificationIcon } from "assets/svg/noti.svg";
import { ReactComponent as FundsIcon } from "assets/svg/funds-icon.svg";
import { ReactComponent as CampaignIcon } from "assets/svg/total-campaign.svg";
import { ReactComponent as TimeIcon } from "assets/svg/time-icon.svg";
import { ReactComponent as OctIcon } from "assets/svg/octicon_tracked-by-closed-completed-24.svg";
import { Link } from "react-router-dom";
import { useAuth } from "store/auth";
import {
  useExporterDashboardStatsQuery,
  useGetAllCampaignsQuery,
  useGetPaymentRequestStatusQuery,
} from "store/campaigns";

import { Box } from "@chakra-ui/react";
import { useWindowWidth } from "utilities/windowWidth";
import { DashboardStats } from "./interface";
import { useGetRejectedDocumentsQuery } from "store/verificationCenter";

export const Dashboard = () => {
  const windowWidth = useWindowWidth();
  const { user } = useAuth();
  const { data: documents } = useGetRejectedDocumentsQuery((() => {})(), {
    pollingInterval: 30000,
  });

  const showVerificationCenterAlert =
    //  checks for  documents fields under request document change
    documents?.data?.dataRequestChange?.some(
      (request: any) =>
        request.documentsToUpload?.length !==
          request.dataRequestDocument?.uploadedDocumentDetails?.length &&
        !(
          Array.isArray(
            request.dataRequestDocument?.inputFieldsUpdatedByUser
          ) && request.dataRequestDocument?.inputFieldsUpdatedByUser.length > 0
        )
    ) ||
    //  checks for input fields under user profile reapproval
    (documents?.data?.userProfileReapproval?.inputFieldsToUpdate?.length > 0 &&
      (!documents?.data?.userProfileReapproval?.inputFieldsUpdatedByUser ||
        documents?.data?.userProfileReapproval?.inputFieldsUpdatedByUser
          ?.length === 0)) ||
    //  checks for  documents fields under user profile reapproval
    (documents?.data?.userProfileReapproval?.documentsAndReasonsToUpload
      ?.length > 0 &&
      (!documents?.data?.userProfileReapproval?.uploadedDocumentDetails ||
        documents?.data?.userProfileReapproval?.uploadedDocumentDetails
          ?.length === 0)) ||
    //  checks for  documents fields under campaign reapproval
    (documents?.data?.campaignReapproval?.documentsAndReasonsToUpload?.length >
      0 &&
      (!documents?.data?.campaignReapproval?.uploadedDocumentDetails ||
        documents?.data?.campaignReapproval?.uploadedDocumentDetails?.length ===
          0));

  const profileCompleted = user?.profileCompleted?.completionRate;
  const { data } = useExporterDashboardStatsQuery();
  const stats: Partial<DashboardStats> = data?.data[0] as DashboardStats;
  const { data: paymentStatus } = useGetPaymentRequestStatusQuery({
    skip: "",
    limit: "",
    sort: "",
    exporterId: user?._id,
  });
  const paymentRequestStatus = paymentStatus?.data;
  const reasonForRejection = paymentStatus?.data[0]?.reasonForRejection;
  const campaignId = paymentStatus?.data[0]?.campaignId;
  const status = paymentStatus?.data[0]?.requestPaymentStatus;
  const { data: campaigns, isLoading } = useGetAllCampaignsQuery(
    {
      skip: 0,
      sort: "",
      exporterId: user?._id,
      status: "",
    },
    {
      pollingInterval: 30000,
    }
  );

  return (
    <div className="dashboard-wrapper">
      <div>
        <>
          {profileCompleted && profileCompleted < 88 && (
            <div className="row mb-2">
              <div className="col-md-8">
                <AlertDefaultBox
                  type="outline"
                  scheme="warning"
                  icon={<WarningIcon />}
                  body={
                    <span className={windowWidth >= 768 ? "fs-14" : "fs-12"}>
                      Your profile is incomplete. Please{" "}
                      <Link
                        className="text-warning-900 fw-bold"
                        to="/exporter/profile/create"
                      >
                        Click here
                      </Link>{" "}
                      or go to{" "}
                      <Link
                        className="text-warning-900 fw-bold"
                        to="/exporter/profile/create"
                      >
                        Profile page
                      </Link>{" "}
                      to complete your profile
                    </span>
                  }
                />
              </div>
            </div>
          )}
          {!user?.enable2fa && (
            <div className="row">
              <div className="col-md-12">
                <AlertDefaultBox
                  className="mt-3"
                  type="outline"
                  scheme="neutral"
                  icon={<NotificationIcon />}
                  body={
                    <span className={windowWidth >= 768 ? "fs-14" : "fs-12"}>
                      Protect your account. Two-Factor Authentication adds an
                      extra layer of security to your account.{" "}
                      <Link
                        className="text-primary-600 fw-bold"
                        to="/exporter/settings"
                      >
                        Click here
                      </Link>{" "}
                      to enable Two-Factor Authentication
                    </span>
                  }
                />
              </div>
            </div>
          )}
          {showVerificationCenterAlert && (
            <div className="row">
              <div className="col-md-12">
                <AlertDefaultBox
                  className="mt-3"
                  type="outline"
                  scheme="neutral"
                  icon={<NotificationIcon />}
                  body={
                    <span className={windowWidth >= 768 ? "fs-14" : "fs-12"}>
                      You have rejected documents. Go to the{" "}
                      <Link
                        className="text-primary-600 fw-bold"
                        to="/exporter/settings?active=2"
                      >
                        Verification Center
                      </Link>{" "}
                      to view and re-upload the required documents.
                    </span>
                  }
                />
              </div>
            </div>
          )}
          {paymentRequestStatus && status === "rejected" && (
            <div className="row">
              <div className="col-md-12">
                <AlertDefaultBox
                  className="mt-3"
                  type="outline"
                  scheme="neutral"
                  icon={<NotificationIcon />}
                  body={
                    <span className={windowWidth >= 768 ? "fs-14" : "fs-12"}>
                      Your Payment Request has been rejected{" "}
                      <span className="fw-600">
                        {reasonForRejection
                          ? `because ${reasonForRejection}`
                          : ""}
                        .
                      </span>{" "}
                      Please go to the{" "}
                      <Link
                        className="text-primary-600 fw-bold"
                        to={`/exporter/campaign/${campaignId}`}
                      >
                        Campaign Page{" "}
                      </Link>
                      to re-submit a new payment request
                    </span>
                  }
                />
              </div>
            </div>
          )}
        </>
      </div>
      <div className="dashboard-statistics">
        {[
          {
            title: "Funds Raised",
            icon: <FundsIcon />,
            value: stats?.totalAmountRaised.$numberDecimal ?? "-",
          },
          {
            title: "Total Campaigns",
            icon: <CampaignIcon />,
            value: stats?.totalCampaigns ?? "-",
          },
          {
            title: "Pending Campaigns",
            icon: <TimeIcon />,
            value: stats?.pendingCampaigns ?? "-",
          },
          {
            title: "Completed Campaigns",
            icon: <OctIcon />,
            value: stats?.completedCampaigns ?? "-",
          },
        ].map((item, key) => (
          <Box className="statistic" key={key}>
            <div className="d-flex flex-row align-items-center justify-content-start gap-2 text-grey-400 w-100">
              <div>{item.icon}</div>
              <p className="my-auto">{item.title}</p>
            </div>
            <h3 className="value lh-1 mb-0 fw-600">{item.value}</h3>
          </Box>
        ))}
      </div>
      <div>
        <h4 className="mb-4 fw-600">My Campaigns</h4>
        <Campaigns campaigns={campaigns} isLoading={isLoading} />
      </div>
    </div>
  );
};
