import { Spinner } from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { MdCheck } from "react-icons/md";

interface RecaptchaProps {
  captchaValue: string | null;
  setCaptchaValue: (state: string) => void;
}

export const Recaptcha: FC<RecaptchaProps> = ({
  captchaValue,
  setCaptchaValue,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    setLoading(true);
    setError("");
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha();
    console.log(token, "tokksssss");

    setLoading(false);
    setCaptchaValue(token);
    setTimeout(() => {
      setCaptchaValue("");
      setError("Verification Expired. Please verify again");
    }, 60000);
  }, [executeRecaptcha]);

  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  if (loading) {
    return (
      <div className="d-flex align-items-center gap-3 mt-3">
        <Spinner color={"teal"} />
        <h6 className="mb-0 fs-13 text-grey-700">Verifying...</h6>
      </div>
    );
  }

  return (
    <>
      {captchaValue ? (
        <div className="d-flex align-items-center gap-3 mt-3">
          <MdCheck className="fs-27 text-primary-500" />
          <h6 className="mb-0 fs-13 text-grey-700">Verified</h6>
        </div>
      ) : (
        <button
          type="button"
          className="border-0 text-primary-600 fs-14 fw-500 mt-3"
          onClick={handleReCaptchaVerify}
        >
          I'm not a robot
        </button>
      )}
      <p className="fs-11 text-error-500 fst-italic mb-0 mt-1">{error}</p>
    </>
  );
};
