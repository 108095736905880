import { ChakraAlertDialog, PrimaryButton, PrimaryInput } from "components";
import React, { FC } from "react";
import { ReactComponent as VerifiedIcon } from "assets/svg/verified-icon.svg"; // import { useNavigate } from "react-router";
import { useModalProvider } from "providers/modalProvider";
import { useFormik } from "formik";
import { WaitlistSchema } from "validations/waitlist";
import { useSubmitWaitListMutation } from "store/waitlist";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useWindowWidth } from "utilities/windowWidth";
import { resolveApiError } from "utilities/errorHandling";

interface WaitListFormModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const WaitListFormModal: FC<WaitListFormModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const windowWidth = useWindowWidth();
  const [request, { isLoading }] = useSubmitWaitListMutation();
  const { initNotification } = usePageNotificationProvider();
  const { initModal } = useModalProvider();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: WaitlistSchema,
    onSubmit: () => submitWaitlist(),
  });
  const submitWaitlist = () => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };
    request(payload)
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });

          return;
        }
        initNotification({
          message: "Success",
        });

        initModal((prev: any) => ({
          ...prev,
          waitListFormModal: false,
          waitListSuccessModal: true,
        }));

        setTimeout(() => {
          initModal((prev: any) => ({
            ...prev,
            waitListSuccessModal: false,
          }));
        }, 3000);
        resetForm();
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useFloatingCancel={false}
        useHeaderCancel={true}
        useCancelButton={false}
        onClose={() => {
          initModal((prev) => ({ ...prev, waitListFormModal: false }));
        }}
        useProceedButton={false}
        // proceedButtonDefaultChild={"Save to Draft"}
        // proceedButtonProps={{
        //   className: "btn-lg primary-btn ms-0 w-100  mx-4",
        // }}
        // cancelButtonDefaultChild={"Cancel and Discard"}
        // cancelButtonProps={{
        //   className: "btn-lg border ms-0 primary-error-btn w-100 text-white",
        //   onClick: handleCancel,
        // }}
        useHeader={true}
        headerProps={{ className: "px-0 pt-0" }}
        // useFloatingCancel={false}
        // useHeaderCancel={false}
        modalFooterProps={{
          className: "d-flex flex-row-reverse w-100 pb-0 px-0 mt-2",
        }}
        title={""}
        size={"lg"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center">
            {/* <VerifiedIcon className="mb-4" /> */}
            <div className={`w-100  mx-auto `}>
              <div className="d-flex gap-3 pb-3">
                <PrimaryInput
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="bg-white h-100 "
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={Boolean(errors?.firstName && touched?.firstName)}
                  bottomText={errors?.firstName || ""}
                />
                <PrimaryInput
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="bg-white h-100 "
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={Boolean(errors?.lastName && touched?.lastName)}
                  bottomText={errors?.lastName || ""}
                />
              </div>
              <PrimaryInput
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={Boolean(errors?.email && touched?.email)}
                bottomText={errors?.email || ""}
                placeholder="Enter your email address"
              />
            </div>

            <div>
              <PrimaryButton
                onClick={submitWaitlist}
                className="btn-md primary-btn mt-3"
              >
                Join the Waitlist
              </PrimaryButton>
            </div>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
