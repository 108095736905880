import { ReactComponent as Icon1 } from "assets/svg/homepage/exp-power-icon.svg";
import { ReactComponent as Icon2 } from "assets/svg/homepage/prof-return-icon.svg";
import { ReactComponent as Icon3 } from "assets/svg/homepage/shape-future-icon.svg";
import { useWindowWidth } from "utilities/windowWidth";

export const BackerDiversify = () => {
  const windowWidth = useWindowWidth();

  return (
    <section className="diversify-container bg-grey-50 ">
      <div className={`${windowWidth <= 768 ? "w-80" : "w-90"}   mx-auto`}>
        <div
          className={`${
            windowWidth <= 768 ? "" : "d-flex"
          }  justify-content-between align-items-center`}
        >
          <div className={`${windowWidth <= 768 ? "w-100" : "w-50"}`}>
            <h2
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "528px",
              }}
              className={`${
                windowWidth <= 768 ? "fs-37" : "fs-47"
              }  fw-600 text-grey-900`}
            >
              Diversify Your Portfolio Secure Your Returns
            </h2>
          </div>

          <div className={`${windowWidth <= 768 ? "w-100 pt-5" : "w-38"}`}>
            <p className="m-0 text-grey-400 fw-400 fs-14">WHY CHOOSE US?</p>

            <h6
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "495px",
              }}
              className={`text-grey-700 fs-16 fw-400 lh-base pt-3`}
            >
              At Krowdfi, we believe in rewarding our backers for their
              invaluable support. Join our community of visionaries, support
              exporters in Nigeria, and unlock exclusive rewards along the way!
            </h6>
          </div>
        </div>

        <div
          className={`${windowWidth <= 768 ? "w-100" : "d-flex  w-85 "}
diversify-outcome align-items-center gap-5 justify-content-around  mx-auto mt-5`}
        >
          <div className={`${windowWidth <= 768 ? "w-100" : "w-40 "}`}>
            <Icon1 />
            <h6 className="text-grey-900 fw-600 fs-16 pt-3 ">
              Empower Exporters
            </h6>
            <p
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "244px",
              }}
              className="m-0 text-grey-700 fw-400 fs-16 lh-base"
            >
              Fuel the growth of agricultural exporters in Nigeria and play a
              vital role in shaping the future of global trade
            </p>
          </div>

          <div className={`${windowWidth <= 768 ? "w-100 py-5" : "w-40 "}`}>
            <Icon2 />
            <h6 className="text-grey-900 fw-600 fs-16 pt-3 ">
              Profitable Returns{" "}
            </h6>
            <p
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "203px",
              }}
              className="m-0 text-grey-700 fw-400 fs-16 lh-base "
            >
              Invest in promising export projects and enjoy lucrative returns on
              your funding support
            </p>
          </div>

          <div className={`${windowWidth <= 768 ? "w-100" : "w-40 "}`}>
            <Icon3 />
            <h6 className="text-grey-900 fw-600 fs-16 pt-3 ">
              Shape the Future{" "}
            </h6>
            <p
              style={{
                maxWidth: windowWidth <= 768 ? "100%" : "256px",
              }}
              className="m-0 text-grey-700 fw-400 fs-16 lh-base "
            >
              Contribute to the future of global agricultural trade financing
              and be part of a movement driving positive change
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
