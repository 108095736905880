import { ReactComponent as BackerProcess } from "assets/svg/homepage/backers-process.svg";
import Circle from "assets/svg/homepage/oval-icon.svg";
import { useWindowWidth } from "utilities/windowWidth";

export const BackerJourneyProcess = () => {
  const windowWidth = useWindowWidth();

  return (
    <section className="backer-journey-container px-5 bg-primary-50">
      {/* <div className="backer-journey-oval ">
        <Circle />
      </div> */}
      <div className="backer-journey-oval  ">
        <img src={Circle} alt="journey-process" />
      </div>
      <h1
        className={`${
          windowWidth <= 768 ? "fs-43 lh-sm" : "fs-59"
        } text-primary-600 fw-600  pt-5"`}
      >
        How Krowdfi Works
      </h1>
      <div className="d-flex justify-content-center align-items-center">
        <BackerProcess />
      </div>
    </section>
  );
};
