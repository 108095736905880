import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { ReactComponent as Logoi } from "assets/svg/homepage/logo-variant-white.svg";

import UserIcon from "assets/svg/profile-circle.svg";
import { Badge, Box, HStack, Icon, IconButton } from "@chakra-ui/react";
import { useGetProfileInformationQuery } from "store/profile";
import { PrimaryButton } from "components/buttons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "store/auth";
import { NotificationComponent } from "./notificationComponent";
import { useModalProvider } from "providers";
import { ReactComponent as HamburgerIcon } from "assets/svg/hamburger.svg";
import { useWindowWidth } from "utilities/windowWidth";
import { useGetActivityQuery } from "store/activityLogs";
import { SearchBar } from "components/custom/searchBar";
import { Link as ScrollLink } from "react-scroll";

interface HeaderComponentProps {
  hamburger?: boolean;
  setHamburger?: React.Dispatch<React.SetStateAction<boolean>>;
  profileInfo?: any;
  error?: any;
  selectedTab?: number;
}

export const HeaderComponent = ({
  hamburger,
  setHamburger,
  selectedTab,
  profileInfo = {},
  error = {},
}: HeaderComponentProps) => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const { user, token: access_token } = useAuth();
  console.log("user", user);
  const { initModal } = useModalProvider();
  const profileImage = user?.image ?? profileInfo?.data?.image ?? UserIcon;
  const profile = profileInfo?.data || user;

  console.log("profile", profile);

  useEffect(() => {
    let err: any = error;

    // if (access_token && err?.data?.statusCode === 403) {
    //   initModal({ sessionExpired: true });
    //   err = null;
    // }
  }, [access_token, error, initModal]);
  const { data: activity, refetch } = useGetActivityQuery((() => {})(), {
    pollingInterval: 30000,
  });

  useEffect(() => {
    if (activity) {
      console.log(
        "ACTIVITYS",
        activity.data.filter((a: any) => a.read === true)
      );
    }
  }, [activity]);

  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const checkScroll = () => {
    const offset = window.scrollY > 760;

    setIsScrolled(offset);
    setIsVisible(!(window.scrollY > 0 && window.scrollY < 760));
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <>
      {location.pathname === "/" ? (
        <>
          {isVisible && (
            <div
              className={`d-flex align-items-center justify-content-between z-3 text-black position-fixed top-0 w-100 py-4 ${
                isScrolled ? "navbar-scrolled w-90" : "bg-transparent"
              } ${windowWidth <= 768 ? "px-3" : "px-5"}`}
            >
              <div>
                <Link to="/">{isScrolled ? <Logo /> : <Logoi />} </Link>
              </div>

              {selectedTab === 0 ? (
                <div>
                  {windowWidth <= 768 ? (
                    <>
                      {/* <IconButton
                      aria-label="Back"
                      icon={<HamburgerIcon />}
                      border={"none"}
                      bg={"transparent"}
                      onClick={(e: React.SyntheticEvent) => {
                        e.stopPropagation();
                        // setHamburger!((prev) => !prev);
                        setIsDropdownOpen(!isDropdownOpen);
                      }}
                      onClick={() => {
                        console.log("Dropdown clicked");
                        setIsDropdownOpen(!isDropdownOpen);
                      }}
                    /> */}
                      {/* {isDropdownOpen && (
                      <div
                        className={`dropdown-menu position-fixed top-0 end-0 h-100 bg-black  transition-all ${
                          isDropdownOpen ? "visible" : "invisible"
                        }`}
                        style={{
                          zIndex: 1000,
                          width: "250px",
                          transform: isDropdownOpen
                            ? "translateX(0)"
                            : "translateX(100%)",
                        }}
                      >
                        <PrimaryButton className="btn-lg primary-btn me-3">
                          <Link to="/register">Get Started</Link>
                        </PrimaryButton>
                        <PrimaryButton
                          className={`px-4 ${
                            isScrolled
                              ? "btn-outline-secondary-scrolled"
                              : "btn-outline-secondary"
                          }`}
                        >
                          <Link to="/login">Log In</Link>
                        </PrimaryButton>
                      </div>
                    )} */}
                    </>
                  ) : (
                    <>
                      <PrimaryButton className="btn-lg primary-btn me-3">
                        <Link to="/register">Get Started</Link>
                      </PrimaryButton>
                      <PrimaryButton
                        className={`px-4 ${
                          isScrolled
                            ? "btn-outline-secondary-scrolled"
                            : "btn-outline-secondary"
                        }`}
                      >
                        <Link to="/login">Log In</Link>
                      </PrimaryButton>
                    </>
                  )}
                </div>
              ) : (
                <div
                  className={`d-flex align-content-center gap-5 fs-20 fw-400 ${
                    isScrolled ? "text-black" : "text-white"
                  }`}
                >
                  {windowWidth <= 768 ? (
                    <>
                      {/* <IconButton
                      aria-label="Back"
                      icon={<HamburgerIcon />}
                      border={"none"}
                      bg={"transparent"}
                      onClick={(e: React.SyntheticEvent) => {
                        e.stopPropagation();
                        setHamburger!((prev) => !prev);
                      }}
                    /> */}
                    </>
                  ) : (
                    <>
                      <p className="m-0 cursor-pointer">
                        <ScrollLink
                          to="how-it-works-section"
                          smooth={true}
                          duration={500}
                        >
                          How it works
                        </ScrollLink>
                      </p>
                      <p className="m-0">
                        <Link to="#">Support</Link>
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <header className="top-site-header bg-white">
          <div className="header-container">
            <div
              className={`d-flex justify-content-between align-items-center ${
                windowWidth <= 768 && "px-2"
              }`}
            >
              <>
                {windowWidth >= 768 && (
                  <Link to="/login">
                    {" "}
                    <Logo className="me-2" />
                  </Link>
                )}
              </>
              <SearchBar
                width={windowWidth >= 768 ? `320px` : `280px`}
                height="33px"
              />
              {/* User profile component */}
              <div
                className={`${
                  windowWidth <= 768 ? "gap-3" : "gap-4"
                } d-flex flex-row align-items-center justify-content-around`}
              >
                {profile ? (
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    {/* <button className="bg-transparent border-0 mb-0 me-2 position-relative touchable" onClick={()=>{
                    console.log("Notification icon clicked");
                    setIsNotification(!isNotification)
                  }}>
                    <NotificationIcon className="h-auto w-18-px" />
                  </button> */}

                    {windowWidth <= 768 && (
                      <IconButton
                        aria-label="Back"
                        icon={<HamburgerIcon />}
                        border={"none"}
                        bg={"transparent"}
                        onClick={(e: React.SyntheticEvent) => {
                          e.stopPropagation();
                          setHamburger!((prev) => !prev);
                        }}
                      />
                    )}

                    <Box position={"relative"} marginRight={"10px"}>
                      <NotificationComponent
                        notifications={activity?.data}
                        refetch={refetch}
                      />
                      {activity &&
                        activity.data.filter((a: any) => a.read === false)
                          .length >= 1 && (
                          <Badge
                            bg="red"
                            position="absolute"
                            top="0.5"
                            right="0.5"
                            rounded={"full"}
                            width={"9px"}
                            height={"9px"}
                          ></Badge>
                        )}
                    </Box>

                    <Link
                      to={
                        (profile?.role === "backer" &&
                          profile?.profileCompleted?.completionRate < 67) ||
                        (profile?.role === "exporter" &&
                          profile?.profileCompleted?.completionRate < 88)
                          ? `/${profile?.role}/profile/create`
                          : `/${profile?.role}/profile`
                      }
                      className="text-decoration-none"
                    >
                      <HStack
                        display={{ base: "flex" }}
                        alignItems="center"
                        spacing="8px"
                        // role="button"
                        // onClick={() => navigate(`/${profile?.role}/profile`)}
                      >
                        {/* <div className="auth-user"> */}
                        <div
                          className={`${
                            windowWidth <= 768
                              ? "border-start ps-2 ms-2"
                              : "border-0"
                          } user-icon`}
                        >
                          <img
                            src={profileImage}
                            alt={"Avatar"}
                            className="rounded rounded-circle"
                            style={{
                              width: windowWidth <= 768 ? "26px" : "48px",
                              height: windowWidth <= 768 ? "26px" : "48px",
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                          />
                        </div>
                        <div className={`mb-0 me-3 d-none d-md-block`}>
                          <div className="text-grey-900 fw-400">
                            {profile?.firstName} {profile?.lastName}
                          </div>
                          <small className="text-grey-400 text-capitalize">
                            {profile?.role}
                          </small>
                        </div>
                        {/* </div> */}
                      </HStack>
                    </Link>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center gap-3">
                    <Link to="/login" className="secondary-btn btn-md">
                      Log In
                    </Link>
                    <Link to="/register" className="primary-btn btn-md">
                      Register
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};
