import { FC, useState } from "react";
import { ReactComponent as LikeIcon } from "assets/svg/like.svg";
import { ReactComponent as CommentIcon } from "assets/svg/comment.svg";
import { ReactComponent as ReplyIcon } from "assets/svg/reply-comment.svg";
import ProfileIcon from "assets/svg/profile-circle.svg";
import {
  AllCommentsResponse,
  useAddCommentMutation,
  useAddReplyMutation,
  useLikeCommentMutation,
} from "store/comments";
import moment from "moment";
import { CustomQuillTextarea } from "components/custom";
import { PrimaryButton } from "components/buttons";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities/errorHandling";
import { useAuth } from "store/auth";
import { CampaignData } from "store/campaigns";

interface BackerCommentsProps {
  campaignData: CampaignData | undefined;
  comments: AllCommentsResponse | undefined;
  refetch: any;
}

export const BackerComments: FC<BackerCommentsProps> = ({ campaignData, comments, refetch }) => {
  console.log("campaignData", campaignData);
  console.log("comments", comments);
  const [addReply, { isLoading: isReplyLoading }] = useAddReplyMutation();
  const [postComment, { isLoading }] = useAddCommentMutation();
  const [likeComment] = useLikeCommentMutation();
  // const [unlikeComment] = useUnLikeCommentMutation();
  const [text, setText] = useState(" ");
  const [replyText, setReplyText] = useState(" ");

  const { initNotification } = usePageNotificationProvider();
  const [replyToCommentId, setReplyToCommentId] = useState<string | null>(null);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showReplies, setShowReplies] = useState<string | null>(null);

  const { user } = useAuth();
  const userId = user?.firstName;
  console.log("userid", user);
  const handleReplyComment = async () => {
    if (!replyText.trim()) {
      initNotification({
        message: "Comment cannot be empty!",
      });
      return;
    }
    if (replyText && replyToCommentId) {
      console.log(replyText);

      const payload = {
        text: replyText,
        commentId: replyToCommentId,
      };
      console.log(payload);
      addReply(payload)
        .unwrap()
        .then((res: any) => {
          console.log(res);
          if (res.error) {
            initNotification({
              message: res.message,
              scheme: "error",
            });
          } else if (res) {
            initNotification({
              message: "Reply sent!",
            });
            refetch();
          }
        })
        .catch((error: any) => {
          console.log(error);
          initNotification({
            message: resolveApiError(error),
            scheme: "error",
          });
        });
      setReplyToCommentId(null);
      setReplyText(" ");
    }
  };

  const handlePostComment = async () => {
    if (!text.trim()) {
      initNotification({
        message: "Comment cannot be empty!",
      });
      return;
    }
    if (text && campaignData?._id) {
      console.log(text);

      const payload = {
        text: text,
        campaignId: campaignData._id,
      };
      postComment(payload)
        .unwrap()
        .then((res: any) => {
          console.log(res);
          if (res.error) {
            initNotification({
              message: res.message,
              scheme: "error",
            });
          } else if (res) {
            initNotification({
              message: "Comment added successfully!",
            });
            setText("");
            setShowCommentBox(false);
            refetch();
          }
        })
        .catch((error: any) => {
          console.log(error);
          initNotification({
            message: resolveApiError(error),
            scheme: "error",
          });
        });
    }
  };

  const handleLikeComment = (commentId: string) => {
    const payload = {
      commentId: commentId,
    };

    likeComment(payload)
      .unwrap()
      .then((response: any) => {
        console.log(response);

        if (response.error) {
          initNotification({
            message: response.error.message,
            scheme: "error",
          });
        } else {
          // initNotification({
          //   message: "Comment liked successfully!",
          // });
          refetch();
        }
      })
      .catch((error: any) => {
        console.error(error);
        initNotification({
          message: resolveApiError(error),
          scheme: "error",
        });
      });
  };

  return (
    <section className='row justify-content-between bg-transparent pb-5'>
      <div className='col-lg-8'>
        {comments && comments?.data?.length ? (
          [...comments!.data]
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .map((comment) => (
              <div key={comment._id}>
                <div className='bg-white rounded-3 shadow-sm p-4 mt-3'>
                  <div className='d-flex gap-3 pb-4'>
                    <div>
                      <img
                        src={comment.authorDetails.image || ProfileIcon}
                        alt={"User Avatar item"}
                        className='rounded rounded-circle'
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = ProfileIcon;
                        }}
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                      />
                    </div>
                    <div>
                      <p className='m-0 pb-2'>
                        {comment.authorDetails.firstName} {comment.authorDetails.lastName}
                      </p>
                      <p className='text-grey-500 fw-400'>{moment(comment.createdAt).fromNow()}</p>
                    </div>
                    <div>
                      {userId === comment.authorDetails.firstName && (
                        <p className='bg-warning-50 text-warning-700 fw-500 fs-12 text-capitalize rounded-5 p-2'>
                          {user?.role}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='d-flex flex-row align-items-center pb-0 gap-2 mb-4'>
                    <p
                      className={`text-grey-500 fw-400 mb-0`}
                      dangerouslySetInnerHTML={{
                        __html: comment.moderated
                          ? `<p class='mb-0'>${comment.moderationComment!}</p>`
                          : comment.text,
                      }}
                    />
                    {comment.moderated === true && (
                      <span
                        className='fs-12 bg-error-50 text-error-700 fw-500 rounded-pill'
                        style={{ paddingBlock: "2px", paddingInline: "8px", height: "fit-content" }}
                      >
                        Moderated
                      </span>
                    )}
                  </div>

                  <div>
                    <div className='d-flex gap-5 '>
                      <div className='d-flex gap-3 py-3'>
                        <div
                          onClick={() => handleLikeComment(comment._id)}
                          className='d-flex gap-2 align-items-center cursor-pointer'
                        >
                          <LikeIcon />
                          <p className='m-0 text-grey-500 '>
                            {comment.likeCount} {comment.likeCount === 1 ? " Like" : " Likes"}
                          </p>
                        </div>

                        <div
                          onClick={() => setShowReplies(showReplies === comment._id ? null : comment._id)}
                          className='d-flex gap-2 align-items-center cursor-pointer'
                        >
                          <CommentIcon />
                          <p className='m-0 text-grey-500'>
                            {`${comment.replies.length} ${
                              comment.replies.length === 1 ? "Comment" : "Comments"
                            }`}
                          </p>
                        </div>

                        {/* <div
                          onClick={() => setReplyToCommentId(comment._id)}
                          className="d-flex gap-2 align-items-center cursor-pointer"
                        >
                          <ReplyIcon />
                          <p className="m-0 text-grey-500">Reply Comment</p>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* displays when a reply button is clicked */}
                  {replyToCommentId === comment._id && (
                    <div className='border rounded-3 ps-4 pt-3 mt-3 shadow-sm'>
                      <div>
                        <CustomQuillTextarea
                          placeholder='Write a comment...'
                          onChange={(val) => setReplyText(val)}
                          minHeight={"80px"}
                        />
                      </div>

                      <div className='d-flex justify-content-end align-items-center border-top mx-3 py-3'>
                        <PrimaryButton
                          type='submit'
                          onClick={handleReplyComment}
                          isLoading={isReplyLoading}
                          className='btn-lg primary-btn'
                        >
                          Reply Comment
                        </PrimaryButton>
                      </div>
                    </div>
                  )}
                </div>

                {/* this displays the replies to a comment */}
                <div className='d-flex flex-column align-items-end  gap-1  justify-content-end'>
                  {showReplies === comment._id &&
                    [...comment.replies]
                      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                      .map((reply) => (
                        <div key={reply._id} className='bg-white rounded-3 w-90  shadow-sm p-3 mt-3'>
                          <div className='d-flex gap-3 pb-4'>
                            <div>
                              <img
                                src={reply.authorDetails.image || ProfileIcon}
                                alt={"User Avatar item"}
                                className='rounded rounded-circle'
                                onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src = ProfileIcon;
                                }}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                }}
                              />
                            </div>
                            <div>
                              <p className='m-0 pb-2'>
                                {reply.authorDetails.firstName} {reply.authorDetails.lastName}
                              </p>
                              <p className='text-grey-500 fw-400'>{moment(reply.createdAt).fromNow()}</p>
                            </div>
                            <div>
                              {userId === reply.authorDetails.firstName && (
                                <p className='bg-warning-50 text-warning-700 fw-500 fs-12 text-capitalize rounded-5 p-2'>
                                  {user?.role}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className='d-flex flex-row pb-0 gap-2 mb-4'>
                            <p
                              className={`text-grey-500 fw-400 mb-0`}
                              dangerouslySetInnerHTML={{
                                __html: reply.moderated
                                  ? `<p class='mb-0'>${reply.moderationComment}</p>`
                                  : reply.text,
                              }}
                            />
                            {reply.moderated === true && (
                              <span
                                className='fs-12 bg-error-50 text-error-700 fw-500 rounded-pill'
                                style={{ paddingBlock: "2px", paddingInline: "8px", height: "fit-content" }}
                              >
                                Moderated
                              </span>
                            )}
                          </div>

                          <div>
                            <div className='d-flex gap-5 '>
                              <div className='d-flex gap-3 py-3'>
                                <div
                                  onClick={() => handleLikeComment(reply._id)}
                                  className='d-flex gap-2 align-items-center cursor-pointer'
                                >
                                  <LikeIcon />
                                  <p className='m-0 text-grey-500'>
                                    {reply.likeCount} {reply.likeCount === 1 ? " Like" : " Likes"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            ))
        ) : (
          <div>
            <div className='d-flex flex-column justify-content-center align-items-center mt-5 '>
              <p>No comments yet.</p>

              {/* <PrimaryButton onClick={() => setShowCommentBox(true)} className='btn primary-btn'>
                Add Comment
              </PrimaryButton> */}
            </div>

            {showCommentBox && (
              <div className='border  rounded-3 ps-4 pt-3 mt-3 shadow-sm'>
                <div>
                  <CustomQuillTextarea
                    placeholder='Write a comment...'
                    onChange={(val) => setText(val)}
                    minHeight={"80px"}
                  />
                </div>

                <div className='d-flex justify-content-end align-items-center border-top mx-3 py-3'>
                  <PrimaryButton
                    type='submit'
                    onClick={handlePostComment}
                    isLoading={isLoading}
                    className='btn-lg primary-btn'
                  >
                    Post Comment
                  </PrimaryButton>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {comments && comments?.data?.length && (
        <div className='col-lg-4 mt-3'>
          <PrimaryButton className='btn primary-btn' onClick={() => setShowCommentBox(!showCommentBox)}>
            Post a Comment
          </PrimaryButton>

          {showCommentBox && (
            <div className='border rounded-3 ps-4 pt-3 mt-3 shadow-sm'>
              <div>
                <CustomQuillTextarea
                  placeholder='Write a comment...'
                  onChange={(val) => setText(val)}
                  minHeight={"80px"}
                />
              </div>

              <div className='d-flex justify-content-end align-items-center border-top mx-3 py-3 '>
                <PrimaryButton
                  type='submit'
                  onClick={handlePostComment}
                  isLoading={isLoading}
                  className='btn-lg primary-btn'
                >
                  Post Comment
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};
