import {
  ChakraAlertDialog,
  PrimaryMultiSelect,
  PrimarySelect,
} from "components";
import { FC } from "react";
import { useFormik } from "formik";
import { useModalProvider } from "providers/modalProvider";
import { useChangeProfileMutation } from "store/profile";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities";
import { ChangeProfileInfoValidationSchema } from "validations/profile";
interface BusinessInformationChangeModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const BusinessInformationChangeModal: FC<
  BusinessInformationChangeModalProps
> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  const [request, { isLoading }] = useChangeProfileMutation();
  const { initNotification } = usePageNotificationProvider();
  const initialValues = {
    request: [] as string[],
    reason: "" as string,
  };

  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ChangeProfileInfoValidationSchema,
    onSubmit: () => changeProfile(),
  });

  const changeProfile = () => {
    const payload = {
      request: values.request.join(","),
      reason: values.reason,
    };
    console.log("before", payload);
    request(payload)
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });

          return;
        }
        console.log("after", payload);
        initNotification({
          message: "Success",
        });

        initModal((prev) => ({
          ...prev,
          editPersonalDetails: false,
          successModal: true,
        }));
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  const options = [
    { label: "Business Name", value: "businessName" },
    { label: "TIN", value: "TIN" },
    { label: "CAC", value: "CACRegNo" },
    { label: "ERC", value: "exportCertificate" },
  ];
  return (
    <>
      <ChakraAlertDialog
        // isOpen={isOpen}
        useFooter={true}
        useCancelButton={true}
        useProceedButton={true}
        useHeader={true}
        useHeaderCancel={false}
        cancelButtonDefaultChild={"Cancel"}
        proceedButtonDefaultChild={"Submit"}
        title={"Request Business Information Change"}
        size={"md"}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        onClose={() => {
          initModal((prev) => ({ ...prev, editBusinessInformation: false }));
        }}
        cancelButtonProps={{
          className: "btn-lg ms-0 px-5 w-50 bg-white border-primary-50",
          onClick: () => {
            initModal((prev) => ({ ...prev, editBusinessInformation: false }));
          },
        }}
        proceedButtonProps={{
          className: "btn-lg primary-btn ms-0 px-5 w-50",
          onClick: changeProfile,
        }}
        headerProps={{ className: "px-0 pt-0" }}
        useFloatingCancel={false}
        modalFooterProps={{
          className:
            "d-flex flex-row justify-content-between gap-5  w-100 px-0 mt-2",
        }}
        closeOnOverlayClick={true}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center gap-4">
            <p className="fs-16 lh-base text-grey-600 fw-400 m-0 w-100 text-start mb-1">
              To continue, please select your request and reason below
            </p>
            <form className="col-md-12 mb-2 w-100">
              <div className=" mb-4">
                <PrimaryMultiSelect
                  name="request"
                  label="Request"
                  error={Boolean(errors?.request && touched?.request)}
                  // bottomText={errors?.request || ""}
                  isMulti={true}
                  options={options}
                  onMultiChange={(value) => {
                    setValues((prev) => {
                      return {
                        ...prev,
                        request: value.map((val) => val.value),
                      };
                    });
                  }}
                  value={options.filter((option) =>
                    values.request?.includes(option.value)
                  )}
                />
              </div>
              <div className=" mb-4">
                <PrimarySelect
                  name="reason"
                  label="Reason for Change "
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.reason}
                  error={Boolean(errors?.reason && touched?.reason)}
                  bottomText={errors?.reason || ""}
                  // bg={windowWidth <= 768 ? "white" : ""}
                >
                  <option hidden>Select </option>
                  <option value="legal change">Legal Change</option>
                  <option value="error during registration">
                    Error during registration
                  </option>
                </PrimarySelect>
              </div>
            </form>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
