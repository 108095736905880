/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { ReactComponent as MailIcon } from "assets/svg/mail-icon.svg";
import queryString from "query-string";
import { VerifyEmailPayload, useVerifyEmailAddressMutation } from "store/auth";
import { usePageNotificationProvider } from "providers";
import { resolveApiError } from "utilities";
import { Link } from "react-router-dom";
import { PrimaryLoader } from "components/loaders";

export const VerifyEmailStatusPage = () => {
  const { key, code } = queryString.parse(window.location.search) as {
    key: string;
    code: string;
  };
  const { initNotification } = usePageNotificationProvider();
  const [request, { isLoading }] = useVerifyEmailAddressMutation();
  const [error, setError] = useState(false);

  const initVerifyEmail = (payload?: VerifyEmailPayload) => {
    request({ email: key, code })
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          setError(res.error);
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "Email verified successfully",
        });
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  React.useEffect(() => {
    if (key && code) {
      initVerifyEmail();
    }
  }, [key, code]);

  return (
    <div
      style={{ minHeight: "100vh", paddingTop: "8%" }}
      className='d-flex flex-column align-items-center bg-grey-25 gap-4'
    >
      {isLoading ? (
        <PrimaryLoader height={"90vh"} />
      ) : (
        <>
          <Link to='/'>
            <Logo />
          </Link>
          <div className='border p-5 bg-grey-25 rounded-3 mt-5 d-flex flex-column align-items-center gap-4 text-center'>
            <MailIcon />

            <h3 className='text-grey-900'>{error ? "An error occured" : "Email verified successfully"}</h3>
            {!error ? (
              <>
                <p className=' text-grey-500 fs-14 lh-base' style={{ maxWidth: "400px" }}>
                  You can now log in to your account.
                </p>
                <Link to='/login' className='primary-btn btn-md text-decoration-none'>
                  Log In
                </Link>
              </>
            ) : (
              <Link to='/login' className='text-primary-500 fw-500'>
                Back to log In
              </Link>
            )}
          </div>
        </>
      )}
    </div>
  );
};
