import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { ReactComponent as VerifiedIcon } from "assets/svg/verified-icon.svg"; // import { useNavigate } from "react-router";
import { useModalProvider } from "providers/modalProvider";
import { useAuth } from "store/auth";

interface VerifyDocumentUploadSuccessModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const VerifyDocumentUploadSuccessModal: FC<
  VerifyDocumentUploadSuccessModalProps
> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { user } = useAuth();
  console.log(user);
  const { initModal } = useModalProvider();

  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useFloatingCancel={false}
        useHeaderCancel={true}
        useCancelButton={false}
        onClose={() => {
          initModal((prev) => ({
            ...prev,
            verifyDocumentUploadSuccess: false,
          }));
          window.location.replace(`/${user?.role}/settings?active=2`);
        }}
        useProceedButton={false}
        // proceedButtonDefaultChild={"Save to Draft"}
        // proceedButtonProps={{
        //   className: "btn-lg primary-btn ms-0 w-100  mx-4",
        // }}
        // cancelButtonDefaultChild={"Cancel and Discard"}
        // cancelButtonProps={{
        //   className: "btn-lg border ms-0 primary-error-btn w-100 text-white",
        //   onClick: handleCancel,
        // }}
        useHeader={true}
        headerProps={{ className: "px-0 pt-0" }}
        // useFloatingCancel={false}
        // useHeaderCancel={false}
        modalFooterProps={{
          className: "d-flex flex-row-reverse w-100 pb-0 px-0 mt-2",
        }}
        title={""}
        size={"md"}
        closeOnOverlayClick={true}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="">
          <div className="text-center d-flex flex-column align-items-center">
            <VerifiedIcon className="mb-4" />
            <p className="fw-500 text-grey-900 mb-2">
              Your request has been submitted successfully!
            </p>
          </div>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
