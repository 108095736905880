import { Campaigns } from "./campaigns";
import { AlertDefaultBox } from "components/custom";
import { ReactComponent as WarningIcon } from "assets/svg/warning-icon.svg";
import { ReactComponent as NotificationIcon } from "assets/svg/noti.svg";
import { ReactComponent as FundsIcon } from "assets/svg/funds-icon.svg";
import { ReactComponent as CampaignIcon } from "assets/svg/total-campaign.svg";
import { ReactComponent as TimeIcon } from "assets/svg/time-icon.svg";
import { ReactComponent as OctIcon } from "assets/svg/octicon_tracked-by-closed-completed-24.svg";
import { Link } from "react-router-dom";
import { useAuth } from "store/auth";
import {
  useGetAllCampaignsQuery,
  useGetBackedCampaignsQuery,
} from "store/campaigns";
import { DashboardStats } from "./interface";
import { useWindowWidth } from "utilities/windowWidth";
import { useGetRejectedDocumentsQuery } from "store/verificationCenter";

export const Dashboard = ({ data }: { data: Partial<DashboardStats> }) => {
  const windowWidth = useWindowWidth();
  const { user } = useAuth();
  console.log(user);
  const { data: documents } = useGetRejectedDocumentsQuery((() => {})(), {
    pollingInterval: 30000,
  });

  const showVerificationCenterAlert =
    //  checks for  documents fields under request document change
    documents?.data?.dataRequestChange?.some(
      (request: any) =>
        request.documentsToUpload?.length !==
          request.dataRequestDocument?.uploadedDocumentDetails?.length &&
        !(
          Array.isArray(
            request.dataRequestDocument?.inputFieldsUpdatedByUser
          ) && request.dataRequestDocument?.inputFieldsUpdatedByUser.length > 0
        )
    ) ||
    //  checks for input fields under user profile reapproval
    (documents?.data?.userProfileReapproval?.inputFieldsToUpdate?.length > 0 &&
      (!documents?.data?.userProfileReapproval?.inputFieldsUpdatedByUser ||
        documents?.data?.userProfileReapproval?.inputFieldsUpdatedByUser
          ?.length === 0)) ||
    //  checks for  documents fields under user profile reapproval
    (documents?.data?.userProfileReapproval?.documentsAndReasonsToUpload
      ?.length > 0 &&
      (!documents?.data?.userProfileReapproval?.uploadedDocumentDetails ||
        documents?.data?.userProfileReapproval?.uploadedDocumentDetails
          ?.length === 0)) ||
    //  checks for  documents fields under campaign reapproval
    (documents?.data?.campaignReapproval?.documentsAndReasonsToUpload?.length >
      0 &&
      (!documents?.data?.campaignReapproval?.uploadedDocumentDetails ||
        documents?.data?.campaignReapproval?.uploadedDocumentDetails?.length ===
          0));

  console.log("showVerificationCenterAlert", showVerificationCenterAlert);

  const profileCompleted = user?.profileCompleted?.completionRate;

  const { data: backedCampaigns } = useGetBackedCampaignsQuery(
    {
      skip: "",
      limit: "",
      sort: "",
      backerId: user?._id,
    },
    {
      pollingInterval: 30000,
    }
  );
  console.log(backedCampaigns);
  return (
    <div className="dashboard-wrapper">
      <div>
        <>
          {profileCompleted && profileCompleted < 67 && (
            <div className="row mb-2">
              <div className="col-md-8">
                <AlertDefaultBox
                  type="outline"
                  scheme="warning"
                  icon={<WarningIcon />}
                  body={
                    <span>
                      Your profile is incomplete. Please{" "}
                      <Link
                        className="text-warning-900 fw-bold"
                        to="/backer/profile/create"
                      >
                        Click here
                      </Link>{" "}
                      or go to{" "}
                      <Link
                        className="text-warning-900 fw-bold"
                        to="/backer/profile/create"
                      >
                        Profile page
                      </Link>{" "}
                      to complete your profile
                    </span>
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              <AlertDefaultBox
                className="mt-3"
                type="outline"
                scheme="neutral"
                icon={<NotificationIcon />}
                body={
                  <span>
                    Protect your account. Two-Factor Authentication adds an
                    extra layer of security to your account.{" "}
                    <Link
                      className="text-primary-600 fw-bold"
                      to="/backer/settings"
                    >
                      Click here
                    </Link>{" "}
                    to enable Two-Factor Authentication
                  </span>
                }
              />
            </div>
          </div>
          {showVerificationCenterAlert && (
            <div className="row">
              <div className="col-md-12">
                <AlertDefaultBox
                  className="mt-3"
                  type="outline"
                  scheme="neutral"
                  icon={<NotificationIcon />}
                  body={
                    <span className={windowWidth >= 768 ? "fs-14" : "fs-12"}>
                      You have rejected documents. Go to the{" "}
                      <Link
                        className="text-primary-600 fw-bold"
                        to="/backer/settings?active=2"
                      >
                        Verification Center
                      </Link>{" "}
                      to view and re-upload the required documents.
                    </span>
                  }
                />
              </div>
            </div>
          )}
        </>
      </div>
      <div className="dashboard-statistics">
        {[
          {
            title: "Funds Contributed",
            icon: <FundsIcon />,
            value: data?.fundsContributed ?? "-",
          },
          {
            title: "Campaigns Supported",
            icon: <CampaignIcon />,
            value: data?.numberOfCampaignsSupported ?? "-",
          },
          {
            title: "Exporters Supported",
            icon: <TimeIcon />,
            value: data?.numberOfExportersSupported ?? "-",
          },
          {
            title: "Total Returns",
            icon: <OctIcon />,
            value: data?.totalReturns ?? "-",
          },
        ].map((item, key) => (
          <div className="statistic" key={key}>
            <div className="d-flex flex-row align-items-center justify-content-start gap-2 text-grey-400">
              {item.icon}
              <p className="my-auto">{item.title}</p>
            </div>
            <h3 className="value lh-1 mb-0 fw-600">{item.value}</h3>
          </div>
        ))}
      </div>
      <Campaigns data={backedCampaigns} />
    </div>
  );
};
