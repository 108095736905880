import { PrimaryButton } from "components/buttons";
import { Link } from "react-router-dom";
import { useWindowWidth } from "utilities/windowWidth";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { useEffect, useLayoutEffect, useState } from "react";
import womanSmiling from "assets/images/woman-smiling.png";
import businessManSmiling from "assets/images/happy_businessman.png";
import { ReactComponent as Minutes } from "assets/svg/minutes_.svg";
import { ReactComponent as NoHassle } from "assets/svg/no_hassle_.svg";
import { ReactComponent as ImageSquiggly } from "assets/svg/img_squiggly.svg";
import { ReactComponent as AttentionLine } from "assets/svg/attention_line.svg";
import { ReactComponent as ValidIcon } from "assets/svg/valid-input-icon.svg";
import { ReactComponent as CampaignGoals } from "assets/svg/campaign-goals.svg";
import { ReactComponent as PaperPlane } from "assets/svg/paper_plane.svg";
import { ReactComponent as CampaignInfo1 } from "assets/svg/campaign_info_1.svg";
import { ReactComponent as CampaignInfo2 } from "assets/svg/campaign_info_2.svg";
import { ReactComponent as CampaignInfo3 } from "assets/svg/campaign_info_3.svg";
import { ReactComponent as CampaignInfo4 } from "assets/svg/campaign_info_4.svg";
import { ReactComponent as CampaignConnector1 } from "assets/svg/info_connector_1.svg";
import { ReactComponent as CampaignConnector2 } from "assets/svg/info_connector_2.svg";
import { ReactComponent as CampaignConnector3 } from "assets/svg/info_connector_3.svg";
import { ReactComponent as Mic } from "assets/svg/mic.svg";
import { ReactComponent as AllSet } from "assets/svg/all-set.svg";
import gsap from "gsap";

import { Image } from "@chakra-ui/react";
import { FooterComponent } from "../footerComponent";
import { BackerTradeSuccess } from "../backersView";
import { FaqSection } from "./faqSection";
import { exporterFaqs } from "utilities/faqQuestions";
import numeral from "numeral";

export const CampaignCreation = () => {
  const totalFundingGoal = "12429292";
  const [fundingGoal, setFundingGoal] = useState(totalFundingGoal.split("").map(Number));
  const windowWidth = useWindowWidth();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isFundCardHovered, setIsFundCardHovered] = useState(false);
  const [isCamapignCardHovered, setIsCampaignCardHovered] = useState(false);
  const [isMouseOverPaperPlane, setIsMouseOverPaperPlane] = useState(false);
  const fund_cards = ["first_fund_card", "second_fund_card", "third_fund_card"];
  const campaign_cards = [
    {
      title: "Campaign Submitted",
      subtitle: "Waiting for approval",
      className: "first_campaign_card",
      icon: <Mic style={{ minWidth: "32px" }} />,
    },
    {
      title: "Campaign Submitted",
      subtitle: "Waiting for approval",
      className: "second_campaign_card",
      icon: <Mic style={{ minWidth: "32px" }} />,
    },
    {
      title: "Campaign Submitted",
      subtitle: "Waiting for approval",
      className: "third_campaign_card",
      icon: <Mic style={{ minWidth: "32px" }} />,
    },
    {
      title: "You’re All Set!",
      subtitle: "Campaign Approved",
      className: "fourth_campaign_card",
      icon: <AllSet style={{ minWidth: "32px" }} />,
    },
    {
      title: "Campaign Submitted",
      subtitle: "Waiting for approval",
      className: "fifth_campaign_card",
      icon: <Mic style={{ minWidth: "32px" }} />,
    },
  ];

  function fundingGoalAnimation() {
    const targetFundingGoal = totalFundingGoal.split("").map(Number);
    let currentIndex = totalFundingGoal.length - 1;
    const intervalId = setInterval(() => {
      setFundingGoal((prev) => {
        const newFundingGoal = [...prev];
        if (newFundingGoal[currentIndex] <= targetFundingGoal[currentIndex] + 1) {
          newFundingGoal[currentIndex] += 1;
        }
        if (newFundingGoal[currentIndex] === targetFundingGoal[currentIndex] + 1) {
          currentIndex--;
          if (currentIndex < 0) {
            clearInterval(intervalId);
          }
        }
        return newFundingGoal;
      });
    }, 100);
  }

  const checkScroll = () => {
    const offset = window.scrollY > 760;
    setIsScrolled(offset);
  };

  useLayoutEffect(() => {
    if (isMouseOverPaperPlane === true) {
      gsap.fromTo(
        ".paper_plane",
        {
          x: -300,
          y: 150,
          opacity: 0,
          duration: 1,
        },
        {
          x: 0,
          y: 0,
          opacity: 1,
          duration: 1,
        }
      );
    }
  }, [isMouseOverPaperPlane]);

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <section className='bg-grey-50'>
      <div
        className={`bg-primary-25 hero-section ${windowWidth >= 768 && "px-5"}`}
        style={{ height: windowWidth >= 768 ? "95vh" : "auto", minHeight: "105vh" }}
      >
        {/* Header */}
        <div
          className={`d-flex align-items-center justify-content-between z-3 text-black position-fixed top-0 w-98 mx-auto py-4 ${
            isScrolled ? "navbar-scrolled w-90" : "bg-transparent"
          } ${windowWidth <= 768 ? "px-3" : "px-5"}`}
        >
          <Link to='/'>
            <Logo />
          </Link>
          <div>
            {windowWidth >= 768 && (
              <PrimaryButton className='btn-lg primary-btn me-3'>
                <Link to='/register'>Get Started</Link>
              </PrimaryButton>
            )}
            <PrimaryButton className={`btn-lg secondary-btn`}>
              <Link to='/login'>Log In</Link>
            </PrimaryButton>
          </div>
        </div>
        {/* End of Header  */}

        {/* Hero Section */}
        <div
          className={`d-flex ${
            windowWidth >= 768 ? "flex-row w-94" : "flex-column w-100 gap-5"
          } align-items-center justify-content-between`}
        >
          <div
            className={`d-flex flex-column align-items-start gap-5 ${
              windowWidth >= 768 ? "w-672-px" : "w-90"
            }`}
          >
            <div className='d-flex flex-column gap-4 mb-0'>
              <h2
                className={`${
                  windowWidth <= 768 ? "fs-39 w-100" : "fs-59"
                } fw-600 text-grey-900 d-flex flex-column mb-0 lh-3`}
                style={{ textAlign: "left" }}
              >
                <span className={`mb-0 ${windowWidth <= 768 && "text-center"}`}>Kickstart Your Funding</span>
                <div className={`d-flex ${windowWidth >= 965 ? "flex-row gap-3" : "flex-column"} mb-0`}>
                  <span className={`mb-0 ${windowWidth <= 768 && "text-center"}`}>Campaign in</span>
                  {windowWidth >= 768 ? (
                    <span
                      className={`mb-0 underline_content ${windowWidth <= 768 && "mx-auto"}`}
                      style={{ width: "fit-content" }}
                    >
                      <span className='mb-0 underline_content'>Minutes,</span>
                      <Minutes className='underline_svg' />
                    </span>
                  ) : (
                    <span className={`mb-0 ${windowWidth <= 768 && "text-center"}`}>Minutes,</span>
                  )}
                </div>
                <div
                  className={`mb-0 underline_content ${windowWidth <= 768 && "mx-auto"}`}
                  style={{ width: "fit-content" }}
                >
                  <span className='mb-0'>No Hassle!</span>
                  <NoHassle className='underline_svg' />
                </div>
              </h2>
              <p
                className={`${windowWidth >= 768 ? "w-80" : ""} fs-16 mb-0 lh-base`}
                style={{ textAlign: windowWidth >= 768 ? "left" : "center" }}
              >
                We offer a seamless solution for funding your export ventures, ensuring that you have the
                resources you need to thrive on a global scale.
              </p>
            </div>
            <PrimaryButton className={`btn-lg primary-btn w-fit ${windowWidth <= 768 && "mx-auto"}`}>
              <Link to='/register'>Get Started Now</Link>
            </PrimaryButton>
          </div>
          <div
            className={`bg-warning-200 ps-2 rounded-5 ${
              windowWidth >= 768 ? "w-525-px" : "w-320-px"
            } underline_content`}
          >
            <Image src={womanSmiling} alt='Smiling woman' className='w-100' />
            <ImageSquiggly className='img_underline_svg' />
          </div>
        </div>
        {/* End of Hero Section */}
      </div>

      {/* Info Section  */}
      <section
        className={`text-grey-50 ${windowWidth >= 992 ? "w-90 py-5" : "w-85 py-3"} mx-auto bg-grey-50`}
      >
        <h1
          className={`fw-600 text-grey-900 w-65 text-left lh-base ${
            windowWidth <= 768 ? "fs-32 w-100" : "fs-47 py-5"
          } my-5`}
        >
          With Krowdfi, launching your funding campaign is a breeze.
        </h1>
        <div className='d-flex flex-column gap-4 mx-auto mb-5'>
          <div
            className={`d-flex ${
              windowWidth >= 992 ? "flex-row" : "flex-column"
            } justify-content-center gap-4 mx-auto w-100`}
          >
            <div
              className={`campaign_info_box d-flex flex-column justify-content-between rounded-3 bg-white position-relative ${
                windowWidth >= 992 ? "w-55" : "w-100 mx-auto"
              } shadow-sm`}
              style={{ padding: "32px", height: "446px" }}
            >
              <div
                className={`campaign_info_group d-flex flex-row justify-content-center position-relative`}
                style={{
                  height: windowWidth >= 768 ? "210px" : "auto",
                  transform: windowWidth <= 768 ? "scale(0.7)" : "",
                }}
              >
                <div
                  className='border border-primary-100 rounded-circle p-4 d-flex justify-content-center align-items-center position-relative top-70 campaign_info_card cursor-pointer'
                  style={{
                    width: "125px",
                    height: "125px",
                  }}
                >
                  <CampaignInfo1 />
                </div>
                <CampaignConnector1 className='position-relative top-60' />
                <div
                  className='border border-primary-100 rounded-circle p-4 d-flex justify-content-center align-items-center position-relative top-10 campaign_info_card cursor-pointer'
                  style={{
                    width: "115px",
                    height: "115px",
                  }}
                >
                  <CampaignInfo2 />
                </div>
                <CampaignConnector2 className='position-relative top-55' />
                <div
                  className='border border-primary-100 rounded-circle p-4 d-flex justify-content-center align-items-center position-relative top-30 campaign_info_card cursor-pointer'
                  style={{
                    width: "130px",
                    height: "130px",
                  }}
                >
                  <CampaignInfo3 />
                </div>
                <CampaignConnector3 className='position-relative top-40' />
                <div
                  className='border border-primary-100 rounded-circle p-4 d-flex justify-content-center align-items-center position-relative top-48 campaign_info_card cursor-pointer'
                  style={{
                    width: "95px",
                    height: "95px",
                  }}
                >
                  <CampaignInfo4 />
                </div>
              </div>
              <div className={windowWidth >= 768 ? "w-75" : "w-100"}>
                <h2 className='text-grey-900 fs-24 fw-600'>Provide Campaign Information</h2>
                <p className='text-grey-700 fs-16 lh-base'>
                  Tell us about your export venture! Share details about your products or services, ensuring
                  that potential backers have a clear understanding of your export business.
                </p>
              </div>
            </div>

            <div
              className={`funding_goal_card p-4 rounded-3 bg-white ${
                windowWidth >= 992 ? "w-40" : "w-100 mx-auto"
              } shadow-sm d-flex flex-column justify-content-between gap-5 position-relative`}
              onMouseEnter={() => {
                setFundingGoal(Array(totalFundingGoal.length).fill(0));
                setIsFundCardHovered(true);
                fundingGoalAnimation();
              }}
              onMouseLeave={() => {
                setIsFundCardHovered(false);
              }}
            >
              <div className='position-relative fund_card_group'>
                {fund_cards.map((card, index) => (
                  <div
                    className={`fund_card border-1 border-primary-100 d-flex flex-row align-items-center justify-content-center px-5 py-4 gap-4 rounded-3 mx-auto ${card} shadow cursor-pointer ${
                      isFundCardHovered && "fund_card_hovered"
                    }`}
                    style={{ minWidth: "70%" }}
                    key={index}
                  >
                    <div>
                      <CampaignGoals style={{ minWidth: "38px" }} />
                    </div>
                    <div className='d-flex flex-column mb-0'>
                      <h2 className='fs-20 fw-600 text-grey-800'>
                        {numeral(fundingGoal.join("")).format("00000000,0.00")} NGN
                      </h2>
                      <p className={`${windowWidth >= 768 ? "fs-14" : "fs-12"} text-grey-600 mb-0`}>
                        Your Funding Goal
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className={windowWidth >= 768 ? "w-75" : "w-100"}>
                <h2 className='text-grey-900 fs-24 fw-600 mb-3'>Set Funding Goals and Terms</h2>
                <p className='text-grey-700 fs-16 lh-base'>
                  Set clear terms for your campaign. With Krowdfi, you have the flexibility to set funding
                  goals that align with your export ambitions.
                </p>
              </div>
            </div>
          </div>

          <div
            className={`d-flex ${
              windowWidth >= 992 ? "flex-row" : "flex-column"
            } justify-content-center gap-4 mx-auto w-100`}
          >
            <div
              className={`campaign_approval_card d-flex flex-column justify-content-between rounded-3 bg-white ${
                windowWidth >= 992 ? "w-40" : "w-100 mx-auto"
              } shadow-sm`}
              style={{ padding: "32px", height: "446px" }}
            >
              <div className='position-relative campaign_card_group'>
                {campaign_cards.map((card, index) => (
                  <div
                    className={`campaign_card border-1 border-primary-100 d-flex flex-row align-items-center ${
                      windowWidth >= 768 ? "justify-content-start px-5" : "justify-content-center px-3"
                    } py-4 gap-4 rounded-3 mx-auto ${card.className} shadow cursor-pointer ${
                      isCamapignCardHovered && "campaign_card_hovered"
                    }`}
                    style={{ width: "100%", height: "105px" }}
                    key={index}
                  >
                    <div>{card.icon}</div>
                    <div className='d-flex flex-column mb-0'>
                      <h2 className='fs-17 fw-600 text-grey-800'>{card.title}</h2>
                      <p className={`${windowWidth >= 768 ? "fs-14" : "fs-12"} text-grey-600 mb-0`}>
                        {card.subtitle}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className={windowWidth >= 768 ? "w-75" : "w-100"}>
                <h2 className='text-grey-900 fs-24 fw-600 mb-3'>Get Campaign Approval</h2>
                <p className='text-grey-700 fs-16 lh-base mb-0'>
                  Once you've provided all the necessary information, our team will review your campaign to
                  ensure it meets our guidelines and standards.
                </p>
              </div>
            </div>

            <div
              className={`d-flex flex-column justify-content-between rounded-3 bg-white ${
                windowWidth >= 992 ? "w-55" : "w-100 mx-auto"
              } shadow-sm`}
              style={{ height: "446px" }}
              onMouseEnter={() => setIsMouseOverPaperPlane(true)}
              onMouseLeave={() => setIsMouseOverPaperPlane(false)}
            >
              <div className='paper_plane_wrapper d-flex justify-content-center align-items-center h-280-px'>
                <PaperPlane className='paper_plane cursor-pointer' />
              </div>
              <div className={windowWidth >= 768 ? "w-75" : "w-100"} style={{ padding: "32px" }}>
                <h2 className='text-grey-900 fs-24 fw-600 mb-3'>Showcase Your Export Business</h2>
                <p className='text-grey-700 fs-16 lh-base mb-0'>
                  Once approved, your campaign will be ready to go live. Get ready to shine and attract
                  backers eager to support your export journey. Showcase what makes your business special to
                  the world!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Info Section  */}

      {/* Attention Section  */}
      <section className='bg-primary-25 w-100 py-5'>
        <div
          className={`d-flex ${
            windowWidth >= 768 ? "flex-row w-80" : "flex-column w-100"
          } align-items-center justify-content-center mx-auto py-5 my-5`}
          style={{ gap: windowWidth >= 768 ? "86px" : "48px" }}
        >
          <div className={`bg-primary-50 ps-2 pb-2 rounded-5 ${windowWidth >= 768 ? "w-540-px" : "w-70"}`}>
            <Image src={businessManSmiling} alt='Businessman smiling' className='w-100' />
          </div>
          <div
            className={`${windowWidth >= 768 ? "w-50" : "w-80"} d-flex flex-column align-items-start`}
            style={{ gap: "32px" }}
          >
            <div className={`d-flex flex-column gap-3 ${windowWidth <= 768 && "w-100"}`}>
              <h2
                className={`${
                  windowWidth <= 768 ? "fs-39 w-100 text-center" : "fs-59 text-left"
                } fw-600 text-grey-900 d-flex flex-column`}
              >
                <div className='mb-0'>
                  <span
                    className={
                      windowWidth >= 768 ? "" : "d-flex justify-content-center align-items-center gap-2"
                    }
                  >
                    <span>Attract </span>
                    <span className='underline_content'>
                      <span>Attention</span>
                      <AttentionLine className='underline_svg' />
                    </span>
                    <span> to</span>
                  </span>
                </div>
                <span className={`mb-0 ${windowWidth <= 768 && "text-center"}`}>Your Export Business</span>
              </h2>
              <p className='text-left fs-16 mb-0 text-grey-700' style={{ textAlign: "left" }}>
                Here’s why we’re an ideal choice!
              </p>
            </div>
            <div className='d-flex flex-column gap-3 text-grey-700 mb-0'>
              <div className='d-flex flex-row gap-2 align-items-center mb-0 fs-16'>
                <ValidIcon />
                <span>Global Reach</span>
              </div>
              <div className='d-flex flex-row gap-2 align-items-center mb-0 fs-16'>
                <ValidIcon />
                <span>Seamless Funding Process</span>
              </div>
              <div className='d-flex flex-row gap-2 align-items-center mb-0 fs-16'>
                <ValidIcon />
                <span>Comprehensive Support</span>
              </div>
              <div className='d-flex flex-row gap-2 align-items-center mb-0 fs-16'>
                <ValidIcon />
                <span>Transparent Process</span>
              </div>
              <div className='d-flex flex-row gap-2 align-items-center mb-0 fs-16'>
                <ValidIcon />
                <span>Commitment to Success</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Attention Section  */}

      <FaqSection faqs={exporterFaqs} />
      <BackerTradeSuccess
        mainText='Ready to Revolutionize Your Export Journey?'
        buttonText='Start Your Krowdfi Journey'
        bgType='backer-tractor-bg'
      />

      {/*========== FOOTER =========== */}
      <FooterComponent />
      {/*========== END OF FOOTER =========== */}
    </section>
  );
};
