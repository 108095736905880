import { ReactComponent as DocsIcon } from "assets/svg/docs.svg";
import { ReactComponent as LikeIcon } from "assets/svg/like.svg";
import { PrimaryButton } from "components/buttons";
import { CustomQuillTextarea, SimpleFileUploadComponent } from "components/custom";
import { useWindowWidth } from "utilities/windowWidth";
import { FC, useState, useEffect } from "react";
import { CampaignData } from "store/campaigns";
import { useAddPostMutation, useFileUploadMutation, useLikeUpdateMutation } from "store/campaignUpdates";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities/errorHandling";
import moment from "moment";
import ProfileIcon from "assets/svg/profile-circle.svg";
import FilePreviewDialog from "components/custom/filePreview";
import { useAuth } from "store/auth";
import { SimpleFileUploadComponentButton } from "components/custom/simpleFileUploadComponentButton";

interface CampaignProps {
  campaignData: CampaignData | undefined;
  updates: any;
  refetch: any;
}
export const PostUpdates: FC<CampaignProps> = ({ campaignData, updates, refetch }) => {
  const [dialogState, setDialogState] = useState({
    billOfLaden: false,
    formQ: false,
  });
  const [latestBOL, setLatestBOL] = useState<any>(false);
  const [latestFQ, setLatestFQ] = useState<any>(false);
  console.log("Campaign Data: ", campaignData);
  console.log("Updates: ", updates);
  const windowWidth = useWindowWidth();
  const [request, { isLoading }] = useAddPostMutation();
  const [likeUpdateRequest, { isLoading: isLikeLoading }] = useLikeUpdateMutation();
  const [text, setText] = useState("");
  const { initNotification } = usePageNotificationProvider();
  const { user } = useAuth();
  const profileImage = user?.image;
  const userId = user?.firstName;
  useEffect(() => {
    const updatesWithBOL = updates && updates.filter((update: any) => update.billOfLaden !== null);
    const updatesWithFQ = updates && updates.filter((update: any) => update.formQ !== null);
    const latestBOL =
      updatesWithBOL &&
      updatesWithBOL.sort(
        (a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      )[0];
    const latestFQ =
      updatesWithFQ &&
      updatesWithFQ.sort(
        (a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      )[0];
    setLatestBOL(latestBOL);
    setLatestFQ(latestFQ);
  }, []);

  const handlePostUpdate = async () => {
    if (!text.trim()) {
      initNotification({
        message: "Update cannot be empty!",
      });
      return;
    }

    if (text && campaignData?._id) {
      console.log(text, campaignData._id);

      const payload = {
        post: text.trim(),
        campaignId: campaignData._id,
      };
      request(payload)
        .unwrap()
        .then((res: any) => {
          console.log(res);
          setText("");
          refetch();
          if (res.error) {
            initNotification({
              message: res.message,
              scheme: "error",
            });
            return;
          }
          if (res) {
            initNotification({
              message: "Update added successfully!",
            });
          }
        })
        .catch((error: any) => {
          console.log(error);
          initNotification({
            message: resolveApiError(error),
            scheme: "error",
          });
        });
    }
  };

  const handleToggleLike = async (postId: string) => {
    console.log(postId, campaignData!._id);
    if (postId && campaignData?._id) {
      const payload = {
        postId: postId,
      };
      likeUpdateRequest(payload)
        .unwrap()
        .then((res: any) => {
          console.log("Like response: ", res);
          if (res.error) {
            initNotification({
              message: res.message,
              scheme: "error",
            });
          }
          refetch();
          return;
        })
        .catch((error: any) => {
          console.log(error);
          initNotification({
            message: resolveApiError(error),
            scheme: "error",
          });
        });
    }
  };

  return (
    <section className='row justify-content-between bg-transparent pb-5'>
      <div className='col-lg-8'>
        <div className='bg-white rounded-3 shadow-sm p-4 mb-5'>
          <div className='d-flex gap-3'>
            <div>
              <img
                src={profileImage || (updates && updates[0].authorDetails.image) || ProfileIcon}
                alt={"User Avatar item"}
                className='rounded rounded-circle'
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = ProfileIcon;
                }}
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
            </div>
          </div>

          <div className='border rounded-3 ps-4 pt-3 mt-3 shadow-sm'>
            <div>
              <CustomQuillTextarea
                placeholder='Post an Update'
                onChange={(val) => {
                  setText(val);
                }}
                minHeight={"80px"}
              />
            </div>

            <div className='d-flex justify-content-end align-items-center border-top mx-3 py-3 px-5'>
              <PrimaryButton
                type='submit'
                onClick={async () => {
                  await handlePostUpdate();
                  await refetch();
                }}
                isLoading={isLoading}
                className='btn-lg primary-btn'
              >
                Post Update
              </PrimaryButton>
            </div>
          </div>
        </div>

        {updates &&
          Array.isArray(updates) &&
          [...updates]
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .map((update: any) => (
              <div className='bg-white rounded-3 shadow-sm p-4 mt-5' key={update._id}>
                <div className='d-flex align-items-center gap-3 pb-3'>
                  <div>
                    <img
                      src={update.authorDetails.image || ProfileIcon}
                      alt={"User Avatar item"}
                      className='rounded rounded-circle'
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = ProfileIcon;
                      }}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                    />
                  </div>
                  <div>
                    <p className='m-0 pb-2'>
                      {update.authorDetails.firstName + " " + update.authorDetails.lastName}
                    </p>
                    <p className='text-grey-500 fw-400'>{moment(update.createdAt).fromNow()}</p>
                  </div>
                  <div>
                    {userId === update.authorDetails.firstName && (
                      <p className='bg-warning-50 text-warning-700 fw-500 fs-12 text-capitalize rounded-5 p-2'>
                        {" "}
                        {user?.role}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <p className='text-grey-500 fw-400' dangerouslySetInnerHTML={{ __html: update.post }} />
                </div>

                <div className='py-3'>
                  <div className='d-flex gap-5 '>
                    <div className='d-flex gap-3'>
                      <div className='d-flex gap-2 align-items-center'>
                        <LikeIcon
                          onClick={async () => {
                            await handleToggleLike(update._id);
                          }}
                          className='cursor-pointer'
                        />
                        <p className='m-0 text-grey-500'>
                          {update.likeCount} {update.likeCount === 1 ? "Like" : "Likes"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </div>

      <div className={`col-lg-6 border rounded-3 p-4 ${windowWidth <= 768 && "mt-4"}`}>
        <div className={latestFQ ? "mb-2" : "mb-5"}>
          {!latestFQ ? (
            <div>
              <SimpleFileUploadComponentButton
                fileClass='formQ'
                label='Form Q'
                category='campaignUpdate'
                postId={updates && updates[0]._id}
                onFileUpload={refetch}
                allowedFIleType='application/pdf, .pdf'
              />
            </div>
          ) : (
            <div className='d-flex flex-row justify-content-between align-items-center p-3'>
              <FilePreviewDialog
                fileUrl={latestBOL?.formQ ?? ""}
                isOpen={dialogState.formQ}
                title={"Form Q"}
                onClose={() => {
                  setDialogState({ ...dialogState, formQ: false });
                }}
              />
              <div
                className='d-flex flex-row align-items-center justify-content-center gap-3 bg-success-100 border border-success-500 py-3 px-4 rounded cursor-pointer'
                onClick={() => {
                  setDialogState({ ...dialogState, formQ: true });
                }}
              >
                <DocsIcon />
                <p className='mb-0'>Form Q</p>
              </div>
              <div className='d-flex flex-row gap-5'>
                <span
                  className='text-success-700 cursor-pointer'
                  onClick={() => {
                    setLatestFQ(false);
                  }}
                >
                  Replace
                </span>
              </div>
            </div>
          )}

          {/* <PrimaryButton className='btn primary-btn mt-3' onClick={handleFileUpload}>
            Upload Form Q
          </PrimaryButton> */}
        </div>

        <div className={latestBOL ? "mt-1" : "mt-5"}>
          {latestBOL ? (
            <div className='d-flex flex-row justify-content-between align-items-center p-3'>
              <FilePreviewDialog
                fileUrl={latestBOL.billOfLaden ?? ""}
                isOpen={dialogState.billOfLaden}
                title={"Bill of Laden"}
                onClose={() => {
                  setDialogState({ ...dialogState, billOfLaden: false });
                }}
              />
              <div
                className='d-flex flex-row align-items-center justify-content-center gap-3 bg-success-100 border border-success-500 py-3 px-4 rounded cursor-pointer'
                onClick={() => {
                  setDialogState({ ...dialogState, billOfLaden: true });
                }}
              >
                <DocsIcon />
                <p className='mb-0'>Bill of Laden</p>
              </div>
              <div className='d-flex flex-row gap-5'>
                <span
                  className='text-success-700 cursor-pointer'
                  onClick={() => {
                    setLatestBOL(false);
                  }}
                >
                  Replace
                </span>
              </div>
            </div>
          ) : (
            <div>
              <SimpleFileUploadComponentButton
                fileClass='billOfLaden'
                label='Bill of Laden'
                category='campaignUpdate'
                postId={updates && updates[0]._id}
                onFileUpload={refetch}
                allowedFIleType='application/pdf, .pdf'
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
