import React, { useState } from "react";
import { ChakraAlertDialog } from "..";

interface FilePreviewDialogProps {
  fileUrl: string | File;
  title: string;
  isOpen?: boolean;
  onClose?: () => void;
  hasProceedButton?: boolean;
  proceedButtonLabel?: string;
  proceedButtonAction?: () => void;
}

const FilePreviewDialog: React.FC<FilePreviewDialogProps> = ({
  fileUrl,
  title,
  hasProceedButton,
  proceedButtonLabel,
  proceedButtonAction,
  onClose,
  ...rest
}) => {
  const [fileData, setFileData] = useState(fileUrl);
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
  };

  function fileToDataURI(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  if (typeof fileUrl !== "string") {
    fileToDataURI((fileUrl as any).data).then((data: any) => {
      setFileData(data);
    });
  }

  return (
    <ChakraAlertDialog
      useFooter={true}
      onClose={onClose}
      useCancelButton={true}
      useProceedButton={hasProceedButton === true ? true : false}
      proceedButtonProps={{
        className: "btn-lg primary-btn w-50 ms-0",
      }}
      cancelButtonProps={{
        className: "btn-lg secondary-btn w-50 ms-0",
      }}
      useHeader={true}
      proceedButtonDefaultChild={proceedButtonLabel}
      modalFooterProps={{
        className: "justify-content-between p-0 w-90 gap-5 mx-auto mt-4",
        onClick: () => {
          proceedButtonAction && proceedButtonAction();
        },
      }}
      title={title}
      size={"5xl"}
      closeOnOverlayClick={true}
      modalContentProps={{ className: "rounded-3 p-4" }}
      modalBodyProps={{
        className: "p-0 d-flex justify-content-center relative",
      }}
      {...rest}
    >
      {!error ? (
        <div style={{ width: "800px", height: "100%" }}>
          {typeof fileUrl !== "string" ? (
            <div
              style={{
                width: "100%",
                minHeight: "500px",
                maxHeight: "600px",
                backgroundImage: `url(${fileData})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
          ) : (
            <object
              data={fileUrl as string}
              type="application/pdf"
              style={{ width: "100%", minHeight: "400px" }}
              aria-label="File preview"
              onError={handleError}
            >
              <p>
                File preview not available. You can download it{" "}
                <a href={fileUrl as string}>here</a>.
              </p>
            </object>
          )}
        </div>
      ) : (
        <p>Error loading file preview.</p>
      )}
    </ChakraAlertDialog>
  );
};

export default FilePreviewDialog;
