import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utilities/axiosQuery/axiosBaseQuery";
import { baseUrl } from "../../utilities/requests";
import {
  BackCampaignPaymentResponse,
  BackCampaignPaymentPayload,
  TransactionStatusResponse,
  TransactionStatusPayload,
  GetAllTransactionsResponse,
} from "./interface";

export const backCampaignPaymentApi: any = createApi({
  reducerPath: "backCampaignPaymentApi",
  baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/paystack` }),
  endpoints: (builder) => ({
    backCampaignPayment: builder.mutation<
      BackCampaignPaymentResponse,
      BackCampaignPaymentPayload
    >({
      query: (payload) => ({
        url: `/${payload.campaignId}/initialize-paystack-payment`,
        method: "POST",
        body: payload,
      }),
    }),
    transactionStatus: builder.mutation<
      TransactionStatusResponse,
      TransactionStatusPayload
    >({
      query: (payload) => ({
        url: `/${payload.reference}/verify-paystack-payment`,
        method: "POST",
        body: payload,
      }),
    }),
    getAllTransactions: builder.query<GetAllTransactionsResponse, void>({
      query: () => ({
        url: `/user-payment-records`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "skip-browser-warning",
        },
      }),
    }),
  }),
});

export const {
  useBackCampaignPaymentMutation,
  useTransactionStatusMutation,
  useGetAllTransactionsQuery,
} = backCampaignPaymentApi;
