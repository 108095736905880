import { Security, Notifications, VerificationCenter } from "./components";
import { useEffect, useState } from "react";
import { useWindowWidth } from "utilities/windowWidth";
import { useGetSettingsQuery } from "store/notificationSettings";
import { useLocation } from "react-router";
import queryString from "query-string";

export const SettingsPage = () => {
  const windowWidth = useWindowWidth();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  const active = searchParams.active ? parseInt(searchParams.active) : 0;
  const [activeMenu, setActiveMenu] = useState(active);
  // const active = searchParams.get("active");
  // const activeValue = active ? Number(active) : 0;

  const { data: notificationSettings, refetch } = useGetSettingsQuery((() => {})(), {
    pollingInterval: 30000,
  });

  useEffect(() => {
    setActiveMenu(active);
  }, [active]);

  return (
    <div className={windowWidth >= 768 ? "px-3" : "px-1"}>
      <h3 className='fw-600'>Settings</h3>

      {activeMenu === 0 && <Security setActiveMenu={setActiveMenu} activeMenu={activeMenu} />}
      {activeMenu === 1 && (
        <Notifications
          setActiveMenu={setActiveMenu}
          activeMenu={activeMenu}
          notificationSettings={notificationSettings?.data}
          refetch={refetch}
        />
      )}
      {activeMenu === 2 && <VerificationCenter setActiveMenu={setActiveMenu} activeMenu={activeMenu} />}
    </div>
  );
};
