import { ReactComponent as HomeIcon } from "assets/svg/sidebar/home.svg";
import { ReactComponent as CampaignIcon } from "assets/svg/sidebar/campaign.svg";
// import { ReactComponent as WalletIcon } from "assets/svg/sidebar/wallet.svg";
import { ReactComponent as ProfileIcon } from "assets/svg/sidebar/profile.svg";
import { ReactComponent as SettingsIcon } from "assets/svg/sidebar/settings.svg";
import { ReactComponent as ActiveHomeIcon } from "assets/svg/sidebar/home-active.svg";
import { ReactComponent as ActiveCampaignIcon } from "assets/svg/sidebar/campaign-active.svg";
// import { ReactComponent as ActiveWalletIcon } from "assets/svg/sidebar/wallet.svg";
import { ReactComponent as ActiveProfileIcon } from "assets/svg/sidebar/profile-active.svg";
import { ReactComponent as ActiveSettingsIcon } from "assets/svg/sidebar/settings-active.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/sidebar/logout.svg";
import { ReactComponent as TransactionIcon } from "assets/svg/transaction-sm.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useModalProvider } from "providers";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { useWindowWidth } from "utilities/windowWidth";
import { ReactComponent as HamburgerClose } from "assets/svg/hamburger-close.svg";
import React, { useEffect } from "react";
import { IconButton } from "@chakra-ui/react";

interface SideBarComponentProps {
  page?: string | null;
  active?: boolean;
  width?: number;
  className: string;
  close?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AdminSideBarComponent: React.FC<SideBarComponentProps> = ({
  page,
  active,
  width,
  className,
  close,
}) => {
  const pageSplit = page ? page.split("/") : [];
  const { initModal } = useModalProvider();
  const location = useLocation();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    document.addEventListener("click", () => {
      close!(false);
    });
    return () => document.removeEventListener("click", () => {});
  }, []);

  return (
    <div
      className={`sidebar position-fixed bg-white py-4 px-4 h-100 border-end ${className}`}
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      <div className='pt-2'>
        {windowWidth <= 768 && (
          <div className='d-flex flex-row justify-content-between align-items-center mb-5'>
            <Logo width={"110px"} />
            <IconButton
              aria-label='Close'
              icon={<HamburgerClose />}
              border={"none"}
              bg={"transparent"}
              onClick={() => close!(false)}
            />
          </div>
        )}
        <div className='g-4 link-group'>
          <div className='mb-4'>
            <h5 className='fs-12 fw-400 text-grey-600 mb-1'>OVERVIEW</h5>
            <NavLink
              className={({ isActive }) => {
                return isActive
                  ? "navlink bg-primary-25 text-primary-600 fw-600 text-decoration-none"
                  : "navlink text-grey-900 fw-400 text-decoration-none";
              }}
              to={`/admin/dashboard`}
              onClick={() => {
                close!(false);
              }}
            >
              {/* <Box className={getNavClassName(item)}> */}
              {pageSplit[0] === "dashboard" ? <ActiveHomeIcon /> : <HomeIcon />}
              <div className='nav-text'>Dashboard</div>
              {/* </Box> */}
            </NavLink>
          </div>

          <div className='mb-4'>
            <h5 className='fs-12 fw-400 text-grey-600 mb-1'>USER MANAGEMENT</h5>
            {[
              {
                label: "Users",
                to: "users",
                icon: <HomeIcon />,
                iconActive: <ActiveHomeIcon />,
              },
              {
                label: "KYC Review",
                to: "kyc-review",
                icon: <CampaignIcon />,
                iconActive: <ActiveCampaignIcon />,
              },
              {
                label: "Transactions",
                to: "transactions",
                icon: <TransactionIcon />,
                iconActive: <TransactionIcon />,
              },
            ].map((item, key) => (
              // <div key={key} className="col-sm-12 col-3 touchable">
              <NavLink
                key={key}
                className={({ isActive }) => {
                  return isActive
                    ? "navlink bg-primary-25 text-primary-600 fw-600 text-decoration-none"
                    : "navlink text-grey-900 fw-400 text-decoration-none";
                }}
                to={`/admin/${item.to}`}
                onClick={() => {
                  close!(false);
                }}
              >
                {/* <Box className={getNavClassName(item)}> */}
                {pageSplit[0] === item.to ? item.iconActive : item.icon}
                <div className='nav-text'>{item.label}</div>
                {/* </Box> */}
              </NavLink>
            ))}
          </div>

          <div className='mb-4'>
            <h5 className='fs-12 fw-400 text-grey-600 mb-1'>CAMPAIGN MANAGEMENT</h5>
            {[
              {
                label: "Campaign",
                to: "campaigns",
                icon: <CampaignIcon />,
                iconActive: <ActiveCampaignIcon />,
              },
              {
                label: "Comments",
                to: "coomments",
                icon: <HomeIcon />,
                iconActive: <ActiveHomeIcon />,
              },
              {
                label: "Risk Report",
                to: "risk-report",
                icon: <TransactionIcon />,
                iconActive: <TransactionIcon />,
              },
            ].map((item, key) => (
              // <div key={key} className="col-sm-12 col-3 touchable">
              <NavLink
                key={key}
                className={({ isActive }) => {
                  return isActive
                    ? "navlink bg-primary-25 text-primary-600 fw-600 text-decoration-none"
                    : "navlink text-grey-900 fw-400 text-decoration-none";
                }}
                to={`/admin/${item.to}`}
                onClick={() => {
                  close!(false);
                }}
              >
                {/* <Box className={getNavClassName(item)}> */}
                {pageSplit[0] === item.to ? item.iconActive : item.icon}
                <div className='nav-text'>{item.label}</div>
                {/* </Box> */}
              </NavLink>
            ))}
          </div>

          <div className='mb-4'>
            <h5 className='fs-12 fw-400 text-grey-600 mb-1'>PAYMENT</h5>
            {[
              {
                label: "Exporters Funding",
                to: "exporters-funding",
                icon: <HomeIcon />,
                iconActive: <ActiveHomeIcon />,
              },
              {
                label: "Backers Payout",
                to: "backers-payout",
                icon: <CampaignIcon />,
                iconActive: <ActiveCampaignIcon />,
              },
            ].map((item, key) => (
              // <div key={key} className="col-sm-12 col-3 touchable">
              <NavLink
                key={key}
                className={({ isActive }) => {
                  return isActive
                    ? "navlink bg-primary-25 text-primary-600 fw-600 text-decoration-none"
                    : "navlink text-grey-900 fw-400 text-decoration-none";
                }}
                to={`/admin/${item.to}`}
                onClick={() => {
                  close!(false);
                }}
              >
                {/* <Box className={getNavClassName(item)}> */}
                {pageSplit[0] === item.to ? item.iconActive : item.icon}
                <div className='nav-text'>{item.label}</div>
                {/* </Box> */}
              </NavLink>
            ))}
          </div>
          <hr className='border-grey-200' />
          {[
            {
              label: "Profile",
              to: "profile",
              icon: <ProfileIcon />,
              iconActive: <ActiveProfileIcon />,
            },
            {
              label: "Settings",
              to: "settings",
              icon: <SettingsIcon />,
              iconActive: <ActiveSettingsIcon />,
            },
          ].map((item, key) => (
            // <div key={key} className="col-sm-12 col-3 touchable">
            <NavLink
              key={key}
              className={({ isActive }) => {
                return isActive
                  ? "navlink bg-primary-25 text-primary-600 fw-600 text-decoration-none"
                  : "navlink text-grey-900 fw-400 text-decoration-none";
              }}
              to={`/admin/${item.to}`}
              onClick={() => {
                close!(false);
              }}
            >
              {/* <Box className={getNavClassName(item)}> */}
              {pageSplit[0] === item.to ? item.iconActive : item.icon}
              <div className='nav-text'>{item.label}</div>
              {/* </Box> */}
            </NavLink>
          ))}
          <button
            onClick={() => {
              initModal((prev) => ({
                ...prev,
                logout: true,
              }));
              location.state = null;
            }}
            className='text-error-600 w-100 navlink border-0 mt-0'
          >
            <LogoutIcon />
            <div className='nav-text fw-400'>Logout</div>
          </button>
        </div>
      </div>
    </div>
  );
};
