import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { PrimaryButton } from "components/buttons";
import { ReactComponent as VerifiedIcon } from "assets/svg/verified-icon.svg";
import { IconButton } from "@chakra-ui/react";

export const ExporterTwoFacAuthSuccessMobile = () => {
  return (
    <div className='d-flex flex-column align-items-center'>
      <h5
        className='fw-semibold fs-20 text-grey-900 py-4 d-flex align-items-center gap-5 justify-content-center w-100'
        style={{ position: "relative" }}
      >
        <div style={{ position: "absolute", left: 0 }}>
          <IconButton
            aria-label='Back'
            icon={<BackIcon />}
            onClick={() => window.history.back()}
            border={"none"}
            bg={"transparent"}
          />
        </div>
        Two-Factor Authentication
      </h5>
      <VerifiedIcon />
      <p className='text-grey-500 fs-14 text-center w-80 mt-3'>
        Two-factor authentication has been successfully enabled for your account
      </p>
      <PrimaryButton className='btn-lg primary-btn mt-4 mx-auto'>Go back to Dashboard</PrimaryButton>
    </div>
  );
};
