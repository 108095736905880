import { number, object, string, date, bool } from "yup";
import * as Yup from "yup";

const bannedCountries = [
  "KP-North Korea",
  "IR-Iran",
  "SY-Syria",
  "RU-Russia",
  "VE-Venezuela",
  "CU-Cuba",
  "SD-Sudan",
  "BY-Belarus",
  "MM-Myanmar",
];

export const CampaignInformationValidationSchema = () => {
  return object({
    title: string().required("Title is required"),
    description: string().required("Description is required"),
    exportingCountry: string()
      .required("Exporting country is required")
      .test("is-banned", "Selected country not supported.", (value) => {
        return !bannedCountries.includes(value);
      }),
  });
};

export const CampaignGoalsValidationSchema = () => {
  return object({
    // fundingGoal: string().required("Funding goal is required"),
    // minimumAmount: string().when("fundingGoal", (fundingGoal, schema) => {
    //   return schema.test({
    //     name: "lessThanFundingGoal",
    //     message: "Minimum amount must be less than funding goal",
    //     test: (value) => {
    //       const cleanMinimumAmount = Number(value?.replace(/,/g, ""));
    //       const cleanFundingGoal = Number(
    //         String(fundingGoal).replace(/,/g, "")
    //       );
    //       return cleanMinimumAmount < cleanFundingGoal;
    //     },
    //   });
    // }),

    fundingGoal: number()
      .required("Funding goal is required")
      .transform((value, originalValue) =>
        parseFloat(originalValue.replace(/,/g, ""))
      ),
    minimumAmount: number()
      // .transform((value, originalValue) =>
      //   parseFloat(originalValue.replace(/,/g, ""))
      // )
      // .test(
      //   // "is-at-least-60-percent",
      //   "Minimum amount should be at least 60% of the funding goal",
      //   function (value) {
      //     const fundingGoal = this.parent.fundingGoal;
      //     if (value === 0) {
      //       return true;
      //     }
      //     return (value ?? 0) >= fundingGoal * 0.6;
      //   }
      // )
      .max(
        Yup.ref("fundingGoal"),
        "Minimum amount should not be greater than funding goal"
      ),
    interestRate: number()
      .min(8, "Interest rate must be at least 8")
      .max(20, "Interest rate must be at most 20")
      .required("Interest rate is required"),

    reward: string().required("Reward is required"),

    // campaignEndDate: date().required("Campaign End Date is required")
    // .max(new Date(), "The date must be before the current date"),
    campaignEndDate: date()
      .required("Campaign End Date is required")
      .min(
        new Date(new Date().setDate(new Date().getDate() + 1)),
        "The date must be after the current date"
      ),

    // termsAndConditions: bool()
    //   .oneOf([true], "")
    //   .required("You must agree with the terms and conditions"),
  });
};
