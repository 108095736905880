import { useEffect } from "react";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import { Auth, setAccessToken, useLogout } from "store/auth";
import { store } from "store/index";
import { jwtDecode } from "jwt-decode";
import { TOKEN_REFRESH_INTERVAL } from "utilities/constants";

const useTokenRefresher = () => {
  const logout = useLogout();

  const handleLogout = () => {
    window.location.replace("/login");
    window.localStorage.clear();
    logout();
  };

  useEffect(() => {
    const refreshAccessToken = async () => {
      try {
        const result: string = reactLocalStorage.get("@krowdfi_user");
        const access_token = result ? (JSON.parse(result) as Auth).access_token : null;
        const refresh_token = result ? (JSON.parse(result) as Auth).refresh_token : null;

        if (refresh_token && access_token) {
          const decodedAccessToken = jwtDecode(access_token);
          const timedifference = Number(decodedAccessToken.exp!) * 1000 - Number(new Date().getTime());

          if (timedifference < 0 || timedifference > TOKEN_REFRESH_INTERVAL * 2) return;

          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/users/generate-new-access-token`,
            { refresh_token },
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );

          if (response.data.data.accessToken && response.data.data.refreshToken) {
            store.dispatch(
              setAccessToken({
                access_token: response.data.data.accessToken,
                refresh_token: response.data.data.refreshToken,
              })
            );
          }
        } else {
          // handleLogout();
        }
      } catch (error) {
        // handleLogout();
      }
    };

    const refreshInterval = setInterval(refreshAccessToken, TOKEN_REFRESH_INTERVAL);

    return () => clearInterval(refreshInterval);
  }, []);

  return null;
};
export default useTokenRefresher;
