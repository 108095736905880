import React from "react";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";
import { ReactComponent as MailIcon } from "assets/svg/mail-icon.svg";
import queryString from "query-string";
import { resolveApiError } from "utilities/index";
import { usePageNotificationProvider } from "providers";
import { useResendVerificationEmailMutation } from "store/auth";
import { useGetProfileInformationQuery } from "store/profile";
import { Link } from "react-router-dom";

export const VerifyEmailPage = () => {
  const { email } = queryString.parse(window.location.search) as {
    email: string;
  };
  const [countdown, setCountdown] = React.useState(0);
  const [clickCount, setClickCount] = React.useState(0);
  const { initNotification } = usePageNotificationProvider();
  const [request, { isLoading }] = useResendVerificationEmailMutation();
  const { data: profileInfo, refetch } = useGetProfileInformationQuery(
    (() => {})(),
    {
      pollingInterval: 30000,
    }
  );
  const profile = profileInfo?.data;

  const initResendVerificationEmail = () => {
    if (countdown > 0) {
      return;
    }
    setClickCount((prevCount) => prevCount + 1);
    if (clickCount === 2) {
      setCountdown(900); //15mins countdown
      setClickCount(0);
    }
    request({ email })
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "Verification email sent successfully.",
        });
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);
    if (profile?.isEmailVerified) {
      window.location.href = `/verify-email/status`;
    }
    return () => clearInterval(interval);
  }, [profile?.isEmailVerified, profile?.role]);

  React.useEffect(() => {
    let timerId: any;
    if (countdown > 0) {
      timerId = setTimeout(() => {
        setCountdown((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearTimeout(timerId);
  }, [countdown]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };
  return (
    <div
      style={{ minHeight: "100vh", paddingTop: "8%" }}
      className="d-flex flex-column align-items-center bg-grey-25 gap-4"
    >
      <Link to="/">
        <Logo />
      </Link>
      <div className="border p-5 bg-grey-25 rounded-3 mt-5 d-flex flex-column align-items-center gap-4 text-center">
        <MailIcon />

        <h3 className="text-grey-900">Please check your email.</h3>

        <p
          className=" text-grey-500 fs-14 lh-base"
          style={{ maxWidth: "400px" }}
        >
          We've sent a link to <span className="fw-600">{email}</span>. If you
          don’t see it, check your spam folder. <br /> <br /> If the email
          address you entered is wrong, kindly restart the registration process.
        </p>

        <p className="mt-0 text-grey-500 fs-14 lh-base">
          Didn’t get a link?{" "}
          <button
            className={`text-primary-600 border-0 bg-transparent p-0 fw-500 ${
              countdown > 0 ? "not-allowed" : ""
            }`}
            onClick={initResendVerificationEmail}
          >
            {isLoading
              ? "Sending..."
              : countdown > 0
              ? `You can resend Email after (${formatTime(countdown)})`
              : "Resend Email"}
          </button>
        </p>
      </div>
    </div>
  );
};
