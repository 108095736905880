import { ChakraAlertDialog } from "components";
import React, { FC } from "react";
import { ReactComponent as VerifiedIcon } from "assets/svg/verified-icon.svg";
import { useModalProvider } from "providers/modalProvider";

interface SubmitCampaignModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const SubmitCampaignModal: FC<SubmitCampaignModalProps> = ({
  //   isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  const { initModal } = useModalProvider();
  const handleProceed = () => {
    window.location.replace(`/exporter/dashboard`);
    onClose();
  };
  return (
    <>
      <ChakraAlertDialog
        useFooter={true}
        useCancelButton={false}
        useProceedButton={true}
        onProceed={() => {
          initModal((prev) => ({ ...prev, submitCampaign: false }));
        }}
        useHeader={false}
        proceedButtonDefaultChild={"Return to Dashboard"}
        proceedButtonProps={{
          className: "btn-md primary-btn w-100 ms-0",
          onClick: handleProceed,
        }}
        modalFooterProps={{
          className: "justify-content-center p-0 w-100",
        }}
        title={""}
        size={"md"}
        closeOnOverlayClick={false}
        modalContentProps={{ className: "rounded-3 p-4" }}
        modalBodyProps={{ className: "p-0" }}
        {...rest}
      >
        <div className="text-center d-flex flex-column align-items-center">
          <VerifiedIcon className="mb-3" />
          <h5 className="fw-600 text-grey-900 mb-3">Campaign Submitted!</h5>
          <p className="fw-400 text-grey-500 mb-3 lh-base">
            Your campaign has been successfully submitted for review.
          </p>
          <p className="fw-400 text-grey-500 mb-4 lh-base">
            Your submission will be carefully evaluated and we'll notify you on
            the status of your campaign
          </p>
        </div>
      </ChakraAlertDialog>
    </>
  );
};
