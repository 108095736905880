import {
  PrimaryInput,
  PrimarySelect,
  PrimaryPhone,
  PrimaryButton,
} from "components";
import React, { FC, useEffect, useState } from "react";
import { BsPencil } from "react-icons/bs";
import { User, setCredential, useAuth } from "store/auth";
import { UploadAvatarButton } from "./uploadAvatarButton";
import { useFormik } from "formik";
import UserIcon from "assets/svg/profile-circle.svg";
import {
  UpdateProfilePayload,
  useCancelProfileChangeRequestMutation,
  useGetProfileInformationQuery,
  useUpdateProfileMutation,
} from "store/profile";
import { maskEmail, resolveApiError } from "utilities";
import { useModalProvider, usePageNotificationProvider } from "providers";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useWindowWidth } from "utilities";
import { useGetRejectedDocumentsQuery } from "store/verificationCenter";

interface PersonalDetailsViewBoxProps {
  profile: User | null;
  initialProfileValues: UpdateProfilePayload;
  isEditable: boolean;
}

const personalDetailsItems = [
  {
    name: "email",
    type: "email",
    label: "Email",
    placeholder: "Enter email",
  },
  {
    name: "phoneNumber",
    type: "phone",
    label: "Phone Number",
    placeholder: "",
  },
  {
    name: "dateOfBirth",
    type: "date",
    label: "Date of Birth",
    placeholder: "",
  },
  {
    name: "sex",
    type: "select",
    label: "Gender",
    placeholder: "",
  },
];

export const PersonalDetailsViewBox: FC<PersonalDetailsViewBoxProps> = ({
  initialProfileValues,
  isEditable,
}) => {
  const windowWidth = useWindowWidth();
  const { user, token: access_token } = useAuth();
  const dispatch = useDispatch();
  const [request, { isLoading }] = useUpdateProfileMutation();
  const [cancelProfileRequestUpdate] = useCancelProfileChangeRequestMutation();
  const { initNotification } = usePageNotificationProvider();
  const { data: documents } = useGetRejectedDocumentsQuery((() => {})(), {
    pollingInterval: 30000,
  });
  const [requestCanceled, setRequestCanceled] = useState(false);

  const data = documents?.data;
  console.log("data", data);

  const userRequest = data?.dataRequestChange.some(
    (requestItem: any) =>
      requestItem.dataRequestDocument.request === "name" ||
      requestItem.dataRequestDocument.request === "gender" ||
      requestItem.dataRequestDocument.request === "dateOfBirth"
  );
  console.log("approv", userRequest);

  const { initModal } = useModalProvider();
  const { data: profileInfo, refetch } = useGetProfileInformationQuery(
    (() => {})(),
    {
      pollingInterval: 30000,
    }
  );
  const profile = profileInfo?.data || user;
  const profileImage = user?.image ?? profileInfo?.data.image ?? UserIcon;
  const [toggleEdit, setToggleEdit] = useState<number | null>(null);

  const handleCancelProfileChangeRequest = () => {
    const targetRequest = data?.dataRequestChange.find((requestItem: any) =>
      ["name", "gender", "dateOfBirth"].includes(
        requestItem.dataRequestDocument.request
      )
    );

    if (targetRequest) {
      const { _id: payload } = targetRequest.dataRequestDocument;
      cancelProfileRequestUpdate(payload)
        .unwrap()
        .then((res: any) => {
          if (res.error) {
            initNotification({
              message: res.message,
              scheme: "error",
            });
            return;
          }
          initNotification({
            message: "Request Cancelled Successfully",
          });
          setRequestCanceled(true);
          refetch();
        })
        .catch((error: any) => {
          console.log(error);
          initNotification({ message: error.message, scheme: "error" });
        });
    } else {
      initNotification({
        message: "No pending request found for name, gender, or dateOfBirth",
        scheme: "info",
      });
    }
  };

  const displayCancelButton = userRequest && !requestCanceled;

  const handleEditToggle = (index: number | null) => {
    setToggleEdit(index);
  };

  const initialValues = {
    // ...initialProfileValues,
    email: (initialProfileValues?.email as string) ?? "",
    phoneNumber: (initialProfileValues?.phoneNumber as string) ?? "",
    sex: (initialProfileValues?.sex as string) ?? "",
    dateOfBirth: (initialProfileValues?.dateOfBirth as string) ?? "",
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: CreatePersonalDetailsValidationSchema,
    onSubmit: () => initRequest(),
  });

  const initRequest = () => {
    request({ ...initialProfileValues, ...values })
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "Personal detail saved",
        });
        refetch();
        dispatch(
          setCredential({
            user: res.data,
            access_token,
          })
        );
        setToggleEdit(null);
      })
      .catch((error) => {
        console.log(error);
        if (error.data.statusCode === 403) initModal({ sessionExpired: true });
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  const formatDate = (date: moment.MomentInput) => {
    const formattedDate = moment(date).format("DD MMMM, YYYY");
    return formattedDate;
  };

  let eighteenYearsAbove = new Date();
  eighteenYearsAbove.setFullYear(eighteenYearsAbove.getFullYear() - 18);

  let futureDate = new Date();
  futureDate.setFullYear(futureDate.getFullYear() - 200);

  return (
    <div
      className={`details-box ${
        windowWidth >= 768 ? "border-1 border-grey-100 p-34-px" : ""
      } br-8 mb-34-px`}
    >
      <div className="w-100 d-flex justify-content-between align-items-center mb-32-px">
        <h5 className="text-grey-900 fw-600">Personal Details</h5>

        {displayCancelButton ? (
          <PrimaryButton
            type="button"
            onClick={handleCancelProfileChangeRequest}
            className="btn-lg secondary-error-btn"
          >
            Cancel Pending Request
          </PrimaryButton>
        ) : (
          <PrimaryButton
            type="button"
            onClick={() => {
              if (profile?.isVerified === "accepted") {
                initModal((prev) => ({
                  ...prev,
                  editPersonalDetails: true,
                }));
              } else {
                initNotification({
                  message:
                    "Account not verified, please verify your account to proceed",
                  scheme: "error",
                });
              }
            }}
            className="btn-lg secondary-btn"
          >
            Request Personal Details Change
          </PrimaryButton>
        )}
      </div>
      <div className="d-flex align-items-center gap-4 mb-4">
        <div className="profile-avatar">
          <img
            src={profileImage}
            alt={profile?.firstName ?? "Avatar"}
            className="img-fluid avatar"
          />
          <UploadAvatarButton className="avatar-upload-btn text-primary-600 bg-primary-50 hover-bg-primary-100 transition-2 d-flex align-items-center justify-content-center fw-600">
            <BsPencil />
          </UploadAvatarButton>
        </div>
        <div>
          <h6 className="text-grey-900 fw-600 fs-16">
            {`${profile?.firstName} ${profile?.lastName}` ?? ""}
          </h6>
          <p className="mb-0 text-grey-500 text-capitalize fs-14">
            {profile?.role}
          </p>
        </div>
      </div>
      {windowWidth >= 768 && <hr className="border-1 border-grey-200" />}
      <div className="pt-4 pb-1">
        {personalDetailsItems.map(
          (
            detail: {
              name: any;
              type: string;
              label: string;
              placeholder: string;
            },
            index
          ) => (
            <div className="row align-items-center justify-content-between mb-30-px ">
              {toggleEdit !== index ? (
                <>
                  <div className="col-md-4">
                    <label className="text-grey-500 fs-15 fw-400" htmlFor="">
                      {detail.label}
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="d-flex align-items-center justify-content-between">
                      <p
                        className={`mb-0 fs-15 ${
                          detail.type === "email" ? "" : "text-capitalize"
                        }`}
                      >
                        {detail.type === "email"
                          ? maskEmail(
                              profile![detail.name as keyof typeof values]
                            )
                          : detail.type === "date"
                          ? profile![detail.name as keyof typeof values]
                            ? formatDate(
                                profile![detail.name as keyof typeof values]
                              )
                            : "None"
                          : profile![detail.name as keyof typeof values] ??
                            "None"}
                        {/* {detail.type === "email"
                          ? maskEmail(
                              profile![detail.name as keyof typeof values]
                            )
                          : "None"}
                        {detail.type === "date" &&
                        profile![detail.name as keyof typeof values]
                          ? formatDate(
                              profile![detail.name as keyof typeof values]
                            )
                          : "None"}
                        {detail.name === "phoneNumber" ||
                        detail.name === "gender"
                          ? profile![detail.name as keyof typeof values]
                          : "None"} */}
                      </p>
                      {/* {isEditable ? ( */}
                      {detail.name === "phoneNumber" ||
                      detail.name === "email" ? (
                        <button
                          type="button"
                          onClick={() => {
                            if (detail.name === "phoneNumber") {
                              initModal({
                                passwordConfirmation: {
                                  payload: {
                                    handleEditToggle,
                                    title: "Change Phone Number",
                                    type: "phoneNumber",
                                    index,
                                  },
                                },
                              });
                            } else if (detail.name === "email") {
                              initModal({
                                passwordConfirmation: {
                                  payload: {
                                    handleEditToggle,
                                    title: "Change Email Address",
                                    type: "email",
                                    index,
                                  },
                                },
                              });
                            } else {
                              handleEditToggle(index);
                            }
                          }}
                          className="border-0 bg-transparent text-primary-600 text-end"
                        >
                          <BsPencil />
                        </button>
                      ) : null}
                      {/* ) : (
                        <p className="mb-0">&nbsp;</p>
                      )} */}
                    </div>
                  </div>
                </>
              ) : (
                <form onSubmit={handleSubmit} className="col-12">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="max-w-300 w-95">
                      {(detail.type === "text" ||
                        detail.type === "date" ||
                        detail.type === "email") && (
                        <PrimaryInput
                          type={detail.type}
                          label={detail.label}
                          name={detail.name}
                          min={futureDate.toISOString().split("T")[0]}
                          max={eighteenYearsAbove.toISOString().split("T")[0]}
                          placeholder={detail.placeholder}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            detail.type === "date"
                              ? values[
                                  detail.name as keyof typeof values
                                ].substring(0, 10)
                              : values[detail.name as keyof typeof values]
                          }
                          autoFocus
                          // error={Boolean(errors?.email && touched?.email)}
                          // bottomText={errors?.email}
                        />
                      )}
                      {detail.type === "select" && (
                        <PrimarySelect
                          name={detail.name}
                          label={detail.label}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={values.sex}
                          autoFocus
                          value={values.sex}
                          error={Boolean(errors?.sex && touched?.sex)}
                          bottomText={errors?.sex || ""}
                        >
                          <option hidden>Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </PrimarySelect>
                      )}
                      {detail.type === "phone" && (
                        <PrimaryPhone
                          label={detail.label}
                          placeholder={detail.placeholder}
                          name={detail.name}
                          invalid={String(Boolean(errors?.phoneNumber))}
                          onChange={(v) =>
                            setValues((prev) => {
                              return { ...prev, phoneNumber: v as string };
                            })
                          }
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          error={Boolean(
                            errors?.phoneNumber && touched?.phoneNumber
                          )}
                          bottomText={errors?.phoneNumber || ""}
                        />
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <PrimaryButton
                        type="button"
                        onClick={() => handleEditToggle(null)}
                        className="btn-lg secondary-btn mt-3 me-3 align-self-baseline"
                      >
                        Cancel
                      </PrimaryButton>
                      <PrimaryButton
                        type="submit"
                        isLoading={isLoading}
                        // onClick={next}
                        className="btn-lg primary-btn mt-3 align-self-baseline"
                      >
                        Submit
                      </PrimaryButton>
                    </div>
                  </div>
                </form>
              )}
            </div>
          )
        )}
      </div>
      {windowWidth <= 768 && <hr className="border-1 border-grey-400" />}
    </div>
  );
};
