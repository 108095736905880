import { Link } from "react-router-dom";
import { breadcrumbConfig } from "navigations";
import { Flex, Box, Radio, RadioGroup, IconButton } from "@chakra-ui/react";
import { ReactComponent as DepositIcon } from "assets/svg/deposit-icon.svg";
import { ReactComponent as BackIcon } from "assets/svg/back-icon.svg";
import { useState } from "react";
import { useWindowWidth } from "utilities/windowWidth";

export const BackerFundWallet = () => {
  const [depositMethod, setDepositMethod] = useState("0");
  const { backerFundWallet } = breadcrumbConfig;
  const windowWidth = useWindowWidth();

  return (
    <div className="ps-3">
      {windowWidth >= 768 && (
        <div className="breadcrumb mb-5" aria-label="breadcrumb">
          {backerFundWallet.map((item, index) => (
            <>
              <Link
                className={`breadcrumb-item text-grey-600 ${
                  backerFundWallet.length - 1 === index ? "active" : ""
                }`}
                to={item.to ?? "#"}
              >
                {item.name}
              </Link>
            </>
          ))}
        </div>
      )}
      {windowWidth <= 768 && (
        <h5
          className={`fs-24 fw-600 pb-4 d-flex flex-row align-items-center gap-3`}
        >
          {windowWidth <= 768 && (
            <IconButton
              aria-label="Back"
              icon={<BackIcon />}
              onClick={() => window.history.back()}
              border={"none"}
              bg={"transparent"}
            />
          )}{" "}
          Fund Wallet
        </h5>
      )}
      <h2 className={`${windowWidth >= 768 ? "fs-24 mb-0" : "fs-16"} fw-600`}>
        Select Deposit Method
      </h2>
      <RadioGroup>
        <Flex marginTop={"48px"} direction={"column"} gap={"24px"}>
          <label
            htmlFor="card"
            className="d-flex flex-row align-items-center border border-primary-200 justify-content-between touchable"
            style={{
              padding: "20px",
              borderRadius: "8px",
              width: windowWidth >= 768 ? "360px" : "100%",
              height: "80px",
            }}
          >
            <Box className="d-flex flex-row align-items-center w-75 gap-3">
              <DepositIcon />
              <h3
                className={`fs-14 fw-600 text-${
                  depositMethod === "1" ? "primary" : "grey"
                }-600 mb-0`}
              >
                Bank Card
              </h3>
            </Box>
            <Radio
              colorScheme="primary"
              defaultChecked={true}
              id="card"
              value="1"
              onChange={() => {
                {
                  /* defaultChecked not working.. */
                }
                setDepositMethod((prev) => (prev === "1" ? "2" : "1"));
              }}
            />
          </label>
          <label
            htmlFor="transfer"
            className="d-flex flex-row align-items-center border border-primary-200 justify-content-between touchable"
            style={{
              padding: "20px",
              borderRadius: "8px",
              width: windowWidth >= 768 ? "360px" : "100%",
              height: "80px",
            }}
          >
            <Box className="d-flex flex-row align-items-center w-75 gap-3">
              <DepositIcon />
              <h3
                className={`fs-14 fw-600 text-${
                  depositMethod === "2" ? "primary" : "grey"
                }-600 mb-0`}
              >
                Bank Transfer
              </h3>
            </Box>
            <Radio
              colorScheme="primary"
              id="transfer"
              value="2"
              onChange={() => {
                setDepositMethod((prev) => (prev === "1" ? "2" : "1"));
              }}
            />
          </label>
        </Flex>
      </RadioGroup>
    </div>
  );
};
