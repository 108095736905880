import React, { FC, useEffect, useState } from "react";
import { PrimaryInput, PrimarySelect, PrimaryButton, MultiSelectWithCreateOption } from "components";
import { useFormik } from "formik";
import { CreateContactAddressValidationSchema } from "validations";
import { UpdateProfilePayload, useUpdateProfileMutation } from "store/profile";
import { usePageNotificationProvider } from "providers";
import { useDispatch } from "react-redux";
import { useAuth, setCredential } from "store/auth";
import { resolveApiError } from "utilities";
import { useWindowWidth } from "utilities";

export type ContactAddressFormProps = {
  next: () => void;
  previous: () => void;
  initialProfile: UpdateProfilePayload;
};

export interface PrimaryMultiSelectOption {
  label: string;
  value: string | number;
  [x: string]: any;
  // back
}

export interface SelectedCountryInfo {
  name: string;
  states: {
    name: string;
    cities: {
      name: string;
    }[];
  }[];
}

export const ContactAddressForm: FC<ContactAddressFormProps> = ({ next, previous, initialProfile }) => {
  const windowWidth = useWindowWidth();
  const { user, token: access_token } = useAuth();
  const dispatch = useDispatch();
  const [request, { isLoading }] = useUpdateProfileMutation();
  const { initNotification } = usePageNotificationProvider();

  const details = user ?? initialProfile;

  const initialValues = {
    // ...initialProfile,
    country: (initialProfile?.country as string) ?? "",
    state: (initialProfile?.state as string) ?? "",
    city: (initialProfile?.city as string) ?? "",
    postalCode: (initialProfile?.postalCode as string) ?? "",
    address: (initialProfile?.address as string) ?? "",
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: CreateContactAddressValidationSchema,
    onSubmit: () => initRequest(),
  });

  const [countries, setCountries] = useState<any>([]);
  const [states, setStates] = useState<any["states"][0]>([]);
  const [cities, setCities] = useState<any["states"][0]["cities"][0]>([]);

  const handleFetchCountriesJson = () => {
    import("assets/json/countries+states+cities.json")
      .then((data) => {
        // console.log(data,)
        const extract = data ? Object.values(data) : null;
        if (extract) {
          const countries = extract as any;
          let mapped: any[] = [];
          countries.map((c: { name: string | any[] }, _i: any) => {
            if (c.name && c.name.length) {
              let mappedObj: any = {
                label: c.name,
                value: c.name,
                data: c,
              };
              mapped.push(mappedObj);
            }
          });
          setCountries(mapped);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const mapCities = () => {
    let mapped: PrimaryMultiSelectOption[] = [];
    cities.map((c: any, _i: number) => {
      if (c.name && c.name.length) {
        let mappedObj: PrimaryMultiSelectOption = { label: c.name, value: c.name };
        mapped.push(mappedObj);
      }
    });

    return mapped;
  };

  useEffect(() => {
    handleFetchCountriesJson();
  }, []);

  useEffect(() => {
    // mapCountries();
    const selectedCountry: any = countries.find((c: { value: any }) => c.value === values.country);
    if (selectedCountry) {
      setStates(selectedCountry.data.states);
    }
    const selectedState: any = states.find((c: { name: any }) => c.name === values.state);
    if (selectedState) {
      setCities(selectedState.cities);
    }
  }, [values.country, values.state, states, countries]);

  const initRequest = () => {
    request({ ...values })
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "Contact address saved",
        });
        dispatch(
          setCredential({
            user: res.data,
            access_token,
          })
        );
        setTimeout(() => {
          next();
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  //   console.log(countries, states, cities);

  return (
    <section className={`container-fluid ${windowWidth >= 768 ? "py-5" : "pt-3 pb-5"} bg-transparent`}>
      <div className='row'>
        <div className='col-md-12'>
          <form onSubmit={handleSubmit} className=''>
            {windowWidth <= 768 && (
              <>
                <h6 className='text-grey-900 fs-20 fw-semibold'>Contact Address</h6>
                <p className='mb-4 text-grey-400'>Provide your contact address</p>
              </>
            )}
            <div className='row'>
              <div className={windowWidth >= 768 ? "col-12 mb-4" : "w-80 mb-3"}>
                <PrimarySelect
                  name='country'
                  label={windowWidth >= 768 ? "Country*" : "Country of Residence*"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.country}
                  error={Boolean(errors?.country && touched?.country)}
                  bottomText={errors?.country || ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                >
                  <option hidden>Select Country</option>
                  {countries?.map(
                    (
                      c: {
                        value: string | number | readonly string[] | undefined;
                        label: string;
                      },
                      i: React.Key | null | undefined
                    ) => (
                      <option key={i} value={c.value}>
                        {c.label}
                      </option>
                    )
                  )}
                </PrimarySelect>
              </div>
              <div className='col-12'>
                <div className={windowWidth >= 768 ? "row" : "d-flex flex-row gap-5 mb-3"}>
                  <div className={windowWidth >= 768 ? "col-md-6 mb-4" : "w-50"}>
                    <PrimarySelect
                      name='state'
                      label='State*'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      error={Boolean(errors?.state && touched?.state)}
                      bottomText={errors?.state || ""}
                      bg={windowWidth <= 768 ? "white" : ""}
                    >
                      <option hidden>Select State</option>
                      {states.map((c: any, i: React.Key | null | undefined) => (
                        <option key={i} value={c.name}>
                          {c.name}
                        </option>
                      ))}
                    </PrimarySelect>
                  </div>
                  <div className={windowWidth >= 768 ? "col-md-6 mb-4" : "w-50"}>
                    <MultiSelectWithCreateOption
                      label='City*'
                      name='city'
                      placeholder='Select a city'
                      value={values?.city && { label: values?.city, value: values?.city }}
                      error={Boolean(errors?.city && touched?.city)}
                      bottomText={errors?.city || ""}
                      options={mapCities()}
                      onChange={(value) => {
                        if (handleChange) {
                          handleChange({ target: { name: "city", value: value } });
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-4'>
                <PrimaryInput
                  type='text'
                  label='Postal Code'
                  name='postalCode'
                  placeholder='Enter postal code'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.postalCode}
                  error={Boolean(errors?.postalCode && touched?.postalCode)}
                  bottomText={errors?.postalCode || ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                />
              </div>
              <div className='col-md-12 mb-4'>
                <PrimaryInput
                  type='text'
                  label='Home Address*'
                  name='address'
                  placeholder='Enter home address'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  error={Boolean(errors?.address && touched?.address)}
                  bottomText={errors?.address || ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                />
              </div>
            </div>
            {/* </div> */}

            <div>
              <PrimaryButton
                type='button'
                onClick={previous}
                className='btn-lg secondary-btn mt-3 me-3 align-self-baseline'
              >
                {windowWidth >= 768 ? "Back" : "Cancel"}
              </PrimaryButton>
              <PrimaryButton
                type='submit'
                className='btn-lg primary-btn mt-3 align-self-baseline'
                isLoading={isLoading}
              >
                Next
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
