import { ProgressBar } from "components/custom";
import { CampaignItemProps } from "./interface";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as BackersIcon } from "assets/svg/backers.svg";
import { ReactComponent as CalenderIcon } from "assets/svg/calender.svg";
import { getDaysLeft, formatDate, useWindowWidth, getPercentage } from "utilities";
import { CampaignData } from "store/campaigns";
import { Badge } from "components";
import React from "react";

export const CampaignItem = ({ campaign, url, clickAction }: CampaignItemProps) => {
  const windowWidth = useWindowWidth();

  console.log("camp", campaign);
  const formFields: (keyof CampaignData)[] = ["fundingGoal", "images", "purchaseOrder", "interestRate"];

  const filledOutFormFields = formFields.filter(
    (field) => field in campaign && campaign[field as keyof CampaignData]
  ).length;

  const completionPercentage = (filledOutFormFields / formFields.length) * 100;
  return (
    // <div className="position-relative border border-grey-100 mt-3 rounded-3 overflow-hidden">
    //   {(!campaign.fundingGoal ||
    //     !campaign.images.length ||
    //     !campaign.purchaseOrder ||
    //     !campaign.interestRate) && (
    //     <Link
    //       className="text-decoration-none"
    //       to={`/exporter/campaign/${campaign._id}`}
    //     >
    //       <div
    //         className={`${
    //           windowWidth >= 768
    //             ? "d-grid flex-row"
    //             : "d-flex flex-column gap-3"
    //         } justify-content-between align-items-center campaign-item`}
    //       >
    //         <div className="d-flex flex-column gap-3 campaign-subitem">
    //           <div className="d-flex align-content-center gap-5">
    //             <h6 className="fw-600 text-grey-900">{campaign.title}</h6>
    //             <p className="text-capitalize bg-error-50 text-error-700 fw-600 fs-12 p-1 m-0">
    //               {campaign.status}
    //             </p>
    //           </div>
    //           <p className="text-grey-600 fw-400 fs-12 mb-0">
    //             {campaign.description}
    //           </p>
    //         </div>
    //         <div className="loading-container campaign-subitem">
    //           {/* <ProgressBar
    //           value={getPercentage(
    //             Number(campaign.fundingGoalString),
    //             Number(campaign.amountRaisedString)
    //           )}
    //           width={windowWidth < 768 ? "auto" : ""}
    //         /> */}
    //           {campaign.status === "draft" ? (
    //             <>
    //               <ProgressBar
    //                 value={completionPercentage}
    //                 width={windowWidth < 768 ? "auto" : ""}
    //               />
    //               <div className="text-center text-grey-900">
    //                 <span>{completionPercentage}% Completed</span>
    //               </div>
    //             </>
    //           ) : (
    //             <>
    //               <ProgressBar
    //                 value={getPercentage(
    //                   Number(campaign.fundingGoalString),
    //                   Number(campaign.amountRaisedString)
    //                 )}
    //                 width={windowWidth < 768 ? "auto" : ""}
    //               />
    //               <div
    //                 className={`d-flex flex-row justify-content-between align-items-center gap-3 text-grey-900 fw-400`}
    //               >
    //                 <p
    //                   className={`mb-0 ${windowWidth <= 768 ? "fs-12" : ""}`}
    //                 >{`${"\u20A6"}${campaign.amountRaisedString} Raised`}</p>
    //                 <p
    //                   className={`mb-0 ${windowWidth <= 768 ? "fs-12" : ""}`}
    //                 >{`${"\u20A6"}${Number(
    //                   campaign.fundingGoalString
    //                 ).toLocaleString()}`}</p>
    //               </div>
    //             </>
    //           )}
    //         </div>
    //         {windowWidth >= 768 ? (
    //           <>
    //             <div className="d-flex flex-column gap-2 campaign-subitem">
    //               <h6 className="fw-600 text-grey-900 mb-0">
    //                 {campaign.noOfBackers}
    //               </h6>
    //               <p className="text-grey-600 mb-0">Backers</p>
    //             </div>
    //             <div className="d-flex flex-column gap-2 campaign-subitem">
    //               <h6 className="fw-600 text-grey-900 mb-0">
    //                 {getDaysLeft(campaign.campaignEndDate)}
    //               </h6>
    //               <p className="text-grey-600 mb-0">{`Ends ${formatDate(
    //                 campaign.campaignEndDate
    //               )}`}</p>
    //             </div>
    //           </>
    //         ) : (
    //           <div className="d-flex flex-row w-100 justify-content-start gap-5 align-items-center mt-3">
    //             <div className="d-flex flex-row align-items-center gap-2">
    //               <BackersIcon />
    //               <div className="d-flex flex-column gap-1 campaign-subitem">
    //                 <h6 className="fw-600 text-grey-900 mb-0 fs-10">{10}</h6>
    //                 <p className="text-grey-600 mb-0 fs-10">Backers</p>
    //               </div>
    //             </div>
    //             <div className="d-flex flex-row align-items-center gap-2">
    //               <CalenderIcon />
    //               <div className="d-flex flex-column gap-1 campaign-subitem align-items-start justify-content-start">
    //                 <h6 className="fw-600 text-grey-900 mb-0 fs-10">
    //                   {getDaysLeft(campaign.campaignEndDate)}
    //                 </h6>
    //                 <p className="text-grey-600 mb-0 fs-10">{`Ends ${formatDate(
    //                   campaign.campaignEndDate
    //                 )}`}</p>
    //               </div>
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //     </Link>
    //   )}
    // </div>
    <div
      className='position-relative border border-grey-100 mt-3 rounded-3 overflow-hidden'
      onClick={clickAction}
    >
      <Link
        className='text-decoration-none'
        to={campaign.status !== "draft" ? url ?? `/exporter/campaign/${campaign._id}` : "/exporter/dashboard"}
        onClick={(e: React.SyntheticEvent) => {
          if (campaign.status === "draft") {
            e.preventDefault();
          }
          clickAction && clickAction();
        }}
      >
        <div
          className={`${
            windowWidth >= 768 ? "d-grid flex-row" : "d-flex flex-column gap-3"
          } justify-content-between align-items-center campaign-item`}
        >
          <div className='d-flex flex-column gap-3 campaign-subitem'>
            <div className='d-flex align-items-center gap-3'>
              <h6 className='fw-600 text-grey-900 m-0'>{campaign.title}</h6>
              {/* <p className="text-capitalize bg-error-50 text-error-700 fw-600 fs-12 p-1 m-0">
                {campaign.status}
              </p> */}
              <Badge text={campaign.status} scheme={campaign.status} />
            </div>
            <p className='text-grey-600 fw-400 fs-12 mb-0'>{campaign.description}</p>
          </div>
          <div className='campaign-subitem d-flex justify-center'>
            {campaign.status === "draft" ? (
              <div className={windowWidth < 768 ? "mt-2 d-flex flex-column gap-2" : ""}>
                <ProgressBar
                  colorScheme='red'
                  value={completionPercentage}
                  width={windowWidth < 768 ? "90%" : "100%"}
                />
                <div className={`text-center text-grey-900 ${windowWidth < 768 && "fs-12"}`}>
                  <span>{completionPercentage}% Completed</span>
                </div>
              </div>
            ) : (
              <div className='d-flex flex-column w-330-px gap-3'>
                <ProgressBar
                  colorScheme='green'
                  value={getPercentage(
                    Number(campaign.fundingGoalString),
                    Number(campaign.amountRaisedString)
                  )}
                  width={windowWidth < 768 ? "90%" : "100%"}
                />
                <div
                  className={`d-flex flex-row justify-content-between align-items-center gap-3 text-grey-900 fw-400`}
                >
                  <p className={`mb-0 ${windowWidth <= 768 ? "fs-12" : ""}`}>{`${"\u20A6"}${
                    campaign.amountRaisedString
                  } Raised`}</p>
                  <p className={`mb-0 ${windowWidth <= 768 ? "fs-12" : ""}`}>{`${"\u20A6"}${Number(
                    campaign.fundingGoalString
                  ).toLocaleString()}`}</p>
                </div>
              </div>
            )}
          </div>
          {windowWidth >= 768 ? (
            <>
              <div className='d-flex flex-column justify-content-center align-items-center gap-2 campaign-subitem'>
                <h6 className='fw-600 text-grey-900 mb-0'>{campaign.noOfBackersString ?? 0}</h6>
                <p className='text-grey-600 mb-0'>Backers</p>
              </div>
              <div className='d-flex flex-column gap-2 campaign-subitem'>
                <h6 className='fw-600 text-grey-900 mb-0'>{getDaysLeft(campaign.campaignEndDate)}</h6>
                <p className='text-grey-600 mb-0'>{`Ends ${formatDate(campaign.campaignEndDate)}`}</p>
              </div>
            </>
          ) : (
            <div className='d-flex flex-row w-100 justify-content-start gap-5 align-items-center mt-3'>
              <div className='d-flex flex-row align-items-center gap-2'>
                <BackersIcon />
                <div className='d-flex flex-column gap-1 campaign-subitem'>
                  <h6 className='fw-600 text-grey-900 mb-0 fs-10'>{10}</h6>
                  <p className='text-grey-600 mb-0 fs-10'>Backers</p>
                </div>
              </div>
              <div className='d-flex flex-row align-items-center gap-2'>
                <CalenderIcon />
                <div className='d-flex flex-column gap-1 campaign-subitem align-items-start justify-content-start'>
                  <h6 className='fw-600 text-grey-900 mb-0 fs-10'>{getDaysLeft(campaign.campaignEndDate)}</h6>
                  <p className='text-grey-600 mb-0 fs-10'>{`Ends ${formatDate(campaign.campaignEndDate)}`}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};
