import { Spinner, Stack, StackProps } from "@chakra-ui/react";

interface PrimaryLoaderProps extends StackProps {
  color?: string;
  positioning?: "fixed" | "absolute";
}
export const PrimaryLoader: React.FC<PrimaryLoaderProps> = ({
  color = "teal",
  positioning = "fixed",
  children,
  ...rest
}) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      minHeight={window.innerHeight}
      position={positioning}
      width='calc(100% - 240px)'
      right='0'
      top='60px'
      zIndex={9999}
      backgroundColor={"#f7f7f7"}
      {...rest}
    >
      {children ?? (
        <div>
          <Spinner color={color} />
        </div>
      )}
    </Stack>
  );
};
