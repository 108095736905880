import { ProgressBar } from "components";
import { CampaignItemProps } from "./interface";
import { Link } from "react-router-dom";
import { formatDate, getDaysLeft, getPercentage } from "utilities";
import { CampaignData } from "store/campaigns";
import { useWindowWidth } from "utilities";

export const CampaignItem = ({ campaign, url, onClick }: CampaignItemProps) => {
  const windowWidth = useWindowWidth();

  const formFields: (keyof CampaignData)[] = [
    "fundingGoal",
    "images",
    "purchaseOrder",
    "interestRate",
  ];

  const filledOutFormFields = formFields.filter(
    (field) => field in campaign && campaign[field as keyof CampaignData]
  ).length;
  const completionPercentage = (filledOutFormFields / formFields.length) * 100;

  return (
    <Link
      className="text-decoration-none"
      to={url ?? `/backer/discover/${campaign._id}`}
      onClick={onClick}
    >
      <div className="mt-3  justify-content-between align-items-center border border-grey-100 campaign-item">
        <div className="d-flex flex-column gap-3 campaign-subitem">
          <div className="d-flex gap-3 align-items-center">
            <h6 className="fw-600 text-grey-900 mb-0">{campaign.title}</h6>
            {!campaign.hasBeenReviewed ? (
              <span className="px-2 py-1 rounded-5 bg-warning-50 text-warning-700 fw-500 fs-12 lh-1">
                Under Review
              </span>
            ) : null}
          </div>
          <p className="text-grey-600 fw-400 fs-12 mb-0">
            {campaign.description}
          </p>
        </div>
        <div className="loading-container campaign-subitem">
          {campaign.status === "draft" ? (
            <>
              <ProgressBar
                value={completionPercentage}
                width={windowWidth < 768 ? "auto" : ""}
              />
              <div className="text-center">
                <span className="text-black">
                  {completionPercentage}% Completed
                </span>
              </div>
            </>
          ) : (
            <>
              <ProgressBar
                value={getPercentage(
                  Number(campaign.fundingGoalString),
                  Number(campaign.amountRaisedString)
                )}
                width={windowWidth < 768 ? "auto" : ""}
              />
              <div
                className={`d-flex flex-row justify-content-between align-items-center gap-3 text-grey-900 fw-400`}
              >
                <p
                  className={`mb-0 ${windowWidth <= 768 ? "fs-12" : ""}`}
                >{`${"\u20A6"}${campaign.amountRaisedString} Raised`}</p>
                <p
                  className={`mb-0 ${windowWidth <= 768 ? "fs-12" : ""}`}
                >{`${"\u20A6"}${Number(
                  campaign.fundingGoalString
                ).toLocaleString()}`}</p>
              </div>
            </>
          )}
        </div>
        <div className="d-flex flex-column gap-2 justify-content-center align-items-center campaign-subitem">
          <h6 className="fw-600 text-grey-900 mb-0">
            {campaign.backers?.length ?? 0}
          </h6>
          <p className="text-grey-600 mb-0">Backers</p>
        </div>
        <div className="d-flex flex-column gap-2 campaign-subitem">
          <h6 className="fw-600 text-grey-900 mb-0">
            {getDaysLeft(campaign.campaignEndDate)}
          </h6>
          <p className="text-grey-600 mb-0">{`Ends ${formatDate(
            campaign.campaignEndDate
          )}`}</p>
        </div>
      </div>
    </Link>
  );
};
