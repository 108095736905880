import React, { FC, useEffect, useState } from "react";
import {
  PrimaryInput,
  PrimarySelect,
  PrimaryButton,
  PrimaryMultiSelect,
  SimpleFileUploadComponent,
} from "components";
import { useFormik } from "formik";
import { CreateBusinessDetailsValidationSchema } from "validations";
import { UpdateProfilePayload, useUpdateProfileMutation } from "store/profile";
import { User, setCredential, useAuth } from "store/auth";
import { resolveApiError } from "utilities/errorHandling";
import { usePageNotificationProvider, useModalProvider } from "providers";
import { useDispatch } from "react-redux";
import { useWindowWidth } from "utilities/windowWidth";
import { countries } from "country-list-json";

export type BusinessInformationFormProps = {
  previous: () => void;
  initialProfile: User | null | any;
};

export const BusinessInformationForm: FC<BusinessInformationFormProps> = ({
  previous,
  initialProfile,
}) => {
  const windowWidth = useWindowWidth();
  const [selectedCountries, setSelectedCountries] = useState<any>([]);
  const { user, token: access_token } = useAuth();
  const dispatch = useDispatch();
  const [request, { isLoading }] = useUpdateProfileMutation();
  const { initNotification } = usePageNotificationProvider();
  const { initModal } = useModalProvider();
  const bizInfo =
    user?.businessInformation ?? initialProfile?.businessInformation;

  const initialValues = {
    exportGoodType: (bizInfo?.exportGoodType as string) ?? "",
    TIN: (bizInfo?.TIN as string) ?? "",
    yearsInExport: (bizInfo?.yearsInExport as string) ?? "",
    exportFrequency: (bizInfo?.exportFrequency as string) ?? "",
    countriesOfExport: (bizInfo?.countriesOfExport as string[]) ?? [],
    businessName: (bizInfo?.businessName as string) ?? "",
    CACRegNo: (bizInfo?.CACRegNo as string) ?? "",
    // exportRegCertificateUrl: (bizInfo?.exportRegCertificateUrl as string) ?? "",
  };

  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: CreateBusinessDetailsValidationSchema,
    onSubmit: () => initRequest(),
  });

  // useEffect(() => {
  //   setValues((prev) => {
  //     return {
  //       ...prev,
  //       exportRegCertificateUrl: initialValues.exportRegCertificateUrl,
  //     };
  //   });
  // }, [initialValues]);

  const initRequest = () => {
    request({
      businessInformation: {
        ...values,
      },
    })
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        initNotification({
          message: "Saved",
        });
        dispatch(
          setCredential({
            user: res.data,
            access_token,
          })
        );
        initModal({ profileCompleted: true });
      })
      .catch((error) => {
        console.log(error);
        if (error.data.statusCode === 403) initModal({ sessionExpired: true });
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  // const countries = [
  //   { label: "Mexico", value: "mexico" },
  //   { label: "Ghana", value: "ghana" },
  //   { label: "Canada", value: "canada" },
  // ];

  console.log(countries);

  return (
    <section
      className={`container-fluid ${
        windowWidth >= 768 ? "py-5" : "pt-3 pb-5"
      } bg-transparent`}
    >
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit} className="">
            {windowWidth <= 768 && (
              <>
                <h6 className="text-grey-900 fs-20 fw-semibold">
                  Business Information
                </h6>
                <p className="mb-4 text-grey-400">
                  Enter in Business information
                </p>
              </>
            )}
            <div className={`row mb-4 ${windowWidth <= 768 && "w-90"}`}>
              <div className="col-12 mb-4">
                <PrimaryInput
                  type="text"
                  label="Business Name*"
                  name="businessName"
                  placeholder="Enter your business name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.businessName}
                  error={Boolean(errors?.businessName && touched?.businessName)}
                  bottomText={errors?.businessName ?? ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                />
              </div>
              <div className="col-12 mb-4">
                <PrimaryInput
                  type="text"
                  label="What type of goods do you export?*"
                  name="exportGoodType"
                  placeholder="Enter type of goods"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.exportGoodType}
                  error={Boolean(
                    errors?.exportGoodType && touched?.exportGoodType
                  )}
                  bottomText={errors?.exportGoodType ?? ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                />
              </div>
              <div className="col-12 mb-4">
                <PrimaryInput
                  type="text"
                  label="Tax Identification Number(TIN)*"
                  name="TIN"
                  placeholder="Enter Your TIN"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.TIN}
                  error={Boolean(errors?.TIN && touched?.TIN)}
                  bottomText={errors?.TIN ?? ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                />
              </div>
              <div className="col-12 mb-4">
                <PrimaryInput
                  type="text"
                  label="Enter your CAC registration number*"
                  name="CACRegNo"
                  placeholder="Eg. BN-1234567"
                  onChange={(e: any) => {
                    var val = e.target.value;
                    if (
                      val.length === 2 &&
                      !(e.nativeEvent.inputType === "deleteContentBackward")
                    ) {
                      val += "-";
                    }
                    handleChange(e);
                    setFieldValue("CACRegNo", val);
                  }}
                  onBlur={handleBlur}
                  value={values.CACRegNo}
                  error={Boolean(errors?.CACRegNo && touched?.CACRegNo)}
                  bottomText={errors?.CACRegNo ?? ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                />
              </div>
              <div className="col-12 mb-4">
                <PrimarySelect
                  name="yearsInExport"
                  label="How long have you been exporting?*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.yearsInExport}
                  error={Boolean(
                    errors?.yearsInExport && touched?.yearsInExport
                  )}
                  bottomText={errors?.yearsInExport ?? ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                >
                  <option hidden>Select option</option>
                  <option value="1-2 years">1-2 years</option>
                  <option value="3-5 years">3-5 years</option>
                  <option value="6-10 years">6-10 years</option>
                  <option value="10+ years">10+ years</option>
                </PrimarySelect>
              </div>
              <div className="col-12 mb-4">
                <PrimarySelect
                  name="exportFrequency"
                  label="What is the frequency of your annual export operation?*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.exportFrequency}
                  error={Boolean(
                    errors?.exportFrequency && touched?.exportFrequency
                  )}
                  bottomText={errors?.exportFrequency ?? ""}
                  bg={windowWidth <= 768 ? "white" : ""}
                >
                  <option hidden>Select option</option>
                  <option value="1-2 times">1-2 times</option>
                  <option value="3-5 times">3-5 times</option>
                  <option value="more than 5 times">More than 5 times</option>
                </PrimarySelect>
              </div>
              <div className="col-12 mb-4">
                <PrimaryMultiSelect
                  name="countriesOfExport"
                  label="Which country(s) do you export to?*"
                  error={Boolean(
                    errors?.countriesOfExport && touched?.countriesOfExport
                  )}
                  // bottomText={errors?.countriesOfExport ?? ""}
                  isMulti={true}
                  options={countries.map((c) => ({
                    label: c.name,
                    value: c.name,
                  }))}
                  onMultiChange={(value) => {
                    console.log(value, "oodkdkdkdkd");
                    // setSelectedCountries(value.map((val) => val.value))
                    setValues((prev) => {
                      return {
                        ...prev,
                        countriesOfExport: value.map((val) => val.value),
                      };
                    });
                  }}
                  value={countries
                    .filter((country) =>
                      values.countriesOfExport?.includes(country.name)
                    )
                    .map((c) => ({
                      label: c.name,
                      value: c.name,
                    }))}
                />
              </div>
              <div className="col-12 mb-4">
                <SimpleFileUploadComponent
                  fileClass="exportCertificate"
                  fileName={bizInfo?.exportRegCertificateUrl ?? ""}
                  label="Upload Export Registration Certificate*"
                  bottomText="File Format must be in JPG, JPEG, PNG, PDF (max. 15mb)"
                  category="profile"
                  allowedFIleType="application/pdf,  image/jpg, image/jpeg, image/png"
                />
              </div>
            </div>

            <div>
              {windowWidth >= 768 && (
                <PrimaryButton
                  type="button"
                  onClick={previous}
                  className="btn-lg secondary-btn mt-3 me-3 align-self-baseline"
                >
                  Back
                </PrimaryButton>
              )}
              <PrimaryButton
                type="submit"
                className="btn-lg primary-btn mt-3 align-self-baseline"
                isLoading={isLoading}
              >
                Submit
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
