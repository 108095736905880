import { useWindowWidth } from "utilities/windowWidth";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronRight } from "assets/svg/chevron-right.svg";
import { ReactComponent as FundWalletIcon } from "assets/svg/fund-wallet-icon.svg";
import { Box, VStack, Flex } from "@chakra-ui/react";

export default function RecentActivity() {
  const windowWidth = useWindowWidth();
  return (
    <Box rounded={"8px"} p={"24px"} className='border border-grey-100 mb-5'>
      <h5 className={`${windowWidth <= 768 ? "fs-16 mb-4" : "fs-20 mb-3"} fw-600`}>Recent Activity</h5>
      <Flex direction={windowWidth >= 768 ? "row" : "column"} gap={"40px"}>
        <VStack gap={"24px"} w={"550px"}>
          <Link
            to=''
            className='d-flex flex-row align-items-center gap-4 p-4 border border-grey-100 campaign-item w-100'
            style={{ height: "88px" }}
          >
            <Box className='h-100'>
              <FundWalletIcon />
            </Box>
            <div className='d-flex flex-column gap-1'>
              <p className='fs-14 fw-400 mb-0'>Ayodeji Balogun submitted a new campaign for review </p>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>3 mins ago</p>
            </div>
          </Link>
          <Link
            to=''
            className='d-flex flex-row align-items-center gap-4 p-4 border border-grey-100 campaign-item w-100'
            style={{ height: "88px" }}
          >
            <Box className='h-100'>
              <FundWalletIcon />
            </Box>
            <div className='d-flex flex-column gap-1'>
              <p className='fs-14 fw-400 mb-0'>Damini posted a comment on Dry Sunflower Export Campaign </p>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>2 hours ago</p>
            </div>
          </Link>
          <Link
            to=''
            className='d-flex flex-row align-items-center gap-4 p-4 border border-grey-100 campaign-item w-100'
            style={{ height: "88px" }}
          >
            <Box className='h-100'>
              <FundWalletIcon />
            </Box>
            <div className='d-flex flex-column gap-1'>
              <p className='fs-14 fw-400 mb-0'>David Adeleke signed up as a Backer </p>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>14 hours ago</p>
            </div>
          </Link>
          <Link
            to=''
            className='d-flex flex-row align-items-center gap-4 p-4 border border-grey-100 campaign-item w-100'
            style={{ height: "88px" }}
          >
            <Box className='h-100'>
              <FundWalletIcon />
            </Box>
            <div className='d-flex flex-column gap-1'>
              <p className='fs-14 fw-400 mb-0'>Michael submitted KYC information for verification</p>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>15 hours ago</p>
            </div>
          </Link>
          <Link
            to=''
            className='d-flex flex-row align-items-center gap-4 p-4 border border-grey-100 campaign-item w-100'
            style={{ height: "88px" }}
          >
            <Box className='h-100'>
              <FundWalletIcon />
            </Box>
            <div className='d-flex flex-column gap-1'>
              <p className='fs-14 fw-400 mb-0'>Olamide requested a payout</p>
              <p className='text-grey-400 fs-14 fw-400 mb-0'>17 hours ago</p>
            </div>
          </Link>
        </VStack>
      </Flex>
    </Box>
  );
}
