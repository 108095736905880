import { Link, useLocation, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from "assets/svg/chevron-left.svg";
import { ReactComponent as Logo } from "assets/svg/krowdfi-logo.svg";

import { ReactComponent as AlertIcon } from "assets/svg/alert.svg";
import ProfileIcon from "assets/svg/profile-circle.svg";
import { useGetProfileInformationQuery } from "store/profile";
import { setCredential, useAuth, useLoginMutation } from "store/auth";
import { PrimaryButton } from "components/buttons";

import {
  AccountInfoValidationSchema,
  LoginValidationSchema,
} from "validations/auth";
import { useFormik } from "formik";
import { AlertDefaultBox, CustomPasswordInput } from "components/custom";
import { useModalProvider } from "providers/modalProvider";
import queryString from "query-string";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { resolveApiError } from "utilities/errorHandling";
import { useDispatch } from "react-redux";
import {
  useGetPaymentRequestStatusQuery,
  useRequestPaymentMutation,
  useUpdatePaymentRequestMutation,
} from "store/campaigns";

export const RequestPaymentVerification = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const bankDetails = queryString.parse(location.search);
  const { initModal } = useModalProvider();
  const { initNotification } = usePageNotificationProvider();
  const [requestPayment, { isLoading: loading }] = useRequestPaymentMutation();
  const [updateRequestPayment, { isLoading: loadingUpdates }] =
    useUpdatePaymentRequestMutation();

  const { user } = useAuth();
  const { data: paymentRequestStatus } = useGetPaymentRequestStatusQuery({
    skip: "",
    limit: "",
    sort: "",
    exporterId: user?._id,
  });

  console.log(paymentRequestStatus);

  const { data: profileInfo } = useGetProfileInformationQuery((() => {})(), {
    pollingInterval: 30000,
  });

  const profile = profileInfo?.data || user;

  const profileImage = user?.image ?? profileInfo?.data.image ?? ProfileIcon;
  const paymentStatus = paymentRequestStatus?.data[0]?.requestPaymentStatus;

  const [request, { isLoading }] = useLoginMutation();
  const {
    values,
    errors,
    setValues,
    resetForm,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: { password: "", email: user?.email as string },
    validationSchema: LoginValidationSchema,
    onSubmit: () => confirmPassword(),
  });

  const initRequestPayment = () => {
    requestPayment({
      campaignId: campaignId,
      data: {
        ...bankDetails,
      },
    })
      .unwrap()
      .then((res: any) => {
        // console.log(res);
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        // resetForm();
        initNotification({
          message: "Payment request successful",
        });
        initModal((prev) => ({
          ...prev,
          paymentSubmitted: true,
        }));
        // setTimeout(() => {
        //   queryParams.set("id", res?.data?._id);
        //   navigate({ search: queryParams.toString() });
        // }, 500);
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  const updatePayment = () => {
    updateRequestPayment({
      campaignId: campaignId,
      data: {
        ...bankDetails,
      },
    })
      .unwrap()
      .then((res: any) => {
        // console.log(res);
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });
          return;
        }

        // resetForm();
        initNotification({
          message: "Payment request Updated successful",
        });
        initModal((prev) => ({
          ...prev,
          paymentSubmitted: true,
        }));
        // setTimeout(() => {
        //   queryParams.set("id", res?.data?._id);
        //   navigate({ search: queryParams.toString() });
        // }, 500);
      })
      .catch((error: any) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  const confirmPassword = () => {
    request(values)
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          initNotification({
            message: res.message,
            scheme: "error",
          });

          return;
        }

        dispatch(
          setCredential({
            user: res.data.user,
            access_token: res.data.token,
            refresh_token: res.data.refreshToken,
          })
        );
        if (paymentStatus === "rejected") {
          updatePayment();
        } else {
          initRequestPayment();
        }

        resetForm();
        // setValues((prev) => {
        //   return { ...prev, password: "" };
        // });
      })
      .catch((error) => {
        console.log(error);
        initNotification({ message: resolveApiError(error), scheme: "error" });
      });
  };

  console.log(bankDetails);

  return (
    <section className="pb-5">
      <div className="d-flex justify-content-between align-items-center py-5 px-5">
        <div className="d-flex gap-2 align-items-center ">
          <BackIcon />
          <Link
            className="text-primary-600 fs-14 fw-400"
            to={`/exporter/campaign/${campaignId}`}
          >
            Return to Campaign
          </Link>
        </div>

        <Link to="/">
          <Logo />
        </Link>

        <div className="d-flex gap-3 align-items-center">
          <img
            src={profileImage}
            alt={"Avatar"}
            className="rounded rounded-circle"
            style={{
              width: 48,
              height: 48,
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
          <h6 className="mb-0 text-grey-900">
            {profile?.firstName} {profile?.lastName}
          </h6>
        </div>
      </div>

      <div className="d-flex flex-column  mx-auto  border rounded-4  w-35 p-5">
        <div>
          <h3 className="fw-600 fs-24">Enter your Krowdfi Password</h3>
        </div>
        <hr />
        <div className="d-flex justify-content-center pt-2">
          <div className="pt-3">
            <form onSubmit={handleSubmit}>
              <AlertDefaultBox
                scheme="warning"
                icon={<AlertIcon />}
                body="Payments will be locked after 3 failed attempts"
              />

              <div className="mt-4">
                <CustomPasswordInput
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={Boolean(errors?.password && touched.password)}
                />
              </div>
              <p className="mt-3">
                <Link
                  to="/forgot-password"
                  className="text-primary-600 text-decoration-none fs-14 fw-400"
                >
                  Forgot Password?
                </Link>
              </p>

              <PrimaryButton
                type="submit"
                isDisabled={loading || isLoading || loadingUpdates}
                isLoading={loading || isLoading || loadingUpdates}
                className="btn-lg primary-btn mt-3 px-4"
              >
                Verify
              </PrimaryButton>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
