import { Text, Flex, Box } from "@chakra-ui/react";
import { PrimaryButton } from "components/buttons";
import { Link } from "react-router-dom";
import { redirectTo } from "utilities/general";
import { useWindowWidth } from "utilities/windowWidth";

export const PaymentInformation = ({ paymentInfo }: { paymentInfo?: any }) => {
  const windowWidth = useWindowWidth();
  return (
    <>
      {!paymentInfo && (
        <Flex
          direction={"column"}
          gap={"16px"}
          justifyContent={"center"}
          alignItems={"center"}
          className="border border-grey-100"
          py={ windowWidth >= 768 ? "54px" : "24px"}
          px={ windowWidth >= 768 ? "28px" : "75px"}
          maxW={ windowWidth >= 768 ? "290px" : windowWidth}
          h={ windowWidth >= 768 ? "215px" : "fit-content" }
          borderRadius={"8px"}
        >
          <Text align={"center"} className="text-grey-400 fs-14">
            Provide your bank details for easy funds disbursal{" "}
          </Text>
          <PrimaryButton
            className="btn-lg primary-btn"
            maxW={"200px"}
            onClick={() => {
              redirectTo("/exporter/transactions/payment-info");
            }}
          >
            Add Payment Information
          </PrimaryButton>
        </Flex>
      )}
      {paymentInfo && (
        <Flex
          direction={"column"}
          gap={"16px"}
          className="border border-grey-100"
          p={"24px"}
          w={ windowWidth >= 768 ? "300px" : ""}
          borderRadius={"8px"}
          h={ windowWidth >= 768 ? "388px" : ""}
        >
          <h4 className={`fw-600 mt-1 ${ windowWidth <= 768 && 'fs-14' } `}>Payment Information</h4>
          <Box className={ windowWidth <= 768 ? 'd-flex flex-row justify-content-between' : '' }>
            <Box>
              <Text className={`text-grey-400 ${ windowWidth >= 768 ? 'fs-14' : 'fs-12' }`}>Bank</Text>
              <Text className={ windowWidth >= 768 ? 'fs-14' : 'fs-12' }>{paymentInfo.bank}</Text>
            </Box>
            <Box>
              <Text className={`text-grey-400 ${ windowWidth >= 768 ? 'fs-14' : 'fs-12' }`}>Account Number</Text>
              <Text className={ windowWidth >= 768 ? 'fs-14' : 'fs-12' }>{paymentInfo.accountNumber}</Text>
            </Box>
            <Box>
              <Text className={`text-grey-400 ${ windowWidth >= 768 ? 'fs-14' : 'fs-12' }`}>Account Name</Text>
              <Text className={ windowWidth >= 768 ? 'fs-14' : 'fs-12' }>{paymentInfo.accountName}</Text>
            </Box>
          </Box>
          <PrimaryButton className={`secondary-btn ${ windowWidth >= 768 ? 'mt-3' : 'mt-1 fs-14' } `} width={"220px"}>
            <Link to="/exporter/transactions/update-payment-info">
              Update Payment Information
            </Link>
          </PrimaryButton>
        </Flex>
      )}
    </>
  );
};
